import { FC } from 'react';
import { StatusLabel } from 'ui/StatusLabel';
import {
  TransactionStatusLabelSize,
  TransactionStatusLabelType,
} from './const';
import {
  getMapTypeToText,
  mapSizeToStatusLabelSize,
  mapTypeToStatusLabelType,
} from './helpers';

interface TransactionStatusLabelProps {
  type: TransactionStatusLabelType;
  size?: TransactionStatusLabelSize;
}

export const TransactionStatusLabel: FC<TransactionStatusLabelProps> = ({
  type,
  size = TransactionStatusLabelSize.Large,
}) => (
  <StatusLabel
    type={mapTypeToStatusLabelType[type]}
    size={mapSizeToStatusLabelSize[size]}
  >
    {getMapTypeToText(type)}
  </StatusLabel>
);
