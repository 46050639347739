import { FC, useCallback, useEffect, useState } from 'react';
import { Button, ButtonSize, ButtonTheme } from 'ui/Button';
import { ReactComponent as ArrowUpIcon } from 'assets/icons/line/AltArrowUp.svg';

const SCROLL_THRESHOLD = 100;

interface ScrollToTopButtonProps {
  scrollableElement: HTMLElement;
}

export const ScrollToTopButton: FC<ScrollToTopButtonProps> = ({
  scrollableElement,
}) => {
  const [shouldRender, setShouldRender] = useState(false);

  const handleClick = () => {
    scrollableElement.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleScroll = useCallback(() => {
    const { scrollTop } = scrollableElement;

    setShouldRender(scrollTop > SCROLL_THRESHOLD);
  }, [scrollableElement]);

  useEffect(() => {
    handleScroll();

    const eventListenerElement =
      scrollableElement === document.documentElement ||
      scrollableElement === document.body
        ? document
        : scrollableElement;

    eventListenerElement.addEventListener('scroll', handleScroll);

    return () => {
      eventListenerElement.removeEventListener('scroll', handleScroll);
    };
  }, [scrollableElement, handleScroll]);

  if (!shouldRender) {
    return null;
  }

  return (
    <Button
      size={ButtonSize.Medium}
      theme={ButtonTheme.BlackTonal}
      iconConfig={{
        iconComponent: ArrowUpIcon,
      }}
      onClick={handleClick}
    />
  );
};
