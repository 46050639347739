import { useMemo } from 'react';
import { useAuth } from 'components/auth';
import { PartnerReferralLinkMediaItemsFilterType } from 'types/generated/gql';
import { FilterType, UserTypeName } from 'types';
import { ReferralLinkMediaItemBasicData } from '../../../../../../../queries/fragments/generated/ReferralLinkMediaItemBasicData';
import { MEDIA_ITEMS_PER_PAGE } from '../const';
import { useGetPartnerReferralLinkMediaItems } from '../queries/generated/GetPartnerReferralLinkMediaItems';

interface UseGetPartnerReferralLinkMediaItemsQueryParams {
  searchQuery?: string;
  filters: Array<FilterType<PartnerReferralLinkMediaItemsFilterType>>;
}

export const useGetPartnerReferralLinkMediaItemsQuery = ({
  searchQuery,
  filters,
}: UseGetPartnerReferralLinkMediaItemsQueryParams) => {
  const { user } = useAuth();

  const { data, fetchMore, error, loading, isSliced } =
    useGetPartnerReferralLinkMediaItems({
      variables: {
        userId: user.id,
        first: MEDIA_ITEMS_PER_PAGE,
        filters,
        ...(Boolean(searchQuery) && { referralLinkSearch: searchQuery }),
      },
      notifyOnNetworkStatusChange: true,
    });

  const referralLinkMediaItemsConnection =
    data?.user.__typename === UserTypeName.Partner
      ? data?.user.referralLinkMediaItems
      : undefined;

  const referralLinkMediaItems:
    | Array<ReferralLinkMediaItemBasicData>
    | undefined = useMemo(
    () => referralLinkMediaItemsConnection?.edges.map((item) => item.node),
    [referralLinkMediaItemsConnection?.edges]
  );

  const pageInfo = referralLinkMediaItemsConnection?.pageInfo;

  return {
    referralLinkMediaItems,
    pageInfo,
    fetchMore,
    isSliced,
    error,
    loading,
  };
};
