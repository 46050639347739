import { Currency } from 'types';
import { Icon, IconSize } from 'ui/Icon';
import { mapCurrencyToIcon } from 'utils/currency/mapCurrencyToIcon';
import { DropdownValue } from '../types';

export const getDropdownStateByCurrency = (
  currencyCode: Currency
): DropdownValue<Currency> => {
  const currencyIcon = mapCurrencyToIcon[currencyCode];

  return {
    value: currencyCode,
    label: currencyCode,
    icon: currencyIcon && (
      <Icon iconComponent={currencyIcon} size={IconSize.Small} />
    ),
  };
};
