import { useCallback, useEffect } from 'react';
import { DateRange, NonNullableDateRange } from 'types';
import { checkIsDateRangeNonNullable } from 'helpers';
import { useSearchParams } from 'react-router-dom';
import { extractDateRangeFromSearchParams } from 'hooks/useDateRangePageParams/helpers';
import { DateRangePageParam } from 'hooks/useDateRangePageParams/const';
import { DateFormat, formatDate } from 'utils/formatDate';

const { DateScalar } = DateFormat;

interface UseDateRangePageParamsProps {
  defaultDateRange?: DateRange;
}

export const useDateRangePageParams = ({
  defaultDateRange,
}: UseDateRangePageParamsProps = {}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dateRange = extractDateRangeFromSearchParams(searchParams);

  const setDateRange = useCallback(
    (range: NonNullableDateRange | undefined) => {
      if (!range) {
        return;
      }

      const [startDate, endDate] = range;

      setSearchParams(
        (params) => {
          params.set(
            DateRangePageParam.Start,
            formatDate(startDate, DateScalar)
          );
          params.set(DateRangePageParam.End, formatDate(endDate, DateScalar));

          return params;
        },
        { replace: true }
      );
    },
    [setSearchParams]
  );

  useEffect(() => {
    if (dateRange || !defaultDateRange) {
      return;
    }

    if (checkIsDateRangeNonNullable(defaultDateRange)) {
      setDateRange(defaultDateRange);
    }
  }, [dateRange, defaultDateRange, setDateRange]);

  return {
    dateRange,
    setDateRange,
    loading: !dateRange,
  };
};
