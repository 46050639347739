import { ListItem } from 'ui/List';
import { Currency } from 'types';
import { Language } from 'types/generated/gql';
import { getCurrencyDisplayName } from 'utils/currency/getCurrencyDisplayName';
import { getCurrencyNameWithSymbol } from 'utils/currency/getCurrencyNameWithSymbol';
import { mapCurrencyToIcon } from 'utils/currency/mapCurrencyToIcon';
import { Icon } from 'ui/Icon';

interface GetListItemsByCurrenciesParams {
  onClick: (value?: Currency) => void;
  language: Language;
  availableCurrencies?: Array<Currency | null>;
}

export const getListItemsByCurrenciesForCurrencyDropdown = ({
  onClick,
  availableCurrencies,
  language,
}: GetListItemsByCurrenciesParams): Array<ListItem<Currency>> => {
  if (!availableCurrencies?.length) {
    return [];
  }

  return availableCurrencies
    .filter((currency): currency is Currency => currency !== null)
    .map((currency) => {
      const currencyIcon = mapCurrencyToIcon[currency];

      return {
        label: getCurrencyDisplayName({ currencyCode: currency, language }),
        labelRight: getCurrencyNameWithSymbol(currency),
        leftElement: currencyIcon && <Icon iconComponent={currencyIcon} />,
        value: currency,
        onClick,
      };
    });
};
