import { t } from 'i18n';
import { TranslationUserType } from 'types';
import { PostbackMethod } from 'types/generated/gql';

export const mapMethodToName = (
  method: PostbackMethod,
  translationUserType: TranslationUserType
): string => {
  const map: Record<PostbackMethod, string> = {
    [PostbackMethod.Get]: t(
      `media:${translationUserType}.content.drawer.details.postback.card.input.value.postbackMethods.get`
    ),
    [PostbackMethod.Post]: t(
      `media:${translationUserType}.content.drawer.details.postback.card.input.value.postbackMethods.post`
    ),
  };

  return map[method];
};
