import { FC } from 'react';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSearchHandler } from 'hooks/table/useSearchHandler';
import { useTranslationUserType } from 'hooks/useTranslationUserType';
import { SearchInput } from 'ui/SearchInput';
import { Currency } from 'types';
import { FieldName } from './const';
import { RevShareStatisticDetailedTableSearchValues } from './types';
import styles from './RevShareStatisticDetailedTableSearch.module.scss';
import { RevShareStatisticDetailedCurrencySetting } from '../RevShareStatisticDetailedCurrencySetting';

interface RevShareStatisticDetailedTableSearchProps {
  updateCurrency: (currency: Currency) => void;
  initialValues?: RevShareStatisticDetailedTableSearchValues;
  disabled?: boolean;
}

export const RevShareStatisticDetailedTableSearch: FC<
  RevShareStatisticDetailedTableSearchProps
> = ({
  initialValues = {
    [FieldName.Search]: '',
    [FieldName.Currency]: Currency.USD,
  },
  updateCurrency,
  disabled = false,
}) => {
  const { t } = useTranslation('statistic');

  const translationUserType = useTranslationUserType();

  const { onSearchChange } = useSearchHandler();

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={({ search }) => onSearchChange(search)}
    >
      {({ values, handleChange, handleSubmit, setFieldValue }) => (
        <Form className={styles.form}>
          <SearchInput
            value={values.search}
            fullWidth
            filterButtonText={t(
              `${translationUserType}.action.filters.button.filtersAndSettings`
            )}
            onChange={(e) => {
              handleChange(e);
              handleSubmit();
            }}
            onClear={() => {
              setFieldValue(FieldName.Search, '');
              handleSubmit();
            }}
            settings={
              <div className={styles.settingsWrapper}>
                <RevShareStatisticDetailedCurrencySetting
                  currentCurrency={values.currency}
                  onChange={(currency: Currency) => {
                    setFieldValue(FieldName.Currency, currency);
                    updateCurrency(currency);
                  }}
                />
              </div>
            }
            name={FieldName.Search}
            placeholder={t(`${translationUserType}.action.search.label.search`)}
            disabled={disabled}
          />
        </Form>
      )}
    </Formik>
  );
};
