import { FC, useEffect, useMemo, useRef, useState } from 'react';
import ReactSelect, { InputActionMeta, SingleValue } from 'react-select';
import { useField } from 'formik';
import SelectType from 'react-select/dist/declarations/src/Select';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { IconComponent } from 'types';
import { Breakpoints } from 'const';
import { getMinimalUnderBreakpoint } from 'helpers';
import {
  FormValidationWrapper,
  FormValidationWrapperProps,
} from '../FormValidationWrapper/FormValidationWrapper';
import { FormLabelWrapper } from '../FormLabelWrapper/FormLabelWrapper';
import styles from './Select.module.scss';
import type { SelectOption } from './type';
import { Option } from './components/Option/Option';
import { Control } from './components/Control/Control';
import { Menu } from './components/Menu/Menu';
import { MenuList } from './components/MenuList/MenuList';
import { IndicatorsContainer } from './components/indicatorsContainer/indicatorsContainer';
import { DropdownIndicator } from './components/DropdownIndicator/DropdownIndicator';
import { Input } from './components/Input';
import { NoOptionsMessage } from './components/NoOptionsMessage';
import { InputIcon } from '../InputIcon/InputIcon';
import { useClearValueIfOptionsChanged } from './hooks/useClearValueIfOptionsChanged';
import { SearchMode } from './const';

interface SelectProps {
  name: string;
  label: string;
  wrapperClassName?: string;
  options?: Array<SelectOption>;
  disabled?: boolean;
  leftIcon?: IconComponent;
  rightIcon?: IconComponent;
  formValidationProps?: Omit<FormValidationWrapperProps, 'name'>;
  hasMinWidth?: boolean;
  /**
   * Defines the search mode for the select component:
   * - 'default': applies standard search functionality provided by the react-select library.
   * - 'custom': enables input for typing, allowing custom handling with the onInputChange prop.
   * - undefined: disables search functionality.
   */
  searchMode?: SearchMode;
  isLoading?: boolean;
  isLoadingError?: boolean;
  loadingErrorCallback?: () => void;
  onScrollToBottom?: () => void;
  onInputChange?: (inputValue: string) => void;
  onChange?: (value: string | null) => void;
  isOpen?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
}

export const Select: FC<SelectProps> = ({
  name,
  label,
  wrapperClassName,
  options,
  disabled,
  leftIcon,
  rightIcon,
  formValidationProps,
  hasMinWidth = true,
  searchMode,
  isLoading,
  isLoadingError,
  loadingErrorCallback,
  onScrollToBottom,
  onInputChange,
  onChange,
  isOpen,
  onFocus,
  onBlur,
}) => {
  const { t } = useTranslation();

  const selectRef = useRef<SelectType<SelectOption>>(null);
  const [inputValue, setInputValue] = useState<string>('');
  const [inputValueSave, setInputValueSave] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(isOpen);

  const [field, meta, helpers] = useField<string | null>(name);
  const { error } = meta;
  const { setValue, setError } = helpers;

  const [searchField, searchMeta, searchHelpers] = useField<string | null>(
    'search'
  );

  const isMobile = getMinimalUnderBreakpoint() === Breakpoints.Sm;
  const isTablet = getMinimalUnderBreakpoint() === Breakpoints.Md;

  const selectedOption = useMemo(
    () => options?.find((option) => field.value === option.value),
    [field.value, options]
  );

  const isSearchable = !!searchMode;

  const shouldHideNativeInput = !isMobile && !isTablet && isSearchable;
  const shouldShowSearchInput = (isMobile || isTablet) && isSearchable;

  const selectTextInInput = () => {
    if (!isMobile && !isTablet) {
      setTimeout(() => {
        selectRef.current?.inputRef?.select();
      }, 0);
    }
  };

  const handleChange = (option: SingleValue<SelectOption>) => {
    if (!option) {
      return;
    }

    setValue(option.value);
    onChange?.(option.value);
  };

  const handleInputChange = (value: string, actionMeta: InputActionMeta) => {
    setInputValue(value);

    if (actionMeta.action === 'input-change') {
      onInputChange?.(value);
    }
  };

  const handleMenuClose = () => {
    setInputValueSave(inputValueSave || inputValue);

    if (!isMobile && !isTablet) {
      setIsMenuOpen(false);
    }
  };

  const onAfterModalClose = () => {
    setInputValueSave(inputValueSave || inputValue);
    setIsMenuOpen(false);
    selectRef.current?.blur();
  };

  const handleMenuOpen = () => {
    setInputValue(inputValueSave);
    setInputValueSave('');
    setError(undefined);

    if (isMobile || isTablet) {
      searchHelpers.setValue('');
    }

    selectTextInInput();
    setIsMenuOpen(true);
  };

  const handleFocus = () => {
    handleMenuOpen();

    onFocus?.();
  };

  useClearValueIfOptionsChanged({
    formikHelpers: helpers,
    options,
    selectedOption,
    formikValue: field.value,
  });

  /**
   * If you do the following steps:
   *
   * 1. Choose a value in the select (formik.value changes)
   * 2. Delete the value from the select (formik.value turns undefined)
   * The ReactSelect value won't reset. This code syncs the select state with formik's state.
   */
  useEffect(() => {
    if (!selectRef.current || field.value) {
      return;
    }

    selectRef.current.clearValue();
  }, [field.value]);

  return (
    <FormValidationWrapper {...formValidationProps} name={name}>
      <FormLabelWrapper
        isEmpty={
          !inputValue && (field.value === undefined || field.value === '')
        }
        isError={!!error}
        label={label}
        reduceLabelOnFocus={shouldHideNativeInput}
        isCursorPointer
        wrapperClassName={wrapperClassName}
        disabled={disabled}
        hasLeftIcon={!!leftIcon}
        hasRightIcon
        shouldBlurOnOverlayClick
        hasMinWidth={hasMinWidth}
      >
        <InputIcon iconConfig={{ icon: leftIcon }} />
        {options && (
          <ReactSelect
            isLoadingError={isLoadingError}
            loadingErrorCallback={loadingErrorCallback}
            /**
             * The react-select library provides a standard prop 'onMenuScrollToBottom', but it has an issue:
             * https://github.com/JedWatson/react-select/issues/3232
             * Due to this, we implemented custom functionality with 'customOnMenuScrollToBottom' without this problem
             */
            customOnMenuScrollToBottom={onScrollToBottom}
            name={name}
            onAfterModalClose={onAfterModalClose}
            label={label}
            onInputChange={handleInputChange}
            inputValue={inputValue}
            isLoading={isLoading}
            ref={selectRef}
            tabSelectsValue={false}
            hideSelectedOptions={false}
            isSearchable={shouldHideNativeInput}
            shouldShowSearchInput={shouldShowSearchInput}
            filterOption={
              searchMode === SearchMode.Custom ? () => true : undefined
            }
            noOptionsMessage={() => t('errors.noOptionsMessage')}
            isDisabled={disabled}
            menuIsOpen={isMenuOpen}
            components={{
              LoadingIndicator: () => null,
              LoadingMessage: () => null,
              NoOptionsMessage,
              Option,
              IndicatorsContainer,
              Menu,
              MenuList,
              Control,
              DropdownIndicator: rightIcon ? null : DropdownIndicator,
              Input,
            }}
            openMenuOnClick
            placeholder=""
            className={classNames(styles.select, {
              [styles.disabled]: disabled,
            })}
            unstyled
            value={selectedOption}
            onChange={handleChange}
            options={options}
            onMenuClose={handleMenuClose}
            onMenuOpen={handleMenuOpen}
            onFocus={handleFocus}
            onBlur={onBlur}
          />
        )}
        <InputIcon iconConfig={{ icon: rightIcon }} />
      </FormLabelWrapper>
    </FormValidationWrapper>
  );
};
