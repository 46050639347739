import classNames from 'classnames';
import {
  ButtonSize,
  ButtonTheme,
  ButtonTextColor,
  LinkStyledButtonTextColor,
} from '../const';
import { LinkStyledProp } from '../types';
import styles from '../Button.module.scss';

const mapSizeToStyle: Record<ButtonSize, string> = {
  [ButtonSize.Large]: styles.large,
  [ButtonSize.Medium]: styles.medium,
  [ButtonSize.Small]: styles.small,
};

const mapButtonThemeToStyle: Record<ButtonTheme, string> = {
  [ButtonTheme.Black]: classNames(styles.blackButton, styles.whiteText),
  [ButtonTheme.BlackTonal]: classNames(
    styles.blackTonalButton,
    styles.blackText
  ),
  [ButtonTheme.BlackGhost]: classNames(
    styles.blackGhostButton,
    styles.blackText
  ),
  [ButtonTheme.GreyGhost]: classNames(styles.greyGhostButton, styles.greyText),
  [ButtonTheme.AccentTonal]: classNames(
    styles.accentTonalButton,
    styles.purpleText
  ),
};

const mapLinkStyledButtonTextColorToStyle: Record<
  LinkStyledButtonTextColor,
  string
> = {
  [LinkStyledButtonTextColor.Black]: styles.blackText,
  [LinkStyledButtonTextColor.Grey]: styles.linkStyledGreyText,
  [LinkStyledButtonTextColor.Blue]: styles.linkStyledBlueText,
};

const getLinkStyledStyles = (linkStyledProp?: LinkStyledProp): string => {
  const defaultColor =
    mapLinkStyledButtonTextColorToStyle[ButtonTextColor.Black];

  if (typeof linkStyledProp === 'object') {
    return classNames(
      styles.linkStyled,
      linkStyledProp.color
        ? mapLinkStyledButtonTextColorToStyle[linkStyledProp.color]
        : defaultColor
    );
  }

  return linkStyledProp ? classNames(styles.linkStyled, defaultColor) : '';
};

export const getButtonStyles = (
  theme: ButtonTheme,
  size: ButtonSize,
  linkStyledProp?: LinkStyledProp
) => {
  const stylesArr: Array<string> = [styles.commonStyles, mapSizeToStyle[size]];

  if (linkStyledProp) {
    stylesArr.push(getLinkStyledStyles(linkStyledProp));
  } else {
    stylesArr.push(styles.button, mapButtonThemeToStyle[theme]);
  }

  return classNames(...stylesArr);
};
