import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { isDeepEqual } from 'utils/isDeepEqual';

export const useOptionalColumnsParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const hasOptionalColumns = useMemo(
    () => searchParams.has('columns'),
    [searchParams]
  );

  const parseUrlColumnsIds = useCallback(() => {
    const splittedColumns = searchParams.get('columns')?.split(',') ?? [];

    const formColumns = splittedColumns.reduce(
      (acc, column) => ({
        ...acc,
        [column]: true,
      }),
      {}
    );

    return { columns: splittedColumns, formColumns };
  }, [searchParams]);

  const updateUrlColumnsParams = useCallback(
    (columns: Array<string>) => {
      const currentUrlColumns = parseUrlColumnsIds();

      if (
        currentUrlColumns &&
        isDeepEqual(columns, currentUrlColumns.columns)
      ) {
        return;
      }

      setSearchParams(
        () => {
          searchParams.delete('columns');

          if (columns.length) {
            searchParams.set('columns', columns.join(','));
          }

          return searchParams;
        },
        { replace: true }
      );
    },
    [setSearchParams, parseUrlColumnsIds, searchParams]
  );

  const handleColumnChange = (columns: Array<string>) => {
    updateUrlColumnsParams(columns);
  };

  return {
    handleColumnChange,
    hasOptionalColumns,
    parseUrlColumnsIds,
  };
};
