import { FC } from 'react';
import { Table } from 'ui/Table';
import { useLoadMore } from 'hooks/useLoadMore';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useGetCompanyPartnersQuery } from './hooks/useGetCompanyPartnersQuery';
import { useCompanyPartnersTableColumns } from './hooks/useCompanyPartnersTableColumns';
import { CompanyPartnersData } from './queries/fragments/generated/CompanyPartnersData';
import { useOpenCompanyPartnerDetailsModal } from '../../hooks/useOpenCompanyPartnerDetailsModal';
import { CompanyPartnersTableSearch } from './components/CompanyPartnersTableSearch';

export const CompanyPartnersTable: FC = () => {
  const { openCompanyPartnerDetailsModal } =
    useOpenCompanyPartnerDetailsModal();

  const { t } = useTranslation(['common', 'companyPartners']);

  const [searchParams] = useSearchParams();
  const searchQuery = searchParams.has('search')
    ? (searchParams.get('search') ?? '')
    : '';

  const {
    companyPartnersItems,
    pageInfo,
    fetchMore,
    error,
    loading,
    isSliced,
  } = useGetCompanyPartnersQuery({ searchQuery });

  const columns = useCompanyPartnersTableColumns();

  const handleRowClick = ({ id }: CompanyPartnersData) => {
    openCompanyPartnerDetailsModal(id);
  };

  const loadMore = useLoadMore(fetchMore, pageInfo);

  const isLoading = !companyPartnersItems && loading;

  const tableData = companyPartnersItems ?? [];

  return (
    <Table
      onRowClick={handleRowClick}
      columns={columns}
      data={tableData}
      error={error}
      emptyText={t('companyPartners:content.placeholder.noData')}
      hasSearchParams={!!searchQuery}
      isLoading={isLoading || isSliced}
      hasMoreData={!!pageInfo?.hasNextPage}
      emptySearchStateConfig={{
        title: t(
          'companyPartners:content.placeholder.main.searchRequest.noDataHeader'
        ),
        description: t(
          'companyPartners:content.placeholder.main.searchRequest.noDataSubheader'
        ),
      }}
      errorStateTitle={t('errors.commonError')}
      fetchMore={loadMore}
      searchComponent={
        <CompanyPartnersTableSearch initialValues={{ search: searchQuery }} />
      }
    />
  );
};
