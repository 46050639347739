import { FC } from 'react';
import { KeyValueGrid } from 'ui/KeyValueGrid';
import { Error } from 'ui/Error';
import { Loader } from 'ui/Loader';
import { useTranslation } from 'react-i18next';
import { TextLink } from 'ui/TextLink';
import { closeModal, useModalContext } from 'ui/Modal';
import { Button, ButtonSize, LinkStyledButtonTextColor } from 'ui/Button';
import { getCompanyPartnerDetailsItemData } from './helpers';
import { useGetCompanyPartnerDetailsQuery } from './hooks/useGetCompanyPartnerDetailsQuery';
import { useStatisticRoutesByCompanyPartnerId } from '../../hooks/useStatisticRoutesByCompanyPartnerId';
import { useMediaRoutesByCompanyPartnerId } from '../../hooks/useMediaRoutesByCompanyPartnerId';
import { useEditCompanyPartnerDetailsModal } from './hooks/useEditCompanyPartnerDetailsModal';
import styles from './CompanyPartnerDetailsModal.module.scss';

interface Props {
  companyPartnerId: string;
}

export const CompanyPartnerDetailsModal: FC<Props> = ({ companyPartnerId }) => {
  const { t } = useTranslation('companyPartners');
  const { companyPartnerDetails, loading, error } =
    useGetCompanyPartnerDetailsQuery(companyPartnerId);
  const { dispatch } = useModalContext();

  const { revShareStatisticRoute, cpaStatisticRoute } =
    useStatisticRoutesByCompanyPartnerId(companyPartnerId);
  const { referralLinksRoute } =
    useMediaRoutesByCompanyPartnerId(companyPartnerId);

  const { openEditCompanyPartnerDetailsModal } =
    useEditCompanyPartnerDetailsModal();

  const links = [
    {
      to: revShareStatisticRoute,
      label: t('dropdown.label.statisticsRevShare'),
    },
    {
      to: cpaStatisticRoute,
      label: t('dropdown.label.statisticsCPA'),
    },
    {
      to: referralLinksRoute,
      label: t('dropdown.label.links'),
    },
  ];

  if (loading) {
    return <Loader />;
  }

  if (error || !companyPartnerDetails) {
    return <Error error={error} />;
  }

  const companyPartnerDetailsData = getCompanyPartnerDetailsItemData(
    companyPartnerDetails
  );

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  return (
    <>
      <KeyValueGrid
        className={styles.keyValueGrid}
        data={companyPartnerDetailsData}
        shouldStackOnMd
      />
      <div className={styles.linksWrapper}>
        {links.map(({ to, label }) => (
          <TextLink
            key={to}
            onClick={handleCloseModal}
            buttonSize={ButtonSize.Large}
            tabIndex={0}
            to={to}
          >
            {label}
          </TextLink>
        ))}

        <Button
          linkStyled={{
            color: LinkStyledButtonTextColor.Grey,
          }}
          onClick={() =>
            openEditCompanyPartnerDetailsModal({
              username: companyPartnerDetails.username,
              id: companyPartnerDetails.id,
              note: companyPartnerDetails?.note || '',
            })
          }
        >
          {t('content.drawer.partnerDetails.button.edit')}
        </Button>
      </div>
    </>
  );
};
