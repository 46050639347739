import { UserTypeName } from 'types';
import { ORIGIN_STATISTIC_ITEMS_PER_PAGE } from '../const';
import {
  GetCompanyCpaReferralLinkPromoCodeStatistic,
  useGetCompanyCpaReferralLinkPromoCodeStatisticLazyQuery,
} from '../queries/generated/GetCompanyCpaReferralLinkPromoCodeStatistic';

interface useGetCompanyCpaReferralLinkPromoCodesStatisticItemsQueryParams {
  start: string;
  end: string;
  userId: string;
}

export const useGetCompanyCpaReferralLinkPromoCodesStatisticItemsQuery = ({
  start,
  end,
  userId,
}: useGetCompanyCpaReferralLinkPromoCodesStatisticItemsQueryParams) => {
  const [fetchCompanyCpaReferralLinkPromoCodeStatistic] =
    useGetCompanyCpaReferralLinkPromoCodeStatisticLazyQuery();

  const handleCompleted = (
    data?: GetCompanyCpaReferralLinkPromoCodeStatistic
  ) => {
    const referralPromoCodesStatisticItems =
      data?.user.__typename === UserTypeName.Company
        ? data.user.cpaReferralLinkPromoCodeStatistic
        : undefined;

    const promoCodesStatisticItems =
      referralPromoCodesStatisticItems?.promoCodes.edges.map((item) => ({
        id: item.node.id,
        type: item.node.type,
        token: item.node.token,
        statisticInfo: item.node.statisticInfo,
        __typename: item.node.__typename,
      })) ?? [];

    const statisticInfo = referralPromoCodesStatisticItems?.statisticInfo;

    if (!statisticInfo || !promoCodesStatisticItems.length) {
      return null;
    }

    return {
      pageInfo: referralPromoCodesStatisticItems?.promoCodes.pageInfo,
      hasMoreData:
        referralPromoCodesStatisticItems?.promoCodes.pageInfo?.hasNextPage,
      data: {
        statisticInfo,
        promoCodes: promoCodesStatisticItems,
      },
    };
  };

  const handleFetch = async (referralLinkId: string, after?: string | null) => {
    const { data, loading, error } =
      await fetchCompanyCpaReferralLinkPromoCodeStatistic({
        variables: {
          referralLinkId,
          start,
          end,
          first: ORIGIN_STATISTIC_ITEMS_PER_PAGE,
          userId,
          after,
        },
        fetchPolicy: 'cache-first',
      });

    const parsedData = handleCompleted(data);

    return {
      data: parsedData ? parsedData.data : null,
      loading,
      error,
      hasMoreData: parsedData?.hasMoreData,
      pageInfo: parsedData?.pageInfo,
    };
  };

  return {
    fetchData: handleFetch,
  };
};
