import { FC, ReactNode } from 'react';
import { Accordion } from 'ui/Accordion';
import styles from './FiltersAndSettings.module.scss';

interface FiltersAndSettingsProps {
  isOpen: boolean;
  filters?: ReactNode;
  settings?: ReactNode;
}

export const FiltersAndSettings: FC<FiltersAndSettingsProps> = ({
  isOpen,
  settings,
  filters,
}) => (
  <Accordion isOpen={isOpen}>
    <div className={styles.expandedContainer}>
      <div className={styles.filtersContainer}>{filters}</div>
      {settings && filters && <div className={styles.divider} />}
      {settings && <div className={styles.settingsContainer}>{settings}</div>}
    </div>
  </Accordion>
);
