import { ReactNode } from 'react';
import classNames from 'classnames';
import { Column } from '@tanstack/react-table';
import { calculateLeftOffset } from 'ui/Table/helpers/calculateLeftOffset';
import styles from './TableSummaryRow.module.scss';
import { TableRowCell } from '../TableRowCell';

interface TableSummaryRowProps<TData> {
  row: Array<ReactNode>;
  columns: Array<Column<TData>>;
  canSomeRowsExpand: boolean;
}

export const TableSummaryRow = <TData,>({
  row,
  columns,
  canSomeRowsExpand,
}: TableSummaryRowProps<TData>) => {
  let nonPinnedColumnsWidth = 0;

  return (
    <div
      className={classNames(styles.container, {
        [styles.withExpand]: canSomeRowsExpand,
      })}
      role="row"
      aria-label="summary-row"
    >
      {row.map((value, index) => {
        const currentColumn = columns[index];
        const { meta, enablePinning } = currentColumn.columnDef;

        if (!currentColumn.getIsVisible()) return null;

        const { left, newNonPinnedColumnsWidth } = calculateLeftOffset({
          column: currentColumn,
          nonPinnedColumnsWidth,
        });

        nonPinnedColumnsWidth = newNonPinnedColumnsWidth;

        return (
          <TableRowCell
            isPinned={enablePinning}
            align={meta?.align}
            isSummaryCell
            left={left}
            key={`summary-row-cell-${currentColumn.id}-${value ?? index}`}
            className={classNames({
              [styles.pinned]: enablePinning,
            })}
          >
            {value}
          </TableRowCell>
        );
      })}
    </div>
  );
};
