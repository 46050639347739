import { t } from 'i18n';
import { FormikErrors } from 'formik';
import { checkIsValidUrl } from 'utils/checkIsValidUrl';
import { getRequiredErrors } from 'utils/form/getRequiredFormikErrors';
import { CompanyIdentitiesValues } from './types';
import { FieldName } from './const';

export const validateProfileIdentities = (
  values: CompanyIdentitiesValues
): FormikErrors<CompanyIdentitiesValues> => {
  const errors: FormikErrors<CompanyIdentitiesValues> = {};

  if (values.website && !checkIsValidUrl(values.website)) {
    errors.website = t(
      'profile:company.content.details.personalDetails.input.validation.invalidWebsiteFormat'
    );
  }

  const requiredErrors = getRequiredErrors(
    values,
    {
      [FieldName.ContactType]: true,
      [FieldName.ContactValue]: true,
    },
    {
      [FieldName.ContactType]: t(
        'profile:company.content.details.personalDetails.input.validation.contactRequired'
      ),
      [FieldName.ContactValue]: t(
        'profile:company.content.details.personalDetails.input.validation.contactRequired'
      ),
    }
  );

  return {
    ...errors,
    ...requiredErrors,
  };
};
