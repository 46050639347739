import { PostbackEvent, PostbackMacros } from 'types/generated/gql';

export const mapRequiredMarcos: Record<PostbackEvent, Array<PostbackMacros>> = {
  [PostbackEvent.FirstDeposit]: [PostbackMacros.ClickId],
  [PostbackEvent.QualificationCheck]: [PostbackMacros.ClickId],
  [PostbackEvent.Registration]: [PostbackMacros.ClickId],
  [PostbackEvent.Reward]: [
    PostbackMacros.ClickId,
    PostbackMacros.Currency,
    PostbackMacros.Reward,
  ],
};
