import * as SchemaTypes from '../../../../../../../../../../types/generated/gql';

import * as CommonType from 'types';
import { DocumentNode } from 'graphql';
import { RevSharePromoCodeStatisticConnectionData } from '../../../queries/fragments/generated/RevSharePromoCodeStatisticConnectionData';
import { RevSharePromoCodeStatisticItemData } from '../../../queries/fragments/generated/RevSharePromoCodeStatisticItemData';
import { RevSharePromoCodeStatisticInfoData } from '../../../queries/fragments/generated/RevSharePromoCodeStatisticInfoData';
import { MoneyAmountData } from '../../../../../../../../../../queries/fragments/generated/MoneyAmountData';
import { PageInfoData } from '../../../../../../../../../../queries/fragments/generated/PageInfoData';
import { RevShareReferralLinkDetailStatisticInfoData } from '../../../queries/fragments/generated/RevShareReferralLinkDetailStatisticInfoData';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetCompanyRevShareReferralLinkPromoCodeStatisticVariables =
  SchemaTypes.Exact<{
    after?: SchemaTypes.InputMaybe<SchemaTypes.Scalars['Cursor']['input']>;
    currency: SchemaTypes.Scalars['Currency']['input'];
    end: SchemaTypes.Scalars['Date']['input'];
    first?: SchemaTypes.InputMaybe<SchemaTypes.Scalars['Int']['input']>;
    referralLinkId: SchemaTypes.Scalars['ID']['input'];
    start: SchemaTypes.Scalars['Date']['input'];
    userId: SchemaTypes.Scalars['ID']['input'];
  }>;

export type GetCompanyRevShareReferralLinkPromoCodeStatistic = {
  __typename: 'Query';
  user:
    | {
        __typename: 'Company';
        id: string;
        revShareReferralLinkPromoCodeStatistic: {
          __typename: 'CompanyRevShareReferralLinkPromoCodeStatistic';
          promoCodes: {
            __typename: 'RevSharePromoCodeStatisticConnection';
            edges: Array<{
              __typename: 'RevSharePromoCodeStatisticEdge';
              cursor: string;
              node: {
                __typename: 'RevSharePromoCodeStatisticItem';
                id: string;
                token: string;
                type: SchemaTypes.PromoCodeMediaItemType;
                statisticInfo: {
                  __typename: 'RevSharePromoCodeStatisticInfo';
                  conversionHostsToRegPercent: number;
                  conversionRegToDepPercent: number;
                  firstDeposits: number;
                  numberOfRedeposits: number;
                  registrations: number;
                  adminFee: {
                    __typename: 'MoneyAmount';
                    currency: CommonType.Currency;
                    value: number;
                  };
                  averageDeposit: {
                    __typename: 'MoneyAmount';
                    currency: CommonType.Currency;
                    value: number;
                  };
                  depositsAll: {
                    __typename: 'MoneyAmount';
                    currency: CommonType.Currency;
                    value: number;
                  };
                  depositsFee: {
                    __typename: 'MoneyAmount';
                    currency: CommonType.Currency;
                    value: number;
                  };
                  redepositsAll: {
                    __typename: 'MoneyAmount';
                    currency: CommonType.Currency;
                    value: number;
                  };
                  reward: {
                    __typename: 'MoneyAmount';
                    currency: CommonType.Currency;
                    value: number;
                  };
                  withdrawalsAll: {
                    __typename: 'MoneyAmount';
                    currency: CommonType.Currency;
                    value: number;
                  };
                  withdrawalsFee: {
                    __typename: 'MoneyAmount';
                    currency: CommonType.Currency;
                    value: number;
                  };
                };
              };
            }>;
            pageInfo: {
              __typename: 'PageInfo';
              endCursor?: string | null;
              hasNextPage: boolean;
              hasPreviousPage: boolean;
              startCursor: string;
            };
          };
          statisticInfo: {
            __typename: 'RevShareReferralLinkDetailStatisticInfo';
            conversionHostsToRegPercent: number;
            conversionRegToDepPercent: number;
            firstDeposits: number;
            hits: number;
            hosts: number;
            numberOfRedeposits: number;
            registrations: number;
            adminFee: {
              __typename: 'MoneyAmount';
              currency: CommonType.Currency;
              value: number;
            };
            averageDeposit: {
              __typename: 'MoneyAmount';
              currency: CommonType.Currency;
              value: number;
            };
            depositsAll: {
              __typename: 'MoneyAmount';
              currency: CommonType.Currency;
              value: number;
            };
            depositsFee: {
              __typename: 'MoneyAmount';
              currency: CommonType.Currency;
              value: number;
            };
            redepositsAll: {
              __typename: 'MoneyAmount';
              currency: CommonType.Currency;
              value: number;
            };
            reward: {
              __typename: 'MoneyAmount';
              currency: CommonType.Currency;
              value: number;
            };
            withdrawalsAll: {
              __typename: 'MoneyAmount';
              currency: CommonType.Currency;
              value: number;
            };
            withdrawalsFee: {
              __typename: 'MoneyAmount';
              currency: CommonType.Currency;
              value: number;
            };
          };
        };
      }
    | { __typename: 'Partner'; id: string };
};

export const GetCompanyRevShareReferralLinkPromoCodeStatisticDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'GetCompanyRevShareReferralLinkPromoCodeStatistic',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Cursor' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'currency' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Currency' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'end' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'referralLinkId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'start' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Company' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'revShareReferralLinkPromoCodeStatistic',
                        },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'referralLinkId' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'referralLinkId' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'promoCodes' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'after' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'after' },
                                  },
                                },
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'currency' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'currency' },
                                  },
                                },
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'end' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'end' },
                                  },
                                },
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'first' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'first' },
                                  },
                                },
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'start' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'start' },
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value:
                                        'RevSharePromoCodeStatisticConnectionData',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'statisticInfo' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'currency' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'currency' },
                                  },
                                },
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'end' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'end' },
                                  },
                                },
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'start' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'start' },
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value:
                                        'RevShareReferralLinkDetailStatisticInfoData',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...RevSharePromoCodeStatisticConnectionData.definitions,
    ...RevSharePromoCodeStatisticItemData.definitions,
    ...RevSharePromoCodeStatisticInfoData.definitions,
    ...MoneyAmountData.definitions,
    ...PageInfoData.definitions,
    ...RevShareReferralLinkDetailStatisticInfoData.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useGetCompanyRevShareReferralLinkPromoCodeStatistic__
 *
 * To run a query within a React component, call `useGetCompanyRevShareReferralLinkPromoCodeStatistic` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyRevShareReferralLinkPromoCodeStatistic` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyRevShareReferralLinkPromoCodeStatistic({
 *   variables: {
 *      after: // value for 'after'
 *      currency: // value for 'currency'
 *      end: // value for 'end'
 *      first: // value for 'first'
 *      referralLinkId: // value for 'referralLinkId'
 *      start: // value for 'start'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetCompanyRevShareReferralLinkPromoCodeStatistic(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetCompanyRevShareReferralLinkPromoCodeStatistic,
    GetCompanyRevShareReferralLinkPromoCodeStatisticVariables
  > &
    (
      | {
          variables: GetCompanyRevShareReferralLinkPromoCodeStatisticVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetCompanyRevShareReferralLinkPromoCodeStatistic,
    GetCompanyRevShareReferralLinkPromoCodeStatisticVariables
  >(GetCompanyRevShareReferralLinkPromoCodeStatisticDocument, options);
}
export function useGetCompanyRevShareReferralLinkPromoCodeStatisticLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetCompanyRevShareReferralLinkPromoCodeStatistic,
    GetCompanyRevShareReferralLinkPromoCodeStatisticVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetCompanyRevShareReferralLinkPromoCodeStatistic,
    GetCompanyRevShareReferralLinkPromoCodeStatisticVariables
  >(GetCompanyRevShareReferralLinkPromoCodeStatisticDocument, options);
}
export function useGetCompanyRevShareReferralLinkPromoCodeStatisticSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    GetCompanyRevShareReferralLinkPromoCodeStatistic,
    GetCompanyRevShareReferralLinkPromoCodeStatisticVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    GetCompanyRevShareReferralLinkPromoCodeStatistic,
    GetCompanyRevShareReferralLinkPromoCodeStatisticVariables
  >(GetCompanyRevShareReferralLinkPromoCodeStatisticDocument, options);
}
export type GetCompanyRevShareReferralLinkPromoCodeStatisticHookResult =
  ReturnType<typeof useGetCompanyRevShareReferralLinkPromoCodeStatistic>;
export type GetCompanyRevShareReferralLinkPromoCodeStatisticLazyQueryHookResult =
  ReturnType<
    typeof useGetCompanyRevShareReferralLinkPromoCodeStatisticLazyQuery
  >;
export type GetCompanyRevShareReferralLinkPromoCodeStatisticSuspenseQueryHookResult =
  ReturnType<
    typeof useGetCompanyRevShareReferralLinkPromoCodeStatisticSuspenseQuery
  >;
export type GetCompanyRevShareReferralLinkPromoCodeStatisticQueryResult =
  Apollo.QueryResult<
    GetCompanyRevShareReferralLinkPromoCodeStatistic,
    GetCompanyRevShareReferralLinkPromoCodeStatisticVariables
  >;
