import { FC } from 'react';
import { Outlet } from 'react-router';
import { AppMenu } from 'components/AppMenu/AppMenu';

import { Modal, ModalProvider } from 'ui/Modal';
import styles from './MainLayout.module.scss';

export const MainLayout: FC = () => (
  <ModalProvider>
    <div className={styles.layout}>
      <AppMenu />
      <Outlet />
    </div>
    <Modal />
  </ModalProvider>
);
