import { IconComponent } from 'types';
import { Currency } from 'types/common';

import { ReactComponent as USDIcon } from 'assets/icons/flags/USA.svg';
import { ReactComponent as RUBIcon } from 'assets/icons/flags/Russia.svg';
import { ReactComponent as EURIcon } from 'assets/icons/flags/EU.svg';
import { ReactComponent as UAHIcon } from 'assets/icons/flags/Ukraine.svg';
import { ReactComponent as KZTIcon } from 'assets/icons/flags/Kazakhstan.svg';
import { ReactComponent as BRLIcon } from 'assets/icons/flags/Brazil.svg';
import { ReactComponent as TRYIcon } from 'assets/icons/flags/Turkey.svg';
import { ReactComponent as MXNIcon } from 'assets/icons/flags/Mexico.svg';
import { ReactComponent as PLNIcon } from 'assets/icons/flags/Poland.svg';
import { ReactComponent as HUFIcon } from 'assets/icons/flags/Hungary.svg';
import { ReactComponent as BGNIcon } from 'assets/icons/flags/Bulgaria.svg';
import { ReactComponent as SEKIcon } from 'assets/icons/flags/Sweden.svg';
import { ReactComponent as JPYIcon } from 'assets/icons/flags/Japan.svg';
import { ReactComponent as KRWIcon } from 'assets/icons/flags/South_Korea.svg';
import { ReactComponent as AUDIcon } from 'assets/icons/flags/Australia.svg';
import { ReactComponent as NOKIcon } from 'assets/icons/flags/Norway.svg';
import { ReactComponent as RONIcon } from 'assets/icons/flags/Romania.svg';
import { ReactComponent as BYNIcon } from 'assets/icons/flags/Belarus.svg';
import { ReactComponent as CADIcon } from 'assets/icons/flags/Canada.svg';
import { ReactComponent as UZSIcon } from 'assets/icons/flags/Uzbekistan.svg';

export const mapCurrencyToIcon: Record<Currency, IconComponent> = {
  [Currency.USD]: USDIcon,
  [Currency.RUB]: RUBIcon,
  [Currency.EUR]: EURIcon,
  [Currency.UAH]: UAHIcon,
  [Currency.KZT]: KZTIcon,
  [Currency.BRL]: BRLIcon,
  [Currency.TRY]: TRYIcon,
  [Currency.MXN]: MXNIcon,
  [Currency.PLN]: PLNIcon,
  [Currency.HUF]: HUFIcon,
  [Currency.BGN]: BGNIcon,
  [Currency.SEK]: SEKIcon,
  [Currency.JPY]: JPYIcon,
  [Currency.KRW]: KRWIcon,
  [Currency.AUD]: AUDIcon,
  [Currency.NOK]: NOKIcon,
  [Currency.RON]: RONIcon,
  [Currency.BYN]: BYNIcon,
  [Currency.CAD]: CADIcon,
  [Currency.UZS]: UZSIcon,
};
