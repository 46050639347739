import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonTheme } from 'ui/Button';
import { useTranslationUserType } from 'hooks/useTranslationUserType';
import { useCreateLocalizedPath } from 'hooks/useCreateLocalizedPath';
import { AppRoute } from 'const';
import { Link } from 'react-router-dom';
import styles from './ChangePassword.module.scss';

export const ChangePassword: FC = () => {
  const { t } = useTranslation('profile');
  const translationUserType = useTranslationUserType();
  const { createLocalizedPath } = useCreateLocalizedPath();

  return (
    <div>
      <div className={styles.text}>
        {t(
          `${translationUserType}.content.drawer.resetPassword.header.subtitle1`
        )}
      </div>
      <div className={styles.text}>
        {t(
          `${translationUserType}.content.drawer.resetPassword.header.subtitle2`
        )}
      </div>
      <Link to={createLocalizedPath(AppRoute.Lang.Support)}>
        <Button theme={ButtonTheme.BlackTonal}>
          {t(
            `${translationUserType}.content.drawer.resetPassword.action.button.secondary`
          )}
        </Button>
      </Link>
    </div>
  );
};
