import { useEffect, useState } from 'react';
import { useAuth } from 'components/auth';
import { DateFormat, formatDate } from 'utils/formatDate';
import { FilterType, NonNullableDateRange, UserTypeName } from 'types';
import {
  PageInfo,
  PartnerCpaStatisticSortInput,
  PartnerStatisticFilterType,
} from 'types/generated/gql';
import { ExtendedTData } from 'ui/Table/types';
import {
  GetPartnerCpaStatisticItems,
  useGetPartnerCpaStatisticItems,
} from '../queries/generated/GetPartnerCpaStatisticItems';
import { STATISTIC_ITEMS_PER_PAGE } from '../const';
import { PartnerCpaStatisticTableData } from '../types';
import { usePartnerCpaStatisticExpandedRows } from './usePartnerCpaStatisticExpandedRows';

const { DateScalar } = DateFormat;

interface UseGetPartnerCpaStatisticItemsQueryParams {
  nonNullableDateRange: NonNullableDateRange;
  filters: Array<FilterType<PartnerStatisticFilterType>>;
  searchQuery?: string;
  sortState?: PartnerCpaStatisticSortInput;
}

export const useGetPartnerCpaStatisticItemsQuery = ({
  nonNullableDateRange,
  searchQuery,
  filters,
  sortState,
}: UseGetPartnerCpaStatisticItemsQueryParams) => {
  const { user } = useAuth();
  const [start, end] = nonNullableDateRange;

  const [parsedData, setParsedData] = useState<
    Array<ExtendedTData<PartnerCpaStatisticTableData>>
  >([]);

  const [pageInfoState, setPageInfoState] = useState<PageInfo>();

  const { handleExpandRow } = usePartnerCpaStatisticExpandedRows({
    parentRowsData: parsedData,
    setParentsRowsData: setParsedData,
    start: formatDate(start, DateScalar),
    end: formatDate(end, DateScalar),
    userId: user.id,
  });

  const handleCompleted = (data: GetPartnerCpaStatisticItems) => {
    const statisticItemsConnection =
      data?.user.__typename === UserTypeName.Partner
        ? data.user.cpaStatistic.statisticItems
        : undefined;

    const newEdges = statisticItemsConnection?.edges;

    const statisticItems =
      newEdges?.map((item) => ({
        ...item.node,
        canExpand: item.node.hasPromoCodeStatistic,
      })) ?? [];

    const pageInfo = statisticItemsConnection?.pageInfo;

    setParsedData((prevData) =>
      statisticItems.map((item, index) => {
        const prevItem = prevData[index];

        if (prevItem?.subRows?.length) {
          return {
            ...item,
            subRows: prevItem.subRows,
          };
        }

        return item;
      })
    );

    setPageInfoState(pageInfo);
  };

  const {
    fetchMore,
    loading,
    error,
    isSliced,
    data: queryData,
  } = useGetPartnerCpaStatisticItems({
    variables: {
      start: formatDate(start, DateScalar),
      end: formatDate(end, DateScalar),
      first: STATISTIC_ITEMS_PER_PAGE,
      userId: user.id,
      filters,
      sort: sortState,
      ...(Boolean(searchQuery) && { referralLinkSearch: searchQuery }),
    },
    onCompleted: handleCompleted,
    cacheFirstOnPop: true,
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (parsedData.length && !queryData) {
      setParsedData([]);
    }
  }, [parsedData.length, queryData]);

  return {
    statisticItems: parsedData,
    fetchMore,
    pageInfo: pageInfoState,
    loading,
    isSliced,
    error,
    handleExpandRow,
  };
};
