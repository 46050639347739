import { FC } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Button, ButtonSize, ButtonTheme } from 'ui/Button';
import { ReactComponent as CloseIcon } from 'assets/icons/line/Close.svg';
import { useFiltersHandlers } from 'hooks/table/useFiltersHandlers';
import { SelectFilter } from 'components/SelectFilter';
import styles from './CompanyRevShareStatisticTableFilters.module.scss';
import { useCompanyRevShareStatisticFilters } from './hooks/useCompanyRevShareStatisticFilters';

interface CompanyRevShareStatisticTableFiltersProps {
  onChange: () => void;
  onClear: () => void;
}

export const CompanyRevShareStatisticTableFilters: FC<
  CompanyRevShareStatisticTableFiltersProps
> = ({ onChange, onClear }) => {
  const { filters } = useCompanyRevShareStatisticFilters();
  const [searchParams] = useSearchParams();

  const { clearFiltersParams, hasFilters } = useFiltersHandlers();

  const isDisabled = searchParams.has('search');

  const handleClear = () => {
    onClear();
    clearFiltersParams();
  };

  if (!filters.length) {
    return null;
  }

  return (
    <div className={styles.container}>
      {hasFilters && (
        <Button
          onClick={handleClear}
          size={ButtonSize.Small}
          theme={ButtonTheme.BlackTonal}
          iconConfig={{
            iconComponent: CloseIcon,
          }}
        />
      )}
      {filters.map(
        ({ type, title, options, loading, loadMore, selectFilterType }) => (
          <SelectFilter
            selectFilterType={selectFilterType}
            key={type}
            type={type}
            title={title}
            options={options}
            loading={loading}
            loadMore={loadMore}
            onChange={onChange}
            disabled={isDisabled}
          />
        )
      )}
    </div>
  );
};
