import { FC, PropsWithChildren, ReactElement } from 'react';
import { TooltipContextProvider } from './components/TooltipContextProvider';
import { TooltipContent } from './components/TooltipContent/TooltipContent';
import { TooltipTrigger } from './components/TooltipTrigger';
import { TooltipTheme } from './const';
import { TooltipOptions } from './types';

interface TooltipProps extends TooltipOptions {
  tooltipContent?: string | ReactElement;
  tooltipContentClassName?: string;
  tooltipTriggerClassName?: string;
  tooltipTheme?: TooltipTheme;
  arrowOffset?: string | number | null;
  shouldSetWidthFitContent?: boolean;
  isAccessibleByTab?: boolean;
  disableHover?: boolean;
}

export const Tooltip: FC<PropsWithChildren<TooltipProps>> = ({
  children,
  tooltipContentClassName,
  tooltipTriggerClassName,
  tooltipContent,
  tooltipTheme,
  arrowOffset,
  shouldSetWidthFitContent = true,
  isAccessibleByTab = false,
  horizontalOffset,
  ...tooltipOptions
}) =>
  tooltipContent ? (
    <TooltipContextProvider
      {...tooltipOptions}
      horizontalOffset={horizontalOffset}
    >
      <TooltipContent
        className={tooltipContentClassName}
        data-testid="tooltip-content"
        tooltipTheme={tooltipTheme}
        arrowOffset={arrowOffset}
      >
        {tooltipContent}
      </TooltipContent>
      <TooltipTrigger
        className={tooltipTriggerClassName}
        shouldSetWidthFitContent={shouldSetWidthFitContent}
        isAccessibleByTab={isAccessibleByTab}
      >
        {children}
      </TooltipTrigger>
    </TooltipContextProvider>
  ) : (
    <div className={tooltipTriggerClassName}>{children}</div>
  );
