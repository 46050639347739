import * as SchemaTypes from '../../../../../../../../../../../types/generated/gql';

import * as CommonType from 'types';
import { DocumentNode } from 'graphql';
import { CompanyRevShareStatisticInfoData } from './CompanyRevShareStatisticInfoData';
import { MoneyAmountData } from '../../../../../../../../../../../queries/fragments/generated/MoneyAmountData';
export type CompanyRevShareStatisticItemData = {
  __typename: 'CompanyRevShareStatisticItem';
  hasPromoCodeStatistic: boolean;
  id: string;
  link: string;
  name: string;
  owner:
    | { __typename: 'Company'; id: string; name: string }
    | { __typename: 'CompanyPartner'; id: string; username: string };
  statisticInfo: {
    __typename: 'CompanyRevShareStatisticInfo';
    conversionHostsToRegPercent: number;
    conversionRegToDepPercent: number;
    firstDeposits: number;
    hits: number;
    hosts: number;
    numberOfRedeposits: number;
    registrations: number;
    adminFee: {
      __typename: 'MoneyAmount';
      currency: CommonType.Currency;
      value: number;
    };
    averageDeposit: {
      __typename: 'MoneyAmount';
      currency: CommonType.Currency;
      value: number;
    };
    depositsAll: {
      __typename: 'MoneyAmount';
      currency: CommonType.Currency;
      value: number;
    };
    depositsFee: {
      __typename: 'MoneyAmount';
      currency: CommonType.Currency;
      value: number;
    };
    redepositsAll: {
      __typename: 'MoneyAmount';
      currency: CommonType.Currency;
      value: number;
    };
    reward: {
      __typename: 'MoneyAmount';
      currency: CommonType.Currency;
      value: number;
    };
    withdrawalsAll: {
      __typename: 'MoneyAmount';
      currency: CommonType.Currency;
      value: number;
    };
    withdrawalsFee: {
      __typename: 'MoneyAmount';
      currency: CommonType.Currency;
      value: number;
    };
  };
};

export const CompanyRevShareStatisticItemData = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CompanyRevShareStatisticItemData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CompanyRevShareStatisticItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasPromoCodeStatistic' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'link' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'owner' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'CompanyPartner' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'username' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Company' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'statisticInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'CompanyRevShareStatisticInfoData',
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
