import { useAuth } from 'components/auth';
import { Currency, NonNullableDateRange } from 'types';
import { DateFormat, formatDate } from 'utils/formatDate';
import { useApolloClient } from '@apollo/client';
import { STATISTIC_ITEMS_PER_PAGE } from '../const';
import {
  GetRevShareStatisticDetailed,
  GetRevShareStatisticDetailedDocument,
  useGetRevShareStatisticDetailed,
} from '../query/generated/GetRevShareStatisticDetailed';

const { DateScalar } = DateFormat;

interface UseGetRevShareStatisticDetailedQueryParams {
  revShareMediaItemId: string;
  nonNullableDateRange: NonNullableDateRange;
  currency: Currency;
  searchQuery?: string;
}

export const useGetRevShareStatisticDetailedQuery = ({
  revShareMediaItemId,
  nonNullableDateRange,
  currency,
  searchQuery,
}: UseGetRevShareStatisticDetailedQueryParams) => {
  const { user } = useAuth();
  const [start, end] = nonNullableDateRange;
  const client = useApolloClient();

  const cachedGetRevShareStatisticDetailedQuery =
    client.readQuery<GetRevShareStatisticDetailed>({
      query: GetRevShareStatisticDetailedDocument,
      variables: {
        start: formatDate(start, DateScalar),
        end: formatDate(end, DateScalar),
        userId: user.id,
        revShareMediaItemId,
        currency,
        ...(Boolean(searchQuery) && { referralNameSearch: searchQuery }),
      },
    });

  const cachedRevShareStatisticDetailedEdges =
    cachedGetRevShareStatisticDetailedQuery?.user?.referralLinkMediaItem
      ?.revShareStatistic?.statisticItems?.edges;

  const first =
    cachedRevShareStatisticDetailedEdges &&
    cachedRevShareStatisticDetailedEdges.length > 0
      ? cachedRevShareStatisticDetailedEdges.length
      : STATISTIC_ITEMS_PER_PAGE;

  const { data, fetchMore, isSliced, loading, error } =
    useGetRevShareStatisticDetailed({
      variables: {
        start: formatDate(start, DateScalar),
        end: formatDate(end, DateScalar),
        userId: user.id,
        revShareMediaItemId,
        first,
        currency,
        ...(Boolean(searchQuery) && { referralNameSearch: searchQuery }),
      },
      notifyOnNetworkStatusChange: true,
      cacheFirstOnPop: true,
    });

  const revShareStatisticDetailed = data?.user.referralLinkMediaItem;

  const pageInfo =
    revShareStatisticDetailed?.revShareStatistic.statisticItems.pageInfo;

  return {
    revShareStatisticDetailed,
    fetchMore,
    pageInfo,
    loading,
    isSliced,
    error,
  };
};
