import { FormikErrors } from 'formik';
import { getRequiredErrors } from 'utils/form/getRequiredFormikErrors';
import { t } from 'i18n';
import { CreateCompanyMediaFormValues } from './types';

export const validateCreateCompanyMediaForm = (
  values: CreateCompanyMediaFormValues
): FormikErrors<CreateCompanyMediaFormValues> =>
  getRequiredErrors(
    values,
    {
      mediaItemName: true,
      mediaCampaignId: true,
      ownerId: true,
      tariffId: true,
    },
    {
      mediaItemName: t(
        `media:company.content.drawer.createMedia.input.validation.titleRequired`
      ),
      mediaCampaignId: t(
        `media:company.content.drawer.createMedia.input.validation.mediaCampaignRequired`
      ),
      ownerId: t(
        `media:company.content.drawer.createMedia.input.validation.ownerRequired`
      ),
      tariffId: t(
        `media:company.content.drawer.createMedia.input.validation.tariffRequired`
      ),
    }
  );
