import { FC, PropsWithChildren, ReactNode } from 'react';
import classNames from 'classnames';
import { BottomIndentSize } from './const';
import styles from './Card.module.scss';

const mapBottomIndentSizeToStyle: Record<BottomIndentSize, string> = {
  [BottomIndentSize.Small]: styles.smallBottomIndent,
  [BottomIndentSize.Large]: styles.largeBottomIndent,
};

interface CardProps {
  title: ReactNode;
  subTitle?: ReactNode;
  headerRightBlock?: ReactNode;
  className?: string;
  hasHeaderSeparator?: boolean;
  bottomIndentSize?: BottomIndentSize;
}

export const Card: FC<PropsWithChildren<CardProps>> = ({
  title,
  subTitle,
  children,
  headerRightBlock,
  className,
  hasHeaderSeparator = true,
  bottomIndentSize = BottomIndentSize.Small,
}) => (
  <div
    data-testid="card"
    className={classNames(className, styles.mainContainer, {
      [styles.hasHeaderSeparator]: hasHeaderSeparator,
    })}
  >
    <div
      className={classNames(styles.header, {
        [styles.hasSubTitle]: Boolean(subTitle),
      })}
    >
      <div className={classNames(styles.headerLeftBlock)}>
        <span className={styles.title}>{title}</span>
        {subTitle && (
          <span data-testid="sub-title" className={styles.subTitle}>
            {subTitle}
          </span>
        )}
      </div>
      {headerRightBlock}
    </div>
    <div
      className={classNames(
        styles.contentAndFooterContainer,
        mapBottomIndentSizeToStyle[bottomIndentSize]
      )}
    >
      <div className={styles.content}>{children}</div>
    </div>
  </div>
);
