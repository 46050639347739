import { useMemo } from 'react';
import { useAuth } from 'components/auth';
import { FilterType, UserTypeName } from 'types';
import { PartnerPromoCodeMediaItemsFilterType } from 'types/generated/gql';
import { PROMO_CODE_MEDIA_ITEMS_PER_PAGE } from '../../../const';
import { useGetPartnerPromoCodeMediaItems } from '../queries/generated/GetPartnerPromoCodeMediaItems';
import { PromoCodeMediaItemData } from '../../../queries/fragments/generated/PromoCodeMediaItemData';

interface UseGetPartnerPromoCodeMediaItemsQueryParams {
  searchQuery?: string;
  filters: Array<FilterType<PartnerPromoCodeMediaItemsFilterType>>;
}

export const useGetPartnerPromoCodeMediaItemsQuery = ({
  searchQuery,
  filters,
}: UseGetPartnerPromoCodeMediaItemsQueryParams) => {
  const { user } = useAuth();

  const { data, fetchMore, loading, error } = useGetPartnerPromoCodeMediaItems({
    variables: {
      first: PROMO_CODE_MEDIA_ITEMS_PER_PAGE,
      userId: user.id,
      filters,
      ...(Boolean(searchQuery) && { promoCodeSearch: searchQuery }),
    },
    notifyOnNetworkStatusChange: true,
  });

  const promoCodeMediaItemsConnection =
    data?.user.__typename === UserTypeName.Partner
      ? data.user.promoCodeMediaItems
      : undefined;

  const promoCodeMediaItems: Array<PromoCodeMediaItemData> | undefined =
    useMemo(
      () => promoCodeMediaItemsConnection?.edges.map((item) => item.node),
      [promoCodeMediaItemsConnection?.edges]
    );

  const pageInfo = promoCodeMediaItemsConnection?.pageInfo;

  return { promoCodeMediaItems, fetchMore, pageInfo, loading, error };
};
