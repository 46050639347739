import { FC } from 'react';
import classNames from 'classnames';
import { Button, ButtonSize } from 'ui/Button';
import { Icon, IconSize } from 'ui/Icon';
import { useLanguage } from 'contexts/LanguageContext';
import { mapLanguageToCountryCode } from 'utils/language/mapLanguageToCountryCode';
import { mapLanguageToDisplayName } from 'utils/language/mapLanguageToDisplayName';
import { getImgSrcByCountry } from 'utils/countries/getImgSrcByCountry';
import styles from './LanguageDropdownButton.module.scss';

interface LanguageDropdownButtonProps {
  isActive?: boolean;
}

export const LanguageDropdownButton: FC<LanguageDropdownButtonProps> = ({
  isActive,
}) => {
  const { currentLanguage } = useLanguage();
  const countryCode = mapLanguageToCountryCode[currentLanguage];
  const countryFlagIcon = getImgSrcByCountry(countryCode);

  return (
    <Button
      className={classNames(styles.button, {
        [styles.activeButton]: isActive,
      })}
      size={ButtonSize.Small}
      linkStyled
    >
      <div className={styles.buttonContent}>
        <div className={styles.innerButtonContent}>
          {countryFlagIcon && (
            <Icon iconComponent={countryFlagIcon} size={IconSize.Medium} />
          )}
          {mapLanguageToDisplayName(currentLanguage)}
        </div>
      </div>
    </Button>
  );
};
