import * as SchemaTypes from '../../../../../../../../../../types/generated/gql';

import * as CommonType from 'types';
import { DocumentNode } from 'graphql';
import { ReferralLinkMediaItemConnectionData } from '../../../../../../../../queries/fragments/generated/ReferralLinkMediaItemConnectionData';
import { ReferralLinkMediaItemData } from '../../../../../../../../queries/fragments/generated/ReferralLinkMediaItemData';
import { ReferralLinkMediaItemBasicData } from '../../../../../../../../queries/fragments/generated/ReferralLinkMediaItemBasicData';
import { TariffForMediaBasicData } from '../../../../../../../../queries/fragments/generated/TariffForMediaBasicData';
import { TariffForMediaData } from '../../../../../../../../queries/fragments/generated/TariffForMediaData';
import { PageInfoData } from '../../../../../../../../../../queries/fragments/generated/PageInfoData';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetCompanyReferralLinkMediaItemsVariables = SchemaTypes.Exact<{
  after?: SchemaTypes.InputMaybe<SchemaTypes.Scalars['Cursor']['input']>;
  filters:
    | Array<SchemaTypes.CompanyReferralLinkMediaItemsFilterInput>
    | SchemaTypes.CompanyReferralLinkMediaItemsFilterInput;
  first?: SchemaTypes.InputMaybe<SchemaTypes.Scalars['Int']['input']>;
  referralLinkSearch?: SchemaTypes.InputMaybe<
    SchemaTypes.Scalars['String']['input']
  >;
  userId: SchemaTypes.Scalars['ID']['input'];
}>;

export type GetCompanyReferralLinkMediaItems = {
  __typename: 'Query';
  user:
    | {
        __typename: 'Company';
        id: string;
        referralLinkMediaItems: {
          __typename: 'ReferralLinkMediaItemConnection';
          edges: Array<{
            __typename: 'ReferralLinkMediaItemConnectionEdge';
            cursor: string;
            node: {
              __typename: 'ReferralLinkMediaItem';
              createdAt: string;
              link: string;
              id: string;
              name: string;
              tariff:
                | {
                    __typename: 'CpaTariff';
                    geolocation: Array<CommonType.Country>;
                    createdAt: string;
                    id: string;
                    name: string;
                    baseline: {
                      __typename: 'MoneyAmount';
                      currency: CommonType.Currency;
                      value: number;
                    };
                    reward: {
                      __typename: 'MoneyAmount';
                      currency: CommonType.Currency;
                      value: number;
                    };
                  }
                | {
                    __typename: 'RevShareTariff';
                    rewardPercent: string;
                    createdAt: string;
                    id: string;
                    name: string;
                  };
              postbackInfo: {
                __typename: 'PostbackInfo';
                commonPostbackStatus: SchemaTypes.CommonPostbackStatus;
              };
              owner:
                | { __typename: 'Company'; id: string; name: string }
                | { __typename: 'CompanyPartner'; id: string; username: string }
                | { __typename: 'Partner'; id: string; username: string };
            };
          }>;
          pageInfo: {
            __typename: 'PageInfo';
            endCursor?: string | null;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            startCursor: string;
          };
        };
      }
    | { __typename: 'Partner'; id: string };
};

export const GetCompanyReferralLinkMediaItemsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCompanyReferralLinkMediaItems' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Cursor' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filters' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: {
                    kind: 'Name',
                    value: 'CompanyReferralLinkMediaItemsFilterInput',
                  },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'referralLinkSearch' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Company' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'referralLinkMediaItems' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'after' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'after' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'filters' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'filters' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'first' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'first' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'referralLinkSearch' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'referralLinkSearch',
                              },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'ReferralLinkMediaItemConnectionData',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...ReferralLinkMediaItemConnectionData.definitions,
    ...ReferralLinkMediaItemData.definitions,
    ...ReferralLinkMediaItemBasicData.definitions,
    ...TariffForMediaBasicData.definitions,
    ...TariffForMediaData.definitions,
    ...PageInfoData.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useGetCompanyReferralLinkMediaItems__
 *
 * To run a query within a React component, call `useGetCompanyReferralLinkMediaItems` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyReferralLinkMediaItems` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyReferralLinkMediaItems({
 *   variables: {
 *      after: // value for 'after'
 *      filters: // value for 'filters'
 *      first: // value for 'first'
 *      referralLinkSearch: // value for 'referralLinkSearch'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetCompanyReferralLinkMediaItems(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetCompanyReferralLinkMediaItems,
    GetCompanyReferralLinkMediaItemsVariables
  > &
    (
      | { variables: GetCompanyReferralLinkMediaItemsVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetCompanyReferralLinkMediaItems,
    GetCompanyReferralLinkMediaItemsVariables
  >(GetCompanyReferralLinkMediaItemsDocument, options);
}
export function useGetCompanyReferralLinkMediaItemsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetCompanyReferralLinkMediaItems,
    GetCompanyReferralLinkMediaItemsVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetCompanyReferralLinkMediaItems,
    GetCompanyReferralLinkMediaItemsVariables
  >(GetCompanyReferralLinkMediaItemsDocument, options);
}
export function useGetCompanyReferralLinkMediaItemsSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    GetCompanyReferralLinkMediaItems,
    GetCompanyReferralLinkMediaItemsVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    GetCompanyReferralLinkMediaItems,
    GetCompanyReferralLinkMediaItemsVariables
  >(GetCompanyReferralLinkMediaItemsDocument, options);
}
export type GetCompanyReferralLinkMediaItemsHookResult = ReturnType<
  typeof useGetCompanyReferralLinkMediaItems
>;
export type GetCompanyReferralLinkMediaItemsLazyQueryHookResult = ReturnType<
  typeof useGetCompanyReferralLinkMediaItemsLazyQuery
>;
export type GetCompanyReferralLinkMediaItemsSuspenseQueryHookResult =
  ReturnType<typeof useGetCompanyReferralLinkMediaItemsSuspenseQuery>;
export type GetCompanyReferralLinkMediaItemsQueryResult = Apollo.QueryResult<
  GetCompanyReferralLinkMediaItems,
  GetCompanyReferralLinkMediaItemsVariables
>;
