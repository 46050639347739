import { FC } from 'react';
import { Button, ButtonSize, ButtonTheme } from 'ui/Button';
import { ToastButton } from '../../types';
import styles from './ToastContent.module.scss';

interface ToastContentProps {
  header: string;
  text?: string;
  button?: ToastButton;
}

export const ToastContent: FC<ToastContentProps> = ({
  header,
  text,
  button,
}) => (
  <>
    <div className={styles.header}>{header}</div>
    {text && <div className={styles.text}>{text}</div>}
    {button?.text && (
      <Button
        theme={ButtonTheme.BlackTonal}
        size={ButtonSize.Small}
        className={styles.button}
        onClick={button.onClick}
      >
        {button.text}
      </Button>
    )}
  </>
);
