import { useRef, useEffect } from 'react';

/**
 * Hook for infinite scrolling using Intersection Observer.
 * @returns JSX.Element, which serves as a trigger for calling callback.
 *
 * @usage
 * returned JSX.Element needs to be added to the component with an infinite scroll after all elements, like this:
 * ```
 * const loadTrigger = useInfiniteScroll(yourLoadMoreFunction);
 *
 * return elementsToShow?.length && (
 *   <>
 *     {elementsToShow.map((item) => <div key={item.id}>{item}</div>)}
 *     {loadTrigger}
 *   </>
 * )
 * ```
 * When you scroll the page to the loadTrigger element, a callback will be called, which should load the following elements.
 *
 * forceObserve is a parameter that triggers re-initialization of observer. For example it could be needed when trigger is
 * in dropdown or any element that's initially out of DOM.
 */

interface UseInfiniteScrollParams {
  callback?: () => void;
  forceObserve?: boolean;
}

export const useInfiniteScroll = ({
  callback,
  forceObserve,
}: UseInfiniteScrollParams): JSX.Element => {
  const observerRef = useRef<IntersectionObserver | null>(null);
  const lastElementRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!callback) {
      return undefined;
    }

    if (lastElementRef.current) {
      observerRef.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          callback();
        }
      });
      observerRef.current.observe(lastElementRef.current);
    }

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
        observerRef.current = null;
      }
    };
  }, [callback, forceObserve]);

  return <div ref={lastElementRef} />;
};
