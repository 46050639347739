export const ERROR_TOOLTIP_OFFSET_DEFAULT = -5;
export const ERROR_TOOLTIP_OFFSET_SMALL = -9;

export enum CheckboxSize {
  Default = 'default',
  Small = 'small',
}

export enum CheckboxType {
  Default = 'default',
  Select = 'select',
}
