export enum PromoCodeStatisticItemTypeName {
  CpaPromoCodeStatisticItem = 'CpaPromoCodeStatisticItem',
  RevSharePromoCodeStatisticItem = 'RevSharePromoCodeStatisticItem',
}

export enum StatisticItemTypeName {
  PartnerCpaStatisticItem = 'PartnerCpaStatisticItem',
  PartnerRevShareStatisticItem = 'PartnerRevShareStatisticItem',
  CompanyCpaStatisticItem = 'CompanyCpaStatisticItem',
  CompanyRevShareStatisticItem = 'CompanyRevShareStatisticItem',
}
