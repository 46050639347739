import { useCreateLocalizedPath } from 'hooks/useCreateLocalizedPath';
import { composeRoutes } from 'utils/routing/composeRoutes';
import { createFilterSearchParams } from 'utils/createFilterSearchParams';
import { AppRoute } from 'const';
import { CompanyReferralLinkMediaItemsFilterType } from 'types/generated/gql';

export const useMediaRoutesByCompanyPartnerId = (companyPartnerId: string) => {
  const { createLocalizedPath } = useCreateLocalizedPath();

  const referralLinksSearchParams = new URLSearchParams(
    createFilterSearchParams({
      filterType: CompanyReferralLinkMediaItemsFilterType.MediaItemOwner,
      value: companyPartnerId,
    })
  );

  const referralLinksRoute = createLocalizedPath(
    composeRoutes([AppRoute.Lang.Media.root, AppRoute.Lang.Media.Links.root], {
      searchParams: referralLinksSearchParams,
    })
  );

  return {
    referralLinksRoute,
  };
};
