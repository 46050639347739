import { ApolloError } from '@apollo/client';
import { LocalError } from 'const';
import {
  blurActiveElement,
  checkIsDateRangeNonNullable,
  mapRewardPeriodToDateRange,
} from 'helpers';
import { useGetCurrentRewardPeriodQuery } from 'hooks/queryHooks/useGetCurrentRewardPeriodQuery';
import { useDateRangePageParams } from 'hooks/useDateRangePageParams';
import { useTranslation } from 'react-i18next';
import { DateRange, NonNullableDateRange } from 'types';
import { DatePickerRange } from 'ui/DatePicker';
import { toast } from 'ui/toast';

export interface UseStatisticDateRangeResult {
  defaultDateRange?: DateRange;
  dateRange?: NonNullableDateRange;
  updateDateRange: (dates: DatePickerRange) => void;
  loading: boolean;
  error?: ApolloError | LocalError;
}

export const useStatisticDateRange = (): UseStatisticDateRangeResult => {
  const { t } = useTranslation(['common', 'statistic']);

  const {
    rewardPeriod,
    loading: queryLoading,
    error: queryError,
  } = useGetCurrentRewardPeriodQuery();

  const defaultDateRange: DateRange | undefined =
    rewardPeriod && mapRewardPeriodToDateRange(rewardPeriod);

  const {
    dateRange,
    setDateRange,
    loading: paramLoading,
  } = useDateRangePageParams({
    defaultDateRange,
  });

  const updateDateRange = (dates: DatePickerRange) => {
    if (!checkIsDateRangeNonNullable(dates)) {
      toast.error({
        header: t('common:errors.commonError'),
        text: LocalError.NullableDateRange,
      });

      return;
    }

    blurActiveElement();
    setDateRange(dates);
  };

  return {
    defaultDateRange,
    dateRange,
    updateDateRange,
    loading: queryLoading || paramLoading,
    error: queryError,
  };
};
