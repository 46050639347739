import { useMemo } from 'react';
import { Currency } from 'types';
import { getCurrencyDisplayName } from 'utils/currency/getCurrencyDisplayName';
import { getCurrencyNameWithSymbol } from 'utils/currency/getCurrencyNameWithSymbol';
import { Icon } from 'ui/Icon';
import { mapCurrencyToIcon } from 'utils/currency/mapCurrencyToIcon';
import { useGetAvailableCurrenciesQuery } from './queryHooks/useGetAvailableCurrenciesQuery';
import { Language } from '../types/generated/gql';
import { useLanguage } from '../contexts/LanguageContext';
import { getSortedCurrencies } from '../utils/currency/getSortedCurrencies';

interface MapCurrencyToSelectOptionsParams {
  currencies: Array<Currency>;
  language: Language;
}

export const mapCurrencyToSelectOptions = ({
  currencies,
  language,
}: MapCurrencyToSelectOptionsParams) =>
  currencies.map((currency) => ({
    label: getCurrencyDisplayName({ currencyCode: currency, language }),
    value: currency,
    leftElement: <Icon iconComponent={mapCurrencyToIcon[currency]} />,
    labelRight: getCurrencyNameWithSymbol(currency),
  }));

export const useCurrencySelectOptions = (selectedCurrency?: Currency) => {
  const { currentLanguage } = useLanguage();

  const { availableCurrencies, loading, error } =
    useGetAvailableCurrenciesQuery();

  const sortedCurrencies = useMemo(() => {
    if (!availableCurrencies) return [];

    return getSortedCurrencies({
      currencies: availableCurrencies,
      language: currentLanguage,
      selectedCurrency,
    });
  }, [availableCurrencies, currentLanguage, selectedCurrency]);

  const currencySelectOptions = useMemo(() => {
    if (!availableCurrencies || !sortedCurrencies.length) return [];

    return mapCurrencyToSelectOptions({
      currencies: sortedCurrencies,
      language: currentLanguage,
    });
  }, [availableCurrencies, sortedCurrencies, currentLanguage]);

  return { currencySelectOptions, loading, error };
};
