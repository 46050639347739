import { useSearchParams } from 'react-router-dom';
import { useDebounce } from 'hooks/useDebounce';
import { useCallback } from 'react';

export const useSearchHandler = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleSearchParams = useCallback(
    (value: string) => {
      const currentParams = searchParams.get('search') ?? '';

      if (currentParams === value) {
        return;
      }

      setSearchParams(
        () => {
          if (value.length === 0) {
            searchParams.delete('search');
          } else {
            searchParams.set('search', value);
          }

          return searchParams;
        },
        { replace: true }
      );
    },
    [setSearchParams, searchParams]
  );

  const debouncedSearch = useDebounce(handleSearchParams);

  const handleSearchChange = (value: string) => {
    if (value.length === 0 || value.length > 1) {
      debouncedSearch(value);
    }
  };

  return { onSearchChange: handleSearchChange };
};
