import { FC, useState } from 'react';
import {
  InputPassword,
  InputPasswordType,
} from 'ui/formItems/components/InputPassword/InputPassword';
import { InputPasswordConfig } from './types';

interface InputPasswordWithConfirmProps {
  passwordConfig: InputPasswordConfig;
  passwordConfirmConfig: InputPasswordConfig;
}

export const InputPasswordWithConfirm: FC<InputPasswordWithConfirmProps> = ({
  passwordConfig,
  passwordConfirmConfig,
}) => {
  const [currentInputType, setCurrentInputType] =
    useState<InputPasswordType>('password');

  return (
    <>
      <InputPassword
        formValidationProps={{
          errorTooltipPlacement: passwordConfig.errorTooltipPlacement,
        }}
        name={passwordConfig.name}
        label={passwordConfig.label}
        autoComplete="new-password"
        forcedCurrentInputType={currentInputType}
        onCurrentInputTypeChange={setCurrentInputType}
      />
      <InputPassword
        formValidationProps={{
          errorTooltipPlacement: passwordConfirmConfig.errorTooltipPlacement,
        }}
        name={passwordConfirmConfig.name}
        label={passwordConfirmConfig.label}
        autoComplete="new-password"
        forcedCurrentInputType={currentInputType}
        onCurrentInputTypeChange={setCurrentInputType}
      />
    </>
  );
};
