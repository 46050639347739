import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AppRoute } from 'const';

export const useFooterContactUsLabel = () => {
  const { pathname } = useLocation();
  const { t } = useTranslation('auth');

  if (
    pathname.includes(AppRoute.Lang.RegistrationTransferCompany) ||
    pathname.includes(AppRoute.Lang.RegistrationTransferPartner)
  ) {
    return t('migration.createAccount.footer.cantCreate');
  }

  if (pathname.includes(AppRoute.Lang.Registration)) {
    return t('footer.label.signUpProblems');
  }

  if (pathname.includes(AppRoute.Lang.LoginTransfer)) {
    return t('migration.transferData.footer.gotQuestion');
  }

  return t('footer.label.loginProblems');
};
