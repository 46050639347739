import { Formik, Form } from 'formik';
import { FC } from 'react';
import { CompanyReferralLinkMediaItemsFilterType } from 'types/generated/gql';
import { useTranslation } from 'react-i18next';
import { useSearchHandler } from 'hooks/table/useSearchHandler';
import { SearchInput } from 'ui/SearchInput';
import { useFiltersHandlers } from 'hooks/table/useFiltersHandlers';
import { ReferralLinkMediaTableSearchValues } from './types';
import { FieldName } from './const';
import { CompanyReferralLinkMediaTableFilters } from '../CompanyReferralLinkMediaTableFilters';
import styles from './CompanyReferralLinkMediaTableSearch.module.scss';

interface PartnerCpaStatisticTableSearchProps {
  initialValues?: ReferralLinkMediaTableSearchValues;
  disabled?: boolean;
}

export const CompanyReferralLinkMediaTableSearch: FC<
  PartnerCpaStatisticTableSearchProps
> = ({
  initialValues = {
    [FieldName.Search]: '',
    [FieldName.Filters]: undefined,
  },
  disabled = false,
}) => {
  const { t } = useTranslation('media');

  const { onSearchChange } = useSearchHandler();
  const { onFiltersChange, hasFilters } = useFiltersHandlers();

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={({ search, filters }) => {
        if (filters) {
          onFiltersChange<CompanyReferralLinkMediaItemsFilterType>(filters);
        }

        onSearchChange(search);
      }}
    >
      {({ values, handleChange, handleSubmit, setFieldValue }) => (
        <Form className={styles.form}>
          <SearchInput
            value={values.search}
            fullWidth
            filterButtonText={t(
              'company.action.filters.button.filtersAndSettings'
            )}
            onChange={(e) => {
              handleChange(e);
              handleSubmit();
            }}
            onClear={() => {
              setFieldValue(FieldName.Search, '');
              handleSubmit();
            }}
            name={FieldName.Search}
            placeholder={t('company.input.label.search')}
            disabled={disabled || hasFilters}
            filters={
              <CompanyReferralLinkMediaTableFilters
                onChange={handleSubmit}
                onClear={() => {
                  setFieldValue(FieldName.Filters, {});
                  handleSubmit();
                }}
              />
            }
          />
        </Form>
      )}
    </Formik>
  );
};
