import { useAuth } from 'components/auth';
import { NonNullableDateRange } from 'types';
import { DateFormat, formatDate } from 'utils/formatDate';
import { useApolloClient } from '@apollo/client';
import { STATISTIC_ITEMS_PER_PAGE } from '../const';
import {
  GetCpaStatisticDetailed,
  GetCpaStatisticDetailedDocument,
  useGetCpaStatisticDetailed,
} from '../query/generated/GetCpaStatisticDetailed';

const { DateScalar } = DateFormat;

interface UseGetCpaStatisticDetailedQueryParams {
  cpaMediaItemId: string;
  nonNullableDateRange: NonNullableDateRange;
  searchQuery?: string;
}

export const useGetCpaStatisticDetailedQuery = ({
  cpaMediaItemId,
  nonNullableDateRange,
  searchQuery,
}: UseGetCpaStatisticDetailedQueryParams) => {
  const { user } = useAuth();
  const [start, end] = nonNullableDateRange;
  const client = useApolloClient();

  const cachedGetCpaStatisticDetailedQuery =
    client.readQuery<GetCpaStatisticDetailed>({
      query: GetCpaStatisticDetailedDocument,
      variables: {
        start: formatDate(start, DateScalar),
        end: formatDate(end, DateScalar),
        userId: user.id,
        cpaMediaItemId,
        ...(Boolean(searchQuery) && { referralNameSearch: searchQuery }),
      },
    });

  const cachedCpaStatisticDetailedEdges =
    cachedGetCpaStatisticDetailedQuery?.user?.referralLinkMediaItem
      ?.cpaStatistic?.statisticItems?.edges;

  const first =
    cachedCpaStatisticDetailedEdges &&
    cachedCpaStatisticDetailedEdges.length > 0
      ? cachedCpaStatisticDetailedEdges.length
      : STATISTIC_ITEMS_PER_PAGE;

  const { data, fetchMore, isSliced, loading, error } =
    useGetCpaStatisticDetailed({
      variables: {
        start: formatDate(start, DateScalar),
        end: formatDate(end, DateScalar),
        userId: user.id,
        cpaMediaItemId,
        first,
        ...(Boolean(searchQuery) && { referralNameSearch: searchQuery }),
      },
      notifyOnNetworkStatusChange: true,
      cacheFirstOnPop: true,
    });

  const cpaStatisticDetailed = data?.user.referralLinkMediaItem;

  const pageInfo = cpaStatisticDetailed?.cpaStatistic.statisticItems.pageInfo;

  return {
    cpaStatisticDetailed,
    fetchMore,
    pageInfo,
    loading,
    isSliced,
    error,
  };
};
