import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterType, NonNullableDateRange } from 'types';
import { PartnerStatisticFilterType } from 'types/generated/gql';
import { getMoneyAmount } from 'utils/currency/getMoneyAmount';
import { useGetPartnerCpaStatisticTotalQuery } from './useGetPartnerCpaStatisticTotalQuery';
import { PartnerCpaStatisticInfoData } from '../queries/fragments/generated/PartnerCpaStatisticInfoData';

type GetSummaryRowData = (
  info: PartnerCpaStatisticInfoData
) => Array<ReactNode>;

const getSummaryRowData: GetSummaryRowData = ({
  hits,
  hosts,
  registrations,
  firstDeposits,
  depositsAll,
  hold,
  qualified,
  approved,
  paid,
  rejected,
  reward,
  averageDeposit,
  redepositsAll,
  conversionHostsToRegPercent,
  conversionRegToDepPercent,
  numberOfRedeposits,
  withdrawalsAll,
}) => {
  const result: Array<ReactNode> = [
    hits,
    hosts,
    registrations,
    firstDeposits,
    getMoneyAmount(depositsAll),
    hold,
    qualified,
    approved,
    paid,
    rejected,
    getMoneyAmount(reward),
    getMoneyAmount(averageDeposit),
    getMoneyAmount(redepositsAll),
    `${conversionHostsToRegPercent}%`,
    `${conversionRegToDepPercent}%`,
    numberOfRedeposits,
    getMoneyAmount(withdrawalsAll),
  ];

  return result;
};

interface UseGeneratePartnerCpaStatisticSummaryRowParams {
  nonNullableDateRange: NonNullableDateRange;
  filters: Array<FilterType<PartnerStatisticFilterType>>;
  searchQuery?: string;
}

export const useGeneratePartnerCpaStatisticSummaryRow = ({
  nonNullableDateRange,
  searchQuery,
  filters,
}: UseGeneratePartnerCpaStatisticSummaryRowParams) => {
  const { t } = useTranslation('statistic');

  const { totalStatistic, error, loading } =
    useGetPartnerCpaStatisticTotalQuery({
      nonNullableDateRange,
      searchQuery,
      filters,
    });

  const summaryRowData = totalStatistic
    ? [
        null,
        t('partner.content.dataTable.cpa.allMedia'),
        ...getSummaryRowData(totalStatistic),
      ]
    : undefined;

  return { summaryRowData, loading, error };
};
