import { useTranslation } from 'react-i18next';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { PaymentModel } from 'components/PaymentModel';
import { CopyLink } from 'ui/CopyLink';
import { TableCellAlign } from 'ui/Table/components/TableColumnCell/types';
import { MultilineCellWithIcon } from 'ui/Table/components/MultilineCellWithIcon';
import { ReferralLinkMediaItemBasicData } from '../../../../../../../queries/fragments/generated/ReferralLinkMediaItemBasicData';
import { useOpenReferralLinkModal } from '../../../../../../../hooks/useOpenReferralLinkModal';
import { EditTab } from '../../../../../../EditReferralLinkMediaModal/const';
import { PostbackButton } from '../../../../PostbackButton';
import { LinkBoosters } from '../../../../LinkBoosters';

const COLUMNS_SIZES = {
  nameAndId: 320,
  tariffSize: 268,
  linkMinSize: 268,
  paymentModelSize: 192,
  postbackSize: 112,
  linkBoostersSize: 112,
} as const;

export const usePartnerReferralLinkMediaTableColumns = (): Array<
  ColumnDef<ReferralLinkMediaItemBasicData>
> => {
  const { t } = useTranslation(['common', 'media']);

  const { openEditModal } = useOpenReferralLinkModal();

  const columnHelper = createColumnHelper<ReferralLinkMediaItemBasicData>();

  /*
   *  We need to use any as generic argument while bug won't be fixed
   *  Issue https://github.com/TanStack/table/issues/4382
   */
  const columns: Array<ColumnDef<ReferralLinkMediaItemBasicData, any>> = [
    columnHelper.accessor('id', {
      header: t('media:partner.content.dataTable.links.column.name'),
      cell: ({ row }) => <MultilineCellWithIcon title={row.original.name} />,
      size: COLUMNS_SIZES.nameAndId,
      enablePinning: true,
    }),
    columnHelper.accessor('tariff', {
      header: t('media:partner.content.dataTable.links.column.tariff'),
      cell: ({ row }) => row.original.tariff.name,
      size: COLUMNS_SIZES.tariffSize,
    }),
    columnHelper.accessor('link', {
      header: t('media:partner.content.dataTable.links.column.link'),
      cell: (info) => (
        <CopyLink
          onClick={(e) => e.stopPropagation()}
          target="_blank"
          to={info.getValue()}
          tooltipText={t('linkCopied')}
        >
          {info.getValue()}
        </CopyLink>
      ),
      minSize: COLUMNS_SIZES.linkMinSize,
    }),
    columnHelper.accessor('tariff', {
      id: 'paymentModel',
      header: t('media:partner.content.dataTable.links.column.paymentType'),
      cell: ({ row }) => (
        <PaymentModel tariffTypeName={row.original.tariff.__typename} />
      ),
      size: COLUMNS_SIZES.paymentModelSize,
    }),
    columnHelper.accessor('postbackInfo', {
      header: t('media:partner.content.dataTable.links.column.postback'),
      cell: ({ row }) => (
        <PostbackButton
          status={row.original.postbackInfo.commonPostbackStatus}
          onClick={(e) => {
            e.stopPropagation();
            openEditModal(row.original.id, EditTab.Postbacks);
          }}
        />
      ),
      meta: {
        align: TableCellAlign.Center,
      },
      size: COLUMNS_SIZES.postbackSize,
    }),

    columnHelper.display({
      id: 'link_boosters',
      header: () => null,
      cell: ({ row }) => (
        <LinkBoosters
          id={row.original.id}
          tariffTypeName={row.original.tariff.__typename}
        />
      ),
      size: COLUMNS_SIZES.linkBoostersSize,
      meta: {
        disableCellOverflow: true,
        align: TableCellAlign.Right,
      },
    }),
  ];

  return columns;
};
