export enum ButtonSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export enum ButtonTheme {
  Black = 'black',
  AccentTonal = 'accentTonal',
  BlackTonal = 'blackTonal',
  BlackGhost = 'blackGhost',
  GreyGhost = 'greyGhost',
}

export enum ButtonTextColor {
  White = 'white',
  Grey = 'grey',
  Black = 'black',
}

export enum LinkStyledButtonTextColor {
  Black = 'black',
  Grey = 'grey',
  Blue = 'blue',
}

export enum ButtonAlign {
  Center = 'center',
  Left = 'left',
  Right = 'right',
}
