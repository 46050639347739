import { HTMLInputAutoCompleteAttribute, useEffect, useState } from 'react';
import { useField } from 'formik';
import { ReactComponent as EyeIcon } from 'assets/icons/line/Eye.svg';
import { ReactComponent as EyeClosedIcon } from 'assets/icons/line/EyeClosed.svg';
import { Input, InputProps } from '../Input/Input';
import { IconComponent } from '../../../../types';
import { ValidationHintRule } from '../ValidationHint/types';
import { ValidationHint } from '../ValidationHint';
import { getDefaultPasswordValidationHints } from './helpers';

export type InputPasswordType = Extract<
  React.HTMLInputTypeAttribute,
  'password' | 'text'
>;

const mapInputTypeToIcon: Record<InputPasswordType, IconComponent> = {
  password: EyeIcon,
  text: EyeClosedIcon,
};

interface InputPasswordProps extends Omit<InputProps, 'autoComplete'> {
  autoComplete: Extract<
    HTMLInputAutoCompleteAttribute,
    'current-password' | 'new-password' | 'off'
  >;
  className?: string;
  showValidationHint?: boolean;
  validationHintRules?: Array<ValidationHintRule>;
  forcedCurrentInputType?: InputPasswordType;
  onCurrentInputTypeChange?: (type: InputPasswordType) => void;
}

export const InputPassword: React.FC<InputPasswordProps> = ({
  className,
  autoComplete,
  showValidationHint = true,
  validationHintRules,
  forcedCurrentInputType,
  onCurrentInputTypeChange,
  ...rest
}) => {
  const { name } = rest;

  const [currentInputType, setCurrentInputType] = useState<'password' | 'text'>(
    'password'
  );

  const [, meta] = useField(name);

  const [focused, setFocused] = useState<boolean>(false);

  const CurrentSvg = mapInputTypeToIcon[currentInputType];

  const handleShowEyeIconClick: React.MouseEventHandler<HTMLSpanElement> = (
    e
  ) => {
    e.preventDefault();
    setCurrentInputType((prev) => (prev === 'password' ? 'text' : 'password'));
    onCurrentInputTypeChange?.(
      currentInputType === 'password' ? 'text' : 'password'
    );
  };

  const handleFocus = () => {
    if (!showValidationHint) {
      return;
    }

    setFocused(true);
  };

  const handleBlur = () => {
    setFocused(false);
  };

  useEffect(() => {
    setCurrentInputType(forcedCurrentInputType ?? 'password');
  }, [forcedCurrentInputType]);

  if (showValidationHint) {
    return (
      <ValidationHint
        isOpen={focused && !meta.error}
        rules={validationHintRules ?? getDefaultPasswordValidationHints()}
        value={meta.value}
      >
        <Input
          {...rest}
          onFocus={handleFocus}
          onBlur={handleBlur}
          type={currentInputType}
          rightIconConfig={{
            icon: CurrentSvg,
            onClick: handleShowEyeIconClick,
          }}
          autoComplete={autoComplete}
        />
      </ValidationHint>
    );
  }

  return (
    <Input
      {...rest}
      type={currentInputType}
      rightIconConfig={{
        icon: CurrentSvg,
        onClick: handleShowEyeIconClick,
      }}
      autoComplete={autoComplete}
    />
  );
};
