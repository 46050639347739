import { useCallback, useEffect } from 'react';
import { Currency } from 'types';
import { useSearchParams } from 'react-router-dom';
import { checkIsCurrency } from 'utils/currency/checkIsCurrency';
import { extractCurrencyFromSearchParams } from './helpers';
import { CURRENCY_PAGE_PARAM } from './const';

interface UseCurrencyParamsProps {
  defaultCurrency?: Currency;
}

export const useCurrencyParams = ({
  defaultCurrency,
}: UseCurrencyParamsProps = {}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentCurrency = extractCurrencyFromSearchParams(searchParams);

  const setCurrency = useCallback(
    (currency?: Currency) => {
      if (!currency || !checkIsCurrency(currency)) {
        return;
      }

      setSearchParams(
        (params) => {
          params.set(CURRENCY_PAGE_PARAM, currency.toLowerCase());

          return params;
        },
        { replace: true }
      );
    },
    [setSearchParams]
  );

  useEffect(() => {
    if (currentCurrency || !defaultCurrency) {
      return;
    }

    if (checkIsCurrency(defaultCurrency)) {
      setCurrency(defaultCurrency);
    }
  }, [currentCurrency, defaultCurrency, setCurrency]);

  return {
    currency: currentCurrency,
    loading: !currentCurrency,
    setCurrency,
  };
};
