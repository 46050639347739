import { FormikErrors } from 'formik';
import { getRequiredErrors } from 'utils/form/getRequiredFormikErrors';
import { t } from 'i18n';
import { CreatePartnerMediaFormValues } from './types';

export const validateCreatePartnerMediaForm = (
  values: CreatePartnerMediaFormValues
): FormikErrors<CreatePartnerMediaFormValues> =>
  getRequiredErrors(
    values,
    {
      mediaItemName: true,
      mediaCampaignId: true,
      tariffId: true,
    },
    {
      mediaItemName: t(
        `media:partner.content.drawer.createMedia.input.validation.titleRequired`
      ),
      mediaCampaignId: t(
        `media:partner.content.drawer.createMedia.input.validation.mediaCampaignRequired`
      ),
      tariffId: t(
        `media:partner.content.drawer.createMedia.input.validation.tariffRequired`
      ),
    }
  );
