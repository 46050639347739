import { FC, ReactNode } from 'react';
import classNames from 'classnames';
import { Breakpoints } from 'const';
import { getMinimalUnderBreakpoint } from 'helpers';
import { BottomSheet } from 'ui/BottomSheet';
import { PopUp } from 'ui/PopUp';
import { BottomSheetProps } from 'ui/BottomSheet/BottomSheet';
import { PopUpProps } from 'ui/PopUp/PopUp';
import { useDropdownTrigger } from './hooks/useDropdownTrigger';
import { DropdownContainer } from './components/DropdownContainer';
import { DropdownPosition, DropdownSize } from './const';
import styles from './Dropdown.module.scss';

export interface DropdownProps {
  children: ReactNode;
  isOpenForced?: boolean;
  addStyleToContainer?: boolean;
  onOpenChange?: (isOpen: boolean) => void;
  trigger?: ReactNode;
  position?: DropdownPosition;
  withStyleMaxContent?: boolean;
  hasGrayOutline?: boolean;
  title: string;
  bottomSheetProps?: Pick<BottomSheetProps, 'contentClassName'>;
  popUpProps?: Pick<PopUpProps, 'contentClassName'>;
  dropdownSize?: DropdownSize;
  /**
   * TODO: remove `withBottomSheet` and `withPopUp` props when
   * URL input component is implemented
   * Issue https://eyeconweb.atlassian.net/browse/AMS-1859
   */
  withBottomSheet?: boolean;
  withPopUp?: boolean;
}

export const Dropdown: FC<DropdownProps> = ({
  children,
  isOpenForced,
  addStyleToContainer = true,
  onOpenChange,
  trigger,
  position,
  withStyleMaxContent = true,
  hasGrayOutline,
  title,
  withBottomSheet = true,
  withPopUp = true,
  bottomSheetProps,
  popUpProps,
  dropdownSize = DropdownSize.Medium,
}) => {
  const { isOpen, handleClick, handleBlur } = useDropdownTrigger({
    isOpenForced,
    onOpenChange,
  });

  const isMobile = getMinimalUnderBreakpoint() === Breakpoints.Sm;
  const isTablet = getMinimalUnderBreakpoint() === Breakpoints.Md;

  const showDesktopDropdown = (!isMobile && !isTablet) || !withBottomSheet;
  const showMobileDropdown = isMobile && withBottomSheet;
  const showTabletDropdown = isTablet && withPopUp;

  return (
    <>
      <div
        className={classNames(styles.container, {
          [styles.withStyleMaxContent]: withStyleMaxContent,
        })}
        tabIndex={0}
        role="menu"
        onBlur={(e) => {
          if (!isMobile && !isTablet) {
            handleBlur(e);
          }
        }}
      >
        {trigger && <div onClick={handleClick}>{trigger}</div>}
        {isOpen && showDesktopDropdown && (
          <DropdownContainer
            position={position}
            withStyleMaxContent={withStyleMaxContent}
            addStyleToContainer={addStyleToContainer}
            hasGrayOutline={hasGrayOutline}
            dropdownSize={dropdownSize}
          >
            {children}
          </DropdownContainer>
        )}
      </div>
      {showMobileDropdown && (
        <BottomSheet
          title={title}
          isOpen={isOpen}
          onClose={handleClick}
          onBlur={handleBlur}
          contentClassName={bottomSheetProps?.contentClassName}
        >
          {children}
        </BottomSheet>
      )}
      {showTabletDropdown && (
        <PopUp
          title={title}
          isOpen={isOpen}
          onClose={handleClick}
          onBlur={handleBlur}
          contentClassName={popUpProps?.contentClassName}
        >
          {children}
        </PopUp>
      )}
    </>
  );
};
