import { FC } from 'react';
import classNames from 'classnames';
import { ListItem } from '../types';
import styles from './List.module.scss';

interface ListProps {
  items: Array<ListItem>;
}

export const List: FC<ListProps> = ({ items }) => (
  <ul className={styles.list}>
    {items.map((item) => (
      <li key={item.number || item.text} className={styles.listItem}>
        <span
          className={classNames(styles.listType, {
            [styles.numeric]: Boolean(item?.number),
          })}
        >
          {item?.number ? item.number : '—'}
        </span>
        <div>
          {item.text}
          {item.items && (
            <ul className={styles.subItemList}>
              <List items={item.items} />
            </ul>
          )}
        </div>
      </li>
    ))}
  </ul>
);
