import { Language } from 'types/generated/gql';
import { ListItem } from 'ui/List';
import { mapLanguageToDisplayName } from 'utils/language/mapLanguageToDisplayName';
import { mapLanguageToCountryCode } from 'utils/language/mapLanguageToCountryCode';
import { getImgSrcByCountry } from 'utils/countries/getImgSrcByCountry';
import { Icon } from 'ui/Icon';
import { DropdownValue } from './types';

export const getDropdownValueByLanguage = (code: Language): DropdownValue => {
  const countryCode = mapLanguageToCountryCode[code];
  const countryFlagIcon = getImgSrcByCountry(countryCode);

  return {
    value: code,
    label: mapLanguageToDisplayName(code),
    icon: countryFlagIcon && <Icon iconComponent={countryFlagIcon} />,
  };
};

export const getListItemsByLanguageCodes = (
  onClick: (value?: Language) => void,
  languageList: Array<DropdownValue>
): Array<ListItem<Language>> => {
  if (!languageList?.length) {
    return [];
  }

  return languageList.map(({ value }) => {
    const countryCode = mapLanguageToCountryCode[value];
    const countryFlagIcon = getImgSrcByCountry(countryCode);

    return {
      label: mapLanguageToDisplayName(value),
      leftElement: countryFlagIcon && <Icon iconComponent={countryFlagIcon} />,
      value,
      onClick,
    };
  });
};
