import { FilterCombination } from 'types';

const ALLOWED_FILTERS_COUNT = 2;

interface GetIsFilterAllowedParams<T extends string> {
  filterKey: T;
  combinations: FilterCombination<T>;
  selectedFilters: Array<T>;
}

export const getIsFilterAllowed = <T extends string>({
  filterKey,
  combinations,
  selectedFilters,
}: GetIsFilterAllowedParams<T>) => {
  if (!selectedFilters.length || selectedFilters.includes(filterKey)) {
    return true;
  }

  if (selectedFilters.length >= ALLOWED_FILTERS_COUNT) {
    return false;
  }

  const isDisabled = selectedFilters.some((selectedFilter) => {
    const availableFilters = combinations[selectedFilter] ?? [];
    const compatibleFilters = combinations[filterKey] ?? [];

    return (
      availableFilters.includes(filterKey) ||
      compatibleFilters.includes(selectedFilter)
    );
  });

  return isDisabled;
};
