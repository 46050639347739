import { Formik, Form } from 'formik';
import { FC } from 'react';
import { AccessorKeyColumnDef } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { useSearchHandler } from 'hooks/table/useSearchHandler';
import { SearchInput } from 'ui/SearchInput';
import { ColumnsSelect } from 'components/ColumnsSelect';
import { Currency } from 'types';
import { useFiltersHandlers } from 'hooks/table/useFiltersHandlers';
import { CompanyStatisticFilterType } from 'types/generated/gql';
import { CompanyRevShareStatisticTableSearchValues } from './types';
import { FieldName } from './const';
import { CompanyRevShareStatisticCurrencySetting } from '../CompanyRevShareStatisticCurrencySetting';
import styles from './CompanyRevShareStatisticTableSearch.module.scss';
import { CompanyRevShareStatisticTableFilters } from '../CompanyRevShareStatisticTableFilters';
import { CompanyRevShareStatisticTableData } from '../../types';

interface CompanyRevShareStatisticTableSearchProps {
  columns: Array<AccessorKeyColumnDef<CompanyRevShareStatisticTableData>>;
  updateCurrency: (currency: Currency) => void;
  initialValues?: CompanyRevShareStatisticTableSearchValues;
  onColumnsChange?: (columns: Array<string>) => void;
  disabled?: boolean;
}

export const CompanyRevShareStatisticTableSearch: FC<
  CompanyRevShareStatisticTableSearchProps
> = ({
  initialValues = {
    [FieldName.Search]: '',
    [FieldName.Columns]: {},
    [FieldName.Currency]: Currency.USD,
    [FieldName.Filters]: undefined,
  },
  disabled = false,
  columns,
  onColumnsChange,
  updateCurrency,
}) => {
  const { t } = useTranslation('statistic');

  const { onSearchChange } = useSearchHandler();
  const { onFiltersChange, hasFilters } = useFiltersHandlers();

  const handleColumnChange = (columnsValues: Record<string, boolean>) => {
    const selectedColumns = Object.keys(columnsValues).filter(
      (key) => columnsValues[key]
    );

    onColumnsChange?.(selectedColumns);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={({ search, columns: columnsValues, filters }) => {
        if (filters) {
          onFiltersChange<CompanyStatisticFilterType>(filters);
        }

        onSearchChange(search);
        handleColumnChange(columnsValues);
      }}
    >
      {({ values, handleChange, handleSubmit, setFieldValue }) => (
        <Form className={styles.form}>
          <SearchInput
            value={values.search}
            fullWidth
            filterButtonText={t(
              'company.action.filters.button.filtersAndSettings'
            )}
            onChange={(e) => {
              handleChange(e);
              handleSubmit();
            }}
            onClear={() => {
              setFieldValue(FieldName.Search, '');
              handleSubmit();
            }}
            name={FieldName.Search}
            placeholder={t('company.action.search.label.search')}
            disabled={disabled || hasFilters}
            filters={
              <CompanyRevShareStatisticTableFilters
                onChange={handleSubmit}
                onClear={() => {
                  setFieldValue(FieldName.Filters, {});
                  handleSubmit();
                }}
              />
            }
            settings={
              <div className={styles.settingsWrapper}>
                <CompanyRevShareStatisticCurrencySetting
                  currentCurrency={values.currency}
                  onChange={(currency: Currency) => {
                    setFieldValue(FieldName.Currency, currency);
                    updateCurrency(currency);
                  }}
                />
                <ColumnsSelect<CompanyRevShareStatisticTableData>
                  columns={columns}
                  buttonText={t('company.action.filters.button.editColumns')}
                  dropdownTitle={t('company.action.filters.button.editColumns')}
                  onColumnsChange={handleSubmit}
                />
              </div>
            }
          />
        </Form>
      )}
    </Formik>
  );
};
