import { NavLinksList, NavLinksListItem } from 'components/NavLinksList';
import { AppRoute } from 'const';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { composeRoutes } from 'utils/routing/composeRoutes';
import { useCreateLocalizedPath } from 'hooks/useCreateLocalizedPath';
import { useTranslationUserType } from 'hooks/useTranslationUserType';

export const MediaNavLinkList: FC = () => {
  const { t } = useTranslation('media');
  const { createLocalizedPath } = useCreateLocalizedPath();
  const translationUserType = useTranslationUserType();

  const navLinksListItems: Array<NavLinksListItem> = [
    {
      title: t(`${translationUserType}.action.tabMenu.links`),
      path: createLocalizedPath(
        composeRoutes([
          AppRoute.Lang.Media.root,
          AppRoute.Lang.Media.Links.root,
        ])
      ),
    },
    {
      title: t(`${translationUserType}.action.tabMenu.promoCodes`),
      path: createLocalizedPath(
        composeRoutes([
          AppRoute.Lang.Media.root,
          AppRoute.Lang.Media.PromoCodes.root,
        ])
      ),
    },
  ];

  return <NavLinksList items={navLinksListItems} />;
};
