import * as SchemaTypes from '../../../../../types/generated/gql';

import * as CommonType from 'types';
import { DocumentNode } from 'graphql';
export type ReferralLinkBasicData = {
  __typename: 'ReferralLinkMediaItem';
  link: string;
  id: string;
  name: string;
};

export const ReferralLinkBasicData = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ReferralLinkBasicData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ReferralLinkMediaItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ReferralLinkMediaItem' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'link' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
