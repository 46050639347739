import classNames from 'classnames';
import { FC } from 'react';
import styles from './TruncatedTextWithHint.module.scss';
import { useTruncateText } from './hooks/useTruncateText';
import { Tooltip } from '../Tooltip';

interface TruncatedTextWithHintProps {
  text: string;
  className?: string;
}

export const TruncatedTextWithHint: FC<TruncatedTextWithHintProps> = ({
  text,
  className,
}) => {
  const { truncated, clampLines, textRef } = useTruncateText();

  return (
    <Tooltip
      tooltipTriggerClassName={styles.tooltipTrigger}
      placement="top-end"
      tooltipContent={text}
      disableHover={!truncated}
      shouldSetWidthFitContent={false}
    >
      <span
        ref={textRef}
        className={classNames(styles.text, className)}
        style={{ WebkitLineClamp: clampLines }}
      >
        {text}
      </span>
    </Tooltip>
  );
};
