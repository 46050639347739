import { CompanyRevShareStatisticOrderByType } from 'types/generated/gql';
import { CompanyRevShareStatisticSortableColumnsType } from '../types';

export const mapColumnToSortColumn: Record<
  keyof CompanyRevShareStatisticSortableColumnsType,
  CompanyRevShareStatisticOrderByType
> = {
  hits: CompanyRevShareStatisticOrderByType.Hits,
  hosts: CompanyRevShareStatisticOrderByType.Hosts,
  registrations: CompanyRevShareStatisticOrderByType.Registrations,
  firstDeposits: CompanyRevShareStatisticOrderByType.FirstDeposits,
  depositsAll: CompanyRevShareStatisticOrderByType.DepositsAll,
  reward: CompanyRevShareStatisticOrderByType.Reward,
  conversionHostsToRegPercent:
    CompanyRevShareStatisticOrderByType.ConversionHostsToRegPercent,
  conversionRegToDepPercent:
    CompanyRevShareStatisticOrderByType.ConversionRegToDepPercent,
};
