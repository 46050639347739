import { Formik, Form } from 'formik';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PartnerPromoCodeMediaItemsFilterType } from 'types/generated/gql';
import { useFiltersHandlers } from 'hooks/table/useFiltersHandlers';
import { useSearchHandler } from 'hooks/table/useSearchHandler';
import { SearchInput } from 'ui/SearchInput';
import { PartnerPromoCodeMediaTableSearchValues } from './types';
import { FieldName } from './const';
import styles from './PartnerPromoCodeMediaTableSearch.module.scss';
import { PartnerPromoCodeMediaTableFilters } from '../PartnerPromoCodeMediaTableFilters';

interface Props {
  initialValues?: PartnerPromoCodeMediaTableSearchValues;
  disabled?: boolean;
}

export const PartnerPromoCodeMediaTableSearch: FC<Props> = ({
  initialValues = {
    [FieldName.Search]: '',
    [FieldName.Filters]: undefined,
  },
  disabled = false,
}) => {
  const { t } = useTranslation('media');

  const { onSearchChange } = useSearchHandler();
  const { onFiltersChange, hasFilters } = useFiltersHandlers();

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={({ search, filters }) => {
        if (filters) {
          onFiltersChange<PartnerPromoCodeMediaItemsFilterType>(filters);
        }

        onSearchChange(search);
      }}
    >
      {({ values, handleChange, handleSubmit, setFieldValue }) => (
        <Form className={styles.form}>
          <SearchInput
            value={values.search}
            fullWidth
            filterButtonText={t(
              `partner.action.filters.button.filtersAndSettings`
            )}
            onChange={(e) => {
              handleChange(e);
              handleSubmit();
            }}
            onClear={() => {
              setFieldValue(FieldName.Search, '');
              handleSubmit();
            }}
            name={FieldName.Search}
            placeholder={t(`partner.input.label.search`)}
            disabled={disabled || hasFilters}
            filters={
              <PartnerPromoCodeMediaTableFilters
                onChange={handleSubmit}
                onClear={() => {
                  setFieldValue(FieldName.Filters, {});
                  handleSubmit();
                }}
              />
            }
          />
        </Form>
      )}
    </Formik>
  );
};
