import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Dropdown } from 'ui/Dropdown';
import { blurActiveElement } from 'helpers';
import { Button, ButtonSize } from 'ui/Button';
import { ReactComponent as MenuIcon } from 'assets/icons/line/Menu.svg';
import { LinkStyledButtonTextColor } from 'ui/Button/const';
import styles from './MobileNavigationHeader.module.scss';

interface MobileNavigationHeaderProps {
  currentSectionTitle: string;
  navigationSectionsData: Array<{
    title: string;
    links: Array<{ id: string; label: string }>;
  }>;
}

export const MobileNavigationHeader: FC<MobileNavigationHeaderProps> = ({
  currentSectionTitle,
  navigationSectionsData,
}) => {
  const { t } = useTranslation('termsOfUse');
  const navigate = useNavigate();

  const handleClick = (id: string) => {
    const element = document.getElementById(id);

    navigate({ hash: id }, { replace: true });

    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }

    blurActiveElement();
  };

  return (
    <div className={styles.mobileNavigationContainer}>
      <span className={styles.mobileHeaderTitle}>{currentSectionTitle}</span>
      <Dropdown
        title={t('content.main.title')}
        withStyleMaxContent={false}
        trigger={
          <Button
            linkStyled={{
              color: LinkStyledButtonTextColor.Grey,
            }}
            size={ButtonSize.Large}
            iconConfig={{ iconComponent: MenuIcon }}
          />
        }
      >
        <div className={styles.mobileNavigationContent}>
          {navigationSectionsData.map((section) => (
            <div key={section.title} className={styles.mobileSection}>
              <div className={styles.mobileOptionWrapper}>
                <h3 className={styles.mobileTitle}>{section.title}</h3>
              </div>
              <ul>
                {section.links.map((link) => (
                  <li className={styles.mobileOptionWrapper} key={link.id}>
                    <Button
                      className={styles.mobileOptionButton}
                      onClick={() => handleClick(link.id)}
                      linkStyled={{
                        color: LinkStyledButtonTextColor.Black,
                      }}
                    >
                      {link.label}
                    </Button>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </Dropdown>
    </div>
  );
};
