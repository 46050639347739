import * as SchemaTypes from '../../../../../../../../../../types/generated/gql';

import * as CommonType from 'types';
import { DocumentNode } from 'graphql';
import { MoneyAmountData } from '../../../../../../../../../../queries/fragments/generated/MoneyAmountData';
export type RevSharePromoCodeStatisticInfoData = {
  __typename: 'RevSharePromoCodeStatisticInfo';
  conversionHostsToRegPercent: number;
  conversionRegToDepPercent: number;
  firstDeposits: number;
  numberOfRedeposits: number;
  registrations: number;
  adminFee: {
    __typename: 'MoneyAmount';
    currency: CommonType.Currency;
    value: number;
  };
  averageDeposit: {
    __typename: 'MoneyAmount';
    currency: CommonType.Currency;
    value: number;
  };
  depositsAll: {
    __typename: 'MoneyAmount';
    currency: CommonType.Currency;
    value: number;
  };
  depositsFee: {
    __typename: 'MoneyAmount';
    currency: CommonType.Currency;
    value: number;
  };
  redepositsAll: {
    __typename: 'MoneyAmount';
    currency: CommonType.Currency;
    value: number;
  };
  reward: {
    __typename: 'MoneyAmount';
    currency: CommonType.Currency;
    value: number;
  };
  withdrawalsAll: {
    __typename: 'MoneyAmount';
    currency: CommonType.Currency;
    value: number;
  };
  withdrawalsFee: {
    __typename: 'MoneyAmount';
    currency: CommonType.Currency;
    value: number;
  };
};

export const RevSharePromoCodeStatisticInfoData = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RevSharePromoCodeStatisticInfoData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RevSharePromoCodeStatisticInfo' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'adminFee' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MoneyAmountData' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageDeposit' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MoneyAmountData' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'conversionHostsToRegPercent' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'conversionRegToDepPercent' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'depositsAll' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MoneyAmountData' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'depositsFee' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MoneyAmountData' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'firstDeposits' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfRedeposits' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'redepositsAll' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MoneyAmountData' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'registrations' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reward' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MoneyAmountData' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'withdrawalsAll' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MoneyAmountData' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'withdrawalsFee' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MoneyAmountData' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
