import { ReferralLinkBasicData } from 'pages/Media/queries/fragments/generated/ReferralLinkBasicData';

export const getReferralLinkOptions = (
  referralLinks?: Array<ReferralLinkBasicData>
) =>
  referralLinks
    ? referralLinks.map((referralLink) => ({
        id: referralLink.id,
        label: referralLink.name,
        subLabel: referralLink.link,
      }))
    : [];
