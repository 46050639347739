import { FC } from 'react';
import { IconComponent } from 'types';
import { Icon, IconSize } from 'ui/Icon';
import styles from './EmptySearchState.module.scss';

interface EmptySearchStateProps {
  title: string;
  description?: string;
  icon?: IconComponent;
}

export const EmptySearchState: FC<EmptySearchStateProps> = ({
  title,
  description,
  icon,
}) => (
  <div className={styles.container}>
    <div className={styles.content}>
      {icon && <Icon iconComponent={icon} size={IconSize.ExtraLarge} />}
      <h3>{title}</h3>
      {description && <span className={styles.description}>{description}</span>}
    </div>
  </div>
);
