import { PostbackEvent, PostbackMacros } from 'types/generated/gql';

export const mapAllowedMacros: Record<PostbackEvent, Array<PostbackMacros>> = {
  [PostbackEvent.FirstDeposit]: [PostbackMacros.ClickId, PostbackMacros.Tid],
  [PostbackEvent.QualificationCheck]: [
    PostbackMacros.ClickId,
    PostbackMacros.Tid,
  ],
  [PostbackEvent.Registration]: [PostbackMacros.ClickId, PostbackMacros.Tid],
  [PostbackEvent.Reward]: [
    PostbackMacros.ClickId,
    PostbackMacros.Currency,
    PostbackMacros.Reward,
    PostbackMacros.Tid,
  ],
};
