import { FC } from 'react';
import { ModalContainer } from 'ui/ModalContainer';
import { Button, ButtonSize, ButtonTheme } from 'ui/Button';
import { Card } from 'ui/Card';
import styles from './ConfirmModal.module.scss';
import { useConfirmModalContext } from './hooks/useConfirmModalContext';
import { ConfirmModalContent } from './types';

export interface ConfirmModalProps {
  content: ConfirmModalContent;
}

export const ConfirmModal: FC<ConfirmModalProps> = ({ content }) => {
  const { isOpen, closeModal, handleCancel, handleConfirm } =
    useConfirmModalContext();

  const { title, text, cancelButton, confirmButton } = content;

  return (
    <ModalContainer
      isOpen={isOpen}
      onRequestClose={closeModal}
      className={{
        base: styles.modalContent,
        afterOpen: styles.afterOpen,
        beforeClose: styles.beforeClose,
      }}
    >
      <Card title={title} className={styles.card}>
        <div className={styles.content}>{text}</div>
        <div className={styles.buttonsBlock}>
          <Button
            className={styles.button}
            size={ButtonSize.Medium}
            theme={ButtonTheme.BlackTonal}
            onClick={handleConfirm}
          >
            {confirmButton}
          </Button>
          <Button
            className={styles.button}
            size={ButtonSize.Medium}
            theme={ButtonTheme.Black}
            onClick={handleCancel}
          >
            {cancelButton}
          </Button>
        </div>
      </Card>
    </ModalContainer>
  );
};
