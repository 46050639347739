import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { KeyValueGrid } from 'ui/KeyValueGrid';
import { Error } from 'ui/Error';
import { Loader } from 'ui/Loader';
import { TextLink } from 'ui/TextLink';
import { closeModal, useModalContext } from 'ui/Modal';
import { ButtonSize } from 'ui/Button';
import { useGetUserTariffQuery } from './hooks/useGetUserTariff';
import { getTariffItemData } from './helpers/getTariffItemData';
import styles from './TariffItem.module.scss';

interface TariffItemProps {
  tariffId: string;
  routeToReferralLinks: string;
  routeToGeneralStatistic: string;
}

export const TariffItem: FC<TariffItemProps> = ({
  tariffId,
  routeToReferralLinks,
  routeToGeneralStatistic,
}) => {
  const { t } = useTranslation('tariffs');
  const { dispatch } = useModalContext();
  const { tariff, loading, error } = useGetUserTariffQuery(tariffId);

  if (!tariff) {
    return null;
  }

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <Error error={error} />;
  }

  const tariffData = getTariffItemData(tariff);

  const handleClick = () => {
    dispatch(closeModal());
  };

  return (
    <>
      <KeyValueGrid
        className={styles.keyValueGrid}
        data={tariffData}
        shouldStackOnMd
      />
      <div className={styles.linksWrapper}>
        <TextLink
          to={routeToGeneralStatistic}
          onClick={handleClick}
          buttonSize={ButtonSize.Large}
        >
          {t('content.drawer.tariffDetails.action.button.statistics')}
        </TextLink>
        <TextLink
          to={routeToReferralLinks}
          onClick={handleClick}
          buttonSize={ButtonSize.Large}
        >
          {t('content.drawer.tariffDetails.action.button.links')}
        </TextLink>
      </div>
    </>
  );
};
