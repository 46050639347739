import { useMemo } from 'react';
import { NetworkStatus } from '@apollo/client';
import { useAuth } from 'components/auth';
import { useTranslation } from 'react-i18next';
import {
  PartnerPromoCodeMediaItemsFilterType,
  PaymentModel,
} from 'types/generated/gql';
import { useGetTariffsName } from 'queries/generated/GetTariffsName';
import { useTranslationUserType } from 'hooks/useTranslationUserType';
import {
  getTariffsOptions,
  getPromoTypeOptions,
  getReferralLinkOptions,
} from 'helpers';
import { useLoadMore } from 'hooks/useLoadMore';
import { ReactComponent as RevShareIcon } from 'assets/icons/line/Revshare.svg';
import { ReactComponent as CPAIcon } from 'assets/icons/line/CPA.svg';
import { Icon, IconSize } from 'ui/Icon';
import { FilterData, SelectFilterType, UserTypeName } from 'types';
import styles from '../PartnerPromoCodeMediaTableFilters.module.scss';
import { useGetPartnerReferralLinksBasicData } from '../../../queries/generated/GetPartnerReferralLinksBasicData';

const REFERRAL_LINKS_PER_PAGE = 10;

export const usePartnerPromoCodeMediaTableFilters = () => {
  const { t } = useTranslation(['media', 'common']);
  const translationUserType = useTranslationUserType();

  const { user } = useAuth();
  const {
    data: tariffsData,
    loading: tariffsLoading,
    error: tariffsError,
    networkStatus: tariffsNetworkStatus,
  } = useGetTariffsName({
    variables: {
      userId: user.id,
    },
  });

  const {
    data: referralLinks,
    fetchMore: referralLinksFetchMore,
    error: referralLinksError,
    loading: referralLinksLoading,
    networkStatus: referralLinksNetworkStatus,
  } = useGetPartnerReferralLinksBasicData({
    variables: {
      userId: user.id,
      first: REFERRAL_LINKS_PER_PAGE,
      filters: [],
    },
    notifyOnNetworkStatusChange: true,
  });

  const referralLinksConnections =
    referralLinks?.user.__typename === UserTypeName.Partner
      ? referralLinks?.user.referralLinkMediaItems
      : undefined;

  const referralLinkItems = useMemo(
    () => referralLinksConnections?.edges.map((item) => item.node),
    [referralLinksConnections?.edges]
  );

  const referralLinksLoadMore = useLoadMore(
    referralLinksFetchMore,
    referralLinksConnections?.pageInfo
  );

  const referralLinksOptions = getReferralLinkOptions(referralLinkItems);

  const promoTypeOptions = getPromoTypeOptions(translationUserType);

  const tariffsOptions = getTariffsOptions({
    tariffs: tariffsData?.user.tariffs,
  });

  const error = tariffsError || referralLinksError;

  const paymentTypeOptions = [
    {
      id: PaymentModel.RevShare,
      label: t('common:dataTypes.value.paymentTypeRevShare'),
      leftElement: (
        <Icon
          iconComponent={RevShareIcon}
          hasDefaultColor={false}
          containerClassName={styles.revShare}
          size={IconSize.Large}
        />
      ),
    },
    {
      id: PaymentModel.Cpa,
      label: t('common:dataTypes.value.paymentTypeCpa'),
      leftElement: (
        <Icon
          iconComponent={CPAIcon}
          hasDefaultColor={false}
          containerClassName={styles.cpa}
          size={IconSize.Large}
        />
      ),
    },
  ];

  const filters: Array<FilterData<PartnerPromoCodeMediaItemsFilterType>> = [
    {
      selectFilterType: SelectFilterType.Single,
      type: PartnerPromoCodeMediaItemsFilterType.PromoCodeType,
      title: t('media:filterTitle.label.promoType'),
      options: promoTypeOptions,
      loading: false,
    },
    {
      selectFilterType: SelectFilterType.Multiple,
      type: PartnerPromoCodeMediaItemsFilterType.Tariff,
      title: t('media:filterTitle.label.tariff'),
      options: tariffsOptions,
      loading: tariffsLoading && tariffsNetworkStatus === NetworkStatus.loading,
    },
    {
      selectFilterType: SelectFilterType.Multiple,
      type: PartnerPromoCodeMediaItemsFilterType.ReferralLink,
      title: t('media:filterTitle.label.link'),
      options: referralLinksOptions,
      loadMore: referralLinksLoadMore,
      loading:
        referralLinksLoading &&
        referralLinksNetworkStatus === NetworkStatus.loading,
    },
    {
      selectFilterType: SelectFilterType.Single,
      type: PartnerPromoCodeMediaItemsFilterType.PaymentModel,
      title: t('media:filterTitle.label.paymentType'),
      options: paymentTypeOptions,
      loading: false,
    },
  ];

  return {
    filters,
    error,
  };
};
