import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useModalContext, openModal } from 'ui/Modal';
import { CompanyPartnerDetailsModal } from '../components/CompanyPartnerDetailsModal';

export const useOpenCompanyPartnerDetailsModal = () => {
  const { t } = useTranslation('companyPartners');
  const { dispatch } = useModalContext();

  const openCompanyPartnerDetailsModal = useCallback(
    (companyPartnerId: string) => {
      dispatch(
        openModal({
          title: t('content.drawer.partnerDetails.header'),
          content: (
            <CompanyPartnerDetailsModal companyPartnerId={companyPartnerId} />
          ),
        })
      );
    },
    [dispatch, t]
  );

  return {
    openCompanyPartnerDetailsModal,
  };
};
