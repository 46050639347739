import { FC, useCallback } from 'react';
import { Button, ButtonSize, ButtonTheme } from 'ui/Button';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AltArrowRightIcon } from 'assets/icons/line/AltArrowRight.svg';
import { openModal as openModalAction, useModalContext } from 'ui/Modal';
import { TextLink } from 'ui/TextLink';
import { Card } from 'ui/Card';
import { TariffItem } from './components/TariffItem';
import { TariffCardBody } from './components/TariffCardBody';
import { TariffBasicData } from '../../queries/generated/TariffBasicData';
import { useTariffRoutes } from './hooks/useTariffRoutes';
import styles from './TariffCard.module.scss';

interface TariffCardProps {
  tariff: TariffBasicData;
  isCompany: boolean;
}

export const TariffCard: FC<TariffCardProps> = ({ tariff, isCompany }) => {
  const { t } = useTranslation('tariffs');
  const { dispatch } = useModalContext();
  const { routeToReferralLinks, routeToGeneralStatistic } = useTariffRoutes({
    isCompany,
    tariffId: tariff.id,
    tariffTypeName: tariff.__typename,
  });

  const openModal = useCallback(() => {
    dispatch(
      openModalAction({
        title: t('content.drawer.tariffDetails.header.title'),
        content: (
          <TariffItem
            tariffId={tariff.id}
            routeToReferralLinks={routeToReferralLinks}
            routeToGeneralStatistic={routeToGeneralStatistic}
          />
        ),
      })
    );
  }, [dispatch, routeToGeneralStatistic, routeToReferralLinks, t, tariff.id]);

  return (
    <Card
      className={styles.card}
      title={tariff.name}
      headerRightBlock={
        <Button
          theme={ButtonTheme.BlackTonal}
          size={ButtonSize.Small}
          onClick={openModal}
          iconConfig={{
            iconComponent: AltArrowRightIcon,
          }}
        />
      }
    >
      <TariffCardBody className={styles.cardBody} tariff={tariff} />
      <div className={styles.linksWrapper}>
        <TextLink to={routeToGeneralStatistic}>
          {t('action.card.statistics')}
        </TextLink>
        <TextLink to={routeToReferralLinks}>{t('action.card.links')}</TextLink>
      </div>
    </Card>
  );
};
