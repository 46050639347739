import { DateFormat, formatDate } from 'utils/formatDate';
import { t } from 'i18n';
import { CompanyPartner } from 'types/generated/gql';
import { KeyValueGridRow } from 'ui/KeyValueGrid';
import { getValueWithFallback } from 'utils/getValueWithFallback';

export const getCompanyPartnerDetailsItemData = ({
  username,
  note,
  createdAt,
}: Omit<CompanyPartner, 'mediaItemsCount'>): Array<KeyValueGridRow> => [
  {
    title: t('companyPartners:content.drawer.partnerDetails.partnerName'),
    value: username,
  },
  {
    title: t('companyPartners:content.drawer.partnerDetails.note'),
    value: getValueWithFallback({ value: note }),
  },
  {
    title: t('companyPartners:content.drawer.partnerDetails.createdOn'),
    value: formatDate(new Date(createdAt), DateFormat.FullDateWithoutHours),
  },
];
