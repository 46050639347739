import { t } from 'i18n';
import { TranslationUserType } from 'types';
import { PostbackMacros } from 'types/generated/gql';

export const getRequiredErrors = (
  requiredMacros: Array<PostbackMacros>,
  translationUserType: TranslationUserType
) => {
  const mapErrorLengthToTranslation: Record<string, string> = {
    '1': t(
      `media:${translationUserType}.content.drawer.details.postback.card.input.validation.urlMacroRequired`,
      { X: requiredMacros[0]?.toLowerCase() }
    ),
    '2': t(`media:details.postback.validation.url.2MacrosRequired`, {
      X: requiredMacros[0]?.toLowerCase(),
      Y: requiredMacros[1]?.toLowerCase(),
    }),
    '3': t(
      `media:${translationUserType}.content.drawer.details.postback.card.input.validation.urlMacrosRequired`,
      {
        X: requiredMacros[0]?.toLowerCase(),
        Y: requiredMacros[1]?.toLowerCase(),
        Z: requiredMacros[2]?.toLowerCase(),
      }
    ),
  };

  return mapErrorLengthToTranslation[requiredMacros.length];
};
