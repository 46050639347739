import { FormikErrors } from 'formik';
import { getRequiredErrors } from 'utils/form/getRequiredFormikErrors';
import { TranslationUserType } from 'types';
import { t } from 'i18n';
import { MediaItemFormValues } from './types';

export const validateMediaItemForm = (
  values: MediaItemFormValues,
  translationUserType: TranslationUserType
): FormikErrors<MediaItemFormValues> =>
  getRequiredErrors(
    values,
    {
      name: true,
    },
    {
      name: t(
        `media:${translationUserType}.content.drawer.createMedia.input.validation.titleRequired`
      ),
    }
  );
