import { FC, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { getMinimalUnderBreakpoint } from 'helpers';
import { Breakpoints } from 'const';
import { Button, ButtonSize, LinkStyledButtonTextColor } from 'ui/Button';
import { getNavigationSectionsData } from './helpers';
import styles from './NavigationBlock.module.scss';
import { MobileNavigationHeader } from './components/MobileNavigationHeader';

interface NavigationBlockProps {
  currentSectionTitle: string;
}

export const NavigationBlock: FC<NavigationBlockProps> = ({
  currentSectionTitle,
}) => {
  const { t } = useTranslation('termsOfUse');

  const containerRef = useRef<HTMLElement>(null);
  const [shouldShowMobileHeader, setShouldShowMobileHeader] = useState(false);

  const navigate = useNavigate();

  const isMobile = getMinimalUnderBreakpoint() === Breakpoints.Sm;

  const navigationSectionsData = getNavigationSectionsData(t);

  const handleClick = (id: string) => {
    const element = document.getElementById(id);

    if (isMobile) {
      navigate({ hash: id }, { replace: true });
    }

    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setShouldShowMobileHeader(!entry.isIntersecting && isMobile);
    });

    if (containerRef.current) observer.observe(containerRef.current);

    return () => {
      observer?.disconnect();
    };
  }, [containerRef, isMobile]);

  return (
    <>
      <section className={styles.wrapper} ref={containerRef}>
        {navigationSectionsData.map((section) => (
          <div key={section.title}>
            <h3 className={styles.title}>{section.title}</h3>
            <ul>
              {section.links.map((link) => (
                <li className={styles.link} key={link.id}>
                  <Button
                    onClick={() => handleClick(link.id)}
                    size={ButtonSize.Medium}
                    linkStyled={{
                      color: LinkStyledButtonTextColor.Blue,
                    }}
                  >
                    {link.label}
                  </Button>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </section>
      {shouldShowMobileHeader && (
        <MobileNavigationHeader
          currentSectionTitle={currentSectionTitle}
          navigationSectionsData={navigationSectionsData}
        />
      )}
    </>
  );
};
