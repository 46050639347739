import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { components, GroupBase, MenuProps } from 'react-select';
import { Breakpoints } from 'const';
import { getMinimalUnderBreakpoint } from 'helpers';
import { BottomSheet } from 'ui/BottomSheet';
import { PopUp } from 'ui/PopUp';
import { Input } from 'ui/formItems/components/Input/Input';
import styles from './Menu.module.scss';
import { SelectOption } from '../../type';

export const Menu = ({
  children,
  ...props
}: MenuProps<SelectOption, false, GroupBase<SelectOption>>) => {
  const { label, onAfterModalClose } = props.selectProps;

  const optionValue = props.selectProps.value as SelectOption<string>;

  const prevValue = useRef(!props.isMulti ? optionValue?.value : null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const isMobile = getMinimalUnderBreakpoint() === Breakpoints.Sm;
  const isTablet = getMinimalUnderBreakpoint() === Breakpoints.Md;

  useEffect(() => {
    if (props.isMulti) return;
    if (!props.selectProps.value) return;

    const newValue = props.selectProps.value as SelectOption<string>;

    const isValueEqual = prevValue.current === newValue.value;

    if (isValueEqual) return;

    if (isMobile || isTablet) {
      setIsMenuOpen(false);
    } else {
      props.selectProps.onMenuClose();
    }
  }, [isMobile, props, isTablet]);

  const handleMenuClose = () => {
    setIsMenuOpen(false);
  };

  /**
   * Workaround to forcefully open the BottomSheet on initial render
   * when the `isMobile` flag is true. This is necessary due to an issue
   * with the `react-modal` library that prevents modal windows from opening
   * immediately after mounting on mobile devices and causes an error 'Cannot register modal instance that's already open'.
   *
   * Issue: https://github.com/reactjs/react-modal/issues/808#issuecomment-2040536762
   */
  useEffect(() => {
    if (isMobile || isTablet) {
      setIsMenuOpen(true);
    }
  }, [isMobile, isTablet]);

  if (isMobile) {
    return (
      <BottomSheet
        isOpen={isMenuOpen}
        title={label}
        onClose={handleMenuClose}
        onAfterClose={() => {
          onAfterModalClose?.();
        }}
      >
        {props.selectProps.shouldShowSearchInput && (
          <Input
            name="search"
            value={props.selectProps.inputValue}
            label={label}
            autoFocus
            onChange={(value) => {
              props.selectProps.onInputChange(value, {
                action: 'input-change',
                prevInputValue: '',
              });
            }}
          />
        )}
        <components.Menu<SelectOption, false, GroupBase<SelectOption>>
          {...props}
          className={classNames(styles.menuAsBottomSheet, {
            [styles.withSearchInput]: props.selectProps.shouldShowSearchInput,
          })}
        >
          {children}
        </components.Menu>
      </BottomSheet>
    );
  }

  if (isTablet) {
    return (
      <PopUp
        isOpen={isMenuOpen}
        title={label}
        onClose={handleMenuClose}
        onAfterClose={() => {
          onAfterModalClose?.();
        }}
      >
        {props.selectProps.shouldShowSearchInput && (
          <Input
            name="search"
            value={props.selectProps.inputValue}
            label={label}
            autoFocus
            onChange={(value) => {
              props.selectProps.onInputChange(value, {
                action: 'input-change',
                prevInputValue: '',
              });
            }}
          />
        )}
        <components.Menu<SelectOption, false, GroupBase<SelectOption>>
          {...props}
          className={classNames(styles.menuAsPopUp, {
            [styles.withSearchInput]: props.selectProps.shouldShowSearchInput,
          })}
        >
          {children}
        </components.Menu>
      </PopUp>
    );
  }

  return (
    <components.Menu<SelectOption, false, GroupBase<SelectOption>>
      {...props}
      className={styles.menu}
    >
      {children}
    </components.Menu>
  );
};
