import { useAuth } from 'components/auth';
import { UserTypeName } from 'types';
import { useGetCompanyPartnerDetails } from '../queries/generated/GetCompanyPartnerDetails';

export const useGetCompanyPartnerDetailsQuery = (companyPartnerId: string) => {
  const { user } = useAuth();

  const { data, loading, error } = useGetCompanyPartnerDetails({
    variables: {
      companyPartnerId,
      userId: user.id,
    },
    fetchPolicy: 'cache-first',
  });

  const companyPartnerDetails =
    data?.user.__typename === UserTypeName.Company
      ? data?.user.companyPartner
      : undefined;

  return {
    companyPartnerDetails,
    loading,
    error,
  };
};
