import type { FC } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { Button, ButtonSize, LinkStyledButtonTextColor } from 'ui/Button';
import { getLinkStyles } from './helpers/getLinkStyles';

interface TextLinkProps extends LinkProps {
  color?: LinkStyledButtonTextColor;
  buttonClassName?: string;
  buttonSize?: ButtonSize;
}

export const TextLink: FC<TextLinkProps> = ({
  children,
  color = LinkStyledButtonTextColor.Black,
  buttonClassName,
  buttonSize = ButtonSize.Medium,
  ...restProps
}) => (
  <Link className={getLinkStyles[buttonSize]} {...restProps}>
    <Button
      size={buttonSize}
      linkStyled={{
        color,
      }}
      className={buttonClassName}
    >
      {children}
    </Button>
  </Link>
);
