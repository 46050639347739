import { AppRoute } from 'const';
import { useLanguage } from 'contexts/LanguageContext';
import { useNavigate } from 'react-router';
import { addBasePath } from 'utils/routing/addBasePath';

export const useNavigateToMainPage = () => {
  const navigate = useNavigate();
  const { currentLanguage } = useLanguage();

  const navigateToMainPage = () => {
    navigate({
      pathname: addBasePath(AppRoute.Lang.Main, currentLanguage),
    });
  };

  return { navigateToMainPage };
};
