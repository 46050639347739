import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { getContentSectionsData } from './helpers';
import { List } from './components';
import { useSectionsObserver } from './hooks/useSectionObserver';
import styles from './ContentBlock.module.scss';

interface ContentBlockProps {
  setCurrentSectionTitle: (newTitle: string) => void;
}

export const ContentBlock: FC<ContentBlockProps> = ({
  setCurrentSectionTitle,
}) => {
  const { t } = useTranslation('termsOfUse');

  const { setObservableRef } = useSectionsObserver({
    onSectionChange: setCurrentSectionTitle,
  });

  return (
    <section className={styles.wrapper}>
      {getContentSectionsData(t).map((section) => (
        <div key={section.id} id={section.id} className={styles.section}>
          <h2 className={styles.title}>{section.title}</h2>
          {section.descriptions.map((description) => (
            <p key={description} className={styles.description}>
              {description}
            </p>
          ))}
          {section?.subsections?.map((subsection, index) => (
            <div
              key={subsection.id}
              id={subsection.id}
              className={styles.subsection}
              ref={(el) => {
                setObservableRef(el, section.id, index);
              }}
              data-title={subsection.title}
            >
              <h3 className={styles.subsectionTitle}>{subsection.title}</h3>
              {subsection?.descriptions.map((description) => (
                <p key={description} className={styles.subsectionDescription}>
                  {description}
                </p>
              ))}
              {subsection?.listTitle && (
                <p className={styles.listTitle}>{subsection.listTitle}</p>
              )}
              {subsection?.items && (
                <div className={styles.listWrapper}>
                  <List items={subsection.items} />
                </div>
              )}
            </div>
          ))}
        </div>
      ))}
    </section>
  );
};
