import { DropdownPosition, DropdownSize } from '../../const';
import styles from './DropdownContainer.module.scss';

export const mapPositionToClassName: Record<DropdownPosition, string> = {
  [DropdownPosition.RightBottom]: styles.rightBottomPosition,
  [DropdownPosition.LeftTop]: styles.leftTopPosition,
  [DropdownPosition.LeftBottom]: styles.leftBottomPosition,
  [DropdownPosition.Right]: styles.rightPosition,
};

export const mapDropdownSizeToClassName: Record<DropdownSize, string> = {
  [DropdownSize.Medium]: styles.medium,
  [DropdownSize.Small]: styles.small,
};
