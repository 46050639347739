import { useEffect, useState } from 'react';
import { AuthStatuses, useAuth } from 'components/auth';
import { AuthStorageKey, Storage } from 'utils/storage';

interface useGetRefreshTokenResult {
  isLoading: boolean;
  userId: string;
}

export const useAuthWithRefreshToken = (): useGetRefreshTokenResult => {
  const auth = useAuth();
  const { authStatus } = auth.user;
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const isLoggedInTransferKey = Storage.authStorage.getValue(
      AuthStorageKey.LoggedInTransferKey
    );

    const isLoggedInKey = Storage.authStorage.getValue(
      AuthStorageKey.LoggedInKey
    );

    const loggedIn = isLoggedInKey || isLoggedInTransferKey;

    const loginWithRefresh = async () => {
      if (loggedIn && authStatus !== AuthStatuses.Authenticated) {
        try {
          await auth.refreshToken();
        } catch (error) {
          auth.logOut();
        } finally {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    };

    loginWithRefresh();

    // we should call code above only once - on the first render of the application
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isLoading, userId: auth.user.id };
};
