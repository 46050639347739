import { ApolloLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { toast } from 'ui/toast';

export const getOnErrorLink = (logOut: () => Promise<boolean>): ApolloLink =>
  onError(({ networkError }): void => {
    if (networkError) {
      toast.error(networkError.message);
    }

    if (
      networkError?.name === 'ServerError' &&
      'statusCode' in networkError &&
      networkError.statusCode === 401
    ) {
      logOut();
    }
  });
