import { ApolloError } from '@apollo/client';
import { IconComponent } from 'types';
import { ReactComponent as EmptyDataIcon } from 'assets/icons/line/DocSearch.svg';
import { EmptyStateConfig } from '../types';

interface GetEmptyStateParams<TData> {
  data: Array<TData>;
  isLoading: boolean;
  hasSearchParams: boolean;
  emptyText: string;
  error?: ApolloError;
  emptySearchStateConfig?: EmptyStateConfig;
}

interface EmptyState {
  isEmpty: boolean;
  isLoadingEmptyState: boolean;
  title: string;
  description?: string;
  icon?: IconComponent;
}

export const getEmptyState = <TData>({
  data,
  isLoading,
  hasSearchParams,
  emptyText,
  error,
  emptySearchStateConfig,
}: GetEmptyStateParams<TData>): EmptyState => {
  const isEmptyWithoutSearchParams =
    data.length === 0 && !isLoading && !hasSearchParams && !error;
  const isEmptyWithSearchParams =
    data.length === 0 && !isLoading && hasSearchParams;
  const isEmpty = isEmptyWithSearchParams || isEmptyWithoutSearchParams;
  const isLoadingEmptyState = data.length === 0 && isLoading;

  const emptyStateTitle =
    isEmptyWithSearchParams && emptySearchStateConfig?.title
      ? emptySearchStateConfig.title
      : emptyText;

  const emptyStateIcon = isEmptyWithSearchParams
    ? emptySearchStateConfig?.icon
    : EmptyDataIcon;

  const emptyStateDescription = isEmptyWithSearchParams
    ? (emptySearchStateConfig?.description ?? '')
    : '';

  return {
    isEmpty,
    isLoadingEmptyState,
    title: emptyStateTitle,
    description: emptyStateDescription,
    icon: emptyStateIcon,
  };
};
