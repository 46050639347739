import { CompanyReferralLinkMediaItemsFilterType } from 'types/generated/gql';
import { FilterCombination } from 'types';

export const companyReferralLinkMediaItemsCombinations: FilterCombination<CompanyReferralLinkMediaItemsFilterType> =
  {
    [CompanyReferralLinkMediaItemsFilterType.MediaItemOwner]: [
      CompanyReferralLinkMediaItemsFilterType.PaymentModel,
      CompanyReferralLinkMediaItemsFilterType.Tariff,
    ],
  };
