import { FC, Fragment } from 'react';
import { Falsy } from 'types';
import classNames from 'classnames';
import styles from './KeyValueGrid.module.scss';
import { KeyValueGridSize } from './const';
import { KeyValueGridRow } from './types';

const mapSizeToClass: Record<KeyValueGridSize, string> = {
  [KeyValueGridSize.Large]: styles.large,
  [KeyValueGridSize.Small]: styles.small,
};

interface KeyValueGridProps {
  data: Array<KeyValueGridRow | Falsy>;
  equalColumnsWidth?: boolean;
  size?: KeyValueGridSize;
  shouldStackOnMd?: boolean;
  className?: string;
}

export const KeyValueGrid: FC<KeyValueGridProps> = ({
  data,
  equalColumnsWidth,
  className,
  shouldStackOnMd,
  size = KeyValueGridSize.Large,
}) => {
  const rows = data.map((item) => {
    if (!item) {
      return null;
    }

    const { title, value } = item;

    return (
      <Fragment key={title}>
        <div className={classNames(styles.gridElem, styles.title)}>{title}</div>
        <div className={classNames(styles.gridElem, styles.value)}>{value}</div>
      </Fragment>
    );
  });

  return (
    <div
      className={classNames(className, styles.container, mapSizeToClass[size], {
        [styles.equalColumnsWidth]: equalColumnsWidth,
        [styles.stacked]: shouldStackOnMd,
      })}
    >
      {rows}
    </div>
  );
};
