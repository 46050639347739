import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, useFormikContext } from 'formik';
import { Button, ButtonSize } from 'ui/Button';
import { FormBlockMarginSize, FormBlockWrapper, Input } from 'ui/formItems';
import { IndentBetweenRowsSize } from 'ui/formItems/components/FormBlockWrapper/const';
import {
  COMPANY_PARTNER_NAME_MAX_LENGTH,
  NOTE_MAX_LENGTH,
  FieldName,
} from '../../const';
import { EditCompanyPartnerDetailsValues } from '../../types';
import styles from './EditCompanyPartnerDetailsForm.module.scss';

interface Props {
  onDirtyChange: (dirty: boolean) => void;
  loading: boolean;
}

export const EditCompanyPartnerDetailsForm: FC<Props> = ({
  onDirtyChange,
  loading,
}) => {
  const { t } = useTranslation('companyPartners');
  const { dirty } = useFormikContext<EditCompanyPartnerDetailsValues>();

  useEffect(() => {
    onDirtyChange(dirty);
  }, [dirty, onDirtyChange]);

  return (
    <Form>
      <FormBlockWrapper
        indentBetweenRowSize={IndentBetweenRowsSize.Large}
        marginBottomSize={FormBlockMarginSize.Large}
      >
        <Input
          name={FieldName.Username}
          label={t('content.drawer.editPartnerDetails.input.label.partnerName')}
          maxLength={COMPANY_PARTNER_NAME_MAX_LENGTH}
          disabled={loading}
        />

        <Input
          name={FieldName.Note}
          label={t('content.drawer.editPartnerDetails.input.label.note')}
          maxLength={NOTE_MAX_LENGTH}
          disabled={loading}
        />
      </FormBlockWrapper>

      <Button
        className={styles.button}
        size={ButtonSize.Large}
        disabled={!dirty || loading}
        type="submit"
        isLoading={loading}
      >
        {t('content.drawer.editPartnerDetails.action.button.send')}
      </Button>
    </Form>
  );
};
