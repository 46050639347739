import {
  ApolloClient,
  InMemoryCache,
  type NormalizedCacheObject,
} from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';
import {
  TariffTypeName,
  UserTypeName,
  WithdrawalInvoiceTypeName,
  WithdrawalMethodTypeName,
} from 'types';
import { useCombinedLink } from './useCombinedLink';

const isProd = process.env.NODE_ENV === 'production';

export const useApolloClient = (): ApolloClient<NormalizedCacheObject> => {
  const link = useCombinedLink();

  return new ApolloClient({
    link,
    cache: new InMemoryCache({
      possibleTypes: {
        UserInterface: Object.values(UserTypeName),
        TariffInterface: Object.values(TariffTypeName),
        WithdrawalInvoiceInterface: Object.values(WithdrawalInvoiceTypeName),
        WithdrawalMethodInterface: Object.values(WithdrawalMethodTypeName),
      },
      typePolicies: {
        Partner: {
          fields: {
            referralLinkMediaItems: relayStylePagination([
              'referralLinkSearch',
              'filters',
            ]),
            revShareStatistic: {
              merge: (existing, incoming) =>
                existing ? { ...existing, ...incoming } : incoming,
            },
            cpaStatistic: {
              merge: (existing, incoming) =>
                existing ? { ...existing, ...incoming } : incoming,
            },
            rewardHistory: relayStylePagination(),
            withdrawalInvoices: relayStylePagination(),
            promoCodeMediaItems: relayStylePagination([
              'promoCodeSearch',
              'filters',
            ]),
            revShareReferralLinkPromoCodeStatistic: {
              merge: (existing, incoming) =>
                existing ? { ...existing, ...incoming } : incoming,
            },
            cpaReferralLinkPromoCodeStatistic: {
              merge: (existing, incoming) =>
                existing ? { ...existing, ...incoming } : incoming,
            },
          },
        },
        PartnerRevShareStatisticItem: {
          keyFields: ['statisticInfo', 'id'],
          fields: {
            statisticInfo: {
              merge: (existing, incoming) => incoming,
            },
          },
        },
        CompanyRevShareStatisticItem: {
          keyFields: ['statisticInfo', 'id'],
          fields: {
            statisticInfo: {
              merge: (existing, incoming) => incoming,
            },
          },
        },
        PartnerCpaStatisticItem: {
          keyFields: ['statisticInfo', 'id'],
          fields: {
            statisticInfo: {
              merge: (existing, incoming) => incoming,
            },
          },
        },
        CompanyCpaStatisticItem: {
          keyFields: ['statisticInfo', 'id'],
          fields: {
            statisticInfo: {
              merge: (existing, incoming) => incoming,
            },
          },
        },
        PartnerRevShareStatistic: {
          fields: {
            statisticItems: relayStylePagination([
              'start',
              'end',
              'currency',
              'referralLinkSearch',
              'filters',
              'sort',
            ]),
          },
        },
        CompanyRevShareStatistic: {
          fields: {
            statisticItems: relayStylePagination([
              'start',
              'end',
              'currency',
              'referralLinkSearch',
              'filters',
              'sort',
            ]),
          },
        },
        PartnerCpaStatistic: {
          fields: {
            statisticItems: relayStylePagination([
              'start',
              'end',
              'referralLinkSearch',
              'filters',
              'sort',
            ]),
          },
        },
        CompanyCpaStatistic: {
          fields: {
            statisticItems: relayStylePagination([
              'start',
              'end',
              'referralLinkSearch',
              'filters',
              'sort',
            ]),
          },
        },
        Company: {
          fields: {
            referralLinkMediaItems: relayStylePagination([
              'referralLinkSearch',
              'filters',
            ]),
            companyPartners: relayStylePagination(['search']),
            revShareStatistic: {
              merge: (existing, incoming) =>
                existing ? { ...existing, ...incoming } : incoming,
            },
            cpaStatistic: {
              merge: (existing, incoming) =>
                existing ? { ...existing, ...incoming } : incoming,
            },
            revShareReferralLinkPromoCodeStatistic: {
              merge: (existing, incoming) =>
                existing ? { ...existing, ...incoming } : incoming,
            },
            rewardHistory: relayStylePagination(),
            withdrawalInvoices: relayStylePagination(),
            promoCodeMediaItems: relayStylePagination([
              'promoCodeSearch',
              'filters',
            ]),
            cpaReferralLinkPromoCodeStatistic: {
              merge: (existing, incoming) =>
                existing ? { ...existing, ...incoming } : incoming,
            },
          },
        },
        ReferralLinkMediaItem: {
          fields: {
            postbackInfo: {
              merge: (existing, incoming) =>
                existing ? { ...existing, ...incoming } : incoming,
            },
            cpaStatistic: {
              merge: (existing, incoming) =>
                existing ? { ...existing, ...incoming } : incoming,
            },
            revShareStatistic: {
              merge: (existing, incoming) =>
                existing ? { ...existing, ...incoming } : incoming,
            },
          },
        },
        CpaReferralLinkStatistic: {
          fields: {
            statisticItems: relayStylePagination([
              'start',
              'end',
              'referralNameSearch',
            ]),
          },
        },
        RevShareReferralLinkStatistic: {
          fields: {
            statisticItems: relayStylePagination([
              'start',
              'end',
              'referralNameSearch',
              'currency',
            ]),
          },
        },
        RevShareReferralLinkStatisticItem: {
          keyFields: ['statisticInfo', 'id'],
          fields: {
            statisticInfo: {
              merge: (existing, incoming) => incoming,
            },
          },
        },
      },
    }),
    devtools: {
      enabled: !isProd,
    },
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: (lastFetchPolicy) => {
          if (
            lastFetchPolicy === 'cache-and-network' ||
            lastFetchPolicy === 'network-only'
          ) {
            return 'cache-first';
          }

          return lastFetchPolicy;
        },
        errorPolicy: 'all',
      },
      mutate: {
        errorPolicy: 'all',
      },
    },
  });
};
