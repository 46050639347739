import { useTranslation } from 'react-i18next';
import {
  AccessorKeyColumnDef,
  ColumnDef,
  createColumnHelper,
} from '@tanstack/react-table';
import { MultilineCellWithIcon } from 'ui/Table/components/MultilineCellWithIcon';
import { getMoneyAmount } from 'utils/currency/getMoneyAmount';
import { CopyLink } from 'ui/CopyLink';
import {
  PromoCodeStatisticItemTypeName,
  StatisticItemTypeName,
} from 'pages/Statistic/pages/StatisticGeneral/types';
import { CopyText } from 'ui/CopyText';
import { MayBeNullCell } from 'ui/Table/components/MayBeNullCell';
import { EXPAND_COLUMN_ID, EXPAND_COLUMN_WIDTH } from 'ui/Table/const';
import { ExpandButton } from 'ui/Table/components/ExpandButton';
import { TableCellAlign } from 'ui/Table/components/TableRowCell/types';
import { mapPromoCodeTypeToText } from 'helpers';
import { TranslationUserType } from 'types';
import { getStatisticCpaItemOwnerToShow } from '../helpers';
import { ALL_COLUMNS, COLUMNS_SIZES } from '../../../const';
import { useCompanyCpaStatisticItemsColumnsManager } from './useCompanyCpaStatisticItemsColumnsManager';
import { CompanyCpaStatisticTableData } from '../types';

interface UseCompanyCpaStatisticItemsColumnsReturnValue {
  visibleColumns: Array<ColumnDef<CompanyCpaStatisticTableData>>;
  updateVisibleColumns: (newColumns: Array<string>) => void;
  optionalColumns: Array<AccessorKeyColumnDef<CompanyCpaStatisticTableData>>;
  formColumns: Record<string, boolean>;
}

interface useCompanyCpaStatisticItemsColumnsParams {
  handleExpandRow: (referralLinkId: string) => void;
}

export const useCompanyCpaStatisticItemsColumns = ({
  handleExpandRow,
}: useCompanyCpaStatisticItemsColumnsParams): UseCompanyCpaStatisticItemsColumnsReturnValue => {
  const { t } = useTranslation(['common', 'statistic']);
  const columnHelper = createColumnHelper<CompanyCpaStatisticTableData>();

  /*
   *  We need to use any as generic argument while bug won't be fixed
   *  Issue https://github.com/TanStack/table/issues/4382
   */
  const accessorColumns: Array<
    AccessorKeyColumnDef<CompanyCpaStatisticTableData, any>
  > = [
    columnHelper.accessor('id', {
      header: t(`statistic:company.content.dataTable.cpa.mediaElement`),
      id: ALL_COLUMNS.id,
      cell: ({ row }) => {
        if (
          row.original.__typename ===
          StatisticItemTypeName.CompanyCpaStatisticItem
        ) {
          if (row.depth > 0) {
            return (
              <MultilineCellWithIcon
                title={
                  <CopyLink
                    tooltipText={t('linkCopied')}
                    to={row.original.link}
                    target="_blank"
                  >
                    {row.original.link}
                  </CopyLink>
                }
                subTitle={t(
                  'statistic:company.content.dataTable.cpa.linkSubtitle'
                )}
              />
            );
          }

          return (
            <MultilineCellWithIcon
              title={row.original.name}
              subTitle={
                <CopyLink
                  tooltipText={t('linkCopied')}
                  to={row.original.link}
                  target="_blank"
                >
                  {row.original.link}
                </CopyLink>
              }
            />
          );
        }

        if (
          row.original.__typename ===
          PromoCodeStatisticItemTypeName.CpaPromoCodeStatisticItem
        ) {
          return (
            <MultilineCellWithIcon
              title={
                <CopyText
                  tooltipText={t('statistic:company.action.hint.copyLink')}
                  truncateText
                  justifyBetween
                >
                  {row.original.token}
                </CopyText>
              }
              subTitle={mapPromoCodeTypeToText(
                row.original.type,
                TranslationUserType.Company
              )}
            />
          );
        }

        return null;
      },
      size: COLUMNS_SIZES.mediaNameSize,
      enablePinning: true,
    }),
    columnHelper.accessor('owner', {
      id: ALL_COLUMNS.owner,
      header: t('statistic:company.content.dataTable.cpa.owner'),
      cell: ({ row }) => {
        const parentRow = row.getParentRow();

        const isNestedRow = row.depth > 0 && parentRow && parentRow.original;

        if (
          isNestedRow &&
          parentRow.original.__typename ===
            StatisticItemTypeName.CompanyCpaStatisticItem
        ) {
          return getStatisticCpaItemOwnerToShow(parentRow.original.owner);
        }

        if (
          row.original.__typename ===
          StatisticItemTypeName.CompanyCpaStatisticItem
        ) {
          return getStatisticCpaItemOwnerToShow(row.original.owner);
        }

        return null;
      },
      minSize: COLUMNS_SIZES.default,
    }),
    columnHelper.accessor('statisticInfo.hits', {
      id: ALL_COLUMNS.hits,
      header: t('statistic:company.content.dataTable.cpa.hits'),
      cell: ({ renderValue }) => <MayBeNullCell value={renderValue()} />,
      minSize: COLUMNS_SIZES.default,
      meta: {
        align: TableCellAlign.Right,
      },
      enableSorting: true,
    }),
    columnHelper.accessor('statisticInfo.hosts', {
      id: ALL_COLUMNS.hosts,
      header: t('statistic:company.content.dataTable.cpa.hosts'),
      cell: ({ renderValue }) => <MayBeNullCell value={renderValue()} />,
      minSize: COLUMNS_SIZES.default,
      meta: {
        align: TableCellAlign.Right,
      },
      enableSorting: true,
    }),
    columnHelper.accessor('statisticInfo.registrations', {
      id: ALL_COLUMNS.registrations,
      header: t('statistic:company.content.dataTable.cpa.registrations'),
      cell: ({ renderValue }) => renderValue(),
      minSize: COLUMNS_SIZES.default,
      meta: {
        align: TableCellAlign.Right,
      },
      enableSorting: true,
    }),
    columnHelper.accessor('statisticInfo.firstDeposits', {
      id: ALL_COLUMNS.firstDeposits,
      header: t('statistic:company.content.dataTable.cpa.firstDeposits'),
      cell: ({ renderValue }) => renderValue(),
      minSize: COLUMNS_SIZES.default,
      meta: {
        align: TableCellAlign.Right,
      },
      enableSorting: true,
    }),
    columnHelper.accessor('statisticInfo.depositsAll', {
      id: ALL_COLUMNS.depositsAll,
      header: t('statistic:company.content.dataTable.cpa.depositsAll'),
      cell: ({ getValue }) => getMoneyAmount(getValue()),
      minSize: COLUMNS_SIZES.default,
      meta: {
        align: TableCellAlign.Right,
      },
      enableSorting: true,
    }),
    columnHelper.accessor('statisticInfo.hold', {
      id: ALL_COLUMNS.hold,
      header: t('statistic:company.content.dataTable.cpa.hold'),
      cell: ({ getValue }) => getValue(),
      minSize: COLUMNS_SIZES.default,
      meta: {
        align: TableCellAlign.Right,
      },
      enableSorting: true,
    }),
    columnHelper.accessor('statisticInfo.qualified', {
      id: ALL_COLUMNS.qualified,
      header: t('statistic:company.content.dataTable.cpa.qualified'),
      cell: ({ getValue }) => getValue(),
      minSize: COLUMNS_SIZES.default,
      meta: {
        align: TableCellAlign.Right,
      },
      enableSorting: true,
    }),
    columnHelper.accessor('statisticInfo.approved', {
      id: ALL_COLUMNS.approved,
      header: t('statistic:company.content.dataTable.cpa.approved'),
      cell: ({ getValue }) => getValue(),
      minSize: COLUMNS_SIZES.default,
      meta: {
        align: TableCellAlign.Right,
      },
      enableSorting: true,
    }),
    columnHelper.accessor('statisticInfo.paid', {
      id: ALL_COLUMNS.paid,
      header: t('statistic:company.content.dataTable.cpa.paid'),
      cell: ({ getValue }) => getValue(),
      minSize: COLUMNS_SIZES.default,
      meta: {
        align: TableCellAlign.Right,
      },
      enableSorting: true,
    }),
    columnHelper.accessor('statisticInfo.rejected', {
      id: ALL_COLUMNS.rejected,
      header: t('statistic:company.content.dataTable.cpa.rejected'),
      cell: ({ getValue }) => getValue(),
      minSize: COLUMNS_SIZES.default,
      meta: {
        align: TableCellAlign.Right,
      },
      enableSorting: true,
    }),
    columnHelper.accessor('statisticInfo.reward', {
      id: ALL_COLUMNS.reward,
      header: t('statistic:company.content.dataTable.cpa.reward'),
      cell: ({ getValue }) => getMoneyAmount(getValue()),
      minSize: COLUMNS_SIZES.default,
      meta: {
        align: TableCellAlign.Right,
      },
      enableSorting: true,
    }),
    columnHelper.accessor('statisticInfo.averageDeposit', {
      id: ALL_COLUMNS.averageDeposit,
      header: t('statistic:company.content.dataTable.cpa.averageDeposit'),
      cell: ({ getValue }) => getMoneyAmount(getValue()),
      minSize: COLUMNS_SIZES.default,
      meta: {
        align: TableCellAlign.Right,
      },
    }),
    columnHelper.accessor('statisticInfo.redepositsAll', {
      id: ALL_COLUMNS.redepositsAll,
      header: t('statistic:company.content.dataTable.cpa.redepositsAll'),
      cell: ({ getValue }) => getMoneyAmount(getValue()),
      minSize: COLUMNS_SIZES.default,
      meta: {
        align: TableCellAlign.Right,
      },
    }),
    columnHelper.accessor('statisticInfo.conversionHostsToRegPercent', {
      id: ALL_COLUMNS.conversionHostsToRegPercent,
      header: t('statistic:company.content.dataTable.cpa.hosts2reg'),
      cell: ({ getValue }) => `${getValue()}%`,
      minSize: COLUMNS_SIZES.default,
      meta: {
        align: TableCellAlign.Right,
      },
      enableSorting: true,
    }),
    columnHelper.accessor('statisticInfo.conversionRegToDepPercent', {
      id: ALL_COLUMNS.conversionRegToDepPercent,
      header: t('statistic:company.content.dataTable.cpa.reg2dep'),
      cell: ({ getValue }) => `${getValue()}%`,
      minSize: COLUMNS_SIZES.default,
      meta: {
        align: TableCellAlign.Right,
      },
      enableSorting: true,
    }),
    columnHelper.accessor('statisticInfo.numberOfRedeposits', {
      id: ALL_COLUMNS.numberOfRedeposits,
      header: t('statistic:company.content.dataTable.cpa.numberOfRedeposits'),
      cell: ({ getValue }) => getValue(),
      minSize: COLUMNS_SIZES.default,
      meta: {
        align: TableCellAlign.Right,
      },
    }),
    columnHelper.accessor('statisticInfo.withdrawalsAll', {
      id: ALL_COLUMNS.withdrawalsAll,
      header: t('statistic:company.content.dataTable.cpa.withdrawalsAll'),
      cell: ({ getValue }) => getMoneyAmount(getValue()),
      minSize: COLUMNS_SIZES.default,
      meta: {
        align: TableCellAlign.Right,
      },
    }),
  ];

  const {
    optionalColumns,
    visibleColumns: accessorVisibleColumns,
    updateVisibleColumns,
    formColumns,
  } = useCompanyCpaStatisticItemsColumnsManager({
    columns: accessorColumns,
  });

  /*
   *  We need to use any as generic argument while bug won't be fixed
   *  Issue https://github.com/TanStack/table/issues/4382
   */
  const visibleColumns: Array<ColumnDef<CompanyCpaStatisticTableData, any>> = [
    columnHelper.display({
      id: EXPAND_COLUMN_ID,
      header: () => null,
      cell: ({ row }) => {
        const onClick = async () => {
          await handleExpandRow(row.original.id);
        };

        return row.getCanExpand() ? (
          <ExpandButton row={row} onClick={onClick} />
        ) : null;
      },
      size: EXPAND_COLUMN_WIDTH,
      enablePinning: true,
    }),
    ...accessorVisibleColumns,
  ];

  return {
    optionalColumns,
    visibleColumns,
    updateVisibleColumns,
    formColumns,
  };
};
