import { FormikErrors } from 'formik';
import { t } from 'i18next';
import { getRequiredErrors } from 'utils/form/getRequiredFormikErrors';
import { PartnerIdentitiesValues } from './types';
import { FieldName } from './const';

export const validateProfileIdentities = (
  values: PartnerIdentitiesValues
): FormikErrors<PartnerIdentitiesValues> => {
  const errors: FormikErrors<PartnerIdentitiesValues> = {};

  const requiredErrors = getRequiredErrors(
    values,
    {
      [FieldName.ContactType]: true,
      [FieldName.ContactValue]: true,
    },
    {
      [FieldName.ContactValue]: t(
        'profile:partner.content.details.personalDetails.input.validation.contactRequired'
      ),
      [FieldName.ContactType]: t(
        'profile:partner.content.details.personalDetails.input.validation.contactRequired'
      ),
    }
  );

  return {
    ...errors,
    ...requiredErrors,
  };
};
