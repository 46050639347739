import { FC, useCallback } from 'react';
import {
  goBackModal as goBackModalAction,
  updateConfirmClose,
  useModalContext,
} from 'ui/Modal';
import { useConfirmModalContext } from 'ui/ConfirmModal';
import { Formik } from 'formik';
import { EditCompanyPartnerDetailsForm } from './components/EditCompanyPartnerDetailsForm';
import { FieldName } from './const';
import { validateEditCompanyPartnerDetails } from './validation';
import { useUpdateCompanyPartnerDetailsMutation } from './hooks/useUpdateCompanyPartnerDetailsMutation';
import { EditCompanyPartnerDetailsValues } from './types';

interface Props {
  companyPartnerId: string;
  username: string;
  note?: string;
}

export const EditCompanyPartnerDetailsModal: FC<Props> = ({
  companyPartnerId,
  username,
  note,
}) => {
  const { dispatch } = useModalContext();
  const { showConfirmation } = useConfirmModalContext();

  const { updateCompanyPartnerDetails, loading } =
    useUpdateCompanyPartnerDetailsMutation({
      companyPartnerId,
    });

  const goBackModal = () => {
    dispatch(goBackModalAction());
  };

  const handleSubmit = async (values: EditCompanyPartnerDetailsValues) => {
    await updateCompanyPartnerDetails(values);

    goBackModal();
  };

  const handleDirtyChange = useCallback(
    (dirty: boolean) => {
      dispatch(
        updateConfirmClose({
          confirmClose: dirty ? showConfirmation : null,
        })
      );
    },
    [dispatch, showConfirmation]
  );

  return (
    <Formik
      initialValues={{
        [FieldName.Username]: username,
        [FieldName.Note]: note,
      }}
      onSubmit={handleSubmit}
      validateOnChange={false}
      validateOnBlur={false}
      validate={validateEditCompanyPartnerDetails}
    >
      <EditCompanyPartnerDetailsForm
        onDirtyChange={handleDirtyChange}
        loading={loading}
      />
    </Formik>
  );
};
