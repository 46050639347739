import { useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownPosition, DropdownSize } from 'ui/Dropdown';
import { Button, ButtonSize, ButtonTheme } from 'ui/Button';
import { LinkStyledButtonTextColor } from 'ui/Button/const';
import { ReactComponent as MenuDotsIcon } from 'assets/icons/line/MenuDots.svg';
import { TextLink } from 'ui/TextLink';
import { useTranslationUserType } from 'hooks/useTranslationUserType';
import { composeRoutes } from 'utils/routing/composeRoutes';
import { AppRoute } from 'const';
import {
  TariffForMediaBasicData_CpaTariff,
  TariffForMediaBasicData_RevShareTariff,
} from 'pages/Media/queries/fragments/generated/TariffForMediaBasicData';
import { useCreateLocalizedPath } from 'hooks/useCreateLocalizedPath';
import { getStatisticRouteByTariffTypeName } from 'helpers';
import styles from './LinkBoosters.module.scss';

interface LinkBoostersProps {
  id: string;
  tariffTypeName:
    | TariffForMediaBasicData_CpaTariff['__typename']
    | TariffForMediaBasicData_RevShareTariff['__typename'];
}

export const LinkBoosters = ({ id, tariffTypeName }: LinkBoostersProps) => {
  const { t } = useTranslation('media');

  const [isActive, setIsActive] = useState<boolean>(false);

  const { createLocalizedPath } = useCreateLocalizedPath();

  const translationUserType = useTranslationUserType();

  const path = getStatisticRouteByTariffTypeName(tariffTypeName);

  const handleOpenChange = (isOpen: boolean) => {
    setIsActive(isOpen);
  };

  return (
    <div className={styles.container} onClick={(e) => e.stopPropagation()}>
      <Dropdown
        onOpenChange={handleOpenChange}
        position={DropdownPosition.RightBottom}
        title={t(`${translationUserType}.name.linkBoosters`)}
        withStyleMaxContent={false}
        dropdownSize={DropdownSize.Small}
        trigger={
          <Button
            iconConfig={{
              iconComponent: MenuDotsIcon,
            }}
            size={ButtonSize.Small}
            theme={ButtonTheme.GreyGhost}
            className={classNames(styles.icon, {
              [styles.activeIcon]: isActive,
            })}
          />
        }
      >
        <div className={styles.dropdownContent}>
          <div className={styles.dropdownItem}>
            <TextLink
              tabIndex={0}
              className={styles.link}
              to={createLocalizedPath(
                composeRoutes([AppRoute.Lang.Statistic.root, path, id])
              )}
              buttonClassName={styles.linkText}
              color={LinkStyledButtonTextColor.Black}
            >
              {t(`${translationUserType}.dropdown.statistics`)}
            </TextLink>
          </div>
        </div>
      </Dropdown>
    </div>
  );
};
