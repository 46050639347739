import { DatePickerHandleProps } from 'ui/DatePicker/types';
import { DateRanges } from '../const';
import { getRangeAllTime } from './getRangeAllTime';
import { normalizeDateRange } from './normalizeDateRange';
import { getRangeLastMonth } from './getRangeLastMonth';
import { getRangeLastReportingPeriod } from './getRangeLastReportingPeriod';
import { getRangeLastWeek } from './getRangeLastWeek';
import { getRangeYesterday } from './getRangeYesterday';
import { getRangeToday } from './getRangeToday';

interface GetRangeTitleFromDatesParams {
  startDate: Date;
  endDate: Date;
  dateResetTo?: DatePickerHandleProps;
  minDateInitial?: Date | null;
}

const getDateWithoutTime = (date: Date) => {
  const dateCopy = new Date(date);

  dateCopy.setHours(0, 0, 0, 0);

  return dateCopy;
};

const isDateRangeMatching = (
  parsedStartDate: Date,
  parsedEndDate: Date,
  { startRange, endRange }: { startRange: Date; endRange: Date }
) =>
  parsedStartDate.getTime() === getDateWithoutTime(startRange).getTime() &&
  parsedEndDate.getTime() === getDateWithoutTime(endRange).getTime();

const checkCurrentReportingPeriod = (
  parsedStartDate: Date,
  parsedEndDate: Date,
  dateResetTo?: DatePickerHandleProps
) => {
  if (!Array.isArray(dateResetTo)) {
    return null;
  }

  const [startNormalized, endNormalized] = normalizeDateRange(dateResetTo);

  if (!startNormalized || !endNormalized) {
    return null;
  }

  return isDateRangeMatching(parsedStartDate, parsedEndDate, {
    startRange: startNormalized,
    endRange: endNormalized,
  })
    ? DateRanges.CurrentReportingPeriod
    : null;
};

const checkSpecificRange = (
  parsedStartDate: Date,
  parsedEndDate: Date,
  getRangeFunc: () => { startRange: Date; endRange: Date },
  rangeType: DateRanges
) => {
  const range = getRangeFunc();

  return isDateRangeMatching(parsedStartDate, parsedEndDate, range)
    ? rangeType
    : null;
};

const checkAllTimeRange = (
  parsedStartDate: Date,
  parsedEndDate: Date,
  minDateInitial?: Date | null
) => {
  const range = getRangeAllTime(minDateInitial);

  return isDateRangeMatching(parsedStartDate, parsedEndDate, range)
    ? DateRanges.AllTime
    : null;
};

export const getRangeTitleFromDates = ({
  minDateInitial,
  startDate,
  endDate,
  dateResetTo,
}: GetRangeTitleFromDatesParams) => {
  const parsedStartDate = getDateWithoutTime(startDate);
  const parsedEndDate = getDateWithoutTime(endDate);

  const rangeChecks = [
    () => checkAllTimeRange(parsedStartDate, parsedEndDate, minDateInitial),
    () =>
      checkCurrentReportingPeriod(parsedStartDate, parsedEndDate, dateResetTo),
    () =>
      checkSpecificRange(
        parsedStartDate,
        parsedEndDate,
        getRangeLastMonth,
        DateRanges.LastMonth
      ),
    () =>
      checkSpecificRange(
        parsedStartDate,
        parsedEndDate,
        getRangeLastReportingPeriod,
        DateRanges.LastReportingPeriod
      ),
    () =>
      checkSpecificRange(
        parsedStartDate,
        parsedEndDate,
        getRangeLastWeek,
        DateRanges.LastWeek
      ),
    () =>
      checkSpecificRange(
        parsedStartDate,
        parsedEndDate,
        getRangeYesterday,
        DateRanges.Yesterday
      ),
    () =>
      checkSpecificRange(
        parsedStartDate,
        parsedEndDate,
        getRangeToday,
        DateRanges.Today
      ),
  ];

  return (
    rangeChecks.map((check) => check()).find((result) => result !== null) ||
    null
  );
};
