import { useEffect, useRef, useState } from 'react';

export const useTruncateText = () => {
  const textRef = useRef<HTMLElement | null>(null);
  const [truncated, setTruncated] = useState(false);
  const [clampLines, setClampLines] = useState(0);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      if (textRef.current) {
        const lineHeight = parseFloat(
          getComputedStyle(textRef.current).lineHeight
        );

        const maxLines = Math.floor(textRef.current.clientHeight / lineHeight);
        const clampedLines = maxLines > 0 ? maxLines : 1;

        setClampLines(clampedLines);
        setTruncated(textRef.current.scrollHeight > lineHeight);
      }
    });

    if (textRef.current) {
      resizeObserver.observe(textRef.current);
    }

    return () => resizeObserver.disconnect();
  }, [textRef]);

  return { textRef, truncated, clampLines };
};
