import { NetworkStatus } from '@apollo/client';
import { useAuth } from 'components/auth';
import { useTranslation } from 'react-i18next';
import {
  CompanyReferralLinkMediaItemsFilterType,
  PaymentModel,
} from 'types/generated/gql';
import { useGetTariffsName } from 'queries/generated/GetTariffsName';
import { getCompanyPartnersOptions, getTariffsOptions } from 'helpers';
import { ReactComponent as RevShareIcon } from 'assets/icons/line/Revshare.svg';
import { ReactComponent as CPAIcon } from 'assets/icons/line/CPA.svg';
import { Icon, IconSize } from 'ui/Icon';
import { FilterData, SelectFilterType, UserTypeName } from 'types';
import { useMemo } from 'react';
import { CompanyPartnersFiltersData } from 'queries/fragments/generated/CompanyPartnersFiltersData';
import { useLoadMore } from 'hooks/useLoadMore';
import { useGetFilterCompanyPartners } from 'queries/generated/GetFilterCompanyPartners';
import { useGetCompanyName } from 'queries/generated/GetCompanyName';
import { MEDIA_ITEMS_PER_PAGE } from '../../../const';
import styles from '../CompanyReferralLinkMediaTableFilters.module.scss';

export const useCompanyReferralLinkMediaFilters = () => {
  const { t } = useTranslation(['media', 'common']);

  const { user } = useAuth();
  const {
    data: tariffsData,
    loading: tariffsLoading,
    error: tariffsError,
    networkStatus: tariffsNetworkStatus,
  } = useGetTariffsName({
    variables: {
      userId: user.id,
    },
  });
  const { data: userIdentities } = useGetCompanyName({
    variables: {
      id: user.id,
    },
  });

  const {
    data: companyPartnersData,
    fetchMore: companyPartnersFetchMore,
    error: companyPartnersError,
    loading: companyPartnersLoading,
    networkStatus: companyPartnersNetworkStatus,
  } = useGetFilterCompanyPartners({
    variables: {
      userId: user.id,
      first: MEDIA_ITEMS_PER_PAGE,
    },
    notifyOnNetworkStatusChange: true,
  });

  const companyPartnersConnection =
    companyPartnersData?.user.__typename === UserTypeName.Company
      ? companyPartnersData.user.companyPartners
      : undefined;

  const companyPartnersLoadMore = useLoadMore(
    companyPartnersFetchMore,
    companyPartnersConnection?.pageInfo
  );

  const companyPartnersItems: Array<CompanyPartnersFiltersData> | undefined =
    useMemo(
      () => companyPartnersConnection?.edges.map((item) => item.node),
      [companyPartnersConnection?.edges]
    );

  const error = tariffsError || companyPartnersError;

  const tariffsOptions = getTariffsOptions({
    tariffs: tariffsData?.user.tariffs,
  });

  const paymentTypeOptions = [
    {
      id: PaymentModel.RevShare,
      label: t('common:dataTypes.value.paymentTypeRevShare'),
      leftElement: (
        <Icon
          iconComponent={RevShareIcon}
          hasDefaultColor={false}
          containerClassName={styles.revShare}
          size={IconSize.Large}
        />
      ),
    },
    {
      id: PaymentModel.Cpa,
      label: t('common:dataTypes.value.paymentTypeCpa'),
      leftElement: (
        <Icon
          iconComponent={CPAIcon}
          hasDefaultColor={false}
          containerClassName={styles.cpa}
          size={IconSize.Large}
        />
      ),
    },
  ];

  const companyPartnersOptions = getCompanyPartnersOptions({
    userIdentities,
    companyPartnersItems,
  });

  const filters: Array<FilterData<CompanyReferralLinkMediaItemsFilterType>> = [
    {
      selectFilterType: SelectFilterType.Multiple,
      type: CompanyReferralLinkMediaItemsFilterType.MediaItemOwner,
      title: t('media:filterTitle.label.owner'),
      options: companyPartnersOptions,
      loadMore: companyPartnersLoadMore,
      loading:
        companyPartnersLoading &&
        companyPartnersNetworkStatus === NetworkStatus.loading,
    },
    {
      selectFilterType: SelectFilterType.Multiple,
      type: CompanyReferralLinkMediaItemsFilterType.Tariff,
      title: t('media:filterTitle.label.tariff'),
      options: tariffsOptions,
      loading: tariffsLoading && tariffsNetworkStatus === NetworkStatus.loading,
    },
    {
      selectFilterType: SelectFilterType.Single,
      type: CompanyReferralLinkMediaItemsFilterType.PaymentModel,
      title: t('media:filterTitle.label.paymentType'),
      options: paymentTypeOptions,
      loading: tariffsLoading && tariffsNetworkStatus === NetworkStatus.loading,
    },
  ];

  return {
    filters,
    error,
  };
};
