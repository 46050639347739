import { FC, HTMLAttributes, ReactNode } from 'react';
import classnames from 'classnames';
import { IconComponent } from 'types';
import styles from './CardWithIcon.module.scss';
import { Icon } from '../Icon';

interface CardWithIconProps extends HTMLAttributes<HTMLDivElement> {
  title: string;
  subTitle: ReactNode;
  onClick: () => void;
  className?: string;
  icon: IconComponent;
  rightElement?: ReactNode;
  isActive?: boolean;
}

export const CardWithIcon: FC<CardWithIconProps> = ({
  title,
  subTitle,
  onClick,
  className,
  icon,
  rightElement,
  isActive,
  ...rest
}) => (
  <div
    className={classnames(
      styles.container,
      { [styles.active]: isActive },
      className
    )}
    onClick={onClick}
    data-testid="card-with-icon"
    {...rest}
  >
    <div className={styles.header}>
      <Icon
        iconComponent={icon}
        containerClassName={styles.iconContainer}
        hasDefaultColor={false}
      />
      {rightElement}
    </div>
    <div className={styles.content}>
      <h3 className={styles.title}>{title}</h3>
      <div className={styles.subTitle}>{subTitle}</div>
    </div>
  </div>
);
