import { useMemo } from 'react';
import { NetworkStatus } from '@apollo/client';
import { useAuth } from 'components/auth';
import { useTranslation } from 'react-i18next';
import {
  CompanyPromoCodeMediaItemsFilterType,
  PaymentModel,
} from 'types/generated/gql';
import { useGetTariffsName } from 'queries/generated/GetTariffsName';
import { useTranslationUserType } from 'hooks/useTranslationUserType';
import { CompanyPartnersFiltersData } from 'queries/fragments/generated/CompanyPartnersFiltersData';
import { useGetCompanyName } from 'queries/generated/GetCompanyName';
import { useGetFilterCompanyPartners } from 'queries/generated/GetFilterCompanyPartners';
import {
  getTariffsOptions,
  getPromoTypeOptions,
  getReferralLinkOptions,
  getCompanyPartnersOptions,
} from 'helpers';
import { useLoadMore } from 'hooks/useLoadMore';
import { ReactComponent as RevShareIcon } from 'assets/icons/line/Revshare.svg';
import { ReactComponent as CPAIcon } from 'assets/icons/line/CPA.svg';
import { Icon, IconSize } from 'ui/Icon';
import { FilterData, SelectFilterType, UserTypeName } from 'types';
import styles from '../CompanyPromoCodeMediaTableFilters.module.scss';
import { PROMO_CODE_MEDIA_ITEMS_PER_PAGE } from '../../../../../const';
import { useGetCompanyReferralLinksBasicData } from '../../../queries/generated/GetCompanyReferralLinksBasicData';

const REFERRAL_LINKS_PER_PAGE = 10;

export const useCompanyPromoCodeMediaTableFilters = () => {
  const { t } = useTranslation(['media', 'common']);
  const translationUserType = useTranslationUserType();

  const { user } = useAuth();
  const {
    data: tariffsData,
    loading: tariffsLoading,
    error: tariffsError,
    networkStatus: tariffsNetworkStatus,
  } = useGetTariffsName({
    variables: {
      userId: user.id,
    },
  });

  const { data: userIdentities } = useGetCompanyName({
    variables: {
      id: user.id,
    },
  });

  const {
    data: companyPartnersData,
    fetchMore: companyPartnersFetchMore,
    error: companyPartnersError,
    loading: companyPartnersLoading,
    networkStatus: companyPartnersNetworkStatus,
  } = useGetFilterCompanyPartners({
    variables: {
      userId: user.id,
      first: PROMO_CODE_MEDIA_ITEMS_PER_PAGE,
    },
    notifyOnNetworkStatusChange: true,
  });

  const companyPartnersConnection =
    companyPartnersData?.user.__typename === UserTypeName.Company
      ? companyPartnersData.user.companyPartners
      : undefined;

  const companyPartnersLoadMore = useLoadMore(
    companyPartnersFetchMore,
    companyPartnersConnection?.pageInfo
  );

  const companyPartnersItems: Array<CompanyPartnersFiltersData> | undefined =
    useMemo(
      () => companyPartnersConnection?.edges.map((item) => item.node),
      [companyPartnersConnection?.edges]
    );

  const companyPartnersOptions = getCompanyPartnersOptions({
    userIdentities,
    companyPartnersItems,
  });

  const {
    data: referralLinks,
    fetchMore: referralLinksFetchMore,
    error: referralLinksError,
    loading: referralLinksLoading,
    networkStatus: referralLinksNetworkStatus,
  } = useGetCompanyReferralLinksBasicData({
    variables: {
      userId: user.id,
      first: REFERRAL_LINKS_PER_PAGE,
      filters: [],
    },
    notifyOnNetworkStatusChange: true,
  });

  const referralLinksConnections =
    referralLinks?.user.__typename === UserTypeName.Company
      ? referralLinks?.user.referralLinkMediaItems
      : undefined;

  const referralLinkItems = useMemo(
    () => referralLinksConnections?.edges.map((item) => item.node),
    [referralLinksConnections?.edges]
  );

  const referralLinksLoadMore = useLoadMore(
    referralLinksFetchMore,
    referralLinksConnections?.pageInfo
  );

  const referralLinksOptions = getReferralLinkOptions(referralLinkItems);

  const promoTypeOptions = getPromoTypeOptions(translationUserType);

  const tariffsOptions = getTariffsOptions({
    tariffs: tariffsData?.user.tariffs,
  });

  const error = tariffsError || referralLinksError || companyPartnersError;

  const paymentTypeOptions = [
    {
      id: PaymentModel.RevShare,
      label: t('common:dataTypes.value.paymentTypeRevShare'),
      leftElement: (
        <Icon
          iconComponent={RevShareIcon}
          hasDefaultColor={false}
          containerClassName={styles.revShare}
          size={IconSize.Large}
        />
      ),
    },
    {
      id: PaymentModel.Cpa,
      label: t('common:dataTypes.value.paymentTypeCpa'),
      leftElement: (
        <Icon
          iconComponent={CPAIcon}
          hasDefaultColor={false}
          containerClassName={styles.cpa}
          size={IconSize.Large}
        />
      ),
    },
  ];

  const filters: Array<FilterData<CompanyPromoCodeMediaItemsFilterType>> = [
    {
      selectFilterType: SelectFilterType.Single,
      type: CompanyPromoCodeMediaItemsFilterType.PromoCodeType,
      title: t('media:filterTitle.label.promoType'),
      options: promoTypeOptions,
      loading: false,
    },
    {
      selectFilterType: SelectFilterType.Multiple,
      type: CompanyPromoCodeMediaItemsFilterType.MediaItemOwner,
      title: t('media:filterTitle.label.owner'),
      options: companyPartnersOptions,
      loadMore: companyPartnersLoadMore,
      loading:
        companyPartnersLoading &&
        companyPartnersNetworkStatus === NetworkStatus.loading,
    },
    {
      selectFilterType: SelectFilterType.Multiple,
      type: CompanyPromoCodeMediaItemsFilterType.Tariff,
      title: t('media:filterTitle.label.tariff'),
      options: tariffsOptions,
      loading: tariffsLoading && tariffsNetworkStatus === NetworkStatus.loading,
    },
    {
      selectFilterType: SelectFilterType.Multiple,
      type: CompanyPromoCodeMediaItemsFilterType.ReferralLink,
      title: t('media:filterTitle.label.link'),
      options: referralLinksOptions,
      loadMore: referralLinksLoadMore,
      loading:
        referralLinksLoading &&
        referralLinksNetworkStatus === NetworkStatus.loading,
    },
    {
      selectFilterType: SelectFilterType.Single,
      type: CompanyPromoCodeMediaItemsFilterType.PaymentModel,
      title: t('media:filterTitle.label.paymentType'),
      options: paymentTypeOptions,
      loading: false,
    },
  ];

  return {
    filters,
    error,
  };
};
