import { NonNullableDateRange, Currency } from 'types';
import { DateFormat, formatDate } from 'utils/formatDate';

const { DateScalar } = DateFormat;

interface GetSharedSearchParamsUrlParams {
  searchParams: URLSearchParams;
  nonNullableDateRange?: NonNullableDateRange;
  currency?: Currency;
}

export const getSharedSearchParamsUrl = ({
  searchParams,
  nonNullableDateRange,
  currency,
}: GetSharedSearchParamsUrlParams): URLSearchParams => {
  const sharedSearchParams = new URLSearchParams();

  if (nonNullableDateRange) {
    const [start, end] = nonNullableDateRange;

    const formattedStart = formatDate(start, DateScalar);
    const formattedEnd = formatDate(end, DateScalar);

    sharedSearchParams.set(
      'start',
      searchParams.get('start') || formattedStart
    );
    sharedSearchParams.set('end', searchParams.get('end') || formattedEnd);
  }

  if (currency) {
    sharedSearchParams.set(
      'currency',
      searchParams.get('currency') || currency
    );
  }

  return sharedSearchParams;
};
