import { Form, useFormikContext } from 'formik';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Currency } from 'types';
import { Tooltip, TooltipTheme } from 'ui/Tooltip';
import { Button } from 'ui/Button';
import { FormBlockMarginSize, FormBlockWrapper, Input } from 'ui/formItems';
import { FieldName, AMOUNT_MAX_LENGTH } from '../../const';
import { ONLY_NUMBERS_REGEX } from './const';
import styles from './WithdrawToGameAccountFormContent.module.scss';

interface WithdrawToGameAccountFormContentProps {
  onDirtyChange: (dirty: boolean) => void;
  currency: Currency;
  loading: boolean;
}

export const WithdrawToGameAccountFormContent: FC<
  WithdrawToGameAccountFormContentProps
> = ({ onDirtyChange, currency, loading }) => {
  const { t } = useTranslation(['common', 'payments']);
  const { dirty, isValid } = useFormikContext();

  const isButtonDisable = !isValid || !dirty;

  useEffect(() => {
    onDirtyChange(dirty);
  }, [dirty, onDirtyChange]);

  const handleNumericInput = (e: React.FormEvent<HTMLInputElement>): void => {
    e.currentTarget.value = e.currentTarget.value.replace(
      ONLY_NUMBERS_REGEX,
      ''
    );
  };

  return (
    <Form>
      <FormBlockWrapper marginBottomSize={FormBlockMarginSize.Medium}>
        <Input
          name={FieldName.AccountNumber}
          label={t(
            'payments:partnerAndCompany.content.drawer.vavadaAccount.input.label.accountNumber'
          )}
          disabled
        />
        <Input
          name={FieldName.Amount}
          type="text"
          label={t(
            'payments:partnerAndCompany.content.drawer.vavadaAccount.input.label.amount',
            { currency }
          )}
          disabled={loading}
          maxLength={AMOUNT_MAX_LENGTH}
          onInput={handleNumericInput}
        />
      </FormBlockWrapper>
      <Tooltip
        tooltipTriggerClassName={styles.tooltip}
        placement="right"
        tooltipTheme={TooltipTheme.Dark}
        tooltipContent={isButtonDisable ? t('errors.fillAllFields') : undefined}
        shouldSetWidthFitContent={false}
      >
        <Button disabled={isButtonDisable} type="submit" isLoading={loading}>
          {t(
            'payments:partnerAndCompany.content.drawer.vavadaAccount.actions.button.withdrawal'
          )}
        </Button>
      </Tooltip>
    </Form>
  );
};
