import { useCreateLocalizedPath } from 'hooks/useCreateLocalizedPath';
import { composeRoutes } from 'utils/routing/composeRoutes';
import { createFilterSearchParams } from 'utils/createFilterSearchParams';
import { AppRoute } from 'const';
import { CompanyStatisticFilterType } from 'types/generated/gql';

export const useStatisticRoutesByCompanyPartnerId = (
  companyPartnerId: string
) => {
  const { createLocalizedPath } = useCreateLocalizedPath();

  const statisticSearchParams = new URLSearchParams(
    createFilterSearchParams({
      filterType: CompanyStatisticFilterType.MediaItemOwner,
      value: companyPartnerId,
    })
  );

  const revShareStatisticRoute = createLocalizedPath(
    composeRoutes(
      [AppRoute.Lang.Statistic.root, AppRoute.Lang.Statistic.RevShare.root],
      {
        searchParams: statisticSearchParams,
      }
    )
  );

  const cpaStatisticRoute = createLocalizedPath(
    composeRoutes(
      [AppRoute.Lang.Statistic.root, AppRoute.Lang.Statistic.Cpa.root],
      {
        searchParams: statisticSearchParams,
      }
    )
  );

  return {
    revShareStatisticRoute,
    cpaStatisticRoute,
  };
};
