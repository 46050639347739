import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PostbackEvent } from 'types/generated/gql';
import { Input, InputWithCopy } from 'ui/formItems';
import { useTranslationUserType } from 'hooks/useTranslationUserType';
import { getMinimalUnderBreakpoint } from 'helpers';
import { Breakpoints } from 'const';
import { PopUp } from 'ui/PopUp';
import { BottomSheet } from 'ui/BottomSheet';
import { FieldName } from '../../../Postback/const';
import { MacrosDropdown } from './components/MacrosDropdown';
import { useMacrosDropdownState } from './hooks/useMacrosDropdownState';
import { URL_MAX_LENGTH } from './const';
import styles from './UrlInput.module.scss';

interface UrlInputProps {
  isLoading: boolean;
  selectedPostback?: PostbackEvent;
  onFocus?: () => void;
  onBlur?: () => void;
}

export const UrlInput: FC<UrlInputProps> = ({
  isLoading,
  selectedPostback,
  onBlur,
  onFocus,
}) => {
  const { t } = useTranslation(['common', 'media']);
  const translationUserType = useTranslationUserType();

  const isMobile = getMinimalUnderBreakpoint() === Breakpoints.Sm;
  const isTablet = getMinimalUnderBreakpoint() === Breakpoints.Md;
  const isDesktop = !isMobile && !isTablet;

  const label = t(
    `media:${translationUserType}.content.drawer.details.postback.card.input.label.url`
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const isDropdownOpen = useMacrosDropdownState(isInputFocused);

  const handleOpen = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleFocus = () => {
    setIsInputFocused(true);

    if (isDesktop) {
      onFocus?.();
    }
  };

  const handleBlur = () => {
    setTimeout(() => {
      setIsInputFocused(false);
      onBlur?.();
    }, 100);
  };

  return (
    <>
      <div
        className={styles.urlInput}
        onBlur={handleBlur}
        onFocus={handleFocus}
      >
        <InputWithCopy
          tooltipText={t('linkCopied')}
          disabled={isLoading}
          name={FieldName.Url}
          label={label}
          formValidationProps={{ errorTooltipPlacement: 'right' }}
          maxLength={URL_MAX_LENGTH}
          onClick={handleOpen}
        />
      </div>

      {isMobile && (
        <BottomSheet
          contentClassName={styles.bottomSheet}
          isOpen={isModalOpen}
          onClose={handleClose}
          title={label}
        >
          <Input
            disabled={isLoading}
            name={FieldName.Url}
            label={label}
            formValidationProps={{ errorTooltipPlacement: 'right' }}
            maxLength={URL_MAX_LENGTH}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
          {selectedPostback && isDropdownOpen && (
            <MacrosDropdown
              selectedPostback={selectedPostback}
              isOpen={isDropdownOpen}
            />
          )}
        </BottomSheet>
      )}

      {isTablet && (
        <PopUp
          contentClassName={styles.popUp}
          title={label}
          isOpen={isModalOpen}
          onClose={handleClose}
        >
          <Input
            disabled={isLoading}
            name={FieldName.Url}
            label={label}
            formValidationProps={{ errorTooltipPlacement: 'right' }}
            maxLength={URL_MAX_LENGTH}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
          {selectedPostback && isDropdownOpen && (
            <MacrosDropdown
              selectedPostback={selectedPostback}
              isOpen={isDropdownOpen}
            />
          )}
        </PopUp>
      )}

      {isDesktop && isDropdownOpen && (
        <MacrosDropdown
          selectedPostback={selectedPostback!}
          isOpen={isDropdownOpen}
        />
      )}
    </>
  );
};
