import { ListItem } from 'ui/List';
import { Currency } from 'types';
import { CurrencyAccount, Language } from 'types/generated/gql';
import { getCurrencyDisplayName } from 'utils/currency/getCurrencyDisplayName';
import { getCurrencyNameWithSymbol } from 'utils/currency/getCurrencyNameWithSymbol';
import { mapCurrencyToIcon } from 'utils/currency/mapCurrencyToIcon';
import { Icon } from 'ui/Icon';

interface GetListItemsByCurrenciesParams {
  onClick: (value?: Currency) => void;
  language: Language;
  availableCurrencyAccounts?: Array<CurrencyAccount | null>;
}

export const getListItemsByCurrencies = ({
  onClick,
  availableCurrencyAccounts,
  language,
}: GetListItemsByCurrenciesParams): Array<ListItem<Currency>> => {
  if (!availableCurrencyAccounts?.length) {
    return [];
  }

  const filteredCurrencyAccounts = availableCurrencyAccounts.filter(
    (account): account is CurrencyAccount => account !== null
  );

  return filteredCurrencyAccounts.map(({ currency }) => {
    const currencyIcon = mapCurrencyToIcon[currency];

    return {
      label: getCurrencyDisplayName({ currencyCode: currency, language }),
      labelRight: getCurrencyNameWithSymbol(currency),
      leftElement: <Icon iconComponent={currencyIcon} />,
      value: currency,
      onClick,
    };
  });
};
