import classNames from 'classnames';
import { Tooltip, TooltipTheme } from 'ui/Tooltip';
import { Icon, IconSize } from '../../../Icon';
import { inputIconConfig } from '../../types';
import styles from './InputIcon.module.scss';

interface InputIconProps {
  className?: string;
  iconConfig?: inputIconConfig;
}

export const InputIcon: React.FC<InputIconProps> = ({
  className,
  iconConfig,
}) => {
  if (!iconConfig?.icon) {
    return null;
  }

  const {
    icon,
    iconSize = IconSize.Large,
    onClick,
    tooltip,
    withTopIndent = true,
  } = iconConfig;

  const containerClassNames = classNames(className, styles.container, {
    [styles.withTopIndent]: withTopIndent,
    [styles.withOnClick]: onClick,
  });

  const iconElement = (
    <Icon
      size={iconSize}
      hasDefaultColor={false}
      iconComponent={icon}
      onClick={onClick}
      containerClassName={tooltip ? undefined : containerClassNames}
    />
  );

  return tooltip ? (
    <Tooltip
      isOpen={tooltip.isTooltipVisible}
      tooltipTheme={TooltipTheme.Light}
      tooltipContent={tooltip.tooltipText}
      placement="top"
      tooltipTriggerClassName={containerClassNames}
    >
      {iconElement}
    </Tooltip>
  ) : (
    iconElement
  );
};
