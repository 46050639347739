import * as SchemaTypes from '../../../../../../types/generated/gql';

import * as CommonType from 'types';
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetCompanyPartnerDetailsVariables = SchemaTypes.Exact<{
  companyPartnerId: SchemaTypes.Scalars['ID']['input'];
  userId: SchemaTypes.Scalars['ID']['input'];
}>;

export type GetCompanyPartnerDetails = {
  __typename: 'Query';
  user:
    | {
        __typename: 'Company';
        id: string;
        companyPartner: {
          __typename: 'CompanyPartner';
          createdAt: string;
          id: string;
          note?: string | null;
          username: string;
        };
      }
    | { __typename: 'Partner'; id: string };
};

export const GetCompanyPartnerDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCompanyPartnerDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'companyPartnerId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Company' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'companyPartner' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'id' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'companyPartnerId' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'createdAt' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'note' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'username' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetCompanyPartnerDetails__
 *
 * To run a query within a React component, call `useGetCompanyPartnerDetails` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyPartnerDetails` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyPartnerDetails({
 *   variables: {
 *      companyPartnerId: // value for 'companyPartnerId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetCompanyPartnerDetails(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetCompanyPartnerDetails,
    GetCompanyPartnerDetailsVariables
  > &
    (
      | { variables: GetCompanyPartnerDetailsVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetCompanyPartnerDetails,
    GetCompanyPartnerDetailsVariables
  >(GetCompanyPartnerDetailsDocument, options);
}
export function useGetCompanyPartnerDetailsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetCompanyPartnerDetails,
    GetCompanyPartnerDetailsVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetCompanyPartnerDetails,
    GetCompanyPartnerDetailsVariables
  >(GetCompanyPartnerDetailsDocument, options);
}
export function useGetCompanyPartnerDetailsSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    GetCompanyPartnerDetails,
    GetCompanyPartnerDetailsVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    GetCompanyPartnerDetails,
    GetCompanyPartnerDetailsVariables
  >(GetCompanyPartnerDetailsDocument, options);
}
export type GetCompanyPartnerDetailsHookResult = ReturnType<
  typeof useGetCompanyPartnerDetails
>;
export type GetCompanyPartnerDetailsLazyQueryHookResult = ReturnType<
  typeof useGetCompanyPartnerDetailsLazyQuery
>;
export type GetCompanyPartnerDetailsSuspenseQueryHookResult = ReturnType<
  typeof useGetCompanyPartnerDetailsSuspenseQuery
>;
export type GetCompanyPartnerDetailsQueryResult = Apollo.QueryResult<
  GetCompanyPartnerDetails,
  GetCompanyPartnerDetailsVariables
>;
