import { IconComponent } from 'types';
import { LabelWithIconSize } from 'ui/LabelWithIcon';

import { ReactComponent as CompletedIcon } from 'assets/icons/solid/Checkbox.svg';
import { ReactComponent as ErrorIcon } from 'assets/icons/solid/Clear.svg';
import { ReactComponent as PendingAndInProgressIcon } from 'assets/icons/solid/ClockCircle.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/line/DangerCircle.svg';

import { StatusLabelSize, StatusLabelType } from './const';
import styles from './StatusLabel.module.scss';

export const mapTypeToClassName: Record<StatusLabelType, string> = {
  [StatusLabelType.Error]: styles.error,
  [StatusLabelType.InProcess]: styles.inProgress,
  [StatusLabelType.Pending]: styles.pending,
  [StatusLabelType.Success]: styles.success,
  [StatusLabelType.Warning]: styles.warning,
};

export const mapTypeToIcon: Record<StatusLabelType, IconComponent> = {
  [StatusLabelType.Success]: CompletedIcon,
  [StatusLabelType.InProcess]: PendingAndInProgressIcon,
  [StatusLabelType.Error]: ErrorIcon,
  [StatusLabelType.Warning]: WarningIcon,
  [StatusLabelType.Pending]: PendingAndInProgressIcon,
};

export const mapSizeToLabelWithIconSize: Record<
  StatusLabelSize,
  LabelWithIconSize
> = {
  [StatusLabelSize.Large]: LabelWithIconSize.Large,
  [StatusLabelSize.Small]: LabelWithIconSize.Medium,
};
