import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { AppRoute, SPEC_HEADER_LARGE } from 'const';
import { VerticalLayout } from 'ui/VerticalLayout';
import { TextWithLink } from 'ui/TextWithLink';
import { ReturnButton } from 'components/ReturnButton';
import { Footer } from 'components/Footer';
import { useCreateLocalizedPath } from 'hooks/useCreateLocalizedPath';
import { ChooseRegistrationType } from './components/ChooseRegistrationType';
import styles from './Registration.module.scss';

export const Registration: FC = () => {
  const { t } = useTranslation(['common', 'auth']);
  const { createLocalizedPath } = useCreateLocalizedPath();

  return (
    <VerticalLayout
      topBlock={<ReturnButton />}
      centralBlock={
        <>
          <div className={styles.textBlock}>
            <h1 className={classNames(styles.header, SPEC_HEADER_LARGE)}>
              {t('auth:signup.accountType.title')}
            </h1>
          </div>

          <ChooseRegistrationType />
          <TextWithLink
            text={t('auth:signup.accountType.action.label.haveAnAccount')}
            linkText={t('auth:signup.accountType.action.button.login')}
            to={createLocalizedPath(AppRoute.Lang.Login)}
          />
        </>
      }
      bottomBlock={<Footer />}
    />
  );
};
