import { PostbackMacros } from 'types/generated/gql';
import { MACROS_REGEXP } from '../const';

export const getMacrosFromUrl = (
  url: string
): Array<PostbackMacros> | undefined => {
  const match = url
    .match(MACROS_REGEXP)
    ?.map((macro) => macro.slice(1, -1).toUpperCase() as PostbackMacros);

  return match ?? [];
};
