import { FC } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { BreadcrumbsItem } from './types';
import styles from './Breadcrumbs.module.scss';

interface BreadcrumbsProps {
  items: Array<BreadcrumbsItem>;
  from?: number;
  className?: string;
}

export const Breadcrumbs: FC<BreadcrumbsProps> = ({
  items,
  from = 0,
  className,
}) => {
  const visibleItems = items.slice(from);

  return (
    <nav className={className}>
      <ol className={styles.breadcrumbs}>
        {visibleItems.map((item, index) => {
          const isActive = index === visibleItems.length - 1;

          return (
            <li
              key={item.label + (item?.path ? item.path : index)}
              className={classNames(styles.breadcrumbsItem, {
                [styles.active]: isActive,
              })}
            >
              {item?.path ? (
                <Link to={item.path} className={styles.link}>
                  {item.label}
                </Link>
              ) : (
                <span className={styles.label}>{item.label}</span>
              )}
              {index < visibleItems.length - 1 && (
                <span className={styles.separator}> / </span>
              )}
            </li>
          );
        })}
      </ol>
    </nav>
  );
};
