import React, { ChangeEvent, FC, ReactElement, useState } from 'react';
import { ReactComponent as SearchIcon } from 'assets/icons/line/Search.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/line/Close.svg';
import classNames from 'classnames';
import { getMinimalUnderBreakpoint } from 'helpers';
import { Breakpoints } from 'const';
import { Button, ButtonSize, LinkStyledButtonTextColor } from 'ui/Button';
import { useOptionalColumnsParams } from 'hooks/table/useOptionalColumnsParams';
import { Icon, IconSize } from '../Icon';
import { FiltersButton } from './components/FiltersButton';
import { FiltersAndSettings } from './components/FiltersAndSettings';
import { useFiltersHandlers } from '../../hooks/table/useFiltersHandlers';
import styles from './SearchInput.module.scss';

interface SearchInputProps {
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onClear: () => void;
  filterButtonText: string;
  name?: string;
  placeholder?: string;
  filters?: ReactElement;
  settings?: ReactElement;
  disabled?: boolean;
  fullWidth?: boolean;
}

export const SearchInput: FC<SearchInputProps> = ({
  value,
  onChange,
  onClear,
  name,
  placeholder,
  filterButtonText,
  filters,
  settings,
  disabled = false,
  fullWidth = false,
}) => {
  const isMobile = getMinimalUnderBreakpoint() === Breakpoints.Sm;

  const { hasFilters } = useFiltersHandlers();
  const { hasOptionalColumns } = useOptionalColumnsParams();

  const initialExpandedState = isMobile || hasFilters || hasOptionalColumns;

  const [isExpanded, setIsExpanded] = useState(initialExpandedState);

  const settingsCount = React.Children.count(settings?.props.children);
  const shouldShowFiltersButton = (settingsCount > 1 || filters) && !isMobile;

  const toggleExpand = () => {
    setIsExpanded((prev) => !prev);
  };

  return (
    <div
      className={classNames(styles.container, {
        [styles.fullWidth]: fullWidth,
      })}
    >
      <div
        className={classNames(styles.searchContainer, {
          [styles.expanded]: isExpanded,
        })}
      >
        <div
          className={classNames(styles.searchForm, {
            [styles.disabled]: disabled,
          })}
        >
          <div className={styles.searchIconWrapper}>
            <Icon
              iconClassName={classNames(styles.icon, {
                [styles.activeIcon]: value,
              })}
              iconComponent={SearchIcon}
              size={IconSize.Small}
              hasDefaultColor={false}
            />
          </div>
          <input
            aria-label="Search"
            type="search"
            value={value}
            onChange={onChange}
            name={name}
            className={styles.searchInput}
            placeholder={placeholder}
            disabled={disabled}
          />
          {value && (
            <div className={styles.clearIconWrapper}>
              <Button
                linkStyled={{
                  color: LinkStyledButtonTextColor.Grey,
                }}
                size={ButtonSize.Small}
                iconConfig={{
                  iconComponent: CloseIcon,
                }}
                onClick={onClear}
              />
            </div>
          )}
        </div>
        {settingsCount === 1 && !filters && (
          <div className={styles.settingsContainer}>{settings}</div>
        )}
        {shouldShowFiltersButton && (
          <FiltersButton
            onClick={toggleExpand}
            isOpen={isExpanded}
            filterButtonText={filterButtonText}
          />
        )}
      </div>
      {(filters || settingsCount > 1) && (
        <FiltersAndSettings
          filters={filters}
          settings={settings}
          isOpen={isExpanded}
        />
      )}
    </div>
  );
};
