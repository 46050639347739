import { FC, ReactNode } from 'react';
import { Currency, NonNullableDateRange, StatisticType } from 'types';
import { CpaStatisticDetailedTable } from '../CpaStatisticDetailedTable';
import { RevShareStatisticDetailedTable } from '../RevShareStatisticDetailedTable';

interface StatisticDetailedByTypeProps {
  type: StatisticType;
  referralLinkMediaItemId: string;
  nonNullableDateRange: NonNullableDateRange;
  updateCurrency: (currency: Currency) => void;
  currency: Currency;
}

export const StatisticDetailedByType: FC<StatisticDetailedByTypeProps> = ({
  referralLinkMediaItemId,
  type,
  nonNullableDateRange,
  updateCurrency,
  currency,
}) => {
  const mapTypeToNode: Record<StatisticType, ReactNode> = {
    [StatisticType.Cpa]: (
      <CpaStatisticDetailedTable
        cpaMediaItemId={referralLinkMediaItemId}
        nonNullableDateRange={nonNullableDateRange}
      />
    ),
    [StatisticType.RevShare]: (
      <RevShareStatisticDetailedTable
        revShareMediaItemId={referralLinkMediaItemId}
        nonNullableDateRange={nonNullableDateRange}
        currency={currency}
        updateCurrency={updateCurrency}
      />
    ),
  };

  return mapTypeToNode[type];
};
