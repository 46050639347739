import { FC } from 'react';
import classNames from 'classnames';
import { DropdownProps } from 'ui/Dropdown/Dropdown';
import { DropdownPosition, DropdownSize } from '../../const';
import { mapDropdownSizeToClassName, mapPositionToClassName } from './helpers';
import styles from './DropdownContainer.module.scss';

type DropdownContainerProps = Pick<
  DropdownProps,
  | 'position'
  | 'addStyleToContainer'
  | 'children'
  | 'withStyleMaxContent'
  | 'hasGrayOutline'
  | 'dropdownSize'
>;

export const DropdownContainer: FC<DropdownContainerProps> = ({
  position = DropdownPosition.LeftBottom,
  dropdownSize = DropdownSize.Medium,
  addStyleToContainer,
  withStyleMaxContent,
  hasGrayOutline,
  children,
}) => (
  <div
    tabIndex={-1}
    className={classNames(
      styles.container,
      {
        [styles.withStyleMaxContent]: withStyleMaxContent,
        [styles.commonStyles]: addStyleToContainer,
        [styles.withGrayOutline]: hasGrayOutline,
      },
      mapPositionToClassName[position],
      mapDropdownSizeToClassName[dropdownSize]
    )}
  >
    {children}
  </div>
);
