import { FC } from 'react';
import { Form, Formik } from 'formik';
import { Button } from 'ui/Button';
import { FormBlockMarginSize, FormBlockWrapper, Input } from 'ui/formItems';
import { Tooltip } from 'ui/Tooltip';
import { TwoFactorValues } from './types';
import { validateTwoFactor } from './validation';
import { CODE_LENGTH, FieldName } from './const';
import { useCheckTwoFactor } from './hooks/useCheckTwoFactor';

const initialValues: TwoFactorValues = {
  code: '',
};

interface TwoFactorSendFormProps {
  buttonText: string;
  disabledButtonTooltip: string;
  inputLabel: string;
}

export const TwoFactorSendForm: FC<TwoFactorSendFormProps> = ({
  buttonText,
  disabledButtonTooltip,
  inputLabel,
}) => {
  const { checkTwoFactor, isLoading } = useCheckTwoFactor();

  const handleSubmit = (values: TwoFactorValues) => {
    checkTwoFactor(values.code);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={validateTwoFactor}
      validateOnChange={false}
    >
      {({ dirty, isValid, values }) => (
        <Form>
          <FormBlockWrapper marginBottomSize={FormBlockMarginSize.Large}>
            <Input
              acceptedCharsRegExp={/[0-9]/}
              name={FieldName.Code}
              label={inputLabel}
              inputMode="numeric"
              maxLength={CODE_LENGTH}
            />
          </FormBlockWrapper>
          <Tooltip
            shouldSetWidthFitContent={false}
            placement="right"
            tooltipContent={
              !isValid || !dirty ? disabledButtonTooltip : undefined
            }
          >
            <Button
              disabled={!isValid || values.code.length !== CODE_LENGTH}
              isFullWidth
              type="submit"
              isLoading={isLoading}
            >
              {buttonText}
            </Button>
          </Tooltip>
        </Form>
      )}
    </Formik>
  );
};
