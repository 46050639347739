import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from 'ui/formItems';
import { PostbackEvent, PostbackMethod } from 'types/generated/gql';
import { useTranslationUserType } from 'hooks/useTranslationUserType';
import { FieldName } from '../../../Postback/const';
import { mapEventToName, mapMethodToName } from '../../../Postback/helpers';
import { CreationState } from '../../../../types';
import styles from './SelectsBlock.module.scss';

interface SelectsBlockProps {
  isLoading: boolean;
  creationState?: CreationState;
  events: Array<PostbackEvent>;
  onFocus?: () => void;
  onBlur?: () => void;
}

export const SelectsBlock: FC<SelectsBlockProps> = ({
  isLoading,
  creationState,
  events,
  onFocus,
  onBlur,
}) => {
  const { t } = useTranslation('media');
  const translationUserType = useTranslationUserType();

  const eventOptions = useMemo(
    () =>
      events.map((event) => ({
        label: mapEventToName(event, translationUserType),
        value: event,
      })),
    [events, translationUserType]
  );

  const methodOptions = useMemo(
    () =>
      Object.values(PostbackMethod).map((method) => ({
        label: mapMethodToName(method, translationUserType),
        value: method,
      })),
    [translationUserType]
  );

  return (
    <div className={styles.selectsContainer} data-testid="selects-block">
      <div className={styles.leftSelect}>
        <Select
          disabled={
            !creationState || creationState.isCreationInProcess || isLoading
          }
          label={t(
            `${translationUserType}.content.drawer.details.postback.card.input.label.event`
          )}
          formValidationProps={{ errorTooltipPlacement: 'right' }}
          name={FieldName.Event}
          options={eventOptions}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      </div>
      <div className={styles.rightSelect}>
        <Select
          disabled={isLoading}
          label={t(
            `${translationUserType}.content.drawer.details.postback.card.input.label.method`
          )}
          formValidationProps={{ errorTooltipPlacement: 'right' }}
          name={FieldName.Method}
          options={methodOptions}
          hasMinWidth={false}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      </div>
    </div>
  );
};
