import { FC } from 'react';
import classNames from 'classnames';
import {
  Button,
  ButtonSize,
  ButtonTheme,
  LinkStyledButtonTextColor,
} from 'ui/Button';
import { Tooltip, TooltipTheme } from 'ui/Tooltip';
import { ReactComponent as CopyIcon } from 'assets/icons/line/Copy.svg';
import { useHandleCopyWithTooltipStatus } from 'hooks/useHandleCopyWithTooltipStatus';
import styles from './CopyText.module.scss';

interface CopyTextProps {
  children: string;
  tooltipText: string;
  justifyBetween?: boolean;
  containerClassName?: string;
  truncateText?: boolean;
  color?: LinkStyledButtonTextColor;
  iconSize?: ButtonSize;
}

export const CopyText: FC<CopyTextProps> = ({
  children,
  tooltipText,
  justifyBetween = false,
  containerClassName,
  truncateText = false,
  color = LinkStyledButtonTextColor.Black,
  iconSize = ButtonSize.Medium,
}) => {
  const { handleClick, isTooltipVisible } = useHandleCopyWithTooltipStatus({
    text: children,
  });

  return (
    <span className={classNames(styles.container, containerClassName)}>
      <div
        role="group"
        className={classNames(styles.copyTextButton, {
          [styles.withJustifyBetween]: justifyBetween,
          [styles.withTruncateTextContainer]: truncateText,
        })}
      >
        <div
          className={classNames(styles.ellipsisWrapper, {
            [styles.withTruncateText]: truncateText,
          })}
        >
          {children}
        </div>

        <Tooltip
          isOpen={isTooltipVisible}
          tooltipTheme={TooltipTheme.Light}
          tooltipContent={tooltipText}
          placement="top"
        >
          <Button
            linkStyled={{ color }}
            className={styles.button}
            size={iconSize}
            theme={ButtonTheme.BlackTonal}
            iconConfig={{
              iconComponent: CopyIcon,
              containerClassName: styles.icon,
            }}
            onClick={handleClick}
          />
        </Tooltip>
      </div>
    </span>
  );
};
