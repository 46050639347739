import * as SchemaTypes from '../../../../../../../../../../types/generated/gql';

import * as CommonType from 'types';
import { DocumentNode } from 'graphql';
import { CpaPromoCodeStatisticConnectionData } from '../../../../queries/fragments/generated/CpaPromoCodeStatisticConnectionData';
import { CpaPromoCodeStatisticItemData } from '../../../../queries/fragments/generated/CpaPromoCodeStatisticItemData';
import { CpaPromoCodeStatisticInfoData } from '../../../../queries/fragments/generated/CpaPromoCodeStatisticInfoData';
import { MoneyAmountData } from '../../../../../../../../../../queries/fragments/generated/MoneyAmountData';
import { PageInfoData } from '../../../../../../../../../../queries/fragments/generated/PageInfoData';
import { CpaReferralLinkDetailStatisticInfoData } from '../../../../queries/fragments/generated/CpaReferralLinkDetailStatisticInfoData';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetPartnerCpaReferralLinkPromoCodeStatisticVariables =
  SchemaTypes.Exact<{
    after?: SchemaTypes.InputMaybe<SchemaTypes.Scalars['Cursor']['input']>;
    end: SchemaTypes.Scalars['Date']['input'];
    first?: SchemaTypes.InputMaybe<SchemaTypes.Scalars['Int']['input']>;
    referralLinkId: SchemaTypes.Scalars['ID']['input'];
    start: SchemaTypes.Scalars['Date']['input'];
    userId: SchemaTypes.Scalars['ID']['input'];
  }>;

export type GetPartnerCpaReferralLinkPromoCodeStatistic = {
  __typename: 'Query';
  user:
    | { __typename: 'Company'; id: string }
    | {
        __typename: 'Partner';
        id: string;
        cpaReferralLinkPromoCodeStatistic: {
          __typename: 'PartnerCpaReferralLinkPromoCodeStatistic';
          promoCodes: {
            __typename: 'CpaPromoCodeStatisticConnection';
            edges: Array<{
              __typename: 'CpaPromoCodeStatisticEdge';
              cursor: string;
              node: {
                __typename: 'CpaPromoCodeStatisticItem';
                id: string;
                token: string;
                type: SchemaTypes.PromoCodeMediaItemType;
                statisticInfo: {
                  __typename: 'CpaPromoCodeStatisticInfo';
                  approved: number;
                  conversionHostsToRegPercent: number;
                  conversionRegToDepPercent: number;
                  firstDeposits: number;
                  hold: number;
                  numberOfRedeposits: number;
                  paid: number;
                  qualified: number;
                  registrations: number;
                  rejected: number;
                  averageDeposit: {
                    __typename: 'MoneyAmount';
                    currency: CommonType.Currency;
                    value: number;
                  };
                  depositsAll: {
                    __typename: 'MoneyAmount';
                    currency: CommonType.Currency;
                    value: number;
                  };
                  redepositsAll: {
                    __typename: 'MoneyAmount';
                    currency: CommonType.Currency;
                    value: number;
                  };
                  reward: {
                    __typename: 'MoneyAmount';
                    currency: CommonType.Currency;
                    value: number;
                  };
                  withdrawalsAll: {
                    __typename: 'MoneyAmount';
                    currency: CommonType.Currency;
                    value: number;
                  };
                };
              };
            }>;
            pageInfo: {
              __typename: 'PageInfo';
              endCursor?: string | null;
              hasNextPage: boolean;
              hasPreviousPage: boolean;
              startCursor: string;
            };
          };
          statisticInfo: {
            __typename: 'CpaReferralLinkDetailStatisticInfo';
            approved: number;
            conversionHostsToRegPercent: number;
            conversionRegToDepPercent: number;
            firstDeposits: number;
            hits: number;
            hold: number;
            hosts: number;
            numberOfRedeposits: number;
            paid: number;
            qualified: number;
            registrations: number;
            rejected: number;
            averageDeposit: {
              __typename: 'MoneyAmount';
              currency: CommonType.Currency;
              value: number;
            };
            depositsAll: {
              __typename: 'MoneyAmount';
              currency: CommonType.Currency;
              value: number;
            };
            redepositsAll: {
              __typename: 'MoneyAmount';
              currency: CommonType.Currency;
              value: number;
            };
            reward: {
              __typename: 'MoneyAmount';
              currency: CommonType.Currency;
              value: number;
            };
            withdrawalsAll: {
              __typename: 'MoneyAmount';
              currency: CommonType.Currency;
              value: number;
            };
          };
        };
      };
};

export const GetPartnerCpaReferralLinkPromoCodeStatisticDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'GetPartnerCpaReferralLinkPromoCodeStatistic',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Cursor' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'end' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'referralLinkId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'start' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Partner' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'cpaReferralLinkPromoCodeStatistic',
                        },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'referralLinkId' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'referralLinkId' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'promoCodes' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'after' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'after' },
                                  },
                                },
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'end' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'end' },
                                  },
                                },
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'first' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'first' },
                                  },
                                },
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'start' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'start' },
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value:
                                        'CpaPromoCodeStatisticConnectionData',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'statisticInfo' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'end' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'end' },
                                  },
                                },
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'start' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'start' },
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value:
                                        'CpaReferralLinkDetailStatisticInfoData',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...CpaPromoCodeStatisticConnectionData.definitions,
    ...CpaPromoCodeStatisticItemData.definitions,
    ...CpaPromoCodeStatisticInfoData.definitions,
    ...MoneyAmountData.definitions,
    ...PageInfoData.definitions,
    ...CpaReferralLinkDetailStatisticInfoData.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useGetPartnerCpaReferralLinkPromoCodeStatistic__
 *
 * To run a query within a React component, call `useGetPartnerCpaReferralLinkPromoCodeStatistic` and pass it any options that fit your needs.
 * When your component renders, `useGetPartnerCpaReferralLinkPromoCodeStatistic` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartnerCpaReferralLinkPromoCodeStatistic({
 *   variables: {
 *      after: // value for 'after'
 *      end: // value for 'end'
 *      first: // value for 'first'
 *      referralLinkId: // value for 'referralLinkId'
 *      start: // value for 'start'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetPartnerCpaReferralLinkPromoCodeStatistic(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetPartnerCpaReferralLinkPromoCodeStatistic,
    GetPartnerCpaReferralLinkPromoCodeStatisticVariables
  > &
    (
      | {
          variables: GetPartnerCpaReferralLinkPromoCodeStatisticVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetPartnerCpaReferralLinkPromoCodeStatistic,
    GetPartnerCpaReferralLinkPromoCodeStatisticVariables
  >(GetPartnerCpaReferralLinkPromoCodeStatisticDocument, options);
}
export function useGetPartnerCpaReferralLinkPromoCodeStatisticLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetPartnerCpaReferralLinkPromoCodeStatistic,
    GetPartnerCpaReferralLinkPromoCodeStatisticVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetPartnerCpaReferralLinkPromoCodeStatistic,
    GetPartnerCpaReferralLinkPromoCodeStatisticVariables
  >(GetPartnerCpaReferralLinkPromoCodeStatisticDocument, options);
}
export function useGetPartnerCpaReferralLinkPromoCodeStatisticSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    GetPartnerCpaReferralLinkPromoCodeStatistic,
    GetPartnerCpaReferralLinkPromoCodeStatisticVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    GetPartnerCpaReferralLinkPromoCodeStatistic,
    GetPartnerCpaReferralLinkPromoCodeStatisticVariables
  >(GetPartnerCpaReferralLinkPromoCodeStatisticDocument, options);
}
export type GetPartnerCpaReferralLinkPromoCodeStatisticHookResult = ReturnType<
  typeof useGetPartnerCpaReferralLinkPromoCodeStatistic
>;
export type GetPartnerCpaReferralLinkPromoCodeStatisticLazyQueryHookResult =
  ReturnType<typeof useGetPartnerCpaReferralLinkPromoCodeStatisticLazyQuery>;
export type GetPartnerCpaReferralLinkPromoCodeStatisticSuspenseQueryHookResult =
  ReturnType<
    typeof useGetPartnerCpaReferralLinkPromoCodeStatisticSuspenseQuery
  >;
export type GetPartnerCpaReferralLinkPromoCodeStatisticQueryResult =
  Apollo.QueryResult<
    GetPartnerCpaReferralLinkPromoCodeStatistic,
    GetPartnerCpaReferralLinkPromoCodeStatisticVariables
  >;
