import * as SchemaTypes from '../../../../../../types/generated/gql';

import * as CommonType from 'types';
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'utils/customApolloQuery';
const defaultOptions = {} as const;
export type UpdateCompanyPartnerDetailsVariables = SchemaTypes.Exact<{
  input: SchemaTypes.UpdateCompanyPartnerInput;
}>;

export type UpdateCompanyPartnerDetails = {
  __typename: 'Mutation';
  updateCompanyPartner: {
    __typename: 'UpdateCompanyPartnerPayload';
    companyPartner: {
      __typename: 'CompanyPartner';
      createdAt: string;
      id: string;
      note?: string | null;
      username: string;
    };
  };
};

export const UpdateCompanyPartnerDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCompanyPartnerDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateCompanyPartnerInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCompanyPartner' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'companyPartner' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'CompanyPartner' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'createdAt' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'note' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'username' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type UpdateCompanyPartnerDetailsMutationFn = Apollo.MutationFunction<
  UpdateCompanyPartnerDetails,
  UpdateCompanyPartnerDetailsVariables
>;

/**
 * __useUpdateCompanyPartnerDetails__
 *
 * To run a mutation, you first call `useUpdateCompanyPartnerDetails` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyPartnerDetails` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyPartnerDetails, { data, loading, error }] = useUpdateCompanyPartnerDetails({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCompanyPartnerDetails(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateCompanyPartnerDetails,
    UpdateCompanyPartnerDetailsVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateCompanyPartnerDetails,
    UpdateCompanyPartnerDetailsVariables
  >(UpdateCompanyPartnerDetailsDocument, options);
}
export type UpdateCompanyPartnerDetailsHookResult = ReturnType<
  typeof useUpdateCompanyPartnerDetails
>;
export type UpdateCompanyPartnerDetailsMutationResult =
  Apollo.MutationResult<UpdateCompanyPartnerDetails>;
export type UpdateCompanyPartnerDetailsMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateCompanyPartnerDetails,
    UpdateCompanyPartnerDetailsVariables
  >;
