import { Formik, Form } from 'formik';
import { FC } from 'react';
import { AccessorKeyColumnDef } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { useSearchHandler } from 'hooks/table/useSearchHandler';
import { useFiltersHandlers } from 'hooks/table/useFiltersHandlers';
import { SearchInput } from 'ui/SearchInput';
import { ColumnsSelect } from 'components/ColumnsSelect';
import { CompanyStatisticFilterType } from 'types/generated/gql';
import { CompanyCpaStatisticTableSearchValues } from './types';
import { FieldName } from './const';
import styles from './CompanyCpaStatisticTableSearch.module.scss';
import { CompanyCpaStatisticTableFilters } from '../CompanyCpaStatisticTableFilters';
import { CompanyCpaStatisticTableData } from '../../types';

interface CompanyCpaStatisticTableSearchProps {
  columns: Array<AccessorKeyColumnDef<CompanyCpaStatisticTableData>>;
  initialValues?: CompanyCpaStatisticTableSearchValues;
  disabled?: boolean;
  onColumnsChange?: (columns: Array<string>) => void;
}

export const CompanyCpaStatisticTableSearch: FC<
  CompanyCpaStatisticTableSearchProps
> = ({
  initialValues = {
    [FieldName.Search]: '',
    [FieldName.Columns]: {},
    [FieldName.Filters]: undefined,
  },
  disabled = false,
  columns,
  onColumnsChange,
}) => {
  const { t } = useTranslation('statistic');

  const { onSearchChange } = useSearchHandler();
  const { onFiltersChange, hasFilters } = useFiltersHandlers();

  const handleColumnChange = (columnsValues: Record<string, boolean>) => {
    const selectedColumns = Object.keys(columnsValues).filter(
      (key) => columnsValues[key]
    );

    onColumnsChange?.(selectedColumns);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={({ search, columns: columnsValues, filters }) => {
        if (filters) {
          onFiltersChange<CompanyStatisticFilterType>(filters);
        }

        onSearchChange(search);
        handleColumnChange(columnsValues);
      }}
    >
      {({ values, handleChange, handleSubmit, setFieldValue }) => (
        <Form className={styles.form}>
          <SearchInput
            value={values.search}
            fullWidth
            filterButtonText={t(
              'company.action.filters.button.filtersAndSettings'
            )}
            onChange={(e) => {
              handleChange(e);
              handleSubmit();
            }}
            onClear={() => {
              setFieldValue(FieldName.Search, '');
              handleSubmit();
            }}
            name={FieldName.Search}
            placeholder={t('company.action.search.label.search')}
            disabled={disabled || hasFilters}
            filters={
              <CompanyCpaStatisticTableFilters
                onChange={handleSubmit}
                onClear={() => {
                  setFieldValue(FieldName.Filters, {});
                  handleSubmit();
                }}
              />
            }
            settings={
              <div>
                <ColumnsSelect<CompanyCpaStatisticTableData>
                  columns={columns}
                  buttonText={t('company.action.filters.button.editColumns')}
                  dropdownTitle={t('company.action.filters.button.editColumns')}
                  onColumnsChange={handleSubmit}
                />
              </div>
            }
          />
        </Form>
      )}
    </Formik>
  );
};
