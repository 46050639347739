import { FC } from 'react';
import { Icon, IconSize } from 'ui/Icon';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import {
  TariffBasicData_CpaTariff,
  TariffBasicData_RevShareTariff,
} from 'pages/Tariffs/queries/generated/TariffBasicData';
import { TariffTypeName } from 'types';
import { mapTariffTypeToClass, mapTariffTypeToIcon } from './const';
import styles from './PaymentModel.module.scss';

interface PaymentModelProps {
  tariffTypeName:
    | TariffBasicData_CpaTariff['__typename']
    | TariffBasicData_RevShareTariff['__typename'];
  iconSize?: IconSize;
}

export const PaymentModel: FC<PaymentModelProps> = ({
  tariffTypeName,
  iconSize = IconSize.Medium,
}) => {
  const { t } = useTranslation();
  const tariffTypeClassName = mapTariffTypeToClass[tariffTypeName];
  const icon = mapTariffTypeToIcon[tariffTypeName];

  const mapTariffTypeToText: Record<TariffTypeName, string> = {
    [TariffTypeName.RevShareTariff]: t('dataTypes.value.paymentTypeRevShare'),
    [TariffTypeName.CpaTariff]: t('dataTypes.value.paymentTypeCpa'),
  };

  const tariffName = mapTariffTypeToText[tariffTypeName];

  return (
    <div className={styles.paymentModel}>
      <Icon
        iconComponent={icon}
        hasDefaultColor={false}
        containerClassName={classnames(
          styles.iconContainer,
          tariffTypeClassName
        )}
        size={iconSize}
      />
      {tariffName}
    </div>
  );
};
