import { useEffect, useState } from 'react';
import { DateFormat, formatDate } from 'utils/formatDate';
import { useAuth } from 'components/auth';
import {
  Currency,
  FilterType,
  NonNullableDateRange,
  UserTypeName,
} from 'types';
import {
  PartnerStatisticFilterType,
  PageInfo,
  PartnerRevShareStatisticSortInput,
} from 'types/generated/gql';
import { ExtendedTData } from 'ui/Table/types';
import {
  useGetPartnerRevShareStatisticItems,
  GetPartnerRevShareStatisticItems,
} from '../queries/generated/GetPartnerRevShareStatisticItems';
import { STATISTIC_ITEMS_PER_PAGE } from '../const';
import { PartnerRevShareStatisticTableData } from '../types';
import { usePartnerRevShareStatisticExpandedRows } from './usePartnerRevShareStatisticExpandedRows';

const { DateScalar } = DateFormat;

interface UseGetPartnerRevShareStatisticItemsQueryParams {
  nonNullableDateRange: NonNullableDateRange;
  filters: Array<FilterType<PartnerStatisticFilterType>>;
  currency: Currency;
  searchQuery?: string;
  sortState?: PartnerRevShareStatisticSortInput;
}

export const useGetPartnerRevShareStatisticItemsQuery = ({
  nonNullableDateRange,
  currency,
  searchQuery,
  filters,
  sortState,
}: UseGetPartnerRevShareStatisticItemsQueryParams) => {
  const { user } = useAuth();
  const [start, end] = nonNullableDateRange;

  const [parsedData, setParsedData] = useState<
    Array<ExtendedTData<PartnerRevShareStatisticTableData>>
  >([]);

  const [pageInfoState, setPageInfoState] = useState<PageInfo>();

  const { handleExpandRow } = usePartnerRevShareStatisticExpandedRows({
    parentRowsData: parsedData,
    setParentsRowsData: setParsedData,
    start: formatDate(start, DateScalar),
    end: formatDate(end, DateScalar),
    userId: user.id,
    currency,
  });

  const handleCompleted = (data: GetPartnerRevShareStatisticItems) => {
    const statisticItemsConnection =
      data?.user.__typename === UserTypeName.Partner
        ? data.user.revShareStatistic.statisticItems
        : undefined;

    const newEdges = statisticItemsConnection?.edges;

    const statisticItems =
      newEdges?.map((item) => ({
        ...item.node,
        canExpand: item.node.hasPromoCodeStatistic,
      })) ?? [];

    const pageInfo = statisticItemsConnection?.pageInfo;

    setParsedData((prevData) =>
      statisticItems.map((item, index) => {
        const prevItem = prevData[index];

        if (prevItem?.subRows?.length) {
          return {
            ...item,
            subRows: prevItem.subRows,
          };
        }

        return item;
      })
    );

    setPageInfoState(pageInfo);
  };

  const {
    fetchMore,
    loading,
    isSliced,
    error,
    data: queryData,
  } = useGetPartnerRevShareStatisticItems({
    variables: {
      start: formatDate(start, DateScalar),
      end: formatDate(end, DateScalar),
      first: STATISTIC_ITEMS_PER_PAGE,
      userId: user.id,
      filters,
      currency,
      sort: sortState,
      ...(Boolean(searchQuery) && { referralLinkSearch: searchQuery }),
    },
    onCompleted: handleCompleted,
    notifyOnNetworkStatusChange: true,
    cacheFirstOnPop: true,
  });

  useEffect(() => {
    if (parsedData.length && !queryData) {
      setParsedData([]);
    }
  }, [parsedData.length, queryData]);

  return {
    statisticItems: parsedData,
    fetchMore,
    pageInfo: pageInfoState,
    loading,
    isSliced,
    error,
    handleExpandRow,
  };
};
