import { CompanyCpaStatisticOrderByType } from 'types/generated/gql';
import { CompanyCpaStatisticSortableColumnsType } from '../types';

export const mapColumnToSortColumn: Record<
  keyof CompanyCpaStatisticSortableColumnsType,
  CompanyCpaStatisticOrderByType
> = {
  hits: CompanyCpaStatisticOrderByType.Hits,
  hosts: CompanyCpaStatisticOrderByType.Hosts,
  registrations: CompanyCpaStatisticOrderByType.Registrations,
  firstDeposits: CompanyCpaStatisticOrderByType.FirstDeposits,
  depositsAll: CompanyCpaStatisticOrderByType.DepositsAll,
  hold: CompanyCpaStatisticOrderByType.Hold,
  qualified: CompanyCpaStatisticOrderByType.Qualified,
  approved: CompanyCpaStatisticOrderByType.Approved,
  paid: CompanyCpaStatisticOrderByType.Paid,
  rejected: CompanyCpaStatisticOrderByType.Rejected,
  reward: CompanyCpaStatisticOrderByType.Reward,
  conversionHostsToRegPercent:
    CompanyCpaStatisticOrderByType.ConversionHostsToRegPercent,
  conversionRegToDepPercent:
    CompanyCpaStatisticOrderByType.ConversionRegToDepPercent,
};
