import { useState } from 'react';
import { AccessorKeyColumnDef } from '@tanstack/react-table';
import { MakeIdRequired } from 'types';
import { STATISTIC_CURSOR_COLUMN } from 'pages/Statistic/pages/StatisticGeneral/const';
import { useOptionalColumnsParams } from 'hooks/table/useOptionalColumnsParams';
import { initialVisibleColumns } from '../../../const';
import { CompanyCpaStatisticTableData } from '../types';

export const useCompanyCpaStatisticItemsColumnsManager = ({
  columns,
}: {
  columns: Array<AccessorKeyColumnDef<CompanyCpaStatisticTableData>>;
}) => {
  const { handleColumnChange, parseUrlColumnsIds } = useOptionalColumnsParams();

  const columnsWithId = columns.filter(
    (
      column
    ): column is MakeIdRequired<
      AccessorKeyColumnDef<CompanyCpaStatisticTableData>
    > => !!column.id
  );

  const requiredColumns = columnsWithId.filter((column) =>
    initialVisibleColumns.includes(column.id)
  );

  const cursorColumnIndex = requiredColumns.findIndex(
    (column) => column.id === STATISTIC_CURSOR_COLUMN
  );

  const optionalColumns = columnsWithId.filter(
    (column) => !initialVisibleColumns.includes(column.id)
  );

  const { columns: parsedOptionalColumnsIds, formColumns } =
    parseUrlColumnsIds();
  const parsedInitialOptionalColumns = optionalColumns.filter((column) =>
    parsedOptionalColumnsIds.includes(column.id)
  );

  const initialColumns = requiredColumns.toSpliced(
    cursorColumnIndex + 1,
    0,
    ...parsedInitialOptionalColumns
  );

  const [visibleColumns, setVisibleColumns] =
    useState<Array<AccessorKeyColumnDef<CompanyCpaStatisticTableData>>>(
      initialColumns
    );

  const updateVisibleColumns = (newColumns: Array<string>) => {
    const parsedOptionalColumns = optionalColumns.filter((column) =>
      newColumns.includes(column.id)
    );

    if (!cursorColumnIndex) {
      setVisibleColumns([...requiredColumns, ...parsedOptionalColumns]);

      return;
    }

    const newVisibleColumns = requiredColumns.toSpliced(
      cursorColumnIndex + 1,
      0,
      ...parsedOptionalColumns
    );

    handleColumnChange(parsedOptionalColumns.map((column) => column.id));
    setVisibleColumns(newVisibleColumns);
  };

  return {
    optionalColumns,
    visibleColumns,
    updateVisibleColumns,
    formColumns,
  };
};
