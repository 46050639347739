import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { Table } from 'ui/Table';
import { CompanyPromoCodeMediaItemsFilterType } from 'types/generated/gql';
import { useLoadMore } from 'hooks/useLoadMore';
import { useFiltersHandlers } from 'hooks/table/useFiltersHandlers';
import { usePromoCodeDetailsModal } from 'pages/Media/components/MediaDataBlock/components/PromoCodeMediaTable/hooks/usePromoCodeDetailsModal';
import { useCompanyPromoCodeMediaItemsColumns } from './hooks/useCompanyPromoCodeMediaItemsColumns';
import { useGetCompanyPromoCodeMediaItemsQuery } from './hooks/useGetCompanyPromoCodeMediaItemsQuery';
import { PromoCodeMediaItemData } from '../../queries/fragments/generated/PromoCodeMediaItemData';
import { CompanyPromoCodeMediaTableSearch } from './components/CompanyPromoCodeMediaTableSearch';

export const CompanyPromoCodeMediaTable: FC = () => {
  const { openDetailsModal } = usePromoCodeDetailsModal();
  const { t } = useTranslation('media');

  const [searchParams] = useSearchParams();
  const { parseUrlFilters } = useFiltersHandlers();

  const searchQuery = searchParams.has('search')
    ? (searchParams.get('search') ?? '')
    : '';

  const { filters, formFilters } =
    parseUrlFilters<CompanyPromoCodeMediaItemsFilterType>();

  const {
    promoCodeMediaItems,
    fetchMore,
    pageInfo,
    loading: getPromoCodeMediaItemLoading,
    error: getPromoCodeMediaItemsError,
  } = useGetCompanyPromoCodeMediaItemsQuery({ searchQuery, filters });

  const loadMore = useLoadMore(fetchMore, pageInfo);

  const isLoading = !promoCodeMediaItems && getPromoCodeMediaItemLoading;
  const error = getPromoCodeMediaItemsError;

  const columns = useCompanyPromoCodeMediaItemsColumns();

  const handleRowClick = ({ id }: PromoCodeMediaItemData) => {
    openDetailsModal(id);
  };

  const tableData = promoCodeMediaItems ?? [];

  return (
    <Table
      columns={columns}
      data={tableData}
      error={error}
      emptyText={t('placeholder.promoCodes.noData')}
      hasSearchParams={!!searchQuery || !!filters.length}
      isLoading={isLoading}
      emptySearchStateConfig={{
        title: t('placeholder.main.searchRequest.noDataHeader'),
        description: t('placeholder.main.searchRequest.noDataSubheader'),
      }}
      errorStateTitle={t('placeholder.loadingError.loadingErrorHeader')}
      errorStateDescription={t(
        'placeholder.loadingError.loadingErrorSubheader'
      )}
      hasMoreData={!!pageInfo?.hasNextPage}
      fetchMore={loadMore}
      onRowClick={handleRowClick}
      searchComponent={
        <CompanyPromoCodeMediaTableSearch
          initialValues={{ search: searchQuery, filters: formFilters }}
        />
      }
    />
  );
};
