import { useField } from 'formik';
import { FC } from 'react';
import { PostbackEvent } from 'types/generated/gql';
import { List } from 'ui/List';
import { Dropdown } from 'ui/Dropdown';
import { useTranslationUserType } from 'hooks/useTranslationUserType';
import { getMinimalUnderBreakpoint } from 'helpers';
import { Breakpoints } from 'const';
import { FieldName } from '../../../../../Postback/const';
import { useGetMacrosByEventQuery } from './hooks/useGetMacrosByEventQuery';
import { getListItemByMacros } from './helpers';
import styles from './MacrosDropdown.module.scss';

interface MacrosDropdownProps {
  isOpen: boolean;
  selectedPostback: PostbackEvent;
  onItemSelect?: () => void;
}

export const MacrosDropdown: FC<MacrosDropdownProps> = ({
  isOpen,
  selectedPostback,
  onItemSelect,
}) => {
  const [field, , helpers] = useField<string>(FieldName.Url);
  const translationUserType = useTranslationUserType();

  const macros = useGetMacrosByEventQuery({ postbackEvent: selectedPostback });

  const handleListItemItemClick = (value?: string) => {
    if (!value) return;
    helpers.setValue(field.value + value);
    onItemSelect?.();
  };

  if (!macros?.length || !isOpen) {
    return null;
  }

  const isMobile = getMinimalUnderBreakpoint() === Breakpoints.Sm;
  const isTablet = getMinimalUnderBreakpoint() === Breakpoints.Md;
  const isDesktop = !isMobile && !isTablet;

  if (isMobile || isTablet) {
    return (
      <List
        listContainerClassName={styles.listContainer}
        highlightOnHover
        items={getListItemByMacros(
          macros,
          handleListItemItemClick,
          translationUserType
        )}
      />
    );
  }

  if (isDesktop) {
    return (
      <Dropdown
        isOpenForced={isOpen}
        withStyleMaxContent={false}
        title=""
        withBottomSheet={false}
        withPopUp={false}
      >
        <List
          listContainerClassName={styles.listContainer}
          highlightOnHover
          items={getListItemByMacros(
            macros,
            handleListItemItemClick,
            translationUserType
          )}
        />
      </Dropdown>
    );
  }

  return null;
};
