import { FC } from 'react';
import { LabelWithIcon } from 'ui/LabelWithIcon';
import { StatusLabelSize, StatusLabelType } from './const';

import {
  mapTypeToClassName,
  mapTypeToIcon,
  mapSizeToLabelWithIconSize,
} from './helpers';

interface StatusLabelProps {
  size?: StatusLabelSize;
  type: StatusLabelType;
  children: string;
}

export const StatusLabel: FC<StatusLabelProps> = ({
  size = StatusLabelSize.Large,
  type,
  children,
}) => (
  <LabelWithIcon
    iconClassName={mapTypeToClassName[type]}
    icon={mapTypeToIcon[type]}
    size={mapSizeToLabelWithIconSize[size]}
  >
    {children}
  </LabelWithIcon>
);
