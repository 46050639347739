import classNames from 'classnames';
import { useField } from 'formik';
import { Placement } from '@floating-ui/react';
import { ReactComponent as DoneIcon } from 'assets/icons/line/DoneSmall.svg';
import { ReactComponent as MinusIcon } from 'assets/icons/line/Minus.svg';
import { FormValidationWrapper } from '../FormValidationWrapper/FormValidationWrapper';
import styles from './Checkbox.module.scss';
import {
  CheckboxSize,
  CheckboxType,
  ERROR_TOOLTIP_OFFSET_DEFAULT,
  ERROR_TOOLTIP_OFFSET_SMALL,
} from './const';
import { Icon, IconSize } from '../../../Icon';

const mapCheckboxSizeToIconSize: Record<CheckboxSize, IconSize> = {
  [CheckboxSize.Default]: IconSize.Large,
  [CheckboxSize.Small]: IconSize.Small,
};

const mapCheckboxSizeToStyle: Record<CheckboxSize, string> = {
  [CheckboxSize.Default]: styles.default,
  [CheckboxSize.Small]: styles.small,
};

interface CheckboxProps {
  name: string;
  label?: string;
  subLabel?: string;
  checkboxSize?: CheckboxSize;
  className?: string;
  onChange?: (value: boolean) => void;
  errorTooltipPlacement?: Placement;
  horizontalOffset?: number;
  disabled?: boolean;
  type?: CheckboxType;
}

export const Checkbox: React.FC<CheckboxProps> = ({
  label,
  name,
  checkboxSize = CheckboxSize.Default,
  type = CheckboxType.Default,
  className,
  onChange,
  errorTooltipPlacement,
  horizontalOffset = CheckboxSize.Default
    ? ERROR_TOOLTIP_OFFSET_DEFAULT
    : ERROR_TOOLTIP_OFFSET_SMALL,
  disabled,
  subLabel,
}) => {
  const [field, , setters] = useField(name);

  const handleClick = () => {
    setters.setValue(!field.value);
    onChange?.(!field.value);

    setTimeout(() => {
      setters.setTouched(true, true);
    }, 0);
  };

  return (
    <FormValidationWrapper
      name={name}
      errorTooltipPlacement={errorTooltipPlacement}
      horizontalOffset={horizontalOffset}
    >
      <button
        type="button"
        className={classNames(styles.checkboxButton, className)}
        onClick={handleClick}
        data-checkbox="data-checkbox"
        disabled={disabled}
      >
        <div
          data-testid="checkboxControl"
          className={classNames(
            styles.checkboxControl,
            mapCheckboxSizeToStyle[checkboxSize],
            {
              [styles.checked]: field.value,
              [styles.disabled]: disabled,
            }
          )}
        >
          <Icon
            size={mapCheckboxSizeToIconSize[checkboxSize]}
            hasDefaultColor={false}
            containerClassName={styles.iconContainer}
            iconClassName={styles.icon}
            iconComponent={type === CheckboxType.Default ? DoneIcon : MinusIcon}
          />
        </div>
        {label && (
          <div className={styles.labelWrapper}>
            <span
              className={classNames(styles.label, {
                [styles.disabled]: disabled,
              })}
            >
              {label}
            </span>
            {subLabel && (
              <span
                className={classNames(styles.subLabel, {
                  [styles.disabled]: disabled,
                })}
              >
                {subLabel}
              </span>
            )}
          </div>
        )}
      </button>
    </FormValidationWrapper>
  );
};
