import { FC, PropsWithChildren } from 'react';
import classnames from 'classnames';
import { TableCellAlign } from './types';
import { mapAlignToStyle } from './helpers';
import styles from './TableRowCell.module.scss';

interface TableRowCellProps {
  isPinned?: boolean;
  align?: TableCellAlign;
  left?: number;
  isSummaryCell?: boolean;
  disableOverflow?: boolean;
  className?: string;
  hoveredColumn?: boolean;
}

export const TableRowCell: FC<PropsWithChildren<TableRowCellProps>> = ({
  children,
  isPinned,
  isSummaryCell = false,
  align = TableCellAlign.Left,
  disableOverflow = false,
  left,
  className,
  hoveredColumn,
}) => (
  <div
    role="cell"
    className={classnames(
      styles.container,
      mapAlignToStyle[align],
      {
        [styles.pinned]: isPinned,
        [styles.summaryCell]: isSummaryCell,
        [styles.disableOverflow]: disableOverflow,
        [styles.hovered]: hoveredColumn,
      },
      className
    )}
    style={{
      left: isPinned && left !== undefined ? `${left}px` : undefined,
    }}
  >
    {children}
  </div>
);
