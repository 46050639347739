import { useGetUserTypeNameQuery } from 'hooks/queryHooks/useGetUserTypeNameQuery';
import { UserTypeName } from 'types';
import { toast } from 'ui/toast';
import { useTranslation } from 'react-i18next';
import { PartnerReferralLinkMediaTable } from './components/PartnerReferralLinkMediaTable';
import { CompanyReferralLinkMediaTable } from './components/CompanyReferralLinkMediaTable';

export const ReferralLinkMediaTable = () => {
  const { t } = useTranslation('common');

  const { userTypeName } = useGetUserTypeNameQuery({
    onError: () => {
      toast.error(t('errors.commonError'));
    },
  });

  return userTypeName === UserTypeName.Company ? (
    <CompanyReferralLinkMediaTable />
  ) : (
    <PartnerReferralLinkMediaTable />
  );
};
