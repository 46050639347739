import * as SchemaTypes from '../../../../../../../../types/generated/gql';

import * as CommonType from 'types';
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'utils/customApolloQuery';
const defaultOptions = {} as const;
export type RegisterMigrationPartnerVariables = SchemaTypes.Exact<{
  input: SchemaTypes.RegisterMigrationPartnerInput;
}>;

export type RegisterMigrationPartner = {
  __typename: 'Mutation';
  registerMigrationPartner: {
    __typename: 'RegisterMigrationPartnerPayload';
    partner: { __typename: 'Partner'; id: string };
  };
};

export const RegisterMigrationPartnerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RegisterMigrationPartner' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RegisterMigrationPartnerInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'registerMigrationPartner' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'partner' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type RegisterMigrationPartnerMutationFn = Apollo.MutationFunction<
  RegisterMigrationPartner,
  RegisterMigrationPartnerVariables
>;

/**
 * __useRegisterMigrationPartner__
 *
 * To run a mutation, you first call `useRegisterMigrationPartner` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMigrationPartner` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMigrationPartner, { data, loading, error }] = useRegisterMigrationPartner({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterMigrationPartner(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RegisterMigrationPartner,
    RegisterMigrationPartnerVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    RegisterMigrationPartner,
    RegisterMigrationPartnerVariables
  >(RegisterMigrationPartnerDocument, options);
}
export type RegisterMigrationPartnerHookResult = ReturnType<
  typeof useRegisterMigrationPartner
>;
export type RegisterMigrationPartnerMutationResult =
  Apollo.MutationResult<RegisterMigrationPartner>;
export type RegisterMigrationPartnerMutationOptions =
  Apollo.BaseMutationOptions<
    RegisterMigrationPartner,
    RegisterMigrationPartnerVariables
  >;
