import classNames from 'classnames';
import { ReactComponent as SelectedCheckboxIcon } from 'assets/icons/line/NonInteractiveSelectedCheckbox.svg';
import { ReactComponent as UnelectedCheckboxIcon } from 'assets/icons/line/NonInteractiveUnselectedCheckbox.svg';
import { ValidationHintRule } from '../types';
import styles from './ValidationHintContent.module.scss';

interface ValidationHintContentProps {
  rules: Array<ValidationHintRule>;
  validationState: Record<string, boolean>;
}

export const ValidationHintContent = ({
  rules,
  validationState,
}: ValidationHintContentProps) => (
  <div className={styles.hintContainer}>
    {rules.map((rule) => {
      const isActive = validationState[rule.name];

      return (
        <div
          key={rule.text}
          className={classNames(styles.hint, {
            [styles.isActive]: isActive,
          })}
        >
          {isActive ? (
            <SelectedCheckboxIcon className={styles.checkbox} />
          ) : (
            <UnelectedCheckboxIcon className={styles.checkbox} />
          )}
          <span className={styles.hintText}>{rule.text}</span>
        </div>
      );
    })}
  </div>
);
