import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnDef } from '@tanstack/react-table';
import { getMoneyAmount } from 'utils/currency/getMoneyAmount';
import { Currency, FilterType, NonNullableDateRange } from 'types';
import { CompanyStatisticFilterType } from 'types/generated/gql';
import { CompanyRevShareStatisticInfoData } from '../queries/fragments/generated/CompanyRevShareStatisticInfoData';
import { useGetCompanyRevShareStatisticTotalQuery } from './useGetCompanyRevShareStatisticTotalQuery';
import { ALL_COLUMNS } from '../../../const';
import { CompanyRevShareStatisticTableData } from '../types';

type GetSummaryRowData = (
  info: CompanyRevShareStatisticInfoData,
  visibleColumns: Array<ColumnDef<CompanyRevShareStatisticTableData>>
) => Array<ReactNode>;

const getSummaryRowData: GetSummaryRowData = (
  {
    hits,
    hosts,
    registrations,
    firstDeposits,
    depositsAll,
    withdrawalsAll,
    depositsFee,
    withdrawalsFee,
    adminFee,
    reward,
    averageDeposit,
    redepositsAll,
    conversionHostsToRegPercent,
    conversionRegToDepPercent,
    numberOfRedeposits,
  },
  visibleColumns
) => {
  const summaryRows: Record<string, ReactNode> = {
    [ALL_COLUMNS.hits]: hits,
    [ALL_COLUMNS.hosts]: hosts,
    [ALL_COLUMNS.registrations]: registrations,
    [ALL_COLUMNS.firstDeposits]: firstDeposits,
    [ALL_COLUMNS.depositsAll]: getMoneyAmount(depositsAll),
    [ALL_COLUMNS.withdrawalsAll]: getMoneyAmount(withdrawalsAll),
    [ALL_COLUMNS.depositsFee]: getMoneyAmount(depositsFee),
    [ALL_COLUMNS.withdrawalsFee]: getMoneyAmount(withdrawalsFee),
    [ALL_COLUMNS.adminFee]: getMoneyAmount(adminFee),
    [ALL_COLUMNS.reward]: getMoneyAmount(reward),
    [ALL_COLUMNS.averageDeposit]: getMoneyAmount(averageDeposit),
    [ALL_COLUMNS.redepositsAll]: getMoneyAmount(redepositsAll),
    [ALL_COLUMNS.conversionHostsToRegPercent]: `${conversionHostsToRegPercent}%`,
    [ALL_COLUMNS.conversionRegToDepPercent]: `${conversionRegToDepPercent}%`,
    [ALL_COLUMNS.numberOfRedeposits]: numberOfRedeposits,
  };

  const result = visibleColumns.reduce<Array<ReactNode>>((acc, column) => {
    if (column.id && column.id in summaryRows) {
      return [...acc, summaryRows[column.id]];
    }

    return acc;
  }, []);

  return result;
};

interface UseGenerateCompanyRevShareStatisticSummaryRowParams {
  nonNullableDateRange: NonNullableDateRange;
  filters: Array<FilterType<CompanyStatisticFilterType>>;
  visibleColumns: Array<ColumnDef<CompanyRevShareStatisticTableData>>;
  currency: Currency;
  searchQuery?: string;
}

export const useGenerateCompanyRevShareStatisticSummaryRow = ({
  nonNullableDateRange,
  filters,
  visibleColumns,
  currency,
  searchQuery,
}: UseGenerateCompanyRevShareStatisticSummaryRowParams) => {
  const { t } = useTranslation('statistic');

  const { totalStatistic, error, loading } =
    useGetCompanyRevShareStatisticTotalQuery({
      nonNullableDateRange,
      filters,
      currency,
      searchQuery,
    });

  const summaryRowData = totalStatistic
    ? [
        null,
        t('company.content.dataTable.revShare.allMedia'),
        undefined,
        ...getSummaryRowData(totalStatistic, visibleColumns),
      ]
    : undefined;

  return { summaryRowData, loading, error };
};
