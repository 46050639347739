import { t } from 'i18n';
import { TranslationUserType } from 'types';
import { PostbackEvent } from 'types/generated/gql';

export const mapEventToName = (
  event: PostbackEvent,
  translationUserType: TranslationUserType
): string => {
  const map: Record<PostbackEvent, string> = {
    [PostbackEvent.FirstDeposit]: t(
      `media:${translationUserType}.content.drawer.details.postback.card.input.value.postbackEvents.firstDeposit`
    ),
    [PostbackEvent.QualificationCheck]: t(
      `media:${translationUserType}.content.drawer.details.postback.card.input.value.postbackEvents.qualificationCheck`
    ),
    [PostbackEvent.Registration]: t(
      `media:${translationUserType}.content.drawer.details.postback.card.input.value.postbackEvents.signUp`
    ),
    [PostbackEvent.Reward]: t(
      `media:${translationUserType}.content.drawer.details.postback.card.input.value.postbackEvents.reward`
    ),
  };

  return map[event];
};
