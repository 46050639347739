export const COLUMNS_SIZES = {
  mediaNameSize: 256,
  default: 160,
} as const;

export const ALL_COLUMNS = {
  id: 'id',
  owner: 'owner',
  hits: 'hits',
  hosts: 'hosts',
  registrations: 'registrations',
  firstDeposits: 'firstDeposits',
  depositsAll: 'depositsAll',
  hold: 'hold',
  qualified: 'qualified',
  approved: 'approved',
  paid: 'paid',
  rejected: 'rejected',
  reward: 'reward',
  averageDeposit: 'averageDeposit',
  redepositsAll: 'redepositsAll',
  conversionHostsToRegPercent: 'conversionHostsToRegPercent',
  conversionRegToDepPercent: 'conversionRegToDepPercent',
  numberOfRedeposits: 'numberOfRedeposits',
  withdrawalsAll: 'withdrawalsAll',
};

export const initialVisibleColumns: Array<string> = [
  ALL_COLUMNS.id,
  ALL_COLUMNS.owner,
  ALL_COLUMNS.hits,
  ALL_COLUMNS.hosts,
  ALL_COLUMNS.registrations,
  ALL_COLUMNS.firstDeposits,
  ALL_COLUMNS.depositsAll,
  ALL_COLUMNS.hold,
  ALL_COLUMNS.qualified,
  ALL_COLUMNS.approved,
  ALL_COLUMNS.paid,
  ALL_COLUMNS.rejected,
  ALL_COLUMNS.reward,
];
