export const COLUMNS_SIZES = {
  mediaNameSize: 256,
  ownerSize: 180,
  default: 160,
} as const;

export const ALL_COLUMNS = {
  id: 'id',
  owner: 'owner',
  hits: 'hits',
  hosts: 'hosts',
  registrations: 'registrations',
  firstDeposits: 'firstDeposits',
  depositsAll: 'depositsAll',
  withdrawalsAll: 'withdrawalsAll',
  depositsFee: 'depositsFee',
  withdrawalsFee: 'withdrawalsFee',
  adminFee: 'adminFee',
  reward: 'reward',
  averageDeposit: 'averageDeposit',
  redepositsAll: 'redepositsAll',
  conversionHostsToRegPercent: 'conversionHostsToRegPercent',
  conversionRegToDepPercent: 'conversionRegToDepPercent',
  numberOfRedeposits: 'numberOfRedeposits',
};

export const initialVisibleColumns: Array<string> = [
  ALL_COLUMNS.id,
  ALL_COLUMNS.owner,
  ALL_COLUMNS.hits,
  ALL_COLUMNS.hosts,
  ALL_COLUMNS.registrations,
  ALL_COLUMNS.firstDeposits,
  ALL_COLUMNS.depositsAll,
  ALL_COLUMNS.withdrawalsAll,
  ALL_COLUMNS.depositsFee,
  ALL_COLUMNS.withdrawalsFee,
  ALL_COLUMNS.adminFee,
  ALL_COLUMNS.reward,
];
