export interface TokenBodyResponse {
  token?: string;
  detail?: string;
  status?: string | number;
  title?: string;
}

export interface TwoFactorBodyResponse {
  qr_content: string;
  secret: string;
}

export enum AuthStatusesBackend {
  Authenticated = 'Authenticated',
  TwoFactorGenerationRequired = 'TwoFactorGenerationRequired',
  TwoFactorAuthenticationRequired = 'TwoFactorAuthenticationRequired',
  AuthenticatedTransferUser = 'AuthenticatedTransferUser',
}
