import { IconComponent } from 'types';
import { SortDirection } from '@tanstack/react-table';
import { ReactComponent as AscendingIcon } from 'assets/icons/line/Ascending.svg';
import { ReactComponent as DescendingIcon } from 'assets/icons/line/Descending.svg';
import { TableCellAlign } from './types';
import styles from './TableColumnCell.module.scss';

export const mapAlignToStyle: Record<TableCellAlign, string> = {
  [TableCellAlign.Left]: styles.alignLeft,
  [TableCellAlign.Right]: styles.alignRight,
  [TableCellAlign.Center]: styles.alignCenter,
};

export const mapSortIcon: Record<SortDirection, IconComponent> = {
  asc: AscendingIcon,
  desc: DescendingIcon,
};
