import { TariffTypeName } from 'types';
import { AppRoute } from 'const';
import {
  TariffBasicData_CpaTariff,
  TariffBasicData_RevShareTariff,
} from '../pages/Tariffs/queries/generated/TariffBasicData';

export const getStatisticRouteByTariffTypeName = (
  typeName:
    | TariffBasicData_CpaTariff['__typename']
    | TariffBasicData_RevShareTariff['__typename']
) =>
  typeName === TariffTypeName.CpaTariff
    ? AppRoute.Lang.Statistic.Cpa.root
    : AppRoute.Lang.Statistic.RevShare.root;
