import type { FC } from 'react';
import classNames from 'classnames';
import { LanguageDropdown } from 'components/LanguageDropdown';
import { useLanguageDropdownButton } from 'hooks/useLanguageDropdownButton';
import { mapLanguageToDisplayName } from 'utils/language/mapLanguageToDisplayName';
import { checkIsLanguage } from 'utils/language/checkIsLanguage';
import { getMinimalUnderBreakpoint } from 'helpers';
import { Breakpoints } from 'const';
import { MenuItemData } from '../../types';
import styles from './LanguageDropdownMenuItem.module.scss';
import { DropdownPosition } from '../../../Dropdown';
import { Icon } from '../../../Icon';

interface LanguageDropdownMenuItemProps {
  menuItem: MenuItemData;
}

export const LanguageDropdownMenuItem: FC<LanguageDropdownMenuItemProps> = ({
  menuItem,
}) => {
  const { isActive: isActiveDropdown, onOpenChange } =
    useLanguageDropdownButton();

  return (
    <LanguageDropdown
      withStyleMaxContent
      position={
        getMinimalUnderBreakpoint() === Breakpoints.Md ||
        getMinimalUnderBreakpoint() === Breakpoints.Sm
          ? DropdownPosition.LeftBottom
          : DropdownPosition.Right
      }
      onOpenChange={onOpenChange}
      trigger={
        <div
          className={classNames(
            styles.menuItem,
            styles.languageDropdownButton,
            {
              [styles.active]: isActiveDropdown,
            }
          )}
        >
          <button className={styles.languageDropdownButton} type="button">
            <Icon
              containerClassName={styles.iconContainer}
              iconComponent={menuItem.icon}
              hasDefaultColor={false}
            />
            {checkIsLanguage(menuItem.text) &&
              mapLanguageToDisplayName(menuItem.text)}
          </button>
        </div>
      }
    />
  );
};
