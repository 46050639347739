import * as SchemaTypes from '../../../../../../../../types/generated/gql';

import * as CommonType from 'types';
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'utils/customApolloQuery';
const defaultOptions = {} as const;
export type RegisterMigrationCompanyVariables = SchemaTypes.Exact<{
  input: SchemaTypes.RegisterMigrationCompanyInput;
}>;

export type RegisterMigrationCompany = {
  __typename: 'Mutation';
  registerMigrationCompany: {
    __typename: 'RegisterMigrationCompanyPayload';
    company: { __typename: 'Company'; id: string };
  };
};

export const RegisterMigrationCompanyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RegisterMigrationCompany' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RegisterMigrationCompanyInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'registerMigrationCompany' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'company' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type RegisterMigrationCompanyMutationFn = Apollo.MutationFunction<
  RegisterMigrationCompany,
  RegisterMigrationCompanyVariables
>;

/**
 * __useRegisterMigrationCompany__
 *
 * To run a mutation, you first call `useRegisterMigrationCompany` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMigrationCompany` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMigrationCompany, { data, loading, error }] = useRegisterMigrationCompany({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterMigrationCompany(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RegisterMigrationCompany,
    RegisterMigrationCompanyVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    RegisterMigrationCompany,
    RegisterMigrationCompanyVariables
  >(RegisterMigrationCompanyDocument, options);
}
export type RegisterMigrationCompanyHookResult = ReturnType<
  typeof useRegisterMigrationCompany
>;
export type RegisterMigrationCompanyMutationResult =
  Apollo.MutationResult<RegisterMigrationCompany>;
export type RegisterMigrationCompanyMutationOptions =
  Apollo.BaseMutationOptions<
    RegisterMigrationCompany,
    RegisterMigrationCompanyVariables
  >;
