import { Dispatch, SetStateAction } from 'react';
import { ExtendedTData } from 'ui/Table/types';
import { Currency } from 'types';
import { ApolloError } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import {
  PageInfo,
  RevSharePromoCodeStatisticItem,
  RevShareReferralLinkDetailStatisticInfo,
} from 'types/generated/gql';
import { StatisticItemTypeName } from 'pages/Statistic/pages/StatisticGeneral/types';
import { CompanyRevShareStatisticTableData } from '../../types';
import { useGetCompanyRevShareReferralLinkPromoCodesStatisticItemsQuery } from '../useGetCompanyRevShareReferralLinkPromoCodesStatisticItemsQuery';

interface GetDataWithSubRowsParams {
  referralLink: ExtendedTData<CompanyRevShareStatisticTableData>;
  expandedData: CompanyRevShareStatisticExpandedData;
  loadMore: (
    referralLink: ExtendedTData<CompanyRevShareStatisticTableData>,
    after?: string | null
  ) => void;
  after?: string | null;
}

interface CompanyRevShareStatisticExpandedData {
  data: {
    statisticInfo: RevShareReferralLinkDetailStatisticInfo;
    promoCodes: Array<RevSharePromoCodeStatisticItem>;
  } | null;
  error?: ApolloError;
  pageInfo?: PageInfo;
  hasMoreData?: boolean;
}

interface UseCompanyRevShareStatisticExpandedRowsParams {
  parentRowsData: Array<ExtendedTData<CompanyRevShareStatisticTableData>>;
  setParentsRowsData: Dispatch<
    SetStateAction<Array<ExtendedTData<CompanyRevShareStatisticTableData>>>
  >;
  start: string;
  end: string;
  userId: string;
  currency: Currency;
}

export const useCompanyRevShareStatisticExpandedRows = ({
  parentRowsData,
  setParentsRowsData,
  start,
  end,
  userId,
  currency,
}: UseCompanyRevShareStatisticExpandedRowsParams) => {
  const { t } = useTranslation('statistic');

  const { fetchData } =
    useGetCompanyRevShareReferralLinkPromoCodesStatisticItemsQuery({
      start,
      end,
      userId,
      currency,
    });

  const updateData = (
    itemId: string,
    updatedData: Partial<ExtendedTData<CompanyRevShareStatisticTableData>>
  ) => {
    setParentsRowsData((prevData) =>
      prevData.map((item) => {
        if (itemId === item.id) {
          return {
            ...item,
            ...updatedData,
          } as ExtendedTData<CompanyRevShareStatisticTableData>;
        }

        return item;
      })
    );
  };

  const setLoading = ({ id, loading }: { id: string; loading: boolean }) => {
    updateData(id, {
      nestedInfo: {
        isLoading: loading,
      },
    });
  };

  const getDataWithSubRows = ({
    referralLink,
    expandedData,
    after,
    loadMore,
  }: GetDataWithSubRowsParams) => {
    if (expandedData.data && !expandedData.error) {
      const subRows = after
        ? [...(referralLink.subRows ?? []), ...expandedData.data.promoCodes]
        : [
            {
              id: referralLink.id,
              hasPromoCodeStatistic: false,
              link:
                referralLink.__typename ===
                StatisticItemTypeName.CompanyRevShareStatisticItem
                  ? referralLink.link
                  : undefined,
              statisticInfo: expandedData.data.statisticInfo,
              __typename: referralLink.__typename,
            },
            ...expandedData.data.promoCodes,
          ];

      const newData = {
        subRows,
        nestedInfo: {
          isLoading: false,
          hasMoreData: expandedData.hasMoreData,
          moreDataButtonText: t('company.content.pagination.pagination'),
        },
      };

      const updatedReferralLink = {
        ...referralLink,
        ...newData,
      } as ExtendedTData<CompanyRevShareStatisticTableData>;

      updateData(referralLink.id, {
        ...updatedReferralLink,
        nestedInfo: {
          ...updatedReferralLink.nestedInfo,
          isLoading: false,
          loadMore: () =>
            loadMore(updatedReferralLink, expandedData.pageInfo?.endCursor),
        },
      });
    }
  };

  const loadMoreNestedData = async (
    referralLink: ExtendedTData<CompanyRevShareStatisticTableData>,
    after?: string | null
  ) => {
    setLoading({ id: referralLink.id, loading: true });

    const expandedData = await fetchData(referralLink.id, after);

    if (expandedData) {
      if (expandedData.error) {
        updateData(referralLink.id, {
          nestedInfo: {
            error: expandedData.error,
            isLoading: false,
            loadMore: () => loadMoreNestedData(referralLink),
          },
        });
      }

      getDataWithSubRows({
        referralLink,
        expandedData,
        after,
        loadMore: loadMoreNestedData,
      });
    } else {
      setLoading({ id: referralLink.id, loading: false });
    }
  };

  const handleExpandRow = async (itemId: string) => {
    const referralLink = parentRowsData.find((item) => item.id === itemId);

    if (referralLink) {
      await loadMoreNestedData(referralLink);
    }
  };

  return {
    handleExpandRow,
  };
};
