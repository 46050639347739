import { createRef, ReactNode } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { ApolloError } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Column, Row } from '@tanstack/react-table';
import { useInfiniteScroll } from 'hooks/useInfiniteScroll';
import { getGridTemplateColumns } from 'ui/Table/helpers';
import { EXPAND_COLUMN_ID } from 'ui/Table/const';
import { Icon, IconSize } from 'ui/Icon';
import { EmptyStateConfig, ExtendedTData } from 'ui/Table/types';
import { ReactComponent as LoadingIcon } from 'assets/icons/solid/Loader.svg';
import { TableRow } from '../TableRow';
import styles from './TableBody.module.scss';
import { TableSummaryRow } from '../TableSummaryRow';
import { EmptySearchState } from '../EmptySearchState';
import { ErrorState } from '../ErrorState';
import { ROW_ANIMATION_DURATION } from '../../const';

interface TableBodyProps<TData> {
  fetchMore: () => void;
  rows: Array<Row<ExtendedTData<TData>>>;
  columns: Array<Column<TData>>;
  isLoading: boolean;
  canSomeRowsExpand: boolean;
  summaryRow?: Array<ReactNode>;
  onRowClick?: (arg: TData) => void;
  isEmpty?: boolean;
  error?: ApolloError;
  emptyStateConfig: EmptyStateConfig;
  errorStateTitle?: string;
  errorStateDescription?: string;
  hoveredColumnId?: string | null;
}

export const TableBody = <TData,>({
  fetchMore,
  rows,
  columns,
  isLoading,
  summaryRow,
  onRowClick,
  isEmpty,
  error,
  errorStateTitle = '',
  errorStateDescription = '',
  canSomeRowsExpand,
  emptyStateConfig,
  hoveredColumnId,
}: TableBodyProps<TData>) => {
  const { t } = useTranslation('common');

  const parsedColumns = columns.filter((column) => {
    if (!canSomeRowsExpand) {
      return column.id !== EXPAND_COLUMN_ID;
    }

    return true;
  });

  const loadTrigger = useInfiniteScroll({ callback: fetchMore });
  const gridTemplateColumns = getGridTemplateColumns(parsedColumns);

  if (error) {
    return (
      <ErrorState title={errorStateTitle} description={errorStateDescription} />
    );
  }

  if (isLoading) {
    return (
      <div className={styles.loadingContainer}>
        <Icon
          iconClassName={styles.loadingIcon}
          iconComponent={LoadingIcon}
          hasDefaultColor={false}
          size={IconSize.Small}
          spin
        />
        <span className={styles.text}>{t('progress.loading')}</span>
      </div>
    );
  }

  if (isEmpty) {
    return (
      <EmptySearchState
        icon={emptyStateConfig.icon}
        title={emptyStateConfig.title}
        description={emptyStateConfig.description}
      />
    );
  }

  return (
    <div className={styles.container}>
      <div
        className={styles.tableBody}
        role="rowgroup"
        style={{ gridTemplateColumns }}
      >
        {summaryRow && (
          <TableSummaryRow
            canSomeRowsExpand={canSomeRowsExpand}
            row={summaryRow}
            columns={columns}
          />
        )}
        <TransitionGroup component={null}>
          {rows.map((row) => {
            const ref = createRef<HTMLDivElement>();

            return (
              <CSSTransition
                classNames={{
                  ...styles,
                }}
                key={`${row.id}-${row.original.id}`}
                nodeRef={ref}
                timeout={ROW_ANIMATION_DURATION}
              >
                <TableRow
                  row={row}
                  onClick={onRowClick}
                  canSomeRowsExpand={canSomeRowsExpand}
                  rowRef={ref}
                  hoveredColumnId={hoveredColumnId}
                />
              </CSSTransition>
            );
          })}
        </TransitionGroup>
        <div className={styles.infinityScrollTrigger}>{loadTrigger}</div>
      </div>
    </div>
  );
};
