import { ButtonTheme } from '../../Button';
import styles from '../DropdownWithButton.module.scss';

export const mapButtonActiveState: Record<
  ButtonTheme.BlackTonal | ButtonTheme.AccentTonal,
  string
> = {
  [ButtonTheme.BlackTonal]: styles.activeBlackTonalButton,
  [ButtonTheme.AccentTonal]: styles.activeAccentTonalButton,
};
