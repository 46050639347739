import { FC } from 'react';
import { Country } from 'types';
import { Icon } from 'ui/Icon';
import { getCountryNameByCode } from 'utils/countries/getCountryNameByCode';
import { useLanguage } from 'contexts/LanguageContext';
import { getImgSrcByCountry } from 'utils/countries/getImgSrcByCountry';
import styles from './CountryWithFlag.module.scss';

interface CountryWithFlagProps {
  country: Country;
}

export const CountryWithFlag: FC<CountryWithFlagProps> = ({ country }) => {
  const { currentLanguage } = useLanguage();
  const countryFlagIcon = getImgSrcByCountry(country);

  return (
    <div className={styles.container}>
      {countryFlagIcon && <Icon iconComponent={countryFlagIcon} />}
      {getCountryNameByCode({
        countryCode: country,
        language: currentLanguage,
      })}
    </div>
  );
};
