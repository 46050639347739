import { TranslationUserType } from 'types';
import { PromoCodeMediaItemType } from 'types/generated/gql';
import { mapPromoCodeTypeToText } from './mapPromoCodeTypeToText';

export const getPromoTypeOptions = (
  translationUserType: TranslationUserType
) => [
  {
    id: PromoCodeMediaItemType.AllPlayers,
    label: mapPromoCodeTypeToText(
      PromoCodeMediaItemType.AllPlayers,
      translationUserType
    ),
  },
  {
    id: PromoCodeMediaItemType.MyPlayers,
    label: mapPromoCodeTypeToText(
      PromoCodeMediaItemType.MyPlayers,
      translationUserType
    ),
  },
  {
    id: PromoCodeMediaItemType.NewPlayers,
    label: mapPromoCodeTypeToText(
      PromoCodeMediaItemType.NewPlayers,
      translationUserType
    ),
  },
];
