import { NetworkStatus } from '@apollo/client';
import { useAuth } from 'components/auth';
import { useTranslation } from 'react-i18next';
import { FilterData, SelectFilterType, TariffTypeName } from 'types';
import { PartnerStatisticFilterType } from 'types/generated/gql';
import { useGetTariffsName } from 'queries/generated/GetTariffsName';
import { getTariffsOptions } from 'helpers';

export const usePartnerRevShareStatisticFilters = () => {
  const { t } = useTranslation('statistic');
  const { user } = useAuth();

  const {
    data: tariffsData,
    loading: tariffsLoading,
    error: tariffsError,
    networkStatus: tariffsNetworkStatus,
  } = useGetTariffsName({
    variables: {
      userId: user.id,
    },
  });

  const tariffsOptions = getTariffsOptions({
    tariffs: tariffsData?.user.tariffs,
    currentTypeName: TariffTypeName.RevShareTariff,
  });

  const filters: Array<FilterData<PartnerStatisticFilterType>> = [
    {
      selectFilterType: SelectFilterType.Multiple,
      type: PartnerStatisticFilterType.Tariff,
      title: t('partner.action.filters.filter.tariff'),
      options: tariffsOptions,
      loading: tariffsLoading && tariffsNetworkStatus === NetworkStatus.loading,
    },
  ];

  return {
    filters,
    error: tariffsError,
  };
};
