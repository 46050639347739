import { FC, MouseEventHandler, ReactNode } from 'react';
import classNames from 'classnames';
import styles from './FormLabelWrapper.module.scss';

interface FormLabelWrapperProps {
  children: ReactNode;
  idForLabel?: string;
  wrapperClassName?: string;
  isEmpty: boolean;
  label?: string;
  disabled?: boolean;
  isError: boolean;
  hasLeftIcon?: boolean;
  hasRightIcon?: boolean;
  reduceLabelOnFocus?: boolean;
  isCursorPointer?: boolean;
  shouldBlurOnOverlayClick?: boolean;
  hasMinWidth?: boolean;
}

export const FormLabelWrapper: FC<FormLabelWrapperProps> = ({
  children,
  wrapperClassName,
  label,
  isEmpty,
  idForLabel,
  disabled,
  isError,
  hasLeftIcon,
  hasRightIcon,
  reduceLabelOnFocus = true,
  isCursorPointer,
  shouldBlurOnOverlayClick,
  hasMinWidth = true,
}) => {
  const handleMouseDown: MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
  };

  const handleClick: MouseEventHandler<HTMLDivElement> = () => {
    if (
      !shouldBlurOnOverlayClick ||
      !(document.activeElement instanceof HTMLElement)
    ) {
      return;
    }

    document.activeElement?.blur();
  };

  return (
    <label
      className={classNames(styles.label, {
        [styles.hasMinWidth]: hasMinWidth,
      })}
      htmlFor={idForLabel}
    >
      <div
        data-testid="container-for-form-item-with-label"
        className={classNames(styles.formItemContainer, wrapperClassName, {
          [styles.isEmpty]: isEmpty,
          [styles.disabled]: disabled,
          [styles.isError]: isError && !disabled,
          [styles.reduceLabelOnFocus]: reduceLabelOnFocus,
          [styles.cursorPointer]: !disabled && isCursorPointer,
          [styles.cursorText]: !disabled && !isCursorPointer,
        })}
      >
        {/**
         * Our input doesn't fill the whole label, causing a focus bug.
         *
         * Clicking inside the label but outside the input makes it lose and then regain focus.
         *
         * We added an overlay to fix this. To see the problem, comment out the overlay in the code,
         * then try clicking the label text in the Select component a few times.
         */}
        <div
          className={styles.overlay}
          onClick={handleClick}
          onMouseDown={handleMouseDown}
        />
        <span
          className={classNames(styles.labelText, {
            [styles.hasIcon]: hasLeftIcon || hasRightIcon,
            [styles.hasTwoIcons]: hasLeftIcon && hasRightIcon,
            [styles.hasLeftIcon]: hasLeftIcon,
          })}
        >
          {label}
        </span>
        <div
          className={classNames(styles.children, {
            [styles.withNoLabel]: !label,
          })}
        >
          {children}
        </div>
      </div>
    </label>
  );
};
