export enum DropdownPosition {
  LeftBottom = 'leftBottom',
  RightBottom = 'rightBottom',
  LeftTop = 'leftTop',
  Right = 'right',
}

export enum DropdownSize {
  Small = 'small',
  Medium = 'medium',
}
