import * as SchemaTypes from '../../../../../../../../../../types/generated/gql';

import * as CommonType from 'types';
import { DocumentNode } from 'graphql';
import { PromoCodeMediaItemsConnectionData } from '../../../../queries/fragments/generated/PromoCodeMediaItemsConnectionData';
import { PromoCodeMediaItemData } from '../../../../queries/fragments/generated/PromoCodeMediaItemData';
import { PageInfoData } from '../../../../../../../../../../queries/fragments/generated/PageInfoData';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetCompanyPromoCodeMediaItemsVariables = SchemaTypes.Exact<{
  after?: SchemaTypes.InputMaybe<SchemaTypes.Scalars['Cursor']['input']>;
  before?: SchemaTypes.InputMaybe<SchemaTypes.Scalars['Cursor']['input']>;
  filters:
    | Array<SchemaTypes.CompanyPromoCodeMediaItemsFilterInput>
    | SchemaTypes.CompanyPromoCodeMediaItemsFilterInput;
  first: SchemaTypes.Scalars['Int']['input'];
  last?: SchemaTypes.InputMaybe<SchemaTypes.Scalars['Int']['input']>;
  promoCodeSearch?: SchemaTypes.InputMaybe<
    SchemaTypes.Scalars['String']['input']
  >;
  userId: SchemaTypes.Scalars['ID']['input'];
}>;

export type GetCompanyPromoCodeMediaItems = {
  __typename: 'Query';
  user:
    | {
        __typename: 'Company';
        id: string;
        promoCodeMediaItems: {
          __typename: 'PromoCodeMediaItemConnection';
          edges: Array<{
            __typename: 'PromoCodeMediaItemConnectionEdge';
            cursor: string;
            node: {
              __typename: 'PromoCodeMediaItem';
              createdAt: string;
              id: string;
              referralLink: string;
              tariffName: string;
              token: string;
              type: SchemaTypes.PromoCodeMediaItemType;
              bonus?: {
                __typename: 'PromoCodeBonus';
                cashout?: number | null;
                wager?: number | null;
                amount: {
                  __typename: 'MoneyAmount';
                  currency: CommonType.Currency;
                  value: number;
                };
                minBalance?: {
                  __typename: 'MoneyAmount';
                  currency: CommonType.Currency;
                  value: number;
                } | null;
              } | null;
              freespin?: {
                __typename: 'PromoCodeFreespin';
                count: number;
                currency: CommonType.Currency;
                wager?: number | null;
                minBalance?: {
                  __typename: 'MoneyAmount';
                  currency: CommonType.Currency;
                  value: number;
                } | null;
              } | null;
              owner:
                | { __typename: 'Company'; id: string; name: string }
                | { __typename: 'CompanyPartner'; id: string; username: string }
                | { __typename: 'Partner'; id: string; username: string };
            };
          }>;
          pageInfo: {
            __typename: 'PageInfo';
            endCursor?: string | null;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
            startCursor: string;
          };
        };
      }
    | { __typename: 'Partner'; id: string };
};

export const GetCompanyPromoCodeMediaItemsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCompanyPromoCodeMediaItems' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Cursor' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'before' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Cursor' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filters' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: {
                    kind: 'Name',
                    value: 'CompanyPromoCodeMediaItemsFilterInput',
                  },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'last' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'promoCodeSearch' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Company' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'promoCodeMediaItems' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'after' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'after' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'before' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'before' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'filters' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'filters' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'first' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'first' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'last' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'last' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'promoCodeSearch' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'promoCodeSearch' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'PromoCodeMediaItemsConnectionData',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...PromoCodeMediaItemsConnectionData.definitions,
    ...PromoCodeMediaItemData.definitions,
    ...PageInfoData.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useGetCompanyPromoCodeMediaItems__
 *
 * To run a query within a React component, call `useGetCompanyPromoCodeMediaItems` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyPromoCodeMediaItems` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyPromoCodeMediaItems({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filters: // value for 'filters'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      promoCodeSearch: // value for 'promoCodeSearch'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetCompanyPromoCodeMediaItems(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetCompanyPromoCodeMediaItems,
    GetCompanyPromoCodeMediaItemsVariables
  > &
    (
      | { variables: GetCompanyPromoCodeMediaItemsVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetCompanyPromoCodeMediaItems,
    GetCompanyPromoCodeMediaItemsVariables
  >(GetCompanyPromoCodeMediaItemsDocument, options);
}
export function useGetCompanyPromoCodeMediaItemsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetCompanyPromoCodeMediaItems,
    GetCompanyPromoCodeMediaItemsVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetCompanyPromoCodeMediaItems,
    GetCompanyPromoCodeMediaItemsVariables
  >(GetCompanyPromoCodeMediaItemsDocument, options);
}
export function useGetCompanyPromoCodeMediaItemsSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    GetCompanyPromoCodeMediaItems,
    GetCompanyPromoCodeMediaItemsVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    GetCompanyPromoCodeMediaItems,
    GetCompanyPromoCodeMediaItemsVariables
  >(GetCompanyPromoCodeMediaItemsDocument, options);
}
export type GetCompanyPromoCodeMediaItemsHookResult = ReturnType<
  typeof useGetCompanyPromoCodeMediaItems
>;
export type GetCompanyPromoCodeMediaItemsLazyQueryHookResult = ReturnType<
  typeof useGetCompanyPromoCodeMediaItemsLazyQuery
>;
export type GetCompanyPromoCodeMediaItemsSuspenseQueryHookResult = ReturnType<
  typeof useGetCompanyPromoCodeMediaItemsSuspenseQuery
>;
export type GetCompanyPromoCodeMediaItemsQueryResult = Apollo.QueryResult<
  GetCompanyPromoCodeMediaItems,
  GetCompanyPromoCodeMediaItemsVariables
>;
