import { useEffect, useId, useRef } from 'react';
import classNames from 'classnames';
import styles from './Textarea.module.scss';
import { useInputManagement } from '../../hooks/useInputManagement';
import { CommonTextFieldProps } from '../../types';
import { FormValidationWrapper } from '../FormValidationWrapper/FormValidationWrapper';
import { FormLabelWrapper } from '../FormLabelWrapper/FormLabelWrapper';

type OriginalTextareaAttributes = Omit<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  'onChange' | 'name'
>;

interface TextareaProps
  extends CommonTextFieldProps,
    OriginalTextareaAttributes {
  autoHeight?: boolean;
  defaultHeight?: number;
}

export const Textarea: React.FC<TextareaProps> = ({
  name,
  label,
  wrapperClassName,
  inputClassName,
  autoFocus,
  autoHeight = true,
  disabled,
  maxLength,
  onChange,
  defaultHeight = 48,
  formValidationProps,
  ...rest
}) => {
  const id = useId();
  const inputRef = useRef<HTMLTextAreaElement | null>(null);

  const { eventProps, field, touched, error } = useInputManagement({
    name,
    inputRef,
    autoFocus,
    onChange,
    maxLength,
  });

  useEffect(() => {
    const textarea = inputRef.current;

    if (!autoHeight || !textarea) {
      return;
    }

    /**
     * If the height is not reset to auto, the textarea starts to "jump",
     * if the number of lines in the textarea is more than one.
     * Therefore, it is necessary to reset and set these values through .style
     */
    const extraHeight = textarea.offsetHeight - textarea.clientHeight;

    textarea.style.height = 'auto';

    let newHeight = textarea.scrollHeight + extraHeight;

    if (newHeight < defaultHeight) {
      newHeight = defaultHeight;
    }

    textarea.style.height = `${newHeight}px`;
  }, [field.value, autoHeight, defaultHeight]);

  return (
    <FormValidationWrapper {...formValidationProps} name={name}>
      <FormLabelWrapper
        idForLabel={id}
        label={label}
        wrapperClassName={classNames(styles.container, wrapperClassName, {
          [styles.autoHeight]: autoHeight,
        })}
        isEmpty={Boolean(!field.value)}
        disabled={disabled}
        isError={error && (touched || field.value)}
      >
        <textarea
          name={field.name}
          id={id}
          rows={1}
          ref={inputRef}
          value={field.value || ''}
          className={classNames(inputClassName, styles.textarea)}
          disabled={disabled}
          placeholder={label}
          {...eventProps}
          {...rest}
        />
      </FormLabelWrapper>
    </FormValidationWrapper>
  );
};
