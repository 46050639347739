import { MouseEventHandler } from 'react';
import { Row } from '@tanstack/react-table';
import classNames from 'classnames';
import { Button, ButtonSize } from 'ui/Button';
import { ReactComponent as AltArrowRight } from 'assets/icons/line/AltArrowRight.svg';
import styles from './ExpandButton.module.scss';
import { LinkStyledButtonTextColor } from '../../../Button/const';

interface ExpandButtonProps<TData> {
  row: Row<TData>;
  onClick: () => void;
}

export const ExpandButton = <TData,>({
  onClick,
  row,
}: ExpandButtonProps<TData>) => {
  const isExpanded = row.getIsExpanded();

  const handleCollapse = () => {
    row.toggleExpanded();
  };

  const handleExpand = () => {
    row.toggleExpanded();

    onClick();
  };

  const handleClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();

    if (isExpanded) {
      handleCollapse();
    } else {
      handleExpand();
    }
  };

  if (!row.getCanExpand()) {
    return null;
  }

  return (
    <Button
      iconConfig={{
        iconComponent: AltArrowRight,
        containerClassName: classNames(styles.icon, {
          [styles.activeIcon]: isExpanded,
        }),
      }}
      className={styles.expandButton}
      size={ButtonSize.Small}
      linkStyled={{
        color: LinkStyledButtonTextColor.Grey,
      }}
      onClick={handleClick}
    />
  );
};
