import { t } from 'i18n';
import { FormikErrors } from 'formik';
import { getRequiredErrors } from 'utils/form/getRequiredFormikErrors';
import { FieldName } from './const';
import { EditCompanyPartnerDetailsValues } from './types';

export const validateEditCompanyPartnerDetails = (
  values: EditCompanyPartnerDetailsValues
): FormikErrors<EditCompanyPartnerDetailsValues> => {
  const errors: FormikErrors<EditCompanyPartnerDetailsValues> = {};

  const requiredErrors = getRequiredErrors(
    values,
    {
      [FieldName.Username]: true,
    },
    {
      [FieldName.Username]: t(
        'companyPartners:content.drawer.editPartnerDetails.hint.buttonHint'
      ),
    }
  );

  return {
    ...errors,
    ...requiredErrors,
  };
};
