import { Currency, CurrencyDropdownValue } from 'types';
import { Icon } from 'ui/Icon';
import { mapCurrencyToIcon } from './mapCurrencyToIcon';

export const getDropdownStateByCurrency = (
  currencyCode: Currency
): CurrencyDropdownValue => {
  const icon = mapCurrencyToIcon[currencyCode];

  return {
    value: currencyCode,
    label: currencyCode,
    icon: icon && <Icon iconComponent={icon} />,
  };
};
