import { AccessorKeyColumnDef } from '@tanstack/react-table';
import { Checkbox } from 'ui/formItems/components/Checkbox';
import { CheckboxSize } from 'ui/formItems/components/Checkbox/const';
import { DropdownWithButton } from 'ui/DropdownWithButton';
import { DropdownPosition } from 'ui/Dropdown';
import { ButtonTheme } from 'ui/Button';
import { ReactComponent as ColumnsIcon } from 'assets/icons/line/TuningV.svg';
import styles from './ColumnsSelect.module.scss';

interface ColumnsSelectProps<T> {
  columns: Array<AccessorKeyColumnDef<T>>;
  dropdownTitle: string;
  buttonText: string;
  onColumnsChange: () => void;
}

export const ColumnsSelect = <T,>({
  columns,
  dropdownTitle,
  buttonText,
  onColumnsChange,
}: ColumnsSelectProps<T>) => (
  <DropdownWithButton
    dropdownProps={{
      position: DropdownPosition.RightBottom,
      title: dropdownTitle,
    }}
    buttonProps={{
      iconConfig: {
        iconComponent: ColumnsIcon,
        before: true,
      },
      theme: ButtonTheme.AccentTonal,
    }}
    buttonContent={buttonText}
    bottomSheetProps={{
      contentClassName: styles.bottomSheetContent,
    }}
    popUpProps={{
      contentClassName: styles.popUpContent,
    }}
  >
    <div className={styles.container}>
      {columns.map((column) => {
        if (column.id) {
          return (
            <Checkbox
              key={column.id}
              name={`columns.${column.id}`}
              label={column.header?.toString()}
              onChange={onColumnsChange}
              checkboxSize={CheckboxSize.Small}
              className={styles.checkbox}
            />
          );
        }

        return null;
      })}
    </div>
  </DropdownWithButton>
);
