import type { FC } from 'react';
import { TextLink } from 'ui/TextLink/TextLink';
import { LinkProps } from 'react-router-dom';
import { LinkStyledButtonTextColor } from '../Button';
import styles from './TextWithLink.module.scss';

interface TextWithLinkProps extends LinkProps {
  text: string;
  linkText: string;
  to: string;
  color?: LinkStyledButtonTextColor;
}

export const TextWithLink: FC<TextWithLinkProps> = ({
  text,
  linkText,
  to,
  ...restProps
}) => (
  <div className={styles.container}>
    {text}
    <TextLink {...restProps} className={styles.link} to={to}>
      {linkText}
    </TextLink>
  </div>
);
