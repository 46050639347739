import { FC, useCallback, useState } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import { toast } from 'ui/toast';
import { useTranslation } from 'react-i18next';
import {
  FormBlockMarginSize,
  FormBlockWrapper,
  Input,
  InputPassword,
} from 'ui/formItems';
import { Button } from 'ui/Button';
import { useAuth } from 'components/auth';
import { checkIsAuthError } from 'components/auth/helpers';
import { useNavigate } from 'react-router';
import { useCreateLocalizedPath } from 'hooks/useCreateLocalizedPath';
import { AppRoute } from 'const';
import { LoginTransferValues } from './types';
import { validateLoginTransfer } from './validation';
import styles from './LoginTransferForm.module.scss';

export const LoginTransferForm: FC = () => {
  const { t } = useTranslation(['common', 'auth']);
  const auth = useAuth();
  const navigate = useNavigate();
  const { createLocalizedPath } = useCreateLocalizedPath();

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = useCallback(
    async (
      values: LoginTransferValues,
      actions: FormikHelpers<LoginTransferValues>
    ) => {
      try {
        setIsLoading(true);
        await auth.logInTransferUser(
          values.emailOrPhoneNumber,
          values.password
        );

        navigate(createLocalizedPath(AppRoute.Lang.Main), {
          replace: true,
        });
      } catch (error) {
        if (checkIsAuthError(error) && error.status === 401) {
          actions.setErrors({
            emailOrPhoneNumber: t(
              'auth:migration.transferData.validation.invalidEmailOrPhone'
            ),
            password: t(
              'auth:migration.transferData.validation.invalidPassword'
            ),
          });

          return;
        }

        if (checkIsAuthError(error) && error.status === 403) {
          navigate(createLocalizedPath(AppRoute.Lang.Login));

          return;
        }

        toast.error(t('errors.commonError'));
      } finally {
        setIsLoading(false);
      }
    },
    [auth, createLocalizedPath, navigate, t]
  );

  return (
    <div>
      <Formik
        initialValues={{
          emailOrPhoneNumber: '',
          password: '',
        }}
        onSubmit={handleSubmit}
        validateOnChange={false}
        /**
         * We cannot validate on blur because the errors from server will be reset
         * see: https://github.com/jaredpalmer/formik/issues/834
         */
        validateOnBlur={false}
        validate={validateLoginTransfer}
      >
        <Form className={styles.form} data-testid="login-form" noValidate>
          <FormBlockWrapper marginBottomSize={FormBlockMarginSize.Small}>
            <Input
              type="text"
              name="emailOrPhoneNumber"
              label={t(
                'auth:migration.transferData.input.EmailOrPhoneRequired'
              )}
              formValidationProps={{ errorTooltipPlacement: 'right' }}
            />
            <InputPassword
              name="password"
              label={t('input.label.password')}
              formValidationProps={{ errorTooltipPlacement: 'right' }}
              autoComplete="current-password"
            />
          </FormBlockWrapper>
          <Button isFullWidth isLoading={isLoading} type="submit">
            {t('auth:migration.transferData.button')}
          </Button>
        </Form>
      </Formik>
    </div>
  );
};
