// We need to turn off ESLint as we have duplicate flags
/* eslint-disable import/no-duplicates */
import { Country, IconComponent } from 'types';

import { ReactComponent as USIcon } from 'assets/icons/flags/USA.svg';
import { ReactComponent as EUIcon } from 'assets/icons/flags/EU.svg';
import { ReactComponent as ATIcon } from 'assets/icons/flags/Austria.svg';
import { ReactComponent as AUIcon } from 'assets/icons/flags/Australia.svg';
import { ReactComponent as ADIcon } from 'assets/icons/flags/Andorra.svg';
import { ReactComponent as AFIcon } from 'assets/icons/flags/Afghanistan.svg';
import { ReactComponent as AGIcon } from 'assets/icons/flags/Antigua_and_Barbuda.svg';
import { ReactComponent as AIIcon } from 'assets/icons/flags/Anguilla.svg';
import { ReactComponent as ALIcon } from 'assets/icons/flags/Albania.svg';
import { ReactComponent as AMIcon } from 'assets/icons/flags/Armenia.svg';
import { ReactComponent as AOIcon } from 'assets/icons/flags/Angola.svg';
import { ReactComponent as ARIcon } from 'assets/icons/flags/Argentina.svg';
import { ReactComponent as ASIcon } from 'assets/icons/flags/American_Samoa.svg';
import { ReactComponent as AWIcon } from 'assets/icons/flags/Aruba.svg';
import { ReactComponent as AXIcon } from 'assets/icons/flags/Aland_Islands.svg';
import { ReactComponent as AZIcon } from 'assets/icons/flags/Azerbaijan.svg';
import { ReactComponent as BAIcon } from 'assets/icons/flags/Bosnia_and_Herzegovina.svg';
import { ReactComponent as BBIcon } from 'assets/icons/flags/Barbados.svg';
import { ReactComponent as BDIcon } from 'assets/icons/flags/Bangladesh.svg';
import { ReactComponent as BEIcon } from 'assets/icons/flags/Belgium.svg';
import { ReactComponent as BFIcon } from 'assets/icons/flags/Burkina_Faso.svg';
import { ReactComponent as BGIcon } from 'assets/icons/flags/Bulgaria.svg';
import { ReactComponent as BHIcon } from 'assets/icons/flags/Bahrain.svg';
import { ReactComponent as BIIcon } from 'assets/icons/flags/Burundi.svg';
import { ReactComponent as BJIcon } from 'assets/icons/flags/Benin.svg';
import { ReactComponent as BLIcon } from 'assets/icons/flags/St_Barts.svg';
import { ReactComponent as BMIcon } from 'assets/icons/flags/Bermuda.svg';
import { ReactComponent as BNIcon } from 'assets/icons/flags/Brunei.svg';
import { ReactComponent as BOIcon } from 'assets/icons/flags/Bolivia.svg';
import { ReactComponent as BQIcon } from 'assets/icons/flags/Bonaire.svg';
import { ReactComponent as BRIcon } from 'assets/icons/flags/Brazil.svg';
import { ReactComponent as BSIcon } from 'assets/icons/flags/Bahamas.svg';
import { ReactComponent as BTIcon } from 'assets/icons/flags/Bhutan.svg';
import { ReactComponent as BVIcon } from 'assets/icons/flags/France.svg';
import { ReactComponent as BWIcon } from 'assets/icons/flags/Botswana.svg';
import { ReactComponent as BYIcon } from 'assets/icons/flags/Belarus.svg';
import { ReactComponent as BZIcon } from 'assets/icons/flags/Belize.svg';
import { ReactComponent as CAIcon } from 'assets/icons/flags/Canada.svg';
import { ReactComponent as CCIcon } from 'assets/icons/flags/Cocos_Island.svg';
import { ReactComponent as CDIcon } from 'assets/icons/flags/Democratic_Republic_of_Congo.svg';
import { ReactComponent as CFIcon } from 'assets/icons/flags/Central_African_Republic.svg';
import { ReactComponent as CGIcon } from 'assets/icons/flags/Republic_of_Congo.svg';
import { ReactComponent as CHIcon } from 'assets/icons/flags/Switzerland.svg';
import { ReactComponent as CIIcon } from 'assets/icons/flags/Ivory_Coast.svg';
import { ReactComponent as CKIcon } from 'assets/icons/flags/Cook_Islands.svg';
import { ReactComponent as CLIcon } from 'assets/icons/flags/Chile.svg';
import { ReactComponent as CMIcon } from 'assets/icons/flags/Cameroon.svg';
import { ReactComponent as CNIcon } from 'assets/icons/flags/China.svg';
import { ReactComponent as COIcon } from 'assets/icons/flags/Colombia.svg';
import { ReactComponent as CRIcon } from 'assets/icons/flags/Costa_Rica.svg';
import { ReactComponent as CUIcon } from 'assets/icons/flags/Cuba.svg';
import { ReactComponent as CVIcon } from 'assets/icons/flags/Cape_Verde.svg';
import { ReactComponent as CWIcon } from 'assets/icons/flags/Curacao.svg';
import { ReactComponent as CXIcon } from 'assets/icons/flags/Christmas_Island.svg';
import { ReactComponent as CYIcon } from 'assets/icons/flags/Cyprus.svg';
import { ReactComponent as CZIcon } from 'assets/icons/flags/Czech_Republic.svg';
import { ReactComponent as DEIcon } from 'assets/icons/flags/Germany.svg';
import { ReactComponent as DJIcon } from 'assets/icons/flags/Djibouti.svg';
import { ReactComponent as DKIcon } from 'assets/icons/flags/Denmark.svg';
import { ReactComponent as DMIcon } from 'assets/icons/flags/Dominica.svg';
import { ReactComponent as DOIcon } from 'assets/icons/flags/Dominican_Republic.svg';
import { ReactComponent as DZIcon } from 'assets/icons/flags/Algeria.svg';
import { ReactComponent as ECIcon } from 'assets/icons/flags/Ecuador.svg';
import { ReactComponent as EEIcon } from 'assets/icons/flags/Estonia.svg';
import { ReactComponent as EGIcon } from 'assets/icons/flags/Egypt.svg';
import { ReactComponent as ERIcon } from 'assets/icons/flags/Eritrea.svg';
import { ReactComponent as ESIcon } from 'assets/icons/flags/Spain.svg';
import { ReactComponent as ETIcon } from 'assets/icons/flags/Ethiopia.svg';
import { ReactComponent as FIIcon } from 'assets/icons/flags/Finland.svg';
import { ReactComponent as FJIcon } from 'assets/icons/flags/Fiji.svg';
import { ReactComponent as FKIcon } from 'assets/icons/flags/Falkland_Islands.svg';
import { ReactComponent as FMIcon } from 'assets/icons/flags/Micronesia.svg';
import { ReactComponent as FOIcon } from 'assets/icons/flags/Faroe_Islands.svg';
import { ReactComponent as FRIcon } from 'assets/icons/flags/France.svg';
import { ReactComponent as GAIcon } from 'assets/icons/flags/Gabon.svg';
import { ReactComponent as GBIcon } from 'assets/icons/flags/United_Kingdom.svg';
import { ReactComponent as GDIcon } from 'assets/icons/flags/Grenada.svg';
import { ReactComponent as GEIcon } from 'assets/icons/flags/Georgia.svg';
import { ReactComponent as GFIcon } from 'assets/icons/flags/French_Guiana.svg';
import { ReactComponent as GGIcon } from 'assets/icons/flags/Guernsey.svg';
import { ReactComponent as GHIcon } from 'assets/icons/flags/Ghana.svg';
import { ReactComponent as GIBIcon } from 'assets/icons/flags/Gibraltar.svg';
import { ReactComponent as GLIcon } from 'assets/icons/flags/Greenland.svg';
import { ReactComponent as GMIcon } from 'assets/icons/flags/Gambia.svg';
import { ReactComponent as GNIcon } from 'assets/icons/flags/Guinea.svg';
import { ReactComponent as GPIcon } from 'assets/icons/flags/France.svg';
import { ReactComponent as GQIcon } from 'assets/icons/flags/Equatorial_Guinea.svg';
import { ReactComponent as GRIcon } from 'assets/icons/flags/Greece.svg';
import { ReactComponent as GTIcon } from 'assets/icons/flags/Guatemala.svg';
import { ReactComponent as GUIcon } from 'assets/icons/flags/Guam.svg';
import { ReactComponent as GWIcon } from 'assets/icons/flags/Guinea_Bissau.svg';
import { ReactComponent as GYIcon } from 'assets/icons/flags/Guyana.svg';
import { ReactComponent as HKIcon } from 'assets/icons/flags/Hong_Kong.svg';
import { ReactComponent as HMIcon } from 'assets/icons/flags/Austria.svg';
import { ReactComponent as HNIcon } from 'assets/icons/flags/Honduras.svg';
import { ReactComponent as HRIcon } from 'assets/icons/flags/Croatia.svg';
import { ReactComponent as HTIcon } from 'assets/icons/flags/Haiti.svg';
import { ReactComponent as HUIcon } from 'assets/icons/flags/Hungary.svg';
import { ReactComponent as IDIcon } from 'assets/icons/flags/Indonesia.svg';
import { ReactComponent as IEIcon } from 'assets/icons/flags/Ireland.svg';
import { ReactComponent as ILIcon } from 'assets/icons/flags/Israel.svg';
import { ReactComponent as IMIcon } from 'assets/icons/flags/Isle_of_Man.svg';
import { ReactComponent as INIcon } from 'assets/icons/flags/India.svg';
import { ReactComponent as IOIcon } from 'assets/icons/flags/British_Indian_Ocean_Territory.svg';
import { ReactComponent as IQIcon } from 'assets/icons/flags/Iraq.svg';
import { ReactComponent as IRIcon } from 'assets/icons/flags/Iran.svg';
import { ReactComponent as ISIcon } from 'assets/icons/flags/Iceland.svg';
import { ReactComponent as ITIcon } from 'assets/icons/flags/Italy.svg';
import { ReactComponent as JEIcon } from 'assets/icons/flags/Jersey.svg';
import { ReactComponent as JMIcon } from 'assets/icons/flags/Jamaica.svg';
import { ReactComponent as JOIcon } from 'assets/icons/flags/Jordan.svg';
import { ReactComponent as JPIcon } from 'assets/icons/flags/Japan.svg';
import { ReactComponent as KEIcon } from 'assets/icons/flags/Kenya.svg';
import { ReactComponent as KGIcon } from 'assets/icons/flags/Kyrgyzstan.svg';
import { ReactComponent as KHIcon } from 'assets/icons/flags/Cambodia.svg';
import { ReactComponent as KIIcon } from 'assets/icons/flags/Kiribati.svg';
import { ReactComponent as KMIcon } from 'assets/icons/flags/Comoros.svg';
import { ReactComponent as KNIcon } from 'assets/icons/flags/Saint_Kitts_and_Nevis.svg';
import { ReactComponent as KPIcon } from 'assets/icons/flags/North_Korea.svg';
import { ReactComponent as KRIcon } from 'assets/icons/flags/South_Korea.svg';
import { ReactComponent as KWIcon } from 'assets/icons/flags/Kuwait.svg';
import { ReactComponent as KYIcon } from 'assets/icons/flags/Cayman_Islands.svg';
import { ReactComponent as KZIcon } from 'assets/icons/flags/Kazakhstan.svg';
import { ReactComponent as LAIcon } from 'assets/icons/flags/Laos.svg';
import { ReactComponent as LBIcon } from 'assets/icons/flags/Lebanon.svg';
import { ReactComponent as LCIcon } from 'assets/icons/flags/Saint_Lucia.svg';
import { ReactComponent as LIIcon } from 'assets/icons/flags/Liechtenstein.svg';
import { ReactComponent as LKIcon } from 'assets/icons/flags/Sri_Lanka.svg';
import { ReactComponent as LRIcon } from 'assets/icons/flags/Liberia.svg';
import { ReactComponent as LSIcon } from 'assets/icons/flags/Lesotho.svg';
import { ReactComponent as LTIcon } from 'assets/icons/flags/Lithuania.svg';
import { ReactComponent as LUIcon } from 'assets/icons/flags/Luxembourg.svg';
import { ReactComponent as LVIcon } from 'assets/icons/flags/Latvia.svg';
import { ReactComponent as LYIcon } from 'assets/icons/flags/Libya.svg';
import { ReactComponent as MAIcon } from 'assets/icons/flags/Morocco.svg';
import { ReactComponent as MCIcon } from 'assets/icons/flags/Monaco.svg';
import { ReactComponent as MDIcon } from 'assets/icons/flags/Moldova.svg';
import { ReactComponent as MEIcon } from 'assets/icons/flags/Montenegro.svg';
import { ReactComponent as MFIcon } from 'assets/icons/flags/France.svg';
import { ReactComponent as MGIcon } from 'assets/icons/flags/Madagascar.svg';
import { ReactComponent as MHIcon } from 'assets/icons/flags/Marshall_Island.svg';
import { ReactComponent as MKIcon } from 'assets/icons/flags/Macedonia.svg';
import { ReactComponent as MLIcon } from 'assets/icons/flags/Mali.svg';
import { ReactComponent as MMIcon } from 'assets/icons/flags/Myanmar.svg';
import { ReactComponent as MNIcon } from 'assets/icons/flags/Mongolia.svg';
import { ReactComponent as MOIcon } from 'assets/icons/flags/Macao.svg';
import { ReactComponent as MPIcon } from 'assets/icons/flags/Northern_Marianas_Islands.svg';
import { ReactComponent as MQIcon } from 'assets/icons/flags/Martinique.svg';
import { ReactComponent as MRIcon } from 'assets/icons/flags/Mauritania.svg';
import { ReactComponent as MSIcon } from 'assets/icons/flags/Montserrat.svg';
import { ReactComponent as MTIcon } from 'assets/icons/flags/Malta.svg';
import { ReactComponent as MUIcon } from 'assets/icons/flags/Mauritius.svg';
import { ReactComponent as MVIcon } from 'assets/icons/flags/Maldives.svg';
import { ReactComponent as MWIcon } from 'assets/icons/flags/Malawi.svg';
import { ReactComponent as MXIcon } from 'assets/icons/flags/Mexico.svg';
import { ReactComponent as MYIcon } from 'assets/icons/flags/Malaysia.svg';
import { ReactComponent as MZIcon } from 'assets/icons/flags/Mozambique.svg';
import { ReactComponent as NAIcon } from 'assets/icons/flags/Namibia.svg';
import { ReactComponent as NCIcon } from 'assets/icons/flags/France.svg';
import { ReactComponent as NEIcon } from 'assets/icons/flags/Niger.svg';
import { ReactComponent as NFIcon } from 'assets/icons/flags/Norfolk_Island.svg';
import { ReactComponent as NGIcon } from 'assets/icons/flags/Nigeria.svg';
import { ReactComponent as NIIcon } from 'assets/icons/flags/Nicaragua.svg';
import { ReactComponent as NLIcon } from 'assets/icons/flags/Netherlands.svg';
import { ReactComponent as NOIcon } from 'assets/icons/flags/Norway.svg';
import { ReactComponent as NPIcon } from 'assets/icons/flags/Nepal.svg';
import { ReactComponent as NRIcon } from 'assets/icons/flags/Nauru.svg';
import { ReactComponent as NUIcon } from 'assets/icons/flags/Niue.svg';
import { ReactComponent as NZIcon } from 'assets/icons/flags/New_Zealand.svg';
import { ReactComponent as OMIcon } from 'assets/icons/flags/Oman.svg';
import { ReactComponent as PAIcon } from 'assets/icons/flags/Panama.svg';
import { ReactComponent as PEIcon } from 'assets/icons/flags/Peru.svg';
import { ReactComponent as PFIcon } from 'assets/icons/flags/French_Polynesia.svg';
import { ReactComponent as PGIcon } from 'assets/icons/flags/Papua_New_Guinea.svg';
import { ReactComponent as PHIcon } from 'assets/icons/flags/Philippines.svg';
import { ReactComponent as PKIcon } from 'assets/icons/flags/Pakistan.svg';
import { ReactComponent as PLIcon } from 'assets/icons/flags/Poland.svg';
import { ReactComponent as PMIcon } from 'assets/icons/flags/Saint_Pierre_and_Miquelon.svg';
import { ReactComponent as PNIcon } from 'assets/icons/flags/Pitcairn_Islands.svg';
import { ReactComponent as PRIcon } from 'assets/icons/flags/Puerto_Rico.svg';
import { ReactComponent as PSIcon } from 'assets/icons/flags/Palestine.svg';
import { ReactComponent as PTIcon } from 'assets/icons/flags/Portugal.svg';
import { ReactComponent as PWIcon } from 'assets/icons/flags/Palau.svg';
import { ReactComponent as PYIcon } from 'assets/icons/flags/Paraguay.svg';
import { ReactComponent as QAIcon } from 'assets/icons/flags/Qatar.svg';
import { ReactComponent as REIcon } from 'assets/icons/flags/Reunion.svg';
import { ReactComponent as RSIcon } from 'assets/icons/flags/Serbia.svg';
import { ReactComponent as RUIcon } from 'assets/icons/flags/Russia.svg';
import { ReactComponent as ROIcon } from 'assets/icons/flags/Romania.svg';
import { ReactComponent as RWIcon } from 'assets/icons/flags/Rwanda.svg';
import { ReactComponent as SAIcon } from 'assets/icons/flags/Saudi_Arabia.svg';
import { ReactComponent as SBIcon } from 'assets/icons/flags/Solomon_Islands.svg';
import { ReactComponent as SCIcon } from 'assets/icons/flags/Seychelles.svg';
import { ReactComponent as SDIcon } from 'assets/icons/flags/Sudan.svg';
import { ReactComponent as SEIcon } from 'assets/icons/flags/Sweden.svg';
import { ReactComponent as SGIcon } from 'assets/icons/flags/Singapore.svg';
import { ReactComponent as SHIcon } from 'assets/icons/flags/Saint_Helena.svg';
import { ReactComponent as SIIcon } from 'assets/icons/flags/Slovenia.svg';
import { ReactComponent as SJIcon } from 'assets/icons/flags/Norway.svg';
import { ReactComponent as SKIcon } from 'assets/icons/flags/Slovakia.svg';
import { ReactComponent as SLIcon } from 'assets/icons/flags/Sierra_Leone.svg';
import { ReactComponent as SMIcon } from 'assets/icons/flags/San_Marino.svg';
import { ReactComponent as SNIcon } from 'assets/icons/flags/Senegal.svg';
import { ReactComponent as SOIcon } from 'assets/icons/flags/Somalia.svg';
import { ReactComponent as SRIcon } from 'assets/icons/flags/Suriname.svg';
import { ReactComponent as SSIcon } from 'assets/icons/flags/South_Sudan.svg';
import { ReactComponent as STIcon } from 'assets/icons/flags/Sao_Tome_and_Principe.svg';
import { ReactComponent as SVIcon } from 'assets/icons/flags/El_Salvador.svg';
import { ReactComponent as SXIcon } from 'assets/icons/flags/Sint_Maarten.svg';
import { ReactComponent as SYIcon } from 'assets/icons/flags/Syria.svg';
import { ReactComponent as SZIcon } from 'assets/icons/flags/Swaziland.svg';
import { ReactComponent as TCIcon } from 'assets/icons/flags/Turks_and_Caicos_Islands.svg';
import { ReactComponent as TDIcon } from 'assets/icons/flags/Chad.svg';
import { ReactComponent as TFIcon } from 'assets/icons/flags/French_Southern_Territories.svg';
import { ReactComponent as TGIcon } from 'assets/icons/flags/Togo.svg';
import { ReactComponent as THIcon } from 'assets/icons/flags/Thailand.svg';
import { ReactComponent as TJIcon } from 'assets/icons/flags/Tajikistan.svg';
import { ReactComponent as TKIcon } from 'assets/icons/flags/Tokelau.svg';
import { ReactComponent as TLIcon } from 'assets/icons/flags/East_Timor.svg';
import { ReactComponent as TMIcon } from 'assets/icons/flags/Turkmenistan.svg';
import { ReactComponent as TNIcon } from 'assets/icons/flags/Tunisia.svg';
import { ReactComponent as TOIcon } from 'assets/icons/flags/Tonga.svg';
import { ReactComponent as TRIcon } from 'assets/icons/flags/Turkey.svg';
import { ReactComponent as TTIcon } from 'assets/icons/flags/Trinidad_and_Tobago.svg';
import { ReactComponent as TVIcon } from 'assets/icons/flags/Tuvalu.svg';
import { ReactComponent as TWIcon } from 'assets/icons/flags/Taiwan.svg';
import { ReactComponent as TZIcon } from 'assets/icons/flags/Tanzania.svg';
import { ReactComponent as UAIcon } from 'assets/icons/flags/Ukraine.svg';
import { ReactComponent as UGIcon } from 'assets/icons/flags/Uganda.svg';
import { ReactComponent as AEIcon } from 'assets/icons/flags/United_Arab_Emirates.svg';
import { ReactComponent as UYIcon } from 'assets/icons/flags/Uruguay.svg';
import { ReactComponent as UZIcon } from 'assets/icons/flags/Uzbekistan.svg';
import { ReactComponent as VAIcon } from 'assets/icons/flags/Vatican_City.svg';
import { ReactComponent as VCIcon } from 'assets/icons/flags/St_Vincent_and_the_Grenadines.svg';
import { ReactComponent as VEIcon } from 'assets/icons/flags/Venezuela.svg';
import { ReactComponent as VGIcon } from 'assets/icons/flags/British_Virgin_Islands.svg';
import { ReactComponent as VIIcon } from 'assets/icons/flags/US_Virgin_Islands.svg';
import { ReactComponent as VNIcon } from 'assets/icons/flags/Vietnam.svg';
import { ReactComponent as VUIcon } from 'assets/icons/flags/Vanuatu.svg';
import { ReactComponent as EHIcon } from 'assets/icons/flags/Sahrawi_Arab_Democratic_Republic.svg';
import { ReactComponent as WFIcon } from 'assets/icons/flags/Wallis_and_Futuna.svg';
import { ReactComponent as WSIcon } from 'assets/icons/flags/Samoa.svg';
import { ReactComponent as YEIcon } from 'assets/icons/flags/Yemen.svg';
import { ReactComponent as YTIcon } from 'assets/icons/flags/France.svg';
import { ReactComponent as ZAIcon } from 'assets/icons/flags/South_Africa.svg';
import { ReactComponent as ZMIcon } from 'assets/icons/flags/Zambia.svg';
import { ReactComponent as ZWIcon } from 'assets/icons/flags/Zimbabwe.svg';

const mapCountryCodeToImgSrc: Record<Country, IconComponent> = {
  [Country.US]: USIcon,
  [Country.RU]: RUIcon,
  [Country.EU]: EUIcon,
  [Country.KZ]: KZIcon,
  [Country.BR]: BRIcon,
  [Country.TR]: TRIcon,
  [Country.MX]: MXIcon,
  [Country.PL]: PLIcon,
  [Country.HU]: HUIcon,
  [Country.BG]: BGIcon,
  [Country.JP]: JPIcon,
  [Country.AU]: AUIcon,
  [Country.NO]: NOIcon,
  [Country.BY]: BYIcon,
  [Country.CA]: CAIcon,
  [Country.UZ]: UZIcon,
  [Country.AD]: ADIcon,
  [Country.AE]: AEIcon,
  [Country.AF]: AFIcon,
  [Country.AG]: AGIcon,
  [Country.AI]: AIIcon,
  [Country.AL]: ALIcon,
  [Country.AM]: AMIcon,
  [Country.AO]: AOIcon,
  [Country.AR]: ARIcon,
  [Country.AS]: ASIcon,
  [Country.AT]: ATIcon,
  [Country.AW]: AWIcon,
  [Country.AX]: AXIcon,
  [Country.AZ]: AZIcon,
  [Country.BA]: BAIcon,
  [Country.BB]: BBIcon,
  [Country.BD]: BDIcon,
  [Country.BE]: BEIcon,
  [Country.BF]: BFIcon,
  [Country.BH]: BHIcon,
  [Country.BI]: BIIcon,
  [Country.BJ]: BJIcon,
  [Country.BL]: BLIcon,
  [Country.BM]: BMIcon,
  [Country.BN]: BNIcon,
  [Country.BO]: BOIcon,
  [Country.BQ]: BQIcon,
  [Country.BS]: BSIcon,
  [Country.BT]: BTIcon,
  [Country.BV]: BVIcon,
  [Country.BW]: BWIcon,
  [Country.BZ]: BZIcon,
  [Country.CC]: CCIcon,
  [Country.CD]: CDIcon,
  [Country.CF]: CFIcon,
  [Country.CG]: CGIcon,
  [Country.CH]: CHIcon,
  [Country.CI]: CIIcon,
  [Country.CK]: CKIcon,
  [Country.CL]: CLIcon,
  [Country.CM]: CMIcon,
  [Country.CN]: CNIcon,
  [Country.CO]: COIcon,
  [Country.CR]: CRIcon,
  [Country.CU]: CUIcon,
  [Country.CV]: CVIcon,
  [Country.CW]: CWIcon,
  [Country.CX]: CXIcon,
  [Country.CY]: CYIcon,
  [Country.CZ]: CZIcon,
  [Country.DE]: DEIcon,
  [Country.DJ]: DJIcon,
  [Country.DK]: DKIcon,
  [Country.DM]: DMIcon,
  [Country.DO]: DOIcon,
  [Country.DZ]: DZIcon,
  [Country.EC]: ECIcon,
  [Country.EE]: EEIcon,
  [Country.EG]: EGIcon,
  [Country.ER]: ERIcon,
  [Country.ES]: ESIcon,
  [Country.ET]: ETIcon,
  [Country.FI]: FIIcon,
  [Country.FJ]: FJIcon,
  [Country.FK]: FKIcon,
  [Country.FM]: FMIcon,
  [Country.FO]: FOIcon,
  [Country.FR]: FRIcon,
  [Country.GA]: GAIcon,
  [Country.GB]: GBIcon,
  [Country.GD]: GDIcon,
  [Country.GE]: GEIcon,
  [Country.GF]: GFIcon,
  [Country.GG]: GGIcon,
  [Country.GH]: GHIcon,
  [Country.GI]: GIBIcon,
  [Country.GL]: GLIcon,
  [Country.GM]: GMIcon,
  [Country.GN]: GNIcon,
  [Country.GP]: GPIcon,
  [Country.GQ]: GQIcon,
  [Country.GR]: GRIcon,
  [Country.GT]: GTIcon,
  [Country.GU]: GUIcon,
  [Country.GW]: GWIcon,
  [Country.GY]: GYIcon,
  [Country.HK]: HKIcon,
  [Country.HM]: HMIcon,
  [Country.HN]: HNIcon,
  [Country.HR]: HRIcon,
  [Country.HT]: HTIcon,
  [Country.ID]: IDIcon,
  [Country.IE]: IEIcon,
  [Country.IL]: ILIcon,
  [Country.IM]: IMIcon,
  [Country.IN]: INIcon,
  [Country.IO]: IOIcon,
  [Country.IQ]: IQIcon,
  [Country.IR]: IRIcon,
  [Country.IS]: ISIcon,
  [Country.IT]: ITIcon,
  [Country.JE]: JEIcon,
  [Country.JM]: JMIcon,
  [Country.JO]: JOIcon,
  [Country.KE]: KEIcon,
  [Country.KG]: KGIcon,
  [Country.KH]: KHIcon,
  [Country.KI]: KIIcon,
  [Country.KM]: KMIcon,
  [Country.KN]: KNIcon,
  [Country.KP]: KPIcon,
  [Country.KR]: KRIcon,
  [Country.KW]: KWIcon,
  [Country.KY]: KYIcon,
  [Country.LA]: LAIcon,
  [Country.LB]: LBIcon,
  [Country.LC]: LCIcon,
  [Country.LI]: LIIcon,
  [Country.LK]: LKIcon,
  [Country.LR]: LRIcon,
  [Country.LS]: LSIcon,
  [Country.LT]: LTIcon,
  [Country.LU]: LUIcon,
  [Country.LV]: LVIcon,
  [Country.LY]: LYIcon,
  [Country.MA]: MAIcon,
  [Country.MC]: MCIcon,
  [Country.MD]: MDIcon,
  [Country.ME]: MEIcon,
  [Country.MF]: MFIcon,
  [Country.MG]: MGIcon,
  [Country.MH]: MHIcon,
  [Country.MK]: MKIcon,
  [Country.ML]: MLIcon,
  [Country.MM]: MMIcon,
  [Country.MN]: MNIcon,
  [Country.MO]: MOIcon,
  [Country.MP]: MPIcon,
  [Country.MQ]: MQIcon,
  [Country.MR]: MRIcon,
  [Country.MS]: MSIcon,
  [Country.MT]: MTIcon,
  [Country.MU]: MUIcon,
  [Country.MV]: MVIcon,
  [Country.MW]: MWIcon,
  [Country.MY]: MYIcon,
  [Country.MZ]: MZIcon,
  [Country.NA]: NAIcon,
  [Country.NC]: NCIcon,
  [Country.NE]: NEIcon,
  [Country.NF]: NFIcon,
  [Country.NG]: NGIcon,
  [Country.NI]: NIIcon,
  [Country.NL]: NLIcon,
  [Country.NP]: NPIcon,
  [Country.NR]: NRIcon,
  [Country.NU]: NUIcon,
  [Country.NZ]: NZIcon,
  [Country.OM]: OMIcon,
  [Country.PA]: PAIcon,
  [Country.PE]: PEIcon,
  [Country.PF]: PFIcon,
  [Country.PG]: PGIcon,
  [Country.PH]: PHIcon,
  [Country.PK]: PKIcon,
  [Country.PM]: PMIcon,
  [Country.PN]: PNIcon,
  [Country.PR]: PRIcon,
  [Country.PS]: PSIcon,
  [Country.PT]: PTIcon,
  [Country.PW]: PWIcon,
  [Country.PY]: PYIcon,
  [Country.QA]: QAIcon,
  [Country.RE]: REIcon,
  [Country.RS]: RSIcon,
  [Country.RO]: ROIcon,
  [Country.RW]: RWIcon,
  [Country.SA]: SAIcon,
  [Country.SB]: SBIcon,
  [Country.SC]: SCIcon,
  [Country.SD]: SDIcon,
  [Country.SE]: SEIcon,
  [Country.SG]: SGIcon,
  [Country.SH]: SHIcon,
  [Country.SI]: SIIcon,
  [Country.SK]: SKIcon,
  [Country.SJ]: SJIcon,
  [Country.SL]: SLIcon,
  [Country.SM]: SMIcon,
  [Country.SN]: SNIcon,
  [Country.SO]: SOIcon,
  [Country.SR]: SRIcon,
  [Country.SS]: SSIcon,
  [Country.ST]: STIcon,
  [Country.SV]: SVIcon,
  [Country.SX]: SXIcon,
  [Country.SY]: SYIcon,
  [Country.SZ]: SZIcon,
  [Country.TC]: TCIcon,
  [Country.TD]: TDIcon,
  [Country.TF]: TFIcon,
  [Country.TG]: TGIcon,
  [Country.TH]: THIcon,
  [Country.TJ]: TJIcon,
  [Country.TK]: TKIcon,
  [Country.TL]: TLIcon,
  [Country.TM]: TMIcon,
  [Country.TN]: TNIcon,
  [Country.TO]: TOIcon,
  [Country.TT]: TTIcon,
  [Country.TV]: TVIcon,
  [Country.TW]: TWIcon,
  [Country.TZ]: TZIcon,
  [Country.UG]: UGIcon,
  [Country.UY]: UYIcon,
  [Country.VA]: VAIcon,
  [Country.VC]: VCIcon,
  [Country.VE]: VEIcon,
  [Country.VG]: VGIcon,
  [Country.VI]: VIIcon,
  [Country.VN]: VNIcon,
  [Country.UA]: UAIcon,
  [Country.VU]: VUIcon,
  [Country.WF]: WFIcon,
  [Country.WS]: WSIcon,
  [Country.EH]: EHIcon,
  [Country.YE]: YEIcon,
  [Country.YT]: YTIcon,
  [Country.ZA]: ZAIcon,
  [Country.ZM]: ZMIcon,
  [Country.ZW]: ZWIcon,
};

export const getImgSrcByCountry = (
  country: Country | string
): IconComponent | null =>
  country in Country ? mapCountryCodeToImgSrc[country as Country] : null;
