import { FC, MouseEventHandler } from 'react';
import { Tooltip } from 'ui/Tooltip';
import { CommonPostbackStatus } from 'types/generated/gql';
import { ReactComponent as PostbackIcon } from 'assets/icons/line/Postback.svg';
import { useTranslationUserType } from 'hooks/useTranslationUserType';
import { Icon, IconSize } from 'ui/Icon';
import {
  mapPostbackStatusToClassName,
  mapPostbackStatusToTooltip,
} from './helpers';
import styles from './PostbackButton.module.scss';

interface PostbackButtonProps {
  status: CommonPostbackStatus;
  onClick: MouseEventHandler;
}

export const PostbackButton: FC<PostbackButtonProps> = ({
  onClick,
  status,
}) => {
  const translationUserType = useTranslationUserType();

  return (
    <Tooltip
      placement="top-end"
      tooltipTriggerClassName={styles.postbackButton}
      tooltipContent={mapPostbackStatusToTooltip(status, translationUserType)}
    >
      <Icon
        data-testid="postback-button"
        containerClassName={mapPostbackStatusToClassName[status]}
        hasDefaultColor={false}
        iconComponent={PostbackIcon}
        size={IconSize.Small}
        onClick={onClick}
      />
    </Tooltip>
  );
};
