import { NetworkStatus } from '@apollo/client';
import { useAuth } from 'components/auth';
import { useTranslation } from 'react-i18next';
import {
  PartnerReferralLinkMediaItemsFilterType,
  PaymentModel,
} from 'types/generated/gql';
import { useGetTariffsName } from 'queries/generated/GetTariffsName';
import { getTariffsOptions } from 'helpers';
import { ReactComponent as RevShareIcon } from 'assets/icons/line/Revshare.svg';
import { ReactComponent as CPAIcon } from 'assets/icons/line/CPA.svg';
import { Icon, IconSize } from 'ui/Icon';
import { FilterData, SelectFilterType } from 'types';
import styles from '../PartnerReferralLinkMediaTableFilters.module.scss';

export const usePartnerReferralLinkMediaFilters = () => {
  const { t } = useTranslation(['media', 'common']);

  const { user } = useAuth();
  const {
    data: tariffsData,
    loading: tariffsLoading,
    error: tariffsError,
    networkStatus: tariffsNetworkStatus,
  } = useGetTariffsName({
    variables: {
      userId: user.id,
    },
  });

  const tariffsOptions = getTariffsOptions({
    tariffs: tariffsData?.user.tariffs,
  });

  const paymentTypeOptions = [
    {
      id: PaymentModel.RevShare,
      label: t('common:dataTypes.value.paymentTypeRevShare'),
      leftElement: (
        <Icon
          iconComponent={RevShareIcon}
          hasDefaultColor={false}
          containerClassName={styles.revShare}
          size={IconSize.Large}
        />
      ),
    },
    {
      id: PaymentModel.Cpa,
      label: t('common:dataTypes.value.paymentTypeCpa'),
      leftElement: (
        <Icon
          iconComponent={CPAIcon}
          hasDefaultColor={false}
          containerClassName={styles.cpa}
          size={IconSize.Large}
        />
      ),
    },
  ];

  const filters: Array<FilterData<PartnerReferralLinkMediaItemsFilterType>> = [
    {
      selectFilterType: SelectFilterType.Multiple,
      type: PartnerReferralLinkMediaItemsFilterType.Tariff,
      title: t('media:filterTitle.label.tariff'),
      options: tariffsOptions,
      loading: tariffsLoading && tariffsNetworkStatus === NetworkStatus.loading,
    },
    {
      selectFilterType: SelectFilterType.Single,
      type: PartnerReferralLinkMediaItemsFilterType.PaymentModel,
      title: t('media:filterTitle.label.paymentType'),
      options: paymentTypeOptions,
      loading: tariffsLoading && tariffsNetworkStatus === NetworkStatus.loading,
    },
  ];

  return {
    filters,
    error: tariffsError,
  };
};
