import { FC, useEffect } from 'react';
import { Breadcrumbs, BreadcrumbsItem } from 'ui/Breadcrumbs';
import { captureException } from '@sentry/react';
import { useBreadcrumbs } from './hooks/useBreadcrumbs';
import { CustomLabelsForCrumbs } from './types';
import { applyCustomLabelsToCrumbs } from './helpers';

interface RouterBreadcrumbsProps {
  from?: number;
  customLabelsForCrumbs?: CustomLabelsForCrumbs;
  className?: string;
}

export const RouterBreadcrumbs: FC<RouterBreadcrumbsProps> = ({
  from = 0,
  customLabelsForCrumbs,
  className,
}) => {
  const { crumbs } = useBreadcrumbs();

  useEffect(() => {
    if (from > crumbs.length) {
      captureException(new Error('there are no crumbs to show'));
    }
  }, [crumbs, from]);

  const items: Array<BreadcrumbsItem> = customLabelsForCrumbs
    ? applyCustomLabelsToCrumbs(crumbs, customLabelsForCrumbs)
    : crumbs;

  return <Breadcrumbs className={className} items={items} from={from} />;
};
