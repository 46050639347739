import { FC } from 'react';
import { Error } from 'ui/Error';
import { Loader } from 'ui/Loader';
import { EmptyDataBlock } from 'components/EmptyDataBlock';
import { useTranslation } from 'react-i18next';
import { useGetUserTypeNameQuery } from 'hooks/queryHooks/useGetUserTypeNameQuery';
import { UserTypeName } from 'types';
import { TariffBasicData } from 'pages/Tariffs/queries/generated/TariffBasicData';
import { TariffCard } from '../TariffCard';
import styles from './TariffsBlock.module.scss';
import { useGetUserTariffsQuery } from './hooks/useGetUserTariffsQuery';

export const TariffsBlock: FC = () => {
  const { t } = useTranslation('tariffs');

  const { tariffs, loading, error } = useGetUserTariffsQuery();
  const {
    userTypeName,
    loading: getUserTypeLoading,
    error: getUserTypeError,
  } = useGetUserTypeNameQuery();

  if (loading || getUserTypeLoading) {
    return <Loader shouldExcludeMenuWidth />;
  }

  if (error || getUserTypeError || !userTypeName) {
    return <Error error={error || getUserTypeError} />;
  }

  if (!tariffs || tariffs.length === 0) {
    return <EmptyDataBlock emptyText={t('content.placeholder.noData')} />;
  }

  const isCompany = userTypeName === UserTypeName.Company;

  return (
    <div className={styles.tariffList}>
      {tariffs.map((tariff: TariffBasicData) => (
        <TariffCard tariff={tariff} key={tariff.id} isCompany={isCompany} />
      ))}
    </div>
  );
};
