import { LocalError } from 'const/LocalError';
import { checkIsDateStringValid } from 'helpers';
import { useGetCurrentRewardPeriod } from 'queries/generated/GetCurrentRewardPeriod';
import { useEffect, useState } from 'react';
import { DateFormat } from 'utils/formatDate';

const { DateScalar } = DateFormat;

export const useGetCurrentRewardPeriodQuery = () => {
  const [dateValidityError, setDateValidityError] = useState<
    undefined | LocalError
  >();

  const { data, error, loading } = useGetCurrentRewardPeriod({
    fetchPolicy: 'cache-first',
  });

  const rewardPeriod = data?.currentRewardPeriod;

  useEffect(() => {
    if (!rewardPeriod) {
      return;
    }

    const isDatesValid =
      checkIsDateStringValid(rewardPeriod.start, DateScalar) &&
      checkIsDateStringValid(rewardPeriod.end, DateScalar);

    setDateValidityError(
      isDatesValid ? undefined : LocalError.InvalidDateRangeFromServer
    );
  }, [rewardPeriod]);

  return {
    rewardPeriod: data?.currentRewardPeriod,
    error: error ?? dateValidityError,
    loading,
  };
};
