import { useCallback } from 'react';
import { useAuth } from 'components/auth';
import { toast } from 'ui/toast';
import { useTranslation } from 'react-i18next';
import { useUpdateCompanyPartnerDetails } from '../queries/generated/UpdateCompanyPartnerDetails';
import {
  GetCompanyPartnerDetails,
  GetCompanyPartnerDetailsDocument,
} from '../../CompanyPartnerDetailsModal/queries/generated/GetCompanyPartnerDetails';
import { EditCompanyPartnerDetailsValues } from '../types';

interface UseUpdateCompanyPartnerDetailsMutationParams {
  companyPartnerId: string;
}

export const useUpdateCompanyPartnerDetailsMutation = ({
  companyPartnerId,
}: UseUpdateCompanyPartnerDetailsMutationParams) => {
  const { user } = useAuth();
  const { t } = useTranslation('common');

  const [updateCompanyPartnerDetailsMutation, { loading }] =
    useUpdateCompanyPartnerDetails();

  const handleError = useCallback(() => {
    toast.error(t('errors.commonError'));
  }, [t]);

  const updateCompanyPartnerDetails = useCallback(
    ({ username, note }: EditCompanyPartnerDetailsValues) => {
      if (!username) {
        return undefined;
      }

      return updateCompanyPartnerDetailsMutation({
        variables: {
          input: {
            id: companyPartnerId,
            username,
            note: note || null,
          },
        },
        onError: handleError,
        update: (cache, { data }) => {
          if (!data?.updateCompanyPartner?.companyPartner) return;

          const { companyPartner } = data.updateCompanyPartner;

          cache.writeQuery<GetCompanyPartnerDetails>({
            query: GetCompanyPartnerDetailsDocument,
            variables: {
              userId: user.id,
              companyPartnerId,
            },
            data: {
              __typename: 'Query',
              user: {
                __typename: 'Company',
                id: user.id,
                companyPartner,
              },
            },
          });
        },
      });
    },
    [
      updateCompanyPartnerDetailsMutation,
      companyPartnerId,
      handleError,
      user.id,
    ]
  );

  return {
    updateCompanyPartnerDetails,
    loading,
  };
};
