import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { FilterType, FormFiltersType, FilterCombination } from 'types';
import { isDeepEqual } from 'utils/isDeepEqual';
import {
  clearFiltersFromParamsObject,
  parseFiltersFromParams,
  serializeFilters,
} from './helpers';
import { FILTER_QUERY_REGEXP } from './const';
import { createFilterSearchParams } from '../../utils/createFilterSearchParams';
import { getIsFilterAllowed } from './helpers/getIsFilterAllowed';

interface GetIsFilterAllowedHandlerParams<T extends string> {
  filterKey: T;
  combinations: FilterCombination<T>;
}

export const useFiltersHandlers = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const hasFilters = useMemo(
    () =>
      Array.from(searchParams.keys()).some((key) =>
        key.match(FILTER_QUERY_REGEXP)
      ),
    [searchParams]
  );

  const clearFiltersParams = () => {
    setSearchParams(
      () => {
        clearFiltersFromParamsObject(searchParams);

        return searchParams;
      },
      { replace: true }
    );
  };

  const parseUrlFilters = useCallback(
    <T extends string>() => parseFiltersFromParams<T>(searchParams),
    [searchParams]
  );

  const getIsFilterAllowedHandler = <T extends string>({
    filterKey,
    combinations,
  }: GetIsFilterAllowedHandlerParams<T>) => {
    const { filtersKeys } = parseUrlFilters<T>();

    return getIsFilterAllowed({
      filterKey,
      combinations,
      selectedFilters: filtersKeys,
    });
  };

  const updateUrlFilterParams = useCallback(
    <T>(filters: Array<FilterType<T>>) => {
      const currentUrlFilters = parseUrlFilters();

      if (
        currentUrlFilters &&
        isDeepEqual(filters, currentUrlFilters.filters)
      ) {
        return;
      }

      setSearchParams(
        () => {
          clearFiltersFromParamsObject(searchParams);

          filters.forEach((filter) => {
            const values = filter.value;

            if (values.length) {
              const filterParams = createFilterSearchParams<T, string>({
                filterType: filter.type,
                value: values.join(','),
              });

              Object.entries(filterParams).forEach(([paramKey, paramValue]) => {
                searchParams.set(paramKey, paramValue);
              });
            }
          });

          return searchParams;
        },
        { replace: true }
      );
    },
    [setSearchParams, parseUrlFilters, searchParams]
  );

  const handleFiltersChange = <T extends string>(
    filters: FormFiltersType<T>
  ) => {
    const parsedFilters = serializeFilters(filters);

    updateUrlFilterParams<T>(parsedFilters);
  };

  return {
    onFiltersChange: handleFiltersChange,
    parseUrlFilters,
    clearFiltersParams,
    hasFilters,
    getIsFilterAllowed: getIsFilterAllowedHandler,
  };
};
