import { useEffect, useState } from 'react';
import { useAuth } from 'components/auth';
import {
  Currency,
  FilterType,
  NonNullableDateRange,
  UserTypeName,
} from 'types';
import { DateFormat, formatDate } from 'utils/formatDate';
import { ExtendedTData } from 'ui/Table/types';
import {
  CompanyRevShareStatisticSortInput,
  CompanyStatisticFilterType,
  PageInfo,
} from 'types/generated/gql';
import {
  GetCompanyRevShareStatisticItems,
  useGetCompanyRevShareStatisticItems,
} from '../queries/generated/GetCompanyRevShareStatisticItems';
import { STATISTIC_ITEMS_PER_PAGE } from '../const';
import { useCompanyRevShareStatisticExpandedRows } from './useCompanyRevShareStatisticExpandedRows';
import { CompanyRevShareStatisticTableData } from '../types';

const { DateScalar } = DateFormat;

interface UseGetCompanyRevShareStatisticItemsQueryParams {
  nonNullableDateRange: NonNullableDateRange;
  filters: Array<FilterType<CompanyStatisticFilterType>>;
  currency: Currency;
  searchQuery?: string;
  sortState?: CompanyRevShareStatisticSortInput;
}

export const useGetCompanyRevShareStatisticItemsQuery = ({
  nonNullableDateRange,
  filters,
  currency,
  searchQuery,
  sortState,
}: UseGetCompanyRevShareStatisticItemsQueryParams) => {
  const { user } = useAuth();
  const [start, end] = nonNullableDateRange;

  const [parsedData, setParsedData] = useState<
    Array<ExtendedTData<CompanyRevShareStatisticTableData>>
  >([]);

  const [pageInfoState, setPageInfoState] = useState<PageInfo>();

  const { handleExpandRow } = useCompanyRevShareStatisticExpandedRows({
    parentRowsData: parsedData,
    setParentsRowsData: setParsedData,
    start: formatDate(start, DateScalar),
    end: formatDate(end, DateScalar),
    userId: user.id,
    currency,
  });

  const handleCompleted = (data: GetCompanyRevShareStatisticItems) => {
    const statisticItemsConnection =
      data?.user.__typename === UserTypeName.Company
        ? data.user.revShareStatistic.statisticItems
        : undefined;

    const newEdges = statisticItemsConnection?.edges;

    const statisticItems =
      newEdges?.map((item) => ({
        ...item.node,
        canExpand: item.node.hasPromoCodeStatistic,
      })) ?? [];

    const pageInfo = statisticItemsConnection?.pageInfo;

    setParsedData((prevData) =>
      statisticItems.map((item, index) => {
        const prevItem = prevData[index];

        if (prevItem?.subRows?.length) {
          return {
            ...item,
            subRows: prevItem.subRows,
          };
        }

        return item;
      })
    );

    setPageInfoState(pageInfo);
  };

  const {
    fetchMore,
    loading,
    isSliced,
    error,
    data: queryData,
  } = useGetCompanyRevShareStatisticItems({
    variables: {
      start: formatDate(start, DateScalar),
      end: formatDate(end, DateScalar),
      first: STATISTIC_ITEMS_PER_PAGE,
      userId: user.id,
      filters,
      currency,
      sort: sortState,
      ...(Boolean(searchQuery) && { referralLinkSearch: searchQuery }),
    },
    onCompleted: handleCompleted,
    notifyOnNetworkStatusChange: true,
    cacheFirstOnPop: true,
  });

  useEffect(() => {
    if (parsedData.length && !queryData) {
      setParsedData([]);
    }
  }, [parsedData.length, queryData]);

  return {
    statisticItems: parsedData,
    fetchMore,
    pageInfo: pageInfoState,
    loading,
    isSliced,
    error,
    handleExpandRow,
  };
};
