import { FC } from 'react';
import { Falsy } from 'types';
import { useTranslation } from 'react-i18next';
import { DateFormat, formatDate } from 'utils/formatDate';
import { KeyValueGrid, KeyValueGridRow } from 'ui/KeyValueGrid';
import { CopyLink } from 'ui/CopyLink';
import { useTranslationUserType } from 'hooks/useTranslationUserType';
import { TextLink } from 'ui/TextLink';
import { useModalContext, closeModal } from 'ui/Modal';
import { composeRoutes } from 'utils/routing/composeRoutes';
import { useCreateLocalizedPath } from 'hooks/useCreateLocalizedPath';
import { AppRoute } from 'const';
import { getStatisticRouteByTariffTypeName } from 'helpers';
import { ButtonSize } from 'ui/Button';
import { ReferralLinkMediaItemData } from '../../../../queries/fragments/generated/ReferralLinkMediaItemData';
import { getMediaItemOwnerToShow } from './helpers';
import { MediaItemName } from './components/MediaItemName';
import styles from './ReferralLinkMediaDetails.module.scss';

interface ReferralLinkMediaDetailsProps {
  referralLinkMediaItem: ReferralLinkMediaItemData;
}

export const ReferralLinkMediaDetails: FC<ReferralLinkMediaDetailsProps> = ({
  referralLinkMediaItem,
}) => {
  const { dispatch } = useModalContext();
  const { createLocalizedPath } = useCreateLocalizedPath();

  const { name, id, link, createdAt, tariff } = referralLinkMediaItem;
  const { t } = useTranslation(['common', 'media']);
  const translationUserType = useTranslationUserType();

  const path = getStatisticRouteByTariffTypeName(tariff.__typename);

  const referralLinkMediaItemOwner = getMediaItemOwnerToShow(
    referralLinkMediaItem.owner
  );

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const keyValueGridRows: Array<KeyValueGridRow | Falsy> = [
    {
      title: t(
        `media:${translationUserType}.content.drawer.details.aboutMedia.label.mediaName`
      ),
      value: <MediaItemName referralLinkMediaItemId={id} initialName={name} />,
    },
    referralLinkMediaItemOwner && {
      title: t('media:company.content.drawer.details.aboutMedia.label.owner'),
      value: referralLinkMediaItemOwner,
    },
    {
      title: t(
        `media:${translationUserType}.content.drawer.details.aboutMedia.label.link`
      ),
      value: (
        <CopyLink target="_blank" to={link} tooltipText={t('linkCopied')}>
          {link}
        </CopyLink>
      ),
    },
    {
      title: t(
        `media:${translationUserType}.content.drawer.details.aboutMedia.label.createdDate`
      ),
      value: formatDate(new Date(createdAt), DateFormat.FullDateWithoutHours),
    },
  ];

  return (
    <>
      <KeyValueGrid
        className={styles.keyValueGrid}
        data={keyValueGridRows}
        shouldStackOnMd
      />
      <TextLink
        to={createLocalizedPath(
          composeRoutes([AppRoute.Lang.Statistic.root, path, id])
        )}
        onClick={handleCloseModal}
        buttonSize={ButtonSize.Large}
      >
        {t(`media:${translationUserType}.dropdown.statistics`)}
      </TextLink>
    </>
  );
};
