import { useTranslation } from 'react-i18next';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { TranslationUserType } from 'types';
import { mapPromoCodeTypeToText } from 'helpers';
import { CopyLink } from 'ui/CopyLink';
import { CopyText } from 'ui/CopyText';
import { COLUMNS_SIZES } from './const';
import { PromoCodeMediaItemData } from '../../../queries/fragments/generated/PromoCodeMediaItemData';

export const usePartnerPromoCodeMediaItemsColumns = (): Array<
  ColumnDef<PromoCodeMediaItemData>
> => {
  const { t } = useTranslation(['common', 'media']);
  const columnHelper = createColumnHelper<PromoCodeMediaItemData>();

  /*
   *  We need to use any as generic argument while bug won't be fixed
   *  Issue https://github.com/TanStack/table/issues/4382
   */
  const columns: Array<ColumnDef<PromoCodeMediaItemData, any>> = [
    columnHelper.accessor('type', {
      header: t(
        `media:partner.content.dataTable.promoCodes.column.typeOfPromo`
      ),
      cell: ({ row }) =>
        mapPromoCodeTypeToText(row.original.type, TranslationUserType.Partner),
      size: COLUMNS_SIZES.typeSize,
      enablePinning: true,
    }),
    columnHelper.accessor('tariffName', {
      header: t(`media:partner.content.dataTable.promoCodes.column.tariff`),
      cell: ({ row }) => row.original.tariffName,
      size: COLUMNS_SIZES.tariffSize,
    }),
    columnHelper.accessor('token', {
      header: t(`media:partner.content.dataTable.promoCodes.column.token`),
      cell: (info) => (
        <CopyText tooltipText={t('tokenCopied')} justifyBetween truncateText>
          {info.getValue()}
        </CopyText>
      ),
      size: COLUMNS_SIZES.tokenSize,
    }),
    columnHelper.accessor('referralLink', {
      header: t(`media:partner.content.dataTable.promoCodes.column.link`),
      cell: (info) => (
        <CopyLink
          onClick={(e) => e.stopPropagation()}
          target="_blank"
          to={info.getValue()}
          tooltipText={t('linkCopied')}
        >
          {info.getValue()}
        </CopyLink>
      ),
      minSize: COLUMNS_SIZES.linkMinSize,
    }),
  ];

  return columns;
};
