import { TextWithLink } from 'ui/TextWithLink';
import { TextLink } from 'ui/TextLink';
import { AppRoute } from 'const';
import { useTranslation } from 'react-i18next';
import { useLanguageDropdownButton } from 'hooks/useLanguageDropdownButton';
import { useCreateLocalizedPath } from 'hooks/useCreateLocalizedPath';
import { FC } from 'react';
import { LanguageDropdown } from '../LanguageDropdown';
import { LanguageDropdownButton } from './components/LanguageDropdownButton';
import { useFooterContactUsLabel } from './hooks/useFooterContactUsLabel';
import styles from './Footer.module.scss';

interface FooterProps {
  showManagerSupportLink?: boolean;
}

export const Footer: FC<FooterProps> = ({
  showManagerSupportLink = false,
}: FooterProps) => {
  const { t } = useTranslation('auth');
  const { createLocalizedPath } = useCreateLocalizedPath();
  const { onOpenChange, isActive } = useLanguageDropdownButton();
  const contactUsLabelText = useFooterContactUsLabel();

  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        {!showManagerSupportLink && (
          <div className={styles.innerWrapper}>
            <TextWithLink
              target="_blank"
              text={contactUsLabelText}
              linkText={t('footer.button.contactUs')}
              to={createLocalizedPath(AppRoute.Lang.Support)}
            />
          </div>
        )}
        <div className={styles.innerWrapper}>
          <TextLink
            target="_blank"
            to={createLocalizedPath(AppRoute.Lang.TermsOfUse)}
          >
            {t('footer.button.terms')}
          </TextLink>
        </div>

        {showManagerSupportLink && (
          <div className={styles.innerWrapper}>
            <TextLink
              target="_blank"
              to={createLocalizedPath(AppRoute.Lang.Support)}
            >
              {t('footer.button.managerSupport')}
            </TextLink>
          </div>
        )}

        <div className={styles.innerWrapper}>
          <LanguageDropdown
            onOpenChange={onOpenChange}
            trigger={<LanguageDropdownButton isActive={isActive} />}
          />
        </div>
      </div>
    </footer>
  );
};
