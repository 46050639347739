import { FilterCombination } from 'types';
import { PartnerPromoCodeMediaItemsFilterType } from 'types/generated/gql';

export const partnerPromoCodeMediaItemsCombinations: FilterCombination<PartnerPromoCodeMediaItemsFilterType> =
  {
    [PartnerPromoCodeMediaItemsFilterType.PromoCodeType]: [
      PartnerPromoCodeMediaItemsFilterType.ReferralLink,
      PartnerPromoCodeMediaItemsFilterType.PaymentModel,
      PartnerPromoCodeMediaItemsFilterType.Tariff,
    ],
  };
