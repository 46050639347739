import { useCreateLocalizedPath } from 'hooks/useCreateLocalizedPath';
import { useMemo } from 'react';
import { AppRoute } from 'const';
import { createFilterSearchParams } from 'utils/createFilterSearchParams';
import { composeRoutes } from 'utils/routing/composeRoutes';
import { getStatisticRouteByTariffTypeName } from 'helpers';
import {
  getReferralLinkMediaItemsFilterType,
  getStatisticTariffFilterType,
} from '../helpers';
import {
  TariffBasicData_CpaTariff,
  TariffBasicData_RevShareTariff,
} from '../../../queries/generated/TariffBasicData';

interface useTariffRoutesParams {
  tariffId: string;
  tariffTypeName:
    | TariffBasicData_CpaTariff['__typename']
    | TariffBasicData_RevShareTariff['__typename'];
  isCompany: boolean;
}

export const useTariffRoutes = ({
  tariffId,
  tariffTypeName,
  isCompany,
}: useTariffRoutesParams) => {
  const { createLocalizedPath } = useCreateLocalizedPath();

  const routeToReferralLinks = useMemo((): string => {
    const referralLinkMediaItemsFilterType =
      getReferralLinkMediaItemsFilterType(isCompany);
    const referralLinksSearchParams = new URLSearchParams(
      createFilterSearchParams({
        filterType: referralLinkMediaItemsFilterType,
        value: tariffId,
      })
    );

    return createLocalizedPath(
      composeRoutes(
        [AppRoute.Lang.Media.root, AppRoute.Lang.Media.Links.root],
        {
          searchParams: referralLinksSearchParams,
        }
      )
    );
  }, [isCompany, tariffId, createLocalizedPath]);

  const routeToGeneralStatistic = useMemo((): string => {
    const statisticTariffFilterType = getStatisticTariffFilterType(isCompany);
    const statisticSearchParams = new URLSearchParams(
      createFilterSearchParams({
        filterType: statisticTariffFilterType,
        value: tariffId,
      })
    );

    return createLocalizedPath(
      composeRoutes(
        [
          AppRoute.Lang.Statistic.root,
          getStatisticRouteByTariffTypeName(tariffTypeName),
        ],
        {
          searchParams: statisticSearchParams,
        }
      )
    );
  }, [isCompany, tariffId, tariffTypeName, createLocalizedPath]);

  return { routeToReferralLinks, routeToGeneralStatistic };
};
