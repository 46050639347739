import { t } from 'i18n';
import { CommonPostbackStatus } from 'types/generated/gql';
import { TranslationUserType } from 'types';
import styles from './PostbackButton.module.scss';

export const mapPostbackStatusToClassName: Record<
  CommonPostbackStatus,
  string
> = {
  [CommonPostbackStatus.NotConnected]: styles.notConnected,
  [CommonPostbackStatus.Connected]: styles.connected,
  [CommonPostbackStatus.Disconnected]: styles.disconnected,
  [CommonPostbackStatus.PartiallyDisconnected]: styles.partiallyDisconnected,
};

export const mapPostbackStatusToTooltip = (
  status: CommonPostbackStatus,
  translationUserType: TranslationUserType
): string => {
  const map: Record<CommonPostbackStatus, string> = {
    [CommonPostbackStatus.NotConnected]: t(
      `media:${translationUserType}.content.dataTable.hint.postback.status1`
    ),
    [CommonPostbackStatus.Connected]: t(
      `media:${translationUserType}.content.dataTable.hint.postback.status2`
    ),
    [CommonPostbackStatus.Disconnected]: t(
      `media:${translationUserType}.content.dataTable.hint.postback.status3`
    ),
    [CommonPostbackStatus.PartiallyDisconnected]: t(
      `media:${translationUserType}.content.dataTable.hint.postback.status4`
    ),
  };

  return map[status];
};
