import { FC, KeyboardEvent, useEffect, useState } from 'react';
import { DatePickerProps } from 'react-datepicker';
import { isEqual, parse, startOfDay } from 'date-fns';
import { useField } from 'formik';
import classnames from 'classnames';
import { t } from 'i18n';
import { DatePicker, DatePickerHandleProps } from 'ui/DatePicker';
import { Input } from 'ui/formItems';
import { getMinimalUnderBreakpoint } from 'helpers';
import { Breakpoints } from 'const';
import { ReactComponent as Calendar } from 'assets/icons/line/Calendar.svg';
import ClickOutside, { useClickOutside } from 'ui/ClickOutside';
import { BottomSheet } from 'ui/BottomSheet';
import { PopUp } from 'ui/PopUp';
import { DateFormat, formatDate } from 'utils/formatDate';
import { useValidatedDateInput } from './hooks/useValidatedDateInput';
import styles from './DatePickerWithInput.module.scss';

interface DatePickerWithInputProps extends Omit<DatePickerProps, 'onChange'> {
  onChange: (date: DatePickerHandleProps) => void;
  label: string;
  disabled?: boolean;
  placeholder?: string;
  name: string;
  isOpen?: boolean;
  openInitialDate?: Date;
}

const dateFormat = DateFormat.DefaultDate;

export const DatePickerWithInput: FC<DatePickerWithInputProps> = ({
  onChange,
  name,
  label,
  disabled,
  wrapperClassName,
  openInitialDate,
  isOpen = false,
  placeholder = `${t('datePicker.typeDate')} ${dateFormat}`,
  ...rest
}) => {
  const [field, meta] = useField(name);
  const { touched, error } = meta;

  const [startDate, setStartDate] = useState(field.value || null);
  const [isOpenState, setIsOpenState] = useState(isOpen);
  const { inputValue, validateAndFormatInput } = useValidatedDateInput(
    startDate && formatDate(startDate, dateFormat)
  );

  const isMobile = getMinimalUnderBreakpoint() === Breakpoints.Sm;
  const isTablet = getMinimalUnderBreakpoint() === Breakpoints.Md;

  const handleSubmit = (date: DatePickerHandleProps) => {
    const parsedInputDate = parse(inputValue, dateFormat, new Date());

    if (Number.isNaN(parsedInputDate.getDate())) {
      setStartDate(date);
      onChange(date);
      setIsOpenState(false);

      return;
    }

    const isInputDateEqualToDatePickerDate = isEqual(
      startOfDay(date as Date),
      startOfDay(parsedInputDate)
    );

    // if date from input is not equal to date from datepicker,
    // it means that user entered date manually and we should use it
    const dateToSubmit = !isInputDateEqualToDatePickerDate
      ? parsedInputDate
      : date;

    setStartDate(dateToSubmit);
    onChange(dateToSubmit);
    setIsOpenState(false);
  };

  const handleChange = (date: DatePickerHandleProps) => {
    setStartDate(date);
  };

  useEffect(() => {
    validateAndFormatInput(startDate && formatDate(startDate, dateFormat));
  }, [startDate, validateAndFormatInput]);

  const handleChangeInput = (value: string) => {
    validateAndFormatInput(value);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.code === 'Enter') {
      const formattedDate = parse(inputValue, dateFormat, new Date());

      if (!Number.isNaN(formattedDate.getDate())) {
        setStartDate(formattedDate);
        onChange(formattedDate);
        setIsOpenState(false);

        return;
      }

      setStartDate(null);
    }
  };

  const handleClickOutside = useClickOutside(() => {
    if (!isMobile && !isTablet) {
      setIsOpenState(false);

      const formattedDate = parse(inputValue, dateFormat, new Date());

      if (!Number.isNaN(formattedDate.getDate())) {
        setStartDate(formattedDate);
        onChange(formattedDate);
      }
    }
  });

  const showDesktopDatePicker = !isMobile && !isTablet && isOpenState;

  return (
    <div className={classnames(wrapperClassName, styles.datePickerWrapper)}>
      <ClickOutside callback={handleClickOutside}>
        {/* todo replace this input to input without form logic */}
        <Input
          leftIconConfig={{ icon: Calendar }}
          label={label}
          onClick={() => setIsOpenState(true)}
          name={name}
          disabled={disabled}
          onKeyDown={handleKeyDown}
          value={inputValue ?? ''}
          placeholder={placeholder}
          onChange={handleChangeInput}
          inputClassName={classnames({
            [styles.pickerTouched]: touched,
            [styles.pickerError]: touched && error,
          })}
        />
        {showDesktopDatePicker && (
          <DatePicker
            {...rest}
            openInitialDate={openInitialDate}
            className={styles.dropdown}
            onChange={handleChange}
            startDateInitial={startDate}
            onSubmit={handleSubmit}
          />
        )}
      </ClickOutside>

      {isTablet && (
        <PopUp
          title={label}
          isOpen={isOpenState}
          onClose={() => setIsOpenState(false)}
        >
          <Input
            leftIconConfig={{ icon: Calendar, withTopIndent: false }}
            name={name}
            value={inputValue ?? ''}
            placeholder={placeholder}
            autoFocus={false}
            onChange={handleChangeInput}
            onKeyDown={handleKeyDown}
            wrapperClassName={styles.inputWrapper}
            inputClassName={classnames({
              [styles.pickerTouched]: touched,
              [styles.pickerError]: touched && error,
            })}
          />
          <DatePicker
            {...rest}
            openInitialDate={openInitialDate}
            onChange={handleChange}
            startDateInitial={startDate}
            onSubmit={handleSubmit}
          />
        </PopUp>
      )}

      {isMobile && (
        <BottomSheet
          isOpen={isOpenState}
          onClose={() => setIsOpenState(false)}
          title={label}
        >
          <Input
            leftIconConfig={{ icon: Calendar, withTopIndent: false }}
            name={name}
            value={inputValue ?? ''}
            placeholder={placeholder}
            autoFocus={false}
            onKeyDown={handleKeyDown}
            wrapperClassName={styles.inputWrapper}
            inputClassName={classnames({
              [styles.pickerTouched]: touched,
              [styles.pickerError]: touched && error,
            })}
            onChange={handleChangeInput}
          />
          <DatePicker
            {...rest}
            openInitialDate={openInitialDate}
            onChange={handleChange}
            startDateInitial={startDate}
            onSubmit={handleSubmit}
          />
        </BottomSheet>
      )}
    </div>
  );
};
