import classNames from 'classnames';
import { ReactComponent as LoadingIcon } from 'assets/icons/solid/Loader.svg';
import { ButtonHTMLAttributes, FC, PropsWithChildren } from 'react';
import styles from './Button.module.scss';
import { ButtonAlign, ButtonSize, ButtonTheme } from './const';
import { getButtonStyles, getIconSize } from './helpers';
import { LinkStyledProp } from './types';
import { Icon } from '../Icon';
import type { IconComponent } from '../../types';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  textWrapperClassName?: string;
  linkStyled?: LinkStyledProp;
  isLoading?: boolean;
  size?: ButtonSize;
  theme?: ButtonTheme;
  align?: ButtonAlign;
  iconConfig?: {
    iconComponent: IconComponent;
    before?: boolean;
    containerClassName?: string;
    onClick?: () => void;
  };
  isFullWidth?: boolean;
}

export const Button: FC<PropsWithChildren<ButtonProps>> = ({
  align = ButtonAlign.Center,
  children,
  className,
  textWrapperClassName,
  linkStyled,
  iconConfig,
  isLoading,
  size = ButtonSize.Large,
  theme = ButtonTheme.Black,
  disabled,
  isFullWidth,
  ...rest
}) => {
  const hasLoading = isLoading && !linkStyled;

  const icon =
    iconConfig &&
    (iconConfig.onClick ? (
      <div
        className={styles.clickableIconContainer}
        role="button"
        tabIndex={0}
        onClick={(e) => {
          e.stopPropagation();
          iconConfig.onClick?.();
        }}
      >
        <Icon
          containerClassName={iconConfig.containerClassName}
          hasDefaultColor={false}
          iconComponent={iconConfig.iconComponent}
          size={getIconSize(size, linkStyled)}
        />
      </div>
    ) : (
      <Icon
        containerClassName={iconConfig.containerClassName}
        hasDefaultColor={false}
        iconComponent={iconConfig.iconComponent}
        size={getIconSize(size, linkStyled)}
      />
    ));

  const contentToRender = (
    <>
      {iconConfig?.before && icon}
      {children && (
        <span className={classNames(textWrapperClassName, styles.text)}>
          {children}
        </span>
      )}
      {!iconConfig?.before && icon}
    </>
  );

  return (
    <button
      data-testid="button"
      type="button"
      className={classNames(
        className,
        styles[align],
        getButtonStyles(theme, size, linkStyled),
        {
          [styles.buttonWithLoading]: hasLoading,
          [styles.fullWidth]: isFullWidth,
          [styles.disabled]: disabled && linkStyled,
        }
      )}
      disabled={disabled || hasLoading}
      {...rest}
    >
      {hasLoading ? (
        <>
          <Icon
            size={getIconSize(size)}
            containerClassName={styles.loadingIcon}
            iconComponent={LoadingIcon}
            hasDefaultColor={false}
            spin
          />
          <div
            className={styles.contentContainerIfLoading}
            data-testid="button-content-wrapper"
          >
            {contentToRender}
          </div>
        </>
      ) : (
        contentToRender
      )}
    </button>
  );
};
