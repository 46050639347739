import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useModalContext, openModal } from 'ui/Modal';
import { ConfirmModal, ConfirmModalContextProvider } from 'ui/ConfirmModal';
import { EditCompanyPartnerDetailsModal } from '../../EditCompanyPartnerDetailsModal';

interface OpenEditCompanyPartnerDetailsModalParams {
  id: string;
  username: string;
  note?: string;
}

export const useEditCompanyPartnerDetailsModal = () => {
  const { t } = useTranslation(['common', 'companyPartners']);
  const { dispatch } = useModalContext();

  const openEditCompanyPartnerDetailsModal = useCallback(
    ({ id, username, note }: OpenEditCompanyPartnerDetailsModalParams) => {
      dispatch(
        openModal({
          title: t(
            'companyPartners:content.drawer.editPartnerDetails.header.title'
          ),
          subTitle: `#${id}`,
          content: (
            <ConfirmModalContextProvider>
              <EditCompanyPartnerDetailsModal
                companyPartnerId={id}
                username={username}
                note={note}
              />
              <ConfirmModal
                content={{
                  cancelButton: t('pageCloseConfirmModalContent.cancelButton'),
                  confirmButton: t(
                    'pageCloseConfirmModalContent.confirmButton'
                  ),
                  text: t('pageCloseConfirmModalContent.text'),
                  title: t('pageCloseConfirmModalContent.title'),
                }}
              />
            </ConfirmModalContextProvider>
          ),
        })
      );
    },
    [dispatch, t]
  );

  return {
    openEditCompanyPartnerDetailsModal,
  };
};
