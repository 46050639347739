import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'ui/toast';
import { UserTypeName } from 'types';
import { useGetUserTypeNameQuery } from 'hooks/queryHooks/useGetUserTypeNameQuery';
import { CompanyPromoCodeMediaTable } from './components/CompanyPromoCodeMediaTable';
import { PartnerPromoCodeMediaTable } from './components/PartnerPromoCodeMediaTable';

export const PromoCodeMediaTable: FC = () => {
  const { t } = useTranslation('common');

  const { userTypeName } = useGetUserTypeNameQuery({
    onError: () => {
      toast.error(t('errors.commonError'));
    },
  });

  return userTypeName === UserTypeName.Company ? (
    <CompanyPromoCodeMediaTable />
  ) : (
    <PartnerPromoCodeMediaTable />
  );
};
