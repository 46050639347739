import { FC, PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { Tooltip, TooltipTheme } from 'ui/Tooltip';
import { ValidationHintRule } from './types';
import { InputProps } from '../Input/Input';
import { getValidationState } from './utils';
import styles from './ValidationHint.module.scss';
import { ValidationHintContent } from './ValidationHintContent';

interface ValidationHintProps {
  isOpen: boolean;
  rules: Array<ValidationHintRule>;
  value: InputProps['value'];
}

export const ValidationHint: FC<PropsWithChildren<ValidationHintProps>> = ({
  isOpen,
  rules,
  children,
  value,
}) => {
  const initialValidationState = getValidationState({ value, rules });

  const [validationState, setValidationState] = useState<
    Record<string, boolean>
  >(initialValidationState);

  const handleValueChange = useCallback(
    (newValue: InputProps['value']) => {
      const newValidationState = getValidationState({ value: newValue, rules });

      setValidationState(newValidationState);
    },
    [rules]
  );

  useEffect(() => {
    handleValueChange(value);
  }, [value, handleValueChange]);

  return (
    <Tooltip
      placement="right-start"
      isOpen={isOpen}
      tooltipContentClassName={styles.tooltipContent}
      tooltipContent={
        <ValidationHintContent
          rules={rules}
          validationState={validationState}
        />
      }
      shouldSetWidthFitContent={false}
      tooltipTheme={TooltipTheme.Light}
    >
      {children}
    </Tooltip>
  );
};
