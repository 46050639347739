import { FC } from 'react';
import { useLoadMore } from 'hooks/useLoadMore';
import { Table } from 'ui/Table';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { CompanyReferralLinkMediaItemsFilterType } from 'types/generated/gql';
import { useFiltersHandlers } from 'hooks/table/useFiltersHandlers';
import { useOpenReferralLinkModal } from '../../../../../../hooks/useOpenReferralLinkModal';
import { ReferralLinkMediaItemBasicData } from '../../../../../../queries/fragments/generated/ReferralLinkMediaItemBasicData';
import { useGetCompanyReferralLinkMediaItemsQuery } from './hooks/useGetCompanyReferralLinkMediaItemsQuery';
import { useCompanyReferralLinkMediaTableColumns } from './hooks/useCompanyReferralLinkMediaTableColumns';
import { CompanyReferralLinkMediaTableSearch } from './components/CompanyReferralLinkMediaTableSearch';

export const CompanyReferralLinkMediaTable: FC = () => {
  const { openEditModal } = useOpenReferralLinkModal();
  const { t } = useTranslation('media');
  const [searchParams] = useSearchParams();
  const { parseUrlFilters } = useFiltersHandlers();

  const searchQuery = searchParams.has('search')
    ? (searchParams.get('search') ?? '')
    : '';

  const { filters, formFilters } =
    parseUrlFilters<CompanyReferralLinkMediaItemsFilterType>();

  const {
    referralLinkMediaItems,
    pageInfo,
    fetchMore,
    isSliced,
    error: getReferralLinkMediaItemsError,
    loading: getReferralLinkMediaItemsLoading,
  } = useGetCompanyReferralLinkMediaItemsQuery({
    searchQuery,
    filters,
  });

  const columns = useCompanyReferralLinkMediaTableColumns();

  const loadMore = useLoadMore(fetchMore, pageInfo);

  const handleRowClick = ({ id }: ReferralLinkMediaItemBasicData) => {
    openEditModal(id);
  };

  const isLoading = !referralLinkMediaItems && getReferralLinkMediaItemsLoading;
  const error = getReferralLinkMediaItemsError;

  const tableData = referralLinkMediaItems ?? [];

  return (
    <Table
      onRowClick={handleRowClick}
      columns={columns}
      data={tableData}
      error={error}
      emptyText={t('placeholder.links.noData')}
      hasSearchParams={!!searchQuery || !!filters.length}
      isLoading={isLoading || isSliced}
      hasMoreData={!!pageInfo?.hasNextPage}
      emptySearchStateConfig={{
        title: t('placeholder.main.searchRequest.noDataHeader'),
        description: t('placeholder.main.searchRequest.noDataSubheader'),
      }}
      errorStateTitle={t('placeholder.loadingError.loadingErrorHeader')}
      errorStateDescription={t(
        'placeholder.loadingError.loadingErrorSubheader'
      )}
      fetchMore={loadMore}
      searchComponent={
        <CompanyReferralLinkMediaTableSearch
          initialValues={{ search: searchQuery, filters: formFilters }}
        />
      }
    />
  );
};
