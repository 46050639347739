import { useState } from 'react';
import { checkIsAuthError, useAuth } from 'components/auth';
import { useTranslation } from 'react-i18next';
import { toast } from 'ui/toast';
import { useNavigateToMainPage } from './useNavigateToMainPage';

export const useCheckTwoFactor = () => {
  const { t } = useTranslation(['common', 'auth']);
  const { navigateToMainPage } = useNavigateToMainPage();
  const [isLoading, setIsLoading] = useState(false);

  const auth = useAuth();

  const checkTwoFactor = async (code: string) => {
    try {
      setIsLoading(true);
      await auth.checkTwoFactor(code);
      navigateToMainPage();
    } catch (e) {
      let errorText: string;

      if (checkIsAuthError(e)) {
        errorText =
          e.status === 401
            ? t('auth:login.input.2FA.validation.invalidCode')
            : e.detail;
      } else {
        errorText = t('errors.commonError');
      }

      toast.error({
        header: 'Error',
        text: errorText,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return {
    checkTwoFactor,
    isLoading,
  };
};
