import { Navigate, Outlet } from 'react-router-dom';
import { AuthStatuses, useAuth } from 'components/auth';
import { AppRoute } from 'const';
import { UserPrivilege } from 'types/generated/userPrivilege';
import { useLocation } from 'react-router';
import { useCreateLocalizedPath } from 'hooks/useCreateLocalizedPath';

export const PrivateUserTransferWrapper = () => {
  const { user } = useAuth();
  const location = useLocation();
  const { createLocalizedPath } = useCreateLocalizedPath();

  if (user.authStatus !== AuthStatuses.AuthenticatedTransferUser) {
    return (
      <Navigate to={createLocalizedPath(AppRoute.Lang.LoginTransfer)} replace />
    );
  }

  if (
    user.privileges.includes(UserPrivilege.RegisterMigrationCompany) &&
    location.pathname ===
      createLocalizedPath(AppRoute.Lang.RegistrationTransferPartner)
  ) {
    return (
      <Navigate
        to={createLocalizedPath(AppRoute.Lang.RegistrationTransferCompany)}
        replace
      />
    );
  }

  if (
    user.privileges.includes(UserPrivilege.RegisterMigrationPartner) &&
    location.pathname ===
      createLocalizedPath(AppRoute.Lang.RegistrationTransferCompany)
  ) {
    return (
      <Navigate
        to={createLocalizedPath(AppRoute.Lang.RegistrationTransferPartner)}
        replace
      />
    );
  }

  return <Outlet />;
};
