import { FormikErrors } from 'formik';
import { getRequiredErrors } from 'utils/form/getRequiredFormikErrors';
import { checkIsValidUrl } from 'utils/checkIsValidUrl';
import { t } from 'i18n';
import { TranslationUserType } from 'types';
import { PostbackValues } from '../../types';
import { getMacrosFromUrl, validateMacros } from './helpers';

export const validatePostbackForm = (
  values: PostbackValues,
  translationUserType: TranslationUserType
): FormikErrors<PostbackValues> => {
  const errors: FormikErrors<PostbackValues> = {};

  if (values.url && !checkIsValidUrl(values.url)) {
    errors.url = t('errors.incorrectFormat');
  }

  if (values.url && checkIsValidUrl(values.url) && values.event) {
    const macros = getMacrosFromUrl(values.url);

    if (macros) {
      const macrosError = validateMacros({
        event: values.event,
        macros,
        translationUserType,
      });

      if (macrosError) {
        errors.url = macrosError;
      }
    }
  }

  const requiredErrors = getRequiredErrors(
    values,
    {
      event: true,
      method: true,
      url: true,
    },
    {
      event: t(
        `media:${translationUserType}.content.drawer.details.postback.card.input.validation.eventRequired`
      ),
      method: t(
        `media:${translationUserType}.content.drawer.details.postback.card.input.validation.methodRequired`
      ),
      url: t(
        `media:${translationUserType}.content.drawer.details.postback.card.input.validation.urlRequired`
      ),
    }
  );

  return {
    ...errors,
    ...requiredErrors,
  };
};
