import * as SchemaTypes from '../../../../../../../../types/generated/gql';

import * as CommonType from 'types';
import { DocumentNode } from 'graphql';
import { RevShareReferralLinkStatisticTotalData } from '../fragments/generated/RevShareReferralLinkStatisticTotalData';
import { RevShareReferralLinkStatisticInfoData } from '../fragments/generated/RevShareReferralLinkStatisticInfoData';
import { MoneyAmountData } from '../../../../../../../../queries/fragments/generated/MoneyAmountData';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'utils/customApolloQuery';
const defaultOptions = {} as const;
export type GetRevShareStatisticDetailedTotalVariables = SchemaTypes.Exact<{
  currency: SchemaTypes.Scalars['Currency']['input'];
  end: SchemaTypes.Scalars['Date']['input'];
  referralNameSearch?: SchemaTypes.InputMaybe<
    SchemaTypes.Scalars['String']['input']
  >;
  revShareMediaItemId: SchemaTypes.Scalars['ID']['input'];
  start: SchemaTypes.Scalars['Date']['input'];
  userId: SchemaTypes.Scalars['ID']['input'];
}>;

export type GetRevShareStatisticDetailedTotal = {
  __typename: 'Query';
  user:
    | {
        __typename: 'Company';
        id: string;
        referralLinkMediaItem: {
          __typename: 'ReferralLinkMediaItem';
          id: string;
          revShareStatistic: {
            __typename: 'RevShareReferralLinkStatistic';
            total: {
              __typename: 'RevShareReferralLinkStatisticInfo';
              numberOfRedeposits: number;
              wasFD: boolean;
              adminFee: {
                __typename: 'MoneyAmount';
                currency: CommonType.Currency;
                value: number;
              };
              averageDeposit: {
                __typename: 'MoneyAmount';
                currency: CommonType.Currency;
                value: number;
              };
              depositsAll: {
                __typename: 'MoneyAmount';
                currency: CommonType.Currency;
                value: number;
              };
              depositsFee: {
                __typename: 'MoneyAmount';
                currency: CommonType.Currency;
                value: number;
              };
              redepositsAll: {
                __typename: 'MoneyAmount';
                currency: CommonType.Currency;
                value: number;
              };
              withdrawalsAll: {
                __typename: 'MoneyAmount';
                currency: CommonType.Currency;
                value: number;
              };
              withdrawalsFee: {
                __typename: 'MoneyAmount';
                currency: CommonType.Currency;
                value: number;
              };
            };
          };
        };
      }
    | {
        __typename: 'Partner';
        id: string;
        referralLinkMediaItem: {
          __typename: 'ReferralLinkMediaItem';
          id: string;
          revShareStatistic: {
            __typename: 'RevShareReferralLinkStatistic';
            total: {
              __typename: 'RevShareReferralLinkStatisticInfo';
              numberOfRedeposits: number;
              wasFD: boolean;
              adminFee: {
                __typename: 'MoneyAmount';
                currency: CommonType.Currency;
                value: number;
              };
              averageDeposit: {
                __typename: 'MoneyAmount';
                currency: CommonType.Currency;
                value: number;
              };
              depositsAll: {
                __typename: 'MoneyAmount';
                currency: CommonType.Currency;
                value: number;
              };
              depositsFee: {
                __typename: 'MoneyAmount';
                currency: CommonType.Currency;
                value: number;
              };
              redepositsAll: {
                __typename: 'MoneyAmount';
                currency: CommonType.Currency;
                value: number;
              };
              withdrawalsAll: {
                __typename: 'MoneyAmount';
                currency: CommonType.Currency;
                value: number;
              };
              withdrawalsFee: {
                __typename: 'MoneyAmount';
                currency: CommonType.Currency;
                value: number;
              };
            };
          };
        };
      };
};

export const GetRevShareStatisticDetailedTotalDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetRevShareStatisticDetailedTotal' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'currency' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Currency' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'end' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'referralNameSearch' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'revShareMediaItemId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'start' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Partner' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'referralLinkMediaItem' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'id' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'revShareMediaItemId',
                              },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: {
                                  kind: 'Name',
                                  value: 'ReferralLinkMediaItem',
                                },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value:
                                        'RevShareReferralLinkStatisticTotalData',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Company' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'referralLinkMediaItem' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'id' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'revShareMediaItemId',
                              },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: {
                                  kind: 'Name',
                                  value: 'ReferralLinkMediaItem',
                                },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value:
                                        'RevShareReferralLinkStatisticTotalData',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...RevShareReferralLinkStatisticTotalData.definitions,
    ...RevShareReferralLinkStatisticInfoData.definitions,
    ...MoneyAmountData.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useGetRevShareStatisticDetailedTotal__
 *
 * To run a query within a React component, call `useGetRevShareStatisticDetailedTotal` and pass it any options that fit your needs.
 * When your component renders, `useGetRevShareStatisticDetailedTotal` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRevShareStatisticDetailedTotal({
 *   variables: {
 *      currency: // value for 'currency'
 *      end: // value for 'end'
 *      referralNameSearch: // value for 'referralNameSearch'
 *      revShareMediaItemId: // value for 'revShareMediaItemId'
 *      start: // value for 'start'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetRevShareStatisticDetailedTotal(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetRevShareStatisticDetailedTotal,
    GetRevShareStatisticDetailedTotalVariables
  > &
    (
      | {
          variables: GetRevShareStatisticDetailedTotalVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetRevShareStatisticDetailedTotal,
    GetRevShareStatisticDetailedTotalVariables
  >(GetRevShareStatisticDetailedTotalDocument, options);
}
export function useGetRevShareStatisticDetailedTotalLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetRevShareStatisticDetailedTotal,
    GetRevShareStatisticDetailedTotalVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetRevShareStatisticDetailedTotal,
    GetRevShareStatisticDetailedTotalVariables
  >(GetRevShareStatisticDetailedTotalDocument, options);
}
export function useGetRevShareStatisticDetailedTotalSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    GetRevShareStatisticDetailedTotal,
    GetRevShareStatisticDetailedTotalVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    GetRevShareStatisticDetailedTotal,
    GetRevShareStatisticDetailedTotalVariables
  >(GetRevShareStatisticDetailedTotalDocument, options);
}
export type GetRevShareStatisticDetailedTotalHookResult = ReturnType<
  typeof useGetRevShareStatisticDetailedTotal
>;
export type GetRevShareStatisticDetailedTotalLazyQueryHookResult = ReturnType<
  typeof useGetRevShareStatisticDetailedTotalLazyQuery
>;
export type GetRevShareStatisticDetailedTotalSuspenseQueryHookResult =
  ReturnType<typeof useGetRevShareStatisticDetailedTotalSuspenseQuery>;
export type GetRevShareStatisticDetailedTotalQueryResult = Apollo.QueryResult<
  GetRevShareStatisticDetailedTotal,
  GetRevShareStatisticDetailedTotalVariables
>;
