import { useTranslation } from 'react-i18next';
import {
  AccessorKeyColumnDef,
  ColumnDef,
  createColumnHelper,
} from '@tanstack/react-table';
import { MultilineCellWithIcon } from 'ui/Table/components/MultilineCellWithIcon';
import { EXPAND_COLUMN_ID, EXPAND_COLUMN_WIDTH } from 'ui/Table/const';
import { CopyText } from 'ui/CopyText';
import { mapPromoCodeTypeToText } from 'helpers';
import { TranslationUserType } from 'types';
import {
  StatisticItemTypeName,
  PromoCodeStatisticItemTypeName,
} from 'pages/Statistic/pages/StatisticGeneral/types';
import { ExpandButton } from 'ui/Table/components/ExpandButton';
import { MayBeNullCell } from 'ui/Table/components/MayBeNullCell';
import { TableCellAlign } from 'ui/Table/components/TableRowCell/types';
import { getMoneyAmount } from 'utils/currency/getMoneyAmount';
import { CopyLink } from 'ui/CopyLink';
import { getStatisticRevShareItemOwnerToShow } from '../helpers';
import { ALL_COLUMNS, COLUMNS_SIZES } from '../../../const';
import { useCompanyRevShareStatisticItemsColumnManager } from './useCompanyRevShareStatisticItemsColumnManager';
import { CompanyRevShareStatisticTableData } from '../types';

interface UseCompanyRevShaveStatisticItemsColumnsReturnValue {
  visibleColumns: Array<ColumnDef<CompanyRevShareStatisticTableData>>;
  updateVisibleColumns: (newColumns: Array<string>) => void;
  optionalColumns: Array<
    AccessorKeyColumnDef<CompanyRevShareStatisticTableData>
  >;
  formColumns: Record<string, boolean>;
}

interface useCompanyRevShareStatisticItemsColumnsParams {
  handleExpandRow: (referralLinkId: string) => void;
}

export const useCompanyRevShareStatisticItemsColumns = ({
  handleExpandRow,
}: useCompanyRevShareStatisticItemsColumnsParams): UseCompanyRevShaveStatisticItemsColumnsReturnValue => {
  const { t } = useTranslation(['common', 'statistic']);
  const columnHelper = createColumnHelper<CompanyRevShareStatisticTableData>();

  /*
   *  We need to use any as generic argument while bug won't be fixed
   *  Issue https://github.com/TanStack/table/issues/4382
   */
  const accessorColumns: Array<
    AccessorKeyColumnDef<CompanyRevShareStatisticTableData, any>
  > = [
    columnHelper.accessor('id', {
      header: t('statistic:company.content.dataTable.revShare.mediaElement'),
      id: ALL_COLUMNS.id,
      cell: ({ row }) => {
        if (
          row.original.__typename ===
          StatisticItemTypeName.CompanyRevShareStatisticItem
        ) {
          if (row.depth > 0) {
            return (
              <MultilineCellWithIcon
                title={
                  <CopyLink
                    tooltipText={t('linkCopied')}
                    to={row.original.link}
                    target="_blank"
                  >
                    {row.original.link}
                  </CopyLink>
                }
                subTitle={t(
                  'statistic:company.content.dataTable.revShare.linkSubtitle'
                )}
              />
            );
          }

          return (
            <MultilineCellWithIcon
              title={row.original.name}
              subTitle={
                <CopyLink
                  tooltipText={t('linkCopied')}
                  to={row.original.link}
                  target="_blank"
                >
                  {row.original.link}
                </CopyLink>
              }
            />
          );
        }

        if (
          row.original.__typename ===
          PromoCodeStatisticItemTypeName.RevSharePromoCodeStatisticItem
        ) {
          return (
            <MultilineCellWithIcon
              title={
                <CopyText
                  tooltipText={t('statistic:company.action.hint.copyLink')}
                  truncateText
                  justifyBetween
                >
                  {row.original.token}
                </CopyText>
              }
              subTitle={mapPromoCodeTypeToText(
                row.original.type,
                TranslationUserType.Company
              )}
            />
          );
        }

        return null;
      },
      size: COLUMNS_SIZES.mediaNameSize,
      enablePinning: true,
    }),
    columnHelper.accessor('owner', {
      header: t('statistic:company.content.dataTable.revShare.owner'),
      id: ALL_COLUMNS.owner,
      cell: ({ row }) => {
        const parentRow = row.getParentRow();

        if (
          row.depth > 0 &&
          parentRow &&
          parentRow.original.__typename ===
            StatisticItemTypeName.CompanyRevShareStatisticItem &&
          parentRow.original.owner
        ) {
          return getStatisticRevShareItemOwnerToShow(parentRow.original.owner);
        }

        if (
          row.original.__typename ===
          StatisticItemTypeName.CompanyRevShareStatisticItem
        ) {
          return getStatisticRevShareItemOwnerToShow(row.original.owner);
        }

        return null;
      },
      minSize: COLUMNS_SIZES.ownerSize,
    }),
    columnHelper.accessor('statisticInfo.hits', {
      header: t('statistic:company.content.dataTable.revShare.hits'),
      id: ALL_COLUMNS.hits,
      cell: ({ renderValue }) => <MayBeNullCell value={renderValue()} />,
      minSize: COLUMNS_SIZES.default,
      meta: {
        align: TableCellAlign.Right,
      },
      enableSorting: true,
    }),
    columnHelper.accessor('statisticInfo.hosts', {
      header: t('statistic:company.content.dataTable.revShare.hosts'),
      id: ALL_COLUMNS.hosts,
      cell: ({ renderValue }) => <MayBeNullCell value={renderValue()} />,
      minSize: COLUMNS_SIZES.default,
      meta: {
        align: TableCellAlign.Right,
      },
      enableSorting: true,
    }),
    columnHelper.accessor('statisticInfo.registrations', {
      header: t('statistic:company.content.dataTable.revShare.registrations'),
      id: ALL_COLUMNS.registrations,
      cell: ({ renderValue }) => renderValue(),
      minSize: COLUMNS_SIZES.default,
      meta: {
        align: TableCellAlign.Right,
      },
      enableSorting: true,
    }),
    columnHelper.accessor('statisticInfo.firstDeposits', {
      header: t('statistic:company.content.dataTable.revShare.firstDeposits'),
      id: ALL_COLUMNS.firstDeposits,
      cell: ({ renderValue }) => renderValue(),
      minSize: COLUMNS_SIZES.default,
      meta: {
        align: TableCellAlign.Right,
      },
      enableSorting: true,
    }),
    columnHelper.accessor('statisticInfo.depositsAll', {
      header: t('statistic:company.content.dataTable.revShare.depositsAll'),
      id: ALL_COLUMNS.depositsAll,
      cell: ({ getValue }) => getMoneyAmount(getValue()),
      minSize: COLUMNS_SIZES.default,
      meta: {
        align: TableCellAlign.Right,
      },
      enableSorting: true,
    }),
    columnHelper.accessor('statisticInfo.withdrawalsAll', {
      header: t('statistic:company.content.dataTable.revShare.withdrawalsAll'),
      id: ALL_COLUMNS.withdrawalsAll,
      cell: ({ getValue }) => getMoneyAmount(getValue()),
      minSize: COLUMNS_SIZES.default,
      meta: {
        align: TableCellAlign.Right,
      },
    }),
    columnHelper.accessor('statisticInfo.depositsFee', {
      header: t('statistic:company.content.dataTable.revShare.depositsFee'),
      id: ALL_COLUMNS.depositsFee,
      cell: ({ getValue }) => getMoneyAmount(getValue()),
      minSize: COLUMNS_SIZES.default,
      meta: {
        align: TableCellAlign.Right,
      },
    }),
    columnHelper.accessor('statisticInfo.withdrawalsFee', {
      header: t('statistic:company.content.dataTable.revShare.withdrawalsFee'),
      id: ALL_COLUMNS.withdrawalsFee,
      cell: ({ getValue }) => getMoneyAmount(getValue()),
      minSize: COLUMNS_SIZES.default,
      meta: {
        align: TableCellAlign.Right,
      },
    }),
    columnHelper.accessor('statisticInfo.adminFee', {
      header: t('statistic:company.content.dataTable.revShare.adminFee'),
      id: ALL_COLUMNS.adminFee,
      cell: ({ getValue }) => getMoneyAmount(getValue()),
      minSize: COLUMNS_SIZES.default,
      meta: {
        align: TableCellAlign.Right,
      },
    }),
    columnHelper.accessor('statisticInfo.reward', {
      header: t('statistic:company.content.dataTable.revShare.reward'),
      id: ALL_COLUMNS.reward,
      cell: ({ getValue }) => getMoneyAmount(getValue()),
      minSize: COLUMNS_SIZES.default,
      meta: {
        align: TableCellAlign.Right,
      },
      enableSorting: true,
    }),
    columnHelper.accessor('statisticInfo.averageDeposit', {
      header: t('statistic:company.content.dataTable.revShare.averageDeposit'),
      id: ALL_COLUMNS.averageDeposit,
      cell: ({ getValue }) => getMoneyAmount(getValue()),
      minSize: COLUMNS_SIZES.default,
      meta: {
        align: TableCellAlign.Right,
      },
    }),
    columnHelper.accessor('statisticInfo.redepositsAll', {
      header: t('statistic:company.content.dataTable.revShare.redepositsAll'),
      id: ALL_COLUMNS.redepositsAll,
      cell: ({ getValue }) => getMoneyAmount(getValue()),
      minSize: COLUMNS_SIZES.default,
      meta: {
        align: TableCellAlign.Right,
      },
    }),

    columnHelper.accessor('statisticInfo.conversionHostsToRegPercent', {
      header: t('statistic:company.content.dataTable.revShare.hosts2reg'),
      id: ALL_COLUMNS.conversionHostsToRegPercent,
      cell: ({ getValue }) => `${getValue()}%`,
      minSize: COLUMNS_SIZES.default,
      meta: {
        align: TableCellAlign.Right,
      },
      enableSorting: true,
    }),
    columnHelper.accessor('statisticInfo.conversionRegToDepPercent', {
      header: t('statistic:company.content.dataTable.revShare.reg2dep'),
      id: ALL_COLUMNS.conversionRegToDepPercent,
      cell: ({ getValue }) => `${getValue()}%`,
      minSize: COLUMNS_SIZES.default,
      meta: {
        align: TableCellAlign.Right,
      },
      enableSorting: true,
    }),
    columnHelper.accessor('statisticInfo.numberOfRedeposits', {
      header: t(
        'statistic:company.content.dataTable.revShare.numberOfRedeposits'
      ),
      id: ALL_COLUMNS.numberOfRedeposits,
      cell: ({ renderValue }) => renderValue(),
      minSize: COLUMNS_SIZES.default,
      meta: {
        align: TableCellAlign.Right,
      },
    }),
  ];

  const {
    optionalColumns,
    visibleColumns: accessorVisibleColumns,
    updateVisibleColumns,
    formColumns,
  } = useCompanyRevShareStatisticItemsColumnManager({
    columns: accessorColumns,
  });

  /*
   *  We need to use any as generic argument while bug won't be fixed
   *  Issue https://github.com/TanStack/table/issues/4382
   */
  const visibleColumns: Array<
    ColumnDef<CompanyRevShareStatisticTableData, any>
  > = [
    columnHelper.display({
      id: EXPAND_COLUMN_ID,
      header: () => null,
      cell: ({ row }) => {
        const onClick = async () => {
          await handleExpandRow(row.original.id);
        };

        return row.getCanExpand() ? (
          <ExpandButton row={row} onClick={onClick} />
        ) : null;
      },
      size: EXPAND_COLUMN_WIDTH,
      enablePinning: true,
    }),
    ...accessorVisibleColumns,
  ];

  return {
    optionalColumns,
    visibleColumns,
    updateVisibleColumns,
    formColumns,
  };
};
