import { PartnerRevShareStatisticOrderByType } from 'types/generated/gql';
import { PartnerRevShareStatisticSortableColumnsType } from './types';

export const mapColumnToSortColumn: Record<
  keyof PartnerRevShareStatisticSortableColumnsType,
  PartnerRevShareStatisticOrderByType
> = {
  hits: PartnerRevShareStatisticOrderByType.Hits,
  hosts: PartnerRevShareStatisticOrderByType.Hosts,
  registrations: PartnerRevShareStatisticOrderByType.Registrations,
  firstDeposits: PartnerRevShareStatisticOrderByType.FirstDeposits,
  depositsAll: PartnerRevShareStatisticOrderByType.DepositsAll,
  reward: PartnerRevShareStatisticOrderByType.Reward,
  conversionHostsToRegPercent:
    PartnerRevShareStatisticOrderByType.ConversionHostsToRegPercent,
  conversionRegToDepPercent:
    PartnerRevShareStatisticOrderByType.ConversionRegToDepPercent,
};
