import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Logo } from 'components/Logo';
import { SPEC_HEADER_LARGE } from 'const';
import { VerticalLayout } from 'ui/VerticalLayout';
import { Footer } from 'components/Footer';
import { LoginTransferForm } from './components/LoginTransferForm';
import styles from './LoginTransfer.module.scss';

export const LoginTransfer: FC = () => {
  const { t } = useTranslation('auth');

  return (
    <VerticalLayout
      topBlock={<Logo className={styles.logo} />}
      centralBlock={
        <>
          <div className={styles.textBlock}>
            <h1 className={SPEC_HEADER_LARGE}>
              {t('migration.transferData.title')}
            </h1>
            <p>{t('migration.transferData.subtitle')}</p>
          </div>

          <LoginTransferForm />
        </>
      }
      bottomBlock={<Footer />}
    />
  );
};
