import { FC } from 'react';
import { FilterOption, SelectFilterType } from 'types';
import { SingleSelectFilter } from './components/SingleSelectFilter';
import { MultipleSelectFilter } from './components/MultipleSelectFilter';

interface SelectFilterProps {
  selectFilterType: SelectFilterType;
  type: string;
  title: string;
  options: Array<FilterOption>;
  loading: boolean;
  onChange: () => void;
  disabled: boolean;
  loadMore?: () => void;
}

export const SelectFilter: FC<SelectFilterProps> = ({
  type,
  title,
  options,
  loading,
  selectFilterType,
  onChange,
  disabled,
  loadMore,
}) => {
  if (selectFilterType === SelectFilterType.Single) {
    return (
      <SingleSelectFilter
        key={type}
        name={type}
        title={title}
        options={options}
        loading={loading}
        loadMore={loadMore}
        onChange={onChange}
        disabled={disabled}
      />
    );
  }

  return (
    <MultipleSelectFilter
      key={type}
      name={type}
      title={title}
      options={options}
      loading={loading}
      onChange={onChange}
      loadMore={loadMore}
      disabled={disabled}
    />
  );
};
