import { PartnerCpaStatisticOrderByType } from 'types/generated/gql';
import { PartnerCpaStatisticSortableColumnsType } from './types';

export const mapColumnToSortColumn: Record<
  keyof PartnerCpaStatisticSortableColumnsType,
  PartnerCpaStatisticOrderByType
> = {
  hits: PartnerCpaStatisticOrderByType.Hits,
  hosts: PartnerCpaStatisticOrderByType.Hosts,
  registrations: PartnerCpaStatisticOrderByType.Registrations,
  firstDeposits: PartnerCpaStatisticOrderByType.FirstDeposits,
  depositsAll: PartnerCpaStatisticOrderByType.DepositsAll,
  hold: PartnerCpaStatisticOrderByType.Hold,
  qualified: PartnerCpaStatisticOrderByType.Qualified,
  approved: PartnerCpaStatisticOrderByType.Approved,
  paid: PartnerCpaStatisticOrderByType.Paid,
  rejected: PartnerCpaStatisticOrderByType.Rejected,
  reward: PartnerCpaStatisticOrderByType.Reward,
  conversionHostsToRegPercent:
    PartnerCpaStatisticOrderByType.ConversionHostsToRegPercent,
  conversionRegToDepPercent:
    PartnerCpaStatisticOrderByType.ConversionRegToDepPercent,
};
