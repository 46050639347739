import { useMemo, useState } from 'react';
import { OnChangeFn, SortingState } from '@tanstack/react-table';
import { SortOrder } from '../../types/generated/gql';

export const useSort = <T>(columnsMap: Record<string, T>) => {
  const [tableSortingState, setTableSortingState] = useState<SortingState>([]);

  const handleSort: OnChangeFn<SortingState> = (state) => {
    setTableSortingState(state);
  };

  const querySortingState = useMemo(() => {
    if (!tableSortingState.length) {
      return undefined;
    }

    return {
      orderBy: columnsMap[tableSortingState[0].id],
      sortOrder: tableSortingState[0].desc ? SortOrder.Desc : SortOrder.Asc,
    };
  }, [tableSortingState, columnsMap]);

  return { onSort: handleSort, tableSortingState, querySortingState };
};
