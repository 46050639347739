import { FC } from 'react';
import classNames from 'classnames';
import { Icon } from 'ui/Icon';
import { IconComponent } from 'types';
import { LabelWithIconSize } from './const';
import { mapSizeToIconSize, mapSizeToClassName } from './helpers';
import styles from './LabelWithIcon.module.scss';

interface LabelWithIconProps {
  icon: IconComponent;
  children: string;
  size?: LabelWithIconSize;
  className?: string;
  iconClassName?: string;
}

export const LabelWithIcon: FC<LabelWithIconProps> = ({
  icon,
  children,
  size = LabelWithIconSize.Large,
  className,
  iconClassName,
}) => (
  <span
    className={classNames(
      styles.container,
      className,
      mapSizeToClassName[size]
    )}
  >
    <Icon
      iconComponent={icon}
      size={mapSizeToIconSize[size]}
      hasDefaultColor={false}
      iconClassName={iconClassName}
    />

    {children}
  </span>
);
