import { Currency, UserTypeName } from 'types';
import {
  PageInfo,
  RevSharePromoCodeStatisticItem,
  RevShareReferralLinkDetailStatisticInfo,
} from 'types/generated/gql';
import {
  GetPartnerRevShareReferralLinkPromoCodeStatistic,
  useGetPartnerRevShareReferralLinkPromoCodeStatisticLazyQuery,
} from '../queries/generated/GetPartnerRevShareReferralLinkPromoCodeStatistic';
import { ORIGIN_STATISTIC_ITEMS_PER_PAGE } from '../const';

interface useGetPartnerRevShareReferralLinkPromoCodesStatisticItemsQueryData {
  data: {
    statisticInfo: RevShareReferralLinkDetailStatisticInfo;
    promoCodes: Array<RevSharePromoCodeStatisticItem>;
  } | null;
  pageInfo?: PageInfo;
  hasMoreData?: boolean;
}

interface useGetPartnerRevShareReferralLinkPromoCodesStatisticItemsQueryParams {
  start: string;
  end: string;
  userId: string;
  currency: Currency;
}

export const useGetPartnerRevShareReferralLinkPromoCodesStatisticItemsQuery = ({
  start,
  end,
  currency,
  userId,
}: useGetPartnerRevShareReferralLinkPromoCodesStatisticItemsQueryParams) => {
  const [fetchPartnerRevShareReferralLinkPromoCodeStatistic] =
    useGetPartnerRevShareReferralLinkPromoCodeStatisticLazyQuery();

  const handleCompleted = (
    data?: GetPartnerRevShareReferralLinkPromoCodeStatistic
  ): useGetPartnerRevShareReferralLinkPromoCodesStatisticItemsQueryData | null => {
    const referralPromoCodesStatisticItems =
      data?.user.__typename === UserTypeName.Partner
        ? data.user.revShareReferralLinkPromoCodeStatistic
        : undefined;

    const promoCodesStatisticItems =
      referralPromoCodesStatisticItems?.promoCodes.edges.map((item) => ({
        id: item.node.id,
        type: item.node.type,
        token: item.node.token,
        statisticInfo: {
          ...item.node.statisticInfo,
          hosts: null,
          hits: null,
        },
        __typename: item.node.__typename,
      })) ?? [];

    const statisticInfo = referralPromoCodesStatisticItems?.statisticInfo;

    if (!statisticInfo || !promoCodesStatisticItems.length) {
      return null;
    }

    return {
      pageInfo: referralPromoCodesStatisticItems?.promoCodes.pageInfo,
      hasMoreData:
        referralPromoCodesStatisticItems?.promoCodes.pageInfo?.hasNextPage,
      data: {
        statisticInfo,
        promoCodes: promoCodesStatisticItems,
      },
    };
  };

  const handleFetch = async (referralLinkId: string, after?: string | null) => {
    const { data, loading, error } =
      await fetchPartnerRevShareReferralLinkPromoCodeStatistic({
        variables: {
          referralLinkId,
          start,
          end,
          first: ORIGIN_STATISTIC_ITEMS_PER_PAGE,
          userId,
          currency,
          after,
        },
        fetchPolicy: 'cache-first',
      });

    const parsedData = handleCompleted(data);

    return {
      data: parsedData ? parsedData.data : null,
      loading,
      error,
      hasMoreData: parsedData?.hasMoreData,
      pageInfo: parsedData?.pageInfo,
    };
  };

  return {
    fetchData: handleFetch,
  };
};
