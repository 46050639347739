import { FC, PropsWithChildren, useState } from 'react';
import { SortDirection } from '@tanstack/react-table';
import classnames from 'classnames';
import { getMinimalUnderBreakpoint } from 'helpers';
import { Breakpoints } from 'const';
import { Icon, IconSize } from 'ui/Icon';
import { TruncatedTextWithHint } from 'ui/TruncatedTextWithHint';
import { ReactComponent as TransferVerticalIcon } from 'assets/icons/line/TransferVertical.svg';
import styles from './TableColumnCell.module.scss';
import { TableCellAlign } from './types';
import { mapAlignToStyle, mapSortIcon } from './helpers';

interface TableColumnCellProps {
  isPinned?: boolean;
  left?: number;
  align?: TableCellAlign;
  className?: string;
  sortable?: boolean;
  sortDirection?: SortDirection | false;
  onSort?: () => undefined | ((event: unknown) => void);
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

export const TableColumnCell: FC<PropsWithChildren<TableColumnCellProps>> = ({
  children,
  isPinned,
  left,
  align = TableCellAlign.Left,
  className,
  sortable,
  onSort,
  sortDirection,
  onMouseEnter,
  onMouseLeave,
}) => {
  const isMobile = getMinimalUnderBreakpoint() === Breakpoints.Sm;
  const isTablet = getMinimalUnderBreakpoint() === Breakpoints.Md;
  const isDesktop = !isMobile && !isTablet;

  const [showSortIconOnHover, setShowSortIconOnHover] = useState(false);

  const showSortIcon =
    sortable && (!isDesktop || sortDirection || showSortIconOnHover);

  const handleMouseEnter = () => {
    if (!sortable) {
      return;
    }

    onMouseEnter?.();
    setShowSortIconOnHover(true);
  };

  const handleMouseLeave = () => {
    if (!sortable) {
      return;
    }

    onMouseLeave?.();
    setShowSortIconOnHover(false);
  };

  return (
    <div
      className={classnames(
        styles.container,
        mapAlignToStyle[align],
        {
          [styles.pinned]: isPinned,
          [styles.sortable]: sortable,
        },
        className
      )}
      style={{
        left: isPinned && left !== undefined ? `${left}px` : undefined,
      }}
      role="columnheader"
      tabIndex={sortable ? 0 : -1}
      onClick={sortable && onSort ? onSort() : undefined}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {typeof children === 'string' ? (
        <TruncatedTextWithHint text={children} />
      ) : (
        <div className={styles.content}>{children}</div>
      )}
      {showSortIcon && (
        <Icon
          iconComponent={
            sortDirection ? mapSortIcon[sortDirection] : TransferVerticalIcon
          }
          size={IconSize.Small}
        />
      )}
    </div>
  );
};
