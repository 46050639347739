import { t } from 'i18n';
import { ValidationHintRule } from '../ValidationHint/types';

export const getDefaultPasswordValidationHints =
  (): Array<ValidationHintRule> => [
    {
      name: 'minLength',
      regExp: /^.{9,20}$/,
      text: t('common:hint.password.passwordRequirement1'),
    },
    {
      name: 'number',
      regExp: /\d/,
      text: t('common:hint.password.passwordRequirement2'),
    },
    {
      name: 'lowercase',
      regExp: /[a-z]/,
      text: t('common:hint.password.passwordRequirement3'),
    },
    {
      name: 'uppercase',
      regExp: /[A-Z]/,
      text: t('common:hint.password.passwordRequirement4'),
    },
  ];
