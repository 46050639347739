import { FC } from 'react';
import { MediaType } from 'types';
import { ReferralLinkMediaTable } from '../ReferralLinkMediaTable';
import { PromoCodeMediaTable } from '../PromoCodeMediaTable';

interface StatisticTableByTypeProps {
  mediaType: MediaType;
}

export const MediaTableByType: FC<StatisticTableByTypeProps> = ({
  mediaType,
}) => {
  const map: Record<MediaType, JSX.Element> = {
    [MediaType.Links]: <ReferralLinkMediaTable />,
    [MediaType.PromoCodes]: <PromoCodeMediaTable />,
  };

  return map[mediaType];
};
