// TODO move to router https://eyeconweb.atlassian.net/browse/AMS-1421

import { Prettify } from 'utils/tsUtils/prettify';

export const AppRoute = {
  Main: '/',
  AllPaths: '*',
  Lang: {
    root: ':lang',
    Main: '',
    Login: 'login',
    Registration: 'registration',
    RegistrationPartner: 'registration/partner',
    RegistrationCompany: 'registration/company',
    Application: 'application',
    Statistic: {
      root: 'statistic',
      Cpa: {
        root: 'cpa',
        MediaItem: ':referralLinkMediaItemId',
      },
      RevShare: {
        root: 'rev-share',
        MediaItem: ':referralLinkMediaItemId',
      },
    },
    Tariffs: 'tariffs',
    TwoFactor: 'two-factor',
    TwoFactorConnected: 'two-factor-connected',
    EmptyRoute: 'empty',
    Media: {
      root: 'media',
      Links: {
        root: 'links',
      },
      PromoCodes: {
        root: 'promo-codes',
      },
    },
    Support: 'support',
    Profile: 'profile',
    CompanyPartners: 'company-partners',
    Payments: 'payments',
    AllPaths: '*',
    NotFoundPage: '404',
    Reapplication: 'reapplication',
    PasswordReset: 'password/reset',
    PasswordChange: 'password/change',
    TermsOfUse: 'rules',
    LoginTransfer: 'login-transfer',
    RegistrationTransferPartner: 'registration-transfer/partner',
    RegistrationTransferCompany: 'registration-transfer/company',
  },
} as const;

type ValueOf<T> = T[keyof T];

type RoutesRecursive<T> = T extends object
  ? ValueOf<{ [K in keyof T]: RoutesRecursive<T[K]> }>
  : T;

export type AppRouteValues = Prettify<RoutesRecursive<typeof AppRoute>>;
