import * as SchemaTypes from '../../../../../../../../../types/generated/gql';

import * as CommonType from 'types';
import { DocumentNode } from 'graphql';
import { CpaPromoCodeStatisticItemData } from './CpaPromoCodeStatisticItemData';
import { CpaPromoCodeStatisticInfoData } from './CpaPromoCodeStatisticInfoData';
import { MoneyAmountData } from '../../../../../../../../../queries/fragments/generated/MoneyAmountData';
import { PageInfoData } from '../../../../../../../../../queries/fragments/generated/PageInfoData';
export type CpaPromoCodeStatisticConnectionData = {
  __typename: 'CpaPromoCodeStatisticConnection';
  edges: Array<{
    __typename: 'CpaPromoCodeStatisticEdge';
    cursor: string;
    node: {
      __typename: 'CpaPromoCodeStatisticItem';
      id: string;
      token: string;
      type: SchemaTypes.PromoCodeMediaItemType;
      statisticInfo: {
        __typename: 'CpaPromoCodeStatisticInfo';
        approved: number;
        conversionHostsToRegPercent: number;
        conversionRegToDepPercent: number;
        firstDeposits: number;
        hold: number;
        numberOfRedeposits: number;
        paid: number;
        qualified: number;
        registrations: number;
        rejected: number;
        averageDeposit: {
          __typename: 'MoneyAmount';
          currency: CommonType.Currency;
          value: number;
        };
        depositsAll: {
          __typename: 'MoneyAmount';
          currency: CommonType.Currency;
          value: number;
        };
        redepositsAll: {
          __typename: 'MoneyAmount';
          currency: CommonType.Currency;
          value: number;
        };
        reward: {
          __typename: 'MoneyAmount';
          currency: CommonType.Currency;
          value: number;
        };
        withdrawalsAll: {
          __typename: 'MoneyAmount';
          currency: CommonType.Currency;
          value: number;
        };
      };
    };
  }>;
  pageInfo: {
    __typename: 'PageInfo';
    endCursor?: string | null;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    startCursor: string;
  };
};

export const CpaPromoCodeStatisticConnectionData = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CpaPromoCodeStatisticConnectionData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CpaPromoCodeStatisticConnection' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'edges' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'cursor' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'node' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'CpaPromoCodeStatisticItemData',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pageInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PageInfoData' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
