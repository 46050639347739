import { InputProps } from '../Input/Input';
import { ValidationHintRule } from './types';

interface GetValidationStateParams {
  rules: Array<ValidationHintRule>;
  value?: InputProps['value'];
}

export const getValidationState = ({
  rules,
  value,
}: GetValidationStateParams) =>
  rules.reduce((validationState, rule) => {
    const stringifiedValue = value?.toString();

    if (!stringifiedValue) {
      return {
        ...validationState,
        [rule.name]: false,
      };
    }

    return {
      ...validationState,
      [rule.name]: rule.regExp.test(stringifiedValue),
    };
  }, {});
