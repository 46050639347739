import { FC, ReactNode } from 'react';
import classnames from 'classnames';
import { Icon } from 'ui/Icon';
import { IconComponent } from 'types';
import { TruncatedTextWithHint } from 'ui/TruncatedTextWithHint';
import styles from './MultilineCellWithIcon.module.scss';

interface MultilineCellWithIconProps {
  title: ReactNode | string;
  icon?: IconComponent;
  subTitle?: ReactNode;
  isAlignRight?: boolean;
}

export const MultilineCellWithIcon: FC<MultilineCellWithIconProps> = ({
  title,
  icon,
  subTitle,
  isAlignRight = false,
}) => (
  <div
    className={classnames(styles.container, {
      [styles.rightAlign]: !icon && isAlignRight,
    })}
    data-testid="multi-line-cell-container"
  >
    {icon && (
      <div className={styles.iconContainer}>
        <Icon iconComponent={icon} />
      </div>
    )}

    <div className={styles.textContainer}>
      {typeof title === 'string' ? (
        <TruncatedTextWithHint className={styles.title} text={title} />
      ) : (
        title
      )}
      {subTitle && typeof subTitle === 'string' ? (
        <TruncatedTextWithHint className={styles.subTitle} text={subTitle} />
      ) : (
        subTitle
      )}
    </div>
  </div>
);
