import { FC } from 'react';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import styles from './NavLinksList.module.scss';
import { NavLinksListItem } from './types';
import { NavLinksListSize } from './const';
import { mapSizeToStyle } from './helpers';

interface NavLinksListProps {
  items: Array<NavLinksListItem>;
  size?: NavLinksListSize;
}

export const NavLinksList: FC<NavLinksListProps> = ({
  items,
  size = NavLinksListSize.Medium,
}) => (
  <div className={styles.container}>
    {items.map(({ path, title }) => (
      <NavLink
        key={path}
        className={({ isActive }) =>
          classNames(styles.link, mapSizeToStyle[size], {
            [styles.active]: isActive,
          })
        }
        to={path}
      >
        {title}
      </NavLink>
    ))}
  </div>
);
