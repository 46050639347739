import { NavLinksList, NavLinksListItem } from 'components/NavLinksList';
import { AppRoute } from 'const';
import { FC } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { composeRoutes } from 'utils/routing/composeRoutes';
import { useCreateLocalizedPath } from 'hooks/useCreateLocalizedPath';
import { useTranslationUserType } from 'hooks/useTranslationUserType';
import { NonNullableDateRange } from 'types';
import { getSharedSearchParamsUrl } from '../../helpers/getSharedSearchParamsUrl';

interface StatisticNavLinksListProps {
  nonNullableDateRange: NonNullableDateRange;
}

export const StatisticNavLinksList: FC<StatisticNavLinksListProps> = ({
  nonNullableDateRange,
}) => {
  const { t } = useTranslation('statistic');
  const [searchParams] = useSearchParams();
  const { createLocalizedPath } = useCreateLocalizedPath();
  const translationUserType = useTranslationUserType();

  const sharedSearchParams = getSharedSearchParamsUrl({
    searchParams,
    nonNullableDateRange,
  });

  const navLinksListItems: Array<NavLinksListItem> = [
    {
      title: t(`${translationUserType}.action.tabMenu.button.revShare`),
      path: createLocalizedPath(
        composeRoutes(
          [AppRoute.Lang.Statistic.root, AppRoute.Lang.Statistic.RevShare.root],
          {
            searchParams: sharedSearchParams,
          }
        )
      ),
    },
    {
      title: t(`${translationUserType}.action.tabMenu.button.cpa`),
      path: createLocalizedPath(
        composeRoutes(
          [AppRoute.Lang.Statistic.root, AppRoute.Lang.Statistic.Cpa.root],
          {
            searchParams: sharedSearchParams,
          }
        )
      ),
    },
  ];

  return <NavLinksList items={navLinksListItems} />;
};
