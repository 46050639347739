import { FC, useState } from 'react';
import { useField, useFormikContext } from 'formik';
import { ReactComponent as CloseIcon } from 'assets/icons/line/Close.svg';
import { DropdownPosition } from 'ui/Dropdown';
import { Skeleton } from 'ui/Skeleton';
import { DropdownWithButton } from 'ui/DropdownWithButton';
import { List, ListItem, ListSize } from 'ui/List';
import { blurActiveElement, focusElementBySelector } from 'helpers';
import { FilterOption } from 'types';
import { useInfiniteScroll } from 'hooks/useInfiniteScroll';
import { ButtonTheme } from 'ui/Button';

interface FilterPillProps {
  title: string;
  name: string;
  options: Array<FilterOption>;
  loading: boolean;
  onChange: () => void;
  loadMore?: () => void;
  disabled?: boolean;
}

export const SingleSelectFilter: FC<FilterPillProps> = ({
  title,
  name,
  options,
  loading,
  onChange,
  loadMore,
  disabled,
}) => {
  const [field, , setter] = useField(`filters.${name}`);
  const { handleSubmit } = useFormikContext();

  const [isOpen, setIsOpen] = useState(false);

  const loadTrigger = useInfiniteScroll({
    callback: loadMore,
    forceObserve: isOpen,
  });

  const handleSelect = (id: string) => {
    const newValue = {
      [id]: true,
    };

    setter.setValue(newValue);
    onChange();

    blurActiveElement();
  };

  const handleClean = () => {
    if (!field.value) return;

    setter.setValue(undefined);
    handleSubmit();

    focusElementBySelector('[data-list-button]');
  };

  const listItems: Array<ListItem> = options.map((option) => ({
    label: option.label,
    value: option.id,
    leftElement: option?.leftElement,
    onClick: () => handleSelect(option.id),
  }));

  if (loading) {
    return <Skeleton width={121} height={32} />;
  }

  if (!options.length) {
    return null;
  }

  return (
    <DropdownWithButton
      dropdownProps={{
        position: DropdownPosition.LeftBottom,
        title,
        onOpenChange: (state) => setIsOpen(state),
      }}
      buttonContent={title}
      buttonProps={{
        disabled,
        ...(field.value && {
          iconConfig: {
            iconComponent: CloseIcon,
            onClick: handleClean,
          },
        }),
        theme: ButtonTheme.BlackTonal,
      }}
    >
      <List items={listItems} size={ListSize.Large} highlightOnHover />
      {loadTrigger}
    </DropdownWithButton>
  );
};
