interface FilterSearchParams<T, K> {
  filterType: T;
  value: K;
}

export const createFilterSearchParams = <T, K>({
  filterType,
  value,
}: FilterSearchParams<T, K>) => ({
  [`filters[${filterType}]`]: value,
});
