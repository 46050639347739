import { format as formatDateFns } from 'date-fns';

export enum DateFormat {
  DateScalar = 'yyyy-MM-dd',
  FullDate = 'dd\u00A0MMM\u00A0yyyy, HH:mm',
  FullDateWithoutHours = 'dd\u00A0MMM\u00A0yyyy',
  DefaultDate = 'dd.MM.yyyy',
  Time = 'hh:mm:ss',
  Year = 'yyyy',
  Month = 'LLLL',
  DayMonth = 'd\u00A0MMMM',
  Day = 'd',
}

export const formatDate = (
  date: Date,
  format = DateFormat.DefaultDate
): string => {
  const formattedDate = formatDateFns(date, format);
  const shortMonthPattern = '\u00A0MMM\u00A0';

  if (format.includes(shortMonthPattern)) {
    return formattedDate.replace(/\./g, '');
  }

  return formattedDate;
};
