import { FormikErrors } from 'formik';
import { t } from 'i18n';
import { LoginTransferValues } from './types';

export const validateLoginTransfer = (
  values: LoginTransferValues
): FormikErrors<LoginTransferValues> => {
  const errors: FormikErrors<LoginTransferValues> = {};

  if (!values.emailOrPhoneNumber) {
    errors.emailOrPhoneNumber = t('errors.requiredField');
  }

  if (!values.password) {
    errors.password = t('errors.passwordError');
  }

  return errors;
};
