import { FilterCombination } from 'types';
import { CompanyPromoCodeMediaItemsFilterType } from 'types/generated/gql';

export const сompanyPromoCodeMediaItemsСombinations: FilterCombination<CompanyPromoCodeMediaItemsFilterType> =
  {
    [CompanyPromoCodeMediaItemsFilterType.MediaItemOwner]: [
      CompanyPromoCodeMediaItemsFilterType.Tariff,
      CompanyPromoCodeMediaItemsFilterType.PaymentModel,
      CompanyPromoCodeMediaItemsFilterType.PromoCodeType,
      CompanyPromoCodeMediaItemsFilterType.ReferralLink,
    ],
    [CompanyPromoCodeMediaItemsFilterType.PromoCodeType]: [
      CompanyPromoCodeMediaItemsFilterType.ReferralLink,
      CompanyPromoCodeMediaItemsFilterType.PaymentModel,
      CompanyPromoCodeMediaItemsFilterType.Tariff,
    ],
  };
