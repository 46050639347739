import {
  FC,
  FocusEvent,
  MouseEventHandler,
  PropsWithChildren,
  useState,
} from 'react';
import classNames from 'classnames';
import { ModalContainer } from 'ui/ModalContainer';
import { ReactComponent as IconClose } from 'assets/icons/line/Close.svg';
import { Button, ButtonSize, ButtonTheme } from 'ui/Button';
import styles from './BottomSheet.module.scss';

export interface BottomSheetProps {
  isOpen: boolean;
  onClose: MouseEventHandler<HTMLElement>;
  title: string;
  onAfterClose?: () => void;
  onBlur?: (e: FocusEvent<HTMLDivElement>) => void;
  contentClassName?: string;
}

export const BottomSheet: FC<PropsWithChildren<BottomSheetProps>> = ({
  isOpen,
  children,
  onClose,
  title,
  onAfterClose,
  onBlur,
  contentClassName,
}) => {
  const [isClosing, setIsClosing] = useState(false);

  const handleClose: MouseEventHandler<HTMLElement> = (e) => {
    if (isOpen) {
      setIsClosing(true);
      onClose(e);
    }
  };

  const handleAfterClose = () => {
    setIsClosing(false);
    onAfterClose?.();
  };

  return (
    <ModalContainer
      isOpen={isOpen && !isClosing}
      onRequestClose={handleClose}
      className={{
        base: styles.modalContainer,
        afterOpen: styles.afterOpen,
        beforeClose: styles.beforeClose,
      }}
      onAfterClose={handleAfterClose}
    >
      <div className={styles.container} onBlur={onBlur} tabIndex={-1}>
        <div className={styles.header}>
          <span className={styles.title}>{title}</span>
          <Button
            iconConfig={{ iconComponent: IconClose }}
            size={ButtonSize.Small}
            onClick={onClose}
            theme={ButtonTheme.BlackTonal}
            className={styles.closeButton}
          />
        </div>
        <div className={classNames(styles.content, contentClassName)}>
          {children}
        </div>
      </div>
    </ModalContainer>
  );
};
