import { useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownPosition, DropdownSize } from 'ui/Dropdown';
import {
  Button,
  ButtonSize,
  ButtonTheme,
  LinkStyledButtonTextColor,
} from 'ui/Button';
import { ReactComponent as MenuDotsIcon } from 'assets/icons/line/MenuDots.svg';
import { TextLink } from 'ui/TextLink';
import { useStatisticRoutesByCompanyPartnerId } from '../../../../hooks/useStatisticRoutesByCompanyPartnerId';
import styles from './LinkBoosters.module.scss';
import { useMediaRoutesByCompanyPartnerId } from '../../../../hooks/useMediaRoutesByCompanyPartnerId';

interface Props {
  companyPartnerId: string;
}

export const LinkBoosters = ({ companyPartnerId }: Props) => {
  const { t } = useTranslation('companyPartners');

  const [isActive, setIsActive] = useState<boolean>(false);

  const { revShareStatisticRoute, cpaStatisticRoute } =
    useStatisticRoutesByCompanyPartnerId(companyPartnerId);
  const { referralLinksRoute } =
    useMediaRoutesByCompanyPartnerId(companyPartnerId);

  const handleOpenChange = (isOpen: boolean) => {
    setIsActive(isOpen);
  };

  const links = [
    {
      to: revShareStatisticRoute,
      label: t('dropdown.label.statisticsRevShare'),
    },
    {
      to: cpaStatisticRoute,
      label: t('dropdown.label.statisticsCPA'),
    },
    {
      to: referralLinksRoute,
      label: t('dropdown.label.links'),
    },
  ];

  return (
    <div className={styles.container} onClick={(e) => e.stopPropagation()}>
      <Dropdown
        onOpenChange={handleOpenChange}
        position={DropdownPosition.RightBottom}
        title={t('dropdown.header.linkBoosters')}
        withStyleMaxContent={false}
        dropdownSize={DropdownSize.Small}
        trigger={
          <Button
            iconConfig={{
              iconComponent: MenuDotsIcon,
            }}
            size={ButtonSize.Small}
            theme={ButtonTheme.GreyGhost}
            className={classNames(styles.icon, {
              [styles.activeIcon]: isActive,
            })}
          />
        }
      >
        <div className={styles.dropdownContent}>
          {links.map(({ to, label }) => (
            <div key={to} className={styles.dropdownItem}>
              <TextLink
                tabIndex={0}
                className={styles.link}
                to={to}
                buttonClassName={styles.linkText}
                color={LinkStyledButtonTextColor.Black}
              >
                {label}
              </TextLink>
            </div>
          ))}
        </div>
      </Dropdown>
    </div>
  );
};
