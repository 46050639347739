import { useTranslation } from 'react-i18next';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { MultilineCellWithIcon } from 'ui/Table/components/MultilineCellWithIcon';
import { getMoneyAmount } from 'utils/currency/getMoneyAmount';
import { CopyLink } from 'ui/CopyLink';
import {
  StatisticItemTypeName,
  PromoCodeStatisticItemTypeName,
} from 'pages/Statistic/pages/StatisticGeneral/types';
import { CopyText } from 'ui/CopyText';
import { mapPromoCodeTypeToText } from 'helpers';
import { TranslationUserType } from 'types';
import { TableCellAlign } from 'ui/Table/components/TableRowCell/types';
import { EXPAND_COLUMN_ID, EXPAND_COLUMN_WIDTH } from 'ui/Table/const';
import { MayBeNullCell } from 'ui/Table/components/MayBeNullCell';
import { ExpandButton } from 'ui/Table/components/ExpandButton';
import { ALL_COLUMNS, COLUMNS_SIZES } from '../../../const';
import { PartnerRevShareStatisticTableData } from '../types';

interface usePartnerRevShareStatisticItemsColumnsParams {
  handleExpandRow: (referralLinkId: string) => void;
}

export const usePartnerRevShareStatisticItemsColumns = ({
  handleExpandRow,
}: usePartnerRevShareStatisticItemsColumnsParams): Array<
  ColumnDef<PartnerRevShareStatisticTableData>
> => {
  const { t } = useTranslation(['common', 'statistic']);
  const columnHelper = createColumnHelper<PartnerRevShareStatisticTableData>();

  /*
   *  We need to use any as generic argument while bug won't be fixed
   *  Issue https://github.com/TanStack/table/issues/4382
   */
  const columns: Array<ColumnDef<PartnerRevShareStatisticTableData, any>> = [
    columnHelper.display({
      id: EXPAND_COLUMN_ID,
      header: () => null,
      cell: ({ row }) => {
        const onClick = async () => {
          await handleExpandRow(row.original.id);
        };

        return row.getCanExpand() ? (
          <ExpandButton row={row} onClick={onClick} />
        ) : null;
      },
      size: EXPAND_COLUMN_WIDTH,
      enablePinning: true,
    }),
    columnHelper.accessor('id', {
      header: t('statistic:partner.content.dataTable.revShare.mediaElement'),
      cell: ({ row }) => {
        if (
          row.original.__typename ===
          StatisticItemTypeName.PartnerRevShareStatisticItem
        ) {
          if (row.depth > 0) {
            return (
              <MultilineCellWithIcon
                title={
                  <CopyLink
                    tooltipText={t('linkCopied')}
                    to={row.original.link}
                    target="_blank"
                  >
                    {row.original.link}
                  </CopyLink>
                }
                subTitle={t(
                  'statistic:partner.content.dataTable.cpa.linkSubtitle'
                )}
              />
            );
          }

          return (
            <MultilineCellWithIcon
              title={row.original.name}
              subTitle={
                <CopyLink
                  tooltipText={t('linkCopied')}
                  to={row.original.link}
                  target="_blank"
                >
                  {row.original.link}
                </CopyLink>
              }
            />
          );
        }

        if (
          row.original.__typename ===
          PromoCodeStatisticItemTypeName.RevSharePromoCodeStatisticItem
        ) {
          return (
            <MultilineCellWithIcon
              title={
                <CopyText
                  tooltipText={t('statistic:partner.action.hint.copyLink')}
                  justifyBetween
                  truncateText
                >
                  {row.original.token}
                </CopyText>
              }
              subTitle={mapPromoCodeTypeToText(
                row.original.type,
                TranslationUserType.Partner
              )}
            />
          );
        }

        return null;
      },
      size: COLUMNS_SIZES.mediaNameSize,
      enablePinning: true,
    }),
    columnHelper.accessor('statisticInfo.hits', {
      id: ALL_COLUMNS.hits,
      header: t('statistic:partner.content.dataTable.revShare.hits'),
      cell: ({ renderValue }) => <MayBeNullCell value={renderValue()} />,
      minSize: COLUMNS_SIZES.default,
      meta: {
        align: TableCellAlign.Right,
      },
      enableSorting: true,
    }),
    columnHelper.accessor('statisticInfo.hosts', {
      id: ALL_COLUMNS.hosts,
      header: t('statistic:partner.content.dataTable.revShare.hosts'),
      cell: ({ renderValue }) => <MayBeNullCell value={renderValue()} />,
      minSize: COLUMNS_SIZES.default,
      meta: {
        align: TableCellAlign.Right,
      },
      enableSorting: true,
    }),
    columnHelper.accessor('statisticInfo.registrations', {
      id: ALL_COLUMNS.registrations,
      header: t('statistic:partner.content.dataTable.revShare.registrations'),
      cell: ({ renderValue }) => renderValue(),
      minSize: COLUMNS_SIZES.default,
      meta: {
        align: TableCellAlign.Right,
      },
      enableSorting: true,
    }),
    columnHelper.accessor('statisticInfo.firstDeposits', {
      id: ALL_COLUMNS.firstDeposits,
      header: t('statistic:partner.content.dataTable.revShare.firstDeposits'),
      cell: ({ renderValue }) => renderValue(),
      minSize: COLUMNS_SIZES.default,
      meta: {
        align: TableCellAlign.Right,
      },
      enableSorting: true,
    }),
    columnHelper.accessor('statisticInfo.depositsAll', {
      id: ALL_COLUMNS.depositsAll,
      header: t('statistic:partner.content.dataTable.revShare.depositsAll'),
      cell: ({ getValue }) => getMoneyAmount(getValue()),
      minSize: COLUMNS_SIZES.default,
      meta: {
        align: TableCellAlign.Right,
      },
      enableSorting: true,
    }),
    columnHelper.accessor('statisticInfo.withdrawalsAll', {
      header: t('statistic:partner.content.dataTable.revShare.withdrawalsAll'),
      cell: ({ getValue }) => getMoneyAmount(getValue()),
      minSize: COLUMNS_SIZES.default,
      meta: {
        align: TableCellAlign.Right,
      },
    }),
    columnHelper.accessor('statisticInfo.depositsFee', {
      header: t('statistic:partner.content.dataTable.revShare.depositsFee'),
      cell: ({ getValue }) => getMoneyAmount(getValue()),
      minSize: COLUMNS_SIZES.default,
      meta: {
        align: TableCellAlign.Right,
      },
    }),
    columnHelper.accessor('statisticInfo.withdrawalsFee', {
      header: t('statistic:partner.content.dataTable.revShare.withdrawalsFee'),
      cell: ({ getValue }) => getMoneyAmount(getValue()),
      minSize: COLUMNS_SIZES.default,
      meta: {
        align: TableCellAlign.Right,
      },
    }),
    columnHelper.accessor('statisticInfo.adminFee', {
      header: t('statistic:partner.content.dataTable.revShare.adminFee'),
      cell: ({ getValue }) => getMoneyAmount(getValue()),
      minSize: COLUMNS_SIZES.default,
      meta: {
        align: TableCellAlign.Right,
      },
    }),
    columnHelper.accessor('statisticInfo.reward', {
      id: ALL_COLUMNS.reward,
      header: t('statistic:partner.content.dataTable.revShare.reward'),
      cell: ({ getValue }) => getMoneyAmount(getValue()),
      minSize: COLUMNS_SIZES.default,
      meta: {
        align: TableCellAlign.Right,
      },
      enableSorting: true,
    }),
    columnHelper.accessor('statisticInfo.averageDeposit', {
      header: t('statistic:partner.content.dataTable.revShare.averageDeposit'),
      cell: ({ getValue }) => getMoneyAmount(getValue()),
      minSize: COLUMNS_SIZES.default,
      meta: {
        align: TableCellAlign.Right,
      },
    }),
    columnHelper.accessor('statisticInfo.redepositsAll', {
      header: t('statistic:partner.content.dataTable.revShare.redepositsAll'),
      cell: ({ getValue }) => getMoneyAmount(getValue()),
      minSize: COLUMNS_SIZES.default,
      meta: {
        align: TableCellAlign.Right,
      },
    }),

    columnHelper.accessor('statisticInfo.conversionHostsToRegPercent', {
      id: ALL_COLUMNS.conversionHostsToRegPercent,
      header: t('statistic:partner.content.dataTable.revShare.hosts2reg'),
      cell: ({ getValue }) => `${getValue()}%`,
      minSize: COLUMNS_SIZES.default,
      meta: {
        align: TableCellAlign.Right,
      },
      enableSorting: true,
    }),
    columnHelper.accessor('statisticInfo.conversionRegToDepPercent', {
      id: ALL_COLUMNS.conversionRegToDepPercent,
      header: t('statistic:partner.content.dataTable.revShare.reg2dep'),
      cell: ({ getValue }) => `${getValue()}%`,
      minSize: COLUMNS_SIZES.default,
      meta: {
        align: TableCellAlign.Right,
      },
      enableSorting: true,
    }),
    columnHelper.accessor('statisticInfo.numberOfRedeposits', {
      header: t(
        'statistic:partner.content.dataTable.revShare.numberOfRedeposits'
      ),
      cell: ({ renderValue }) => renderValue(),
      minSize: COLUMNS_SIZES.default,
      meta: {
        align: TableCellAlign.Right,
      },
    }),
  ];

  return columns;
};
