import { FC } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Table } from 'ui/Table';
import { useLoadMore } from 'hooks/useLoadMore';
import { composeRoutes } from 'utils/routing/composeRoutes';
import { AppRoute } from 'const';
import { NonNullableDateRange } from 'types';
import { CompanyStatisticFilterType } from 'types/generated/gql';
import { useFiltersHandlers } from 'hooks/table/useFiltersHandlers';
import { useCreateLocalizedPath } from 'hooks/useCreateLocalizedPath';
import { useSort } from 'hooks/table/useSort';
import { useGetCompanyCpaStatisticItemsQuery } from './hooks/useGetCompanyCpaStatisticItemsQuery';
import { useCompanyCpaStatisticItemsColumns } from './hooks/useCompanyCpaStatisticItemsColumns';
import { useGenerateCompanyCpaStatisticSummaryRow } from './hooks/useGenerateCompanyCpaStatisticSummaryRow';
import { CompanyCpaStatisticTableSearch } from './components/CompanyCpaStatisticTableSearch';
import { CompanyCpaStatisticTableData } from './types';
import { getSharedSearchParamsUrl } from '../../../../helpers/getSharedSearchParamsUrl';
import { mapColumnToSortColumn } from './helpers';

interface CompanyCpaStatisticTableProps {
  nonNullableDateRange: NonNullableDateRange;
}

export const CompanyCpaStatisticTable: FC<CompanyCpaStatisticTableProps> = ({
  nonNullableDateRange,
}) => {
  const { t } = useTranslation('statistic');
  const [searchParams] = useSearchParams();
  const { createLocalizedPath } = useCreateLocalizedPath();
  const { parseUrlFilters } = useFiltersHandlers();
  const { tableSortingState, onSort, querySortingState } = useSort(
    mapColumnToSortColumn
  );

  const searchQuery = searchParams.has('search')
    ? (searchParams.get('search') ?? '')
    : '';

  const sharedSearchParams = getSharedSearchParamsUrl({
    searchParams,
    nonNullableDateRange,
  });

  const navigate = useNavigate();

  const { filters, formFilters } =
    parseUrlFilters<CompanyStatisticFilterType>();

  const {
    statisticItems,
    fetchMore,
    pageInfo,
    loading: itemsQueryLoading,
    error: itemsQueryError,
    isSliced,
    handleExpandRow,
  } = useGetCompanyCpaStatisticItemsQuery({
    nonNullableDateRange,
    searchQuery,
    filters,
    sortState: querySortingState,
  });

  const loadMore = useLoadMore(fetchMore, pageInfo);

  const { visibleColumns, optionalColumns, updateVisibleColumns, formColumns } =
    useCompanyCpaStatisticItemsColumns({ handleExpandRow });

  const {
    summaryRowData,
    loading: totalQueryLoading,
    error: totalQueryError,
  } = useGenerateCompanyCpaStatisticSummaryRow({
    nonNullableDateRange,
    visibleColumns,
    searchQuery,
    filters,
  });

  const loading = itemsQueryLoading || totalQueryLoading;
  const error = itemsQueryError || totalQueryError;

  const handleClickOnRow = ({ id }: CompanyCpaStatisticTableData) => {
    const path = createLocalizedPath(
      composeRoutes(
        [AppRoute.Lang.Statistic.root, AppRoute.Lang.Statistic.Cpa.root, id],
        {
          searchParams: sharedSearchParams,
        }
      )
    );

    navigate(path);
  };

  const tableData = statisticItems ?? [];

  return (
    <Table
      onRowClick={handleClickOnRow}
      columns={visibleColumns}
      data={tableData}
      error={error}
      emptyText={t('company.content.placeholder.noData')}
      summaryRow={summaryRowData}
      hasSearchParams={!!searchQuery || !!filters.length}
      isLoading={loading || isSliced}
      hasMoreData={!!pageInfo?.hasNextPage}
      emptySearchStateConfig={{
        title: t('company.content.placeholder.noDataHeader'),
        description: t('company.content.placeholder.noDataSubheader'),
      }}
      errorStateTitle={t('placeholder.loadingError.loadingErrorHeader')}
      errorStateDescription={t(
        'placeholder.loadingError.loadingErrorSubheader'
      )}
      fetchMore={loadMore}
      sortingState={tableSortingState}
      onSort={onSort}
      searchComponent={
        <CompanyCpaStatisticTableSearch
          columns={optionalColumns}
          initialValues={{
            search: searchQuery,
            columns: formColumns,
            filters: formFilters,
          }}
          onColumnsChange={updateVisibleColumns}
        />
      }
    />
  );
};
