import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonSize, ButtonTheme } from 'ui/Button';
import { ReactComponent as TrashIcon } from 'assets/icons/line/Trash.svg';
import { Tooltip } from 'ui/Tooltip';
import { useTranslationUserType } from 'hooks/useTranslationUserType';
import styles from './ButtonsBlock.module.scss';
import { getTooltipText } from './helpers';
import { CreationState } from '../../../../types';

interface ButtonsBlockProps {
  onRemoveButtonClick: () => void;
  shouldShowRemoveButton: boolean;
  creationState?: CreationState;
  isValid: boolean;
  isDirty: boolean;
  isDeleteLoading?: boolean;
  isSubmitting?: boolean;
}

export const ButtonsBlock: FC<ButtonsBlockProps> = ({
  onRemoveButtonClick,
  shouldShowRemoveButton,
  creationState,
  isDirty,
  isValid,
  isDeleteLoading,
  isSubmitting,
}) => {
  const { t } = useTranslation('media');
  const translationUserType = useTranslationUserType();

  return (
    <div className={styles.buttonsBlock} data-testid="buttons-block">
      {shouldShowRemoveButton && (
        <Button
          disabled={isSubmitting}
          isLoading={isDeleteLoading}
          onClick={onRemoveButtonClick}
          size={ButtonSize.Medium}
          theme={ButtonTheme.BlackGhost}
          iconConfig={{
            before: true,
            iconComponent: TrashIcon,
          }}
        >
          {t(
            `${translationUserType}.content.drawer.details.postback.action.button.card.delete`
          )}
        </Button>
      )}
      <Tooltip
        placement="right"
        tooltipContent={getTooltipText({
          isDirty,
          isValid,
          creationState,
          isLoading: !!isSubmitting,
        })}
      >
        <Button
          disabled={
            creationState?.isCreationInProcess ||
            !isValid ||
            !isDirty ||
            isDeleteLoading
          }
          isLoading={isSubmitting}
          type="submit"
          size={ButtonSize.Medium}
          theme={ButtonTheme.BlackTonal}
        >
          {t(
            `${translationUserType}.content.drawer.details.postback.action.button.card.save`
          )}
        </Button>
      </Tooltip>
    </div>
  );
};
