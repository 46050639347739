import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as MediaIcon } from 'assets/icons/colors/Image.svg';
import { useOutletContext } from 'react-router';
import { TopBar } from 'components/TopBar';
import { Error } from 'ui/Error';
import { SubHeaderBlock } from 'components/SubHeaderBlock';
import { MediaNavLinkList } from '../MediaNavLinkList';
import { useOpenReferralLinkModal } from '../../hooks/useOpenReferralLinkModal';
import { MediaTableByType } from './components/MediaTableByType';
import { MediaOutletContext } from '../../types';

export const MediaDataBlock: FC = () => {
  const { t } = useTranslation('media');
  const { openCreateModal } = useOpenReferralLinkModal();
  const { mediaType } = useOutletContext<MediaOutletContext>();

  if (!mediaType) {
    return <Error />;
  }

  return (
    <>
      <TopBar
        title={t('placeholder.header.header')}
        icon={MediaIcon}
        action={{
          buttonText: t('placeholder.action.button.addMedia'),
          onClick: openCreateModal,
        }}
      />
      <SubHeaderBlock>
        <MediaNavLinkList />
      </SubHeaderBlock>
      <MediaTableByType mediaType={mediaType} />
    </>
  );
};
