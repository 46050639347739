import {
  DatePicker,
  DatePickerHandleProps,
  DatePickerRange,
} from 'ui/DatePicker';
import { Button, ButtonSize, ButtonTheme } from 'ui/Button';
import classnames from 'classnames';
import { getMinimalUnderBreakpoint } from 'helpers';
import { Breakpoints } from 'const';
import { useDatePickerWithRange } from './hooks/useDatePickerWithRange';
import { ButtonAlign } from '../Button/const';
import { DateRangeTitle } from './components/DateRangeTitle';
import { DateRanges } from './const';
import styles from './DatePickerWithRange.module.scss';

export interface DatePickerWithRangeProps {
  onDateSubmit: (date: DatePickerRange) => void;
  setActiveCustomRange: (range: DateRanges | null) => void;
  activeCustomRange?: DateRanges | null;
  disabled?: boolean;
  name?: string;
  openInitialDate?: Date;
  minDateInitial?: Date | null;
  startDateInitial?: Date | null;
  endDateInitial?: Date | null;
  onChange?: (date: DatePickerRange) => void;
  dateResetTo?: DatePickerHandleProps;
}

export const DatePickerWithRange = ({
  onDateSubmit,
  endDateInitial,
  startDateInitial,
  dateResetTo,
  minDateInitial,
  openInitialDate,
  activeCustomRange,
  setActiveCustomRange,
  ...rest
}: DatePickerWithRangeProps) => {
  const {
    customRanges,
    startDate,
    endDate,
    handleSubmit,
    handleChange,
    dateTitleRange,
  } = useDatePickerWithRange({
    minDateInitial,
    onDateSubmit,
    startDateInitial,
    endDateInitial,
    activeCustomRange,
    setActiveCustomRange,
    dateResetTo,
  });
  const [formattedStartDate, formattedEndDate] = dateTitleRange;

  const isMobile = getMinimalUnderBreakpoint() === Breakpoints.Sm;
  const isTablet = getMinimalUnderBreakpoint() === Breakpoints.Md;

  const showSmallButtonSize = isMobile || isTablet;

  return (
    <div className={styles.datePickerContainer}>
      <DateRangeTitle
        startDate={formattedStartDate}
        endDate={formattedEndDate}
      />
      <div className={styles.container}>
        <div className={styles.rangesContainer}>
          {customRanges.map(({ title, onClick, id }) => (
            <Button
              align={ButtonAlign.Left}
              key={id}
              size={showSmallButtonSize ? ButtonSize.Small : ButtonSize.Medium}
              theme={ButtonTheme.GreyGhost}
              className={classnames(styles.range, {
                [styles.active]: activeCustomRange === id,
              })}
              onClick={onClick}
            >
              {title}
            </Button>
          ))}
        </div>
        <div className={styles.datePicker}>
          <DatePicker
            {...rest}
            openInitialDate={openInitialDate}
            isSelectsRange
            className={styles.dropdown}
            onChange={handleChange}
            startDateInitial={startDate}
            dateResetTo={dateResetTo}
            endDateInitial={endDate}
            onSubmit={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
};
