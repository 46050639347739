import { useEffect, useState } from 'react';
import { useAuth } from 'components/auth';
import { FilterType, NonNullableDateRange, UserTypeName } from 'types';
import {
  CompanyCpaStatisticSortInput,
  CompanyStatisticFilterType,
  PageInfo,
} from 'types/generated/gql';
import { DateFormat, formatDate } from 'utils/formatDate';
import { ExtendedTData } from 'ui/Table/types';
import { STATISTIC_ITEMS_PER_PAGE } from '../const';
import {
  GetCompanyCpaStatisticItems,
  useGetCompanyCpaStatisticItems,
} from '../queries/generated/GetCompanyCpaStatisticItems';
import { CompanyCpaStatisticTableData } from '../types';
import { useCompanyCpaStatisticExpandedRows } from './useCompanyCpaStatisticExpandedRows';

const { DateScalar } = DateFormat;

interface UseGetCompanyCpaStatisticItemsQueryParams {
  nonNullableDateRange: NonNullableDateRange;
  filters: Array<FilterType<CompanyStatisticFilterType>>;
  searchQuery?: string;
  sortState?: CompanyCpaStatisticSortInput;
}

export const useGetCompanyCpaStatisticItemsQuery = ({
  nonNullableDateRange,
  searchQuery,
  filters,
  sortState,
}: UseGetCompanyCpaStatisticItemsQueryParams) => {
  const { user } = useAuth();

  const [start, end] = nonNullableDateRange;

  const [parsedData, setParsedData] = useState<
    Array<ExtendedTData<CompanyCpaStatisticTableData>>
  >([]);

  const [pageInfoState, setPageInfoState] = useState<PageInfo>();

  const handleCompleted = (data: GetCompanyCpaStatisticItems) => {
    const statisticItemsConnection =
      data?.user.__typename === UserTypeName.Company
        ? data.user.cpaStatistic.statisticItems
        : undefined;

    const newEdges = statisticItemsConnection?.edges;

    const statisticItems =
      newEdges?.map((item) => ({
        ...item.node,
        canExpand: item.node.hasPromoCodeStatistic,
      })) ?? [];

    const pageInfo = statisticItemsConnection?.pageInfo;

    setParsedData((prevData) =>
      statisticItems.map((item, index) => {
        const prevItem = prevData[index];

        if (prevItem?.subRows?.length) {
          return {
            ...item,
            subRows: prevItem.subRows,
          };
        }

        return item;
      })
    );

    setPageInfoState(pageInfo);
  };

  const { handleExpandRow } = useCompanyCpaStatisticExpandedRows({
    parentRowsData: parsedData,
    setParentsRowsData: setParsedData,
    start: formatDate(start, DateScalar),
    end: formatDate(end, DateScalar),
    userId: user.id,
  });

  const {
    data: queryData,
    fetchMore,
    loading,
    isSliced,
    error,
  } = useGetCompanyCpaStatisticItems({
    variables: {
      start: formatDate(start, DateScalar),
      end: formatDate(end, DateScalar),
      first: STATISTIC_ITEMS_PER_PAGE,
      userId: user.id,
      filters,
      sort: sortState,
      ...(Boolean(searchQuery) && { referralLinkSearch: searchQuery }),
    },
    onCompleted: handleCompleted,

    notifyOnNetworkStatusChange: true,
    cacheFirstOnPop: true,
  });

  useEffect(() => {
    if (parsedData.length && !queryData) {
      setParsedData([]);
    }
  }, [parsedData.length, queryData]);

  return {
    statisticItems: parsedData,
    fetchMore,
    pageInfo: pageInfoState,
    loading,
    isSliced,
    error,
    handleExpandRow,
  };
};
