import { ReactNode, useEffect, useState } from 'react';
import {
  ColumnDef,
  getCoreRowModel,
  getExpandedRowModel,
  OnChangeFn,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import classNames from 'classnames';
import { ApolloError } from '@apollo/client';
import { TableBody } from './components/TableBody';
import { TableHeader } from './components/TableHeader';
import { LoadMoreStatus } from './components/LoadMoreStatus';
import styles from './Table.module.scss';
import { SearchBlock } from './components/SearchBlock';
import { EmptyStateConfig, ExtendedTData } from './types';
import { EXPAND_COLUMN_ID } from './const';
import { getEmptyState } from './helpers';

interface TableProps<TData> {
  columns: Array<ColumnDef<TData>>;
  data: Array<TData>;
  isLoading: boolean;
  hasMoreData: boolean;
  fetchMore: () => void;
  emptyText: string;
  error?: ApolloError;
  summaryRow?: Array<ReactNode>;
  onRowClick?: (arg: TData) => void;
  searchComponent?: ReactNode;
  hasSearchParams?: boolean;
  emptySearchStateConfig?: EmptyStateConfig;
  errorStateTitle?: string;
  errorStateDescription?: string;
  sortingState?: SortingState;
  onSort?: OnChangeFn<SortingState>;
}

export const Table = <TData,>({
  columns,
  data,
  isLoading,
  hasMoreData,
  fetchMore,
  error,
  summaryRow,
  onRowClick,
  emptyText,
  searchComponent,
  hasSearchParams = false,
  emptySearchStateConfig,
  errorStateTitle,
  errorStateDescription,
  sortingState,
  onSort,
}: TableProps<ExtendedTData<TData>>) => {
  const [hoveredColumnId, setHoveredColumnId] = useState<string | null>(null);

  const tableInstance = useReactTable({
    data,
    columns,
    autoResetExpanded: false,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSubRows: (originalRow) => originalRow.subRows,
    getRowCanExpand: (row) => Boolean(row.original.canExpand),
    defaultColumn: {
      size: undefined,
      minSize: undefined,
    },
    manualSorting: true,
    enableSortingRemoval: true,
    state: {
      sorting: sortingState,
    },
    onSortingChange: onSort,
    sortDescFirst: true,
  });

  const canSomeRowsExpand = tableInstance.getCanSomeRowsExpand();

  const { isEmpty, isLoadingEmptyState, title, description, icon } =
    getEmptyState({
      data,
      isLoading,
      emptyText,
      error,
      hasSearchParams,
      emptySearchStateConfig,
    });

  const onShowSortButtonByColumnId = (columnId: string | null) => {
    setHoveredColumnId(columnId);
  };

  useEffect(() => {
    const expandColumn = tableInstance
      .getAllColumns()
      .find((col) => col.id === EXPAND_COLUMN_ID);

    if (expandColumn) {
      expandColumn.toggleVisibility(canSomeRowsExpand);
    }

    return () => {
      tableInstance.resetExpanded();
    };
  }, [canSomeRowsExpand, tableInstance]);

  return (
    <div className={styles.container} role="table">
      <div className={styles.content}>
        <SearchBlock searchComponent={searchComponent} />
        <div
          className={classNames(styles.table, {
            [styles.withEmpty]: isEmpty || isLoadingEmptyState,
          })}
        >
          <TableHeader
            canSomeRowsExpand={canSomeRowsExpand}
            columns={tableInstance.getVisibleFlatColumns()}
            headerGroups={tableInstance.getHeaderGroups()}
            onShowSortButtonByColumnId={onShowSortButtonByColumnId}
          />
          <TableBody
            error={error}
            summaryRow={summaryRow}
            isEmpty={isEmpty}
            isLoading={isLoadingEmptyState}
            columns={tableInstance.getAllColumns()}
            rows={tableInstance.getExpandedRowModel().rows}
            fetchMore={fetchMore}
            onRowClick={onRowClick}
            emptyStateConfig={{
              title,
              description,
              icon,
            }}
            errorStateTitle={errorStateTitle}
            errorStateDescription={errorStateDescription}
            canSomeRowsExpand={canSomeRowsExpand}
            hoveredColumnId={hoveredColumnId}
          />
        </div>
      </div>
      {!isEmpty && !isLoadingEmptyState && (
        <div className={styles.loadMoreStatusContainer}>
          <LoadMoreStatus isLoading={isLoading} hasMoreData={hasMoreData} />
        </div>
      )}
    </div>
  );
};
