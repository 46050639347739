import { FC, Fragment, ReactNode, useState } from 'react';
import classnames from 'classnames';
import { Tab } from './types';
import { TabsSize } from './const';
import { mapSizeToStyle } from './helpers';
import styles from './Tabs.module.scss';

interface TabsProps {
  tabs: Array<Tab>;
  size?: TabsSize;
  defaultActiveTab?: number;
  tabsControlsClass?: string;
  onBeforeTabChange?: () => Promise<boolean>;
  rightBlock?: ReactNode;
  onTabChange?: () => void;
}

export const Tabs: FC<TabsProps> = ({
  tabs,
  onBeforeTabChange,
  size = TabsSize.Medium,
  defaultActiveTab = 0,
  tabsControlsClass,
  rightBlock,
  onTabChange,
}) => {
  const [activeTab, setActiveTab] = useState<number>(defaultActiveTab);

  const handleClick = async (index: number) => {
    if (activeTab === index) {
      return;
    }

    try {
      const shouldChangeTab = onBeforeTabChange
        ? await onBeforeTabChange()
        : true;

      if (shouldChangeTab) {
        setActiveTab(index);
        onTabChange?.();
      }
      // eslint-disable-next-line no-empty
    } catch {}
  };

  return (
    <>
      <div className={classnames(styles.container, tabsControlsClass)}>
        <div className={styles.tabs}>
          {tabs.map(({ title }, index) => {
            const isActive = index === activeTab;

            if (!title) return null;

            return (
              <button
                type="button"
                onClick={() => handleClick(index)}
                key={title.toString()}
                className={classnames(styles.tab, mapSizeToStyle[size], {
                  [styles.active]: isActive,
                })}
              >
                {title}
              </button>
            );
          })}
        </div>
        {rightBlock}
      </div>
      {tabs.map(({ title, content }, index) => {
        if (index === activeTab) {
          return <Fragment key={title.toString()}>{content}</Fragment>;
        }

        return null;
      })}
    </>
  );
};
