import { t } from 'i18n';
import { TranslationUserType } from 'types';
import { PostbackEvent, PostbackMacros } from 'types/generated/gql';
import { mapAllowedMacros } from './mapAllowedMacros';
import { mapRequiredMarcos } from './mapRequiredMacros';
import { getRequiredErrors } from './getRequiredErrors';

interface ValidateMacrosParams {
  event: PostbackEvent;
  macros: Array<PostbackMacros>;
  translationUserType: TranslationUserType;
}

export const validateMacros = ({
  event,
  macros,
  translationUserType,
}: ValidateMacrosParams) => {
  const hasInvalidMacros = macros.some(
    (macro) => !mapAllowedMacros[event].includes(macro)
  );

  const requiredMacros = mapRequiredMarcos[event].filter(
    (macro) => !macros.includes(macro)
  );

  if (!requiredMacros.length && hasInvalidMacros) {
    return t(
      `media:${translationUserType}.content.drawer.details.postback.card.input.validation.invalidUrlMarcos`
    );
  }

  if (requiredMacros.length && hasInvalidMacros) {
    return `${getRequiredErrors(requiredMacros, translationUserType)}${t(
      `media:${translationUserType}.content.drawer.details.postback.card.input.validation.invalidUrlMarcos`
    )}`;
  }

  if (requiredMacros.length && !hasInvalidMacros) {
    return getRequiredErrors(requiredMacros, translationUserType);
  }

  return undefined;
};
